import React from "react";
import {
  Datagrid,
  List,
  TextField,
  NumberField,
  DateField,
  BooleanField,
  FunctionField,
} from "react-admin";
import ContadorArquivoEnviarEmailButton from "./ContadorArquivoEnviarEmailButton";

const ContadorArquivoList = (props) => (
  <List {...props} sort={{ field: "id", order: "DESC" }} exporter={false}>
    <Datagrid>
      <NumberField source="id" label="#" />
      <FunctionField
        render={(record) =>
          new Date(record.anoMesReferencia).toLocaleDateString("pt-BR", {
            timeZone: "UTC",
            month: "long",
            year: "numeric",
          })
        }
        label="Mês Referência"
      />
      <BooleanField source="email_enviado" label="Email Enviado" />
      <DateField
        source="enviadoEm"
        label="Enviado em"
        showTime
        locales="pt-BR"
      />
      <TextField source="erro" />
      <ContadorArquivoEnviarEmailButton />
    </Datagrid>
  </List>
);

export default ContadorArquivoList;
