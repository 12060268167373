import React from "react";
import aleloURL from "../../app/assets/payment-flat-rounded/alelo.svg";
import amexURL from "../../app/assets/payment-flat-rounded/amex.svg";
import sodexoURL from "../../app/assets/payment-flat-rounded/sodexo.svg";
import ticketURL from "../../app/assets/payment-flat-rounded/ticket.svg";
import verdecardURL from "../../app/assets/payment-flat-rounded/verdecard.svg";
import vrURL from "../../app/assets/payment-flat-rounded/vr.svg";
import dinheiroURL from "../../app/assets/payment-flat-rounded/dinheiro.svg";
import mercadoPagoURL from "../../app/assets/payment-flat-rounded/mercado-pago.svg";
import visaURL from "../../app/assets/payment-flat-rounded/visa.svg";
import mastercardURL from "../../app/assets/payment-flat-rounded/mastercard.svg";
import hipercardURL from "../../app/assets/payment-flat-rounded/hipercard.svg";
import eloURL from "../../app/assets/payment-flat-rounded/elo.svg";
import pixURL from "../../app/assets/payment-flat-rounded/pix.svg";
import genericURL from "../../app/assets/payment-flat-rounded/generic.svg";
import benURL from "../../app/assets/payment-flat-rounded/ben.svg";

export default function PaymentMethodIcon({ paymentLabel, ...props }) {
  return (
    <img src={getURL(paymentLabel)} alt={paymentLabel} height="24" {...props} />
  );
}

const getURL = (paymentLabel) => {
  const fullLabel = paymentLabel ? paymentLabel.toLowerCase() : "";
  const match = (part) => fullLabel.includes(part);

  if (match("alelo")) {
    return aleloURL;
  } else if (match("ben")) {
    return benURL;
  } else if (match("amex")) {
    return amexURL;
  } else if (match("sodexo")) {
    return sodexoURL;
  } else if (match("ticket")) {
    return ticketURL;
  } else if (match("verdecard")) {
    return verdecardURL;
  } else if (match("vr")) {
    return vrURL;
  } else if (match("dinheiro")) {
    return dinheiroURL;
  } else if (match("mercado")) {
    return mercadoPagoURL;
  } else if (match("visa")) {
    return visaURL;
  } else if (match("mastercard")) {
    return mastercardURL;
  } else if (match("hipercard")) {
    return hipercardURL;
  } else if (match("elo")) {
    return eloURL;
  } else if (match("pix")) {
    return pixURL;
  } else if (match("mercado pago")) {
    return mercadoPagoURL;
  } else {
    return genericURL;
  }
};
