import React from "react";
import { Create } from "react-admin";

import LojaHorarioForm from "./LojaHorarioForm";
import LojaHorarioToolbar from "./LojaHorarioToolbar";

const LojaHorarioCreate = props => (
  <Create {...props}>
    <LojaHorarioForm toolbar={<LojaHorarioToolbar />} />
  </Create>
);

export default LojaHorarioCreate;
