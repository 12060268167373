import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NumberInput } from "react-admin";
import { Tooltip } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  readOnlyInput: {
    cursor: "not-allowed",
  },
}));

function NumberInputReadonly({ InputProps = {}, ...rest }) {
  const classes = useStyles();
  return (
    <Tooltip title="Preenchido automaticamente">
      <NumberInput
        {...rest}
        variant="outlined"
        onWheel={(e) => e.target.blur()}
        InputProps={{
          ...InputProps,
          readOnly: true,
          classes: {
            ...(InputProps.classes ? InputProps.classes : {}),
            input: classes.readOnlyInput,
          },
        }}
      />
    </Tooltip>
  );
}

export default NumberInputReadonly;
