import * as React from "react";
import { cloneElement } from "react";
import {
  useListContext,
  TopToolbar,
  CloneButton,
  CreateButton,
  sanitizeListRestProps,
} from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import { INUTILIZACAO } from "./calculos";

const NFeEmissaoListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, basePath, showFilter } =
    useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CloneButton
        basePath={basePath}
        record={{ informacao_adicional: INUTILIZACAO }}
        label="NOVA INUTILIZAÇÃO"
        icon={<AddIcon />}
      />
      <CreateButton basePath={basePath} label="NOVA NFE" />
    </TopToolbar>
  );
};

export default NFeEmissaoListActions;
