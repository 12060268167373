import React from "react";
import { required } from "react-admin";
import { Grid } from "@mui/material";
import ArrayInputRemoveButton from "../../common/form/ArrayInputRemoveButton";
import { DiaSemanaInput } from "../../common/form/DiaSemanaInput";
import { TimeInput } from "react-admin-date-inputs";

const validateReq = [required()];

function DisponibilidadeItem({ record, formData, itemData, getSource }) {
  return (
    <Grid container alignItems="center" spacing={3}>
      <Grid item xs={1.2}>
        <DiaSemanaInput
          source={getSource("dia_semana_ini")}
          label="De"
          validate={validateReq}
          fullWidth
        />
      </Grid>

      <Grid item xs={1.2}>
        <DiaSemanaInput
          source={getSource("dia_semana_fim")}
          label="Até"
          validate={validateReq}
          fullWidth
        />
      </Grid>

      <Grid item xs={1}>
        <TimeInput
          source={getSource("inicio")}
          label="Das"
          validate={validateReq}
          options={{ format: "HH:mm", ampm: false, clearable: true }}
          fullWidth
        />
      </Grid>

      <Grid item xs={1}>
        <TimeInput
          source={getSource("fim")}
          label="Até"
          validate={validateReq}
          options={{ format: "HH:mm", ampm: false, clearable: true }}
          fullWidth
        />
      </Grid>
      <Grid item>
        <ArrayInputRemoveButton
          formData={formData}
          itemData={itemData}
          getSource={getSource}
        />
      </Grid>
    </Grid>
  );
}

export default DisponibilidadeItem;
