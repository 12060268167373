import React, { useState, Fragment } from "react";
import { Confirm, useAuthenticated } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import DoneIcon from "@material-ui/icons/Done";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { withUser } from "../common/util/hocs";
import Table from "../common/table/Table";
import { fetchJSON } from "../dataProvider";
import AppStatus from "./AppStatus";
import UXDelay from "../dataProvider/uxdelay";
import { Box, Link, Typography } from "@mui/material";
import NpsSurvey from "../resources/nps/NpsSurvey";

const useStyles = makeStyles((theme) => ({
  progress: {
    marginRight: "1em",
  },
}));

const publicarStatus = ["Publicar", "Publicando", "Publicado", "Erro"];

function ButtonPromise({
  statusText,
  callback,
  callbackErro,
  confirm,
  confirmTitle,
  confirmMessage,
  confirmButton,
  ...rest
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(0);
  const [erro, setErro] = useState(undefined);
  const getText = () => {
    return statusText.length > status
      ? statusText[status]
      : "Status não definido";
  };

  const handleClick = async (e) => {
    if (status === 2) {
      return;
    }

    if (status === 3) {
      callbackErro(erro, fetch);
      return;
    }

    if (confirm) {
      setOpen(true);
      return;
    }

    await fetch();
  };

  const fetch = async () => {
    setStatus(1);

    try {
      await UXDelay(callback());
      setStatus(2);
    } catch (err) {
      setErro(err.body.error.message);
      setStatus(3);
    } finally {
      setOpen(false);
    }
  };

  const handleDialogClose = () => setOpen(false);

  return (
    <>
      <Button
        variant="contained"
        type="submit"
        {...rest}
        disabled={status === 1}
        onClick={handleClick}
      >
        {status === 1 && (
          <CircularProgress
            size={25}
            thickness={2}
            className={classes.progress}
          />
        )}
        {status === 2 && <DoneIcon className={classes.progress} />}
        {status === 3 && <ErrorOutlineIcon className={classes.progress} />}
        {getText()}
      </Button>
      {confirm && (
        <Confirm
          isOpen={open}
          loading={status === 1}
          title={confirmTitle}
          content={confirmMessage}
          confirm={confirmButton}
          onConfirm={fetch}
          onClose={handleDialogClose}
        />
      )}
    </>
  );
}

function ButtonPublicarAppDelivery({ loja, callbackErro }) {
  const publicar = async () => {
    return fetchJSON("Apps/publicarMenu", {
      method: "POST",
      body: JSON.stringify({
        lojaId: loja.id,
      }),
    });
  };

  return (
    <ButtonPromise
      color="primary"
      statusText={publicarStatus}
      callback={publicar}
      callbackErro={callbackErro}
    />
  );
}

function ButtonPublicarLoja({ loja, callbackErro }) {
  const publicar = async () => {
    return fetchJSON("Equipamento_eventos/publicar", {
      method: "POST",
      body: JSON.stringify({
        lojaId: loja.id,
      }),
    });
  };

  return (
    <ButtonPromise
      color="default"
      statusText={publicarStatus}
      callback={publicar}
      callbackErro={callbackErro}
      confirm={true}
      confirmTitle="Publicar loja física"
      confirmMessage="Confirma a publicação para a loja física? Isso irá substituir todos os dados no sistema da loja."
      confirmButton="Publicar"
    />
  );
}

function PublicarMenu(props) {
  useAuthenticated();
  const { usuario, loja, admin_user } = props;
  const [rows, setRows] = useState(undefined);
  const [dialog, setDialog] = React.useState({
    open: false,
  });

  const handleClickOpen = (erro, callback) => {
    setDialog({
      open: true,
      erro,
      callback,
    });
  };

  const handleDialogClose = (tentarDeNovo) => {
    if (tentarDeNovo) {
      dialog.callback();
    }

    setDialog({
      open: false,
    });
  };

  const handleFechar = () => {
    handleDialogClose(false);
  };

  const handleDeNovo = () => {
    handleDialogClose(true);
  };

  const columns = [
    {
      title: "Loja",
      editable: "never",
      width: "50%",
      render: (rowData) => (
        <Box sx={{ my: 1 }}>
          <Typography variant="h6">{rowData.loja.nome_fantasia}</Typography>
          {rowData.appUrl && (
            <Link
              href={rowData.appUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <small>{rowData.appUrl}</small>
              <br />
            </Link>
          )}
          {rowData.appUrlMesa && (
            <Link
              href={rowData.appUrlMesa}
              target="_blank"
              rel="noopener noreferrer"
            >
              <small>{rowData.appUrlMesa}</small>
              <br />
            </Link>
          )}
          {rowData.appUrlComanda && (
            <Link
              href={rowData.appUrlComanda}
              target="_blank"
              rel="noopener noreferrer"
            >
              <small>{rowData.appUrlComanda}</small>
              <br />
            </Link>
          )}
        </Box>
      ),
    },
    {
      title: "App Delivery",
      width: "30%",
      render: (rowData) =>
        admin_user.cardapio_digital &&
        (rowData.appUrl || rowData.appUrlMesa) ? (
          <ButtonPublicarAppDelivery
            loja={rowData.loja}
            callbackErro={handleClickOpen}
          />
        ) : null,
    },
    {
      title: "Loja Física",
      width: "20%",
      render: (rowData) =>
        rowData.loja.publicarLojaFisica ? (
          <ButtonPublicarLoja
            loja={rowData.loja}
            callbackErro={handleClickOpen}
          />
        ) : null,
    },
  ];

  if (rows === undefined) {
    setRows(
      usuario.lojas.map((l) => {
        return {
          loja: l,
          appDelivery: 0,
          appUrl:
            l.appDeliveryUri && (l.appEntrega || l.appRetirada)
              ? `https://delivery.sistemapallas.com.br/${l.appDeliveryUri}`
              : null,
          appUrlMesa:
            l.appDeliveryUri && l.appMesa
              ? `https://delivery.sistemapallas.com.br/${l.appDeliveryUri}/mesa/1`
              : null,
          appUrlComanda:
            l.appDeliveryUri && l.appMesa && l.appMesaComanda
              ? `https://delivery.sistemapallas.com.br/${l.appDeliveryUri}/comanda/1`
              : null,
        };
      })
    );
  }

  return (
    <Fragment>
      <NpsSurvey />
      {admin_user.cardapio_digital && <AppStatus loja={loja} />}
      <Card>
        <Table
          options={{
            paging: false,
            search: false,
            tableLayout: "fixed",
          }}
          title="Publicar menu"
          columns={columns}
          data={rows}
        />
        <Dialog
          open={dialog.open}
          onClose={handleFechar}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Erro ao publicar menu"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialog.erro}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeNovo} color="primary">
              Tentar novamente
            </Button>
            <Button onClick={handleFechar} color="primary" autoFocus>
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </Fragment>
  );
}

export default withUser(PublicarMenu);
