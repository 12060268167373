import React from "react";
import { TextInput, Filter, NumberInput, DateTimeInput } from "react-admin";
import { ConnectStoneStatusInput } from "./ConnectStoneStatusField";

const ConnectStoneFilter = (props) => (
  <Filter {...props}>
    <ConnectStoneStatusInput source="status" label="Status" alwaysOn />
    <NumberInput source="valor" label="Valor" alwaysOn />
    <DateTimeInput source="createdAt_lte" label="Data" alwaysOn />
    <TextInput source="terminalSN" label="Terminal" />
  </Filter>
);

export default ConnectStoneFilter;
