import queryString from "query-string";
import fetchJson from "./fetchJson";
import { API_URL } from "../config/constants";
import { uploadImages } from "../aws";
import { conta_filters } from "../resources/conta/ContaFilterSidebar";
import { produto_filters } from "../resources/produto/ProdutoFilter";
import { nps_filters } from "../resources/nps/NpsFilter";
import { parceiro_filters } from "../resources/parceiro/ParceiroFilter";
import { nfce_filters } from "../resources/nfce/NFCeFilter";

const FTS = ["+", "-", "*", '"', "(", ")", "<", ">", "~"];
const _BETWEEN = "_between",
  _LIKE = "_like",
  _GTE = "_gte",
  _LTE = "_lte",
  _GT = "_gt",
  _LT = "_lt";
const CLAUSES = [_BETWEEN, _LIKE, _GTE, _LTE, _GT, _LT];
const cached = {};

const RESOURCES_ADMIN = [
  "Segmentos",
  "AppPedidos",
  "AppEventos",
  "Loja_certificados",
  "IFood_oauth",
  "Logs",
  "Nps",
];

const RESOURCES_NO_CACHE = [
  ...RESOURCES_ADMIN,
  "Mercado_Pago_Preference",
  "Loja_moeda_apps",
  "Caixas",
  "nfes",
  "Conta",
  "user_lojas",
  "Contador_arquivo",
];

const FILTERS_PRE_BUILDED = {
  ...produto_filters,
  ...parceiro_filters,
  ...conta_filters,
  ...nps_filters,
  ...nfce_filters,
};

const getLojaId = () => {
  const id = localStorage.getItem("lojaId");
  return id ? parseInt(id, 10) : id;
};

const parseWhere = (o, level = 0) => {
  const parsed = {};
  for (const key in o) {
    const value = o[key];

    const preBuildedFilter = FILTERS_PRE_BUILDED[key];
    if (preBuildedFilter) {
      const preBuildedFilterValues =
        preBuildedFilter && value === true
          ? preBuildedFilter
          : preBuildedFilter[value];
      for (const pbfKey in preBuildedFilterValues) {
        parsed[pbfKey] = preBuildedFilterValues[pbfKey];
      }
      continue;
    }

    const clause = CLAUSES.find((c) => key.includes(c));
    if (clause) {
      const newKey = key.replace(clause, "");
      if (clause === _LIKE) {
        parsed[newKey] = { like: `%${value}%` };
      } else {
        const newClause = clause.replace("_", "");
        parsed[newKey] = { [newClause]: value };
      }
    } else if (typeof value === "object") {
      parsed[key] = parseWhere(value, level + 1);
    } else if (key === "q") {
      if (typeof value === "string" && value.length > 2) {
        const containsSpecialCharacteres =
          value.split("").filter((c) => FTS.includes(c)).length === 0;

        parsed[key] = containsSpecialCharacteres
          ? value
              .split(" ")
              .filter((v) => v.length > 2)
              .map((v) => `+${v}*`)
              .join(" ")
          : value;

        // console.log("FTS", parsed[key]);
      }
    } else {
      parsed[key] = value;
    }
  }

  return parsed;
};

const getFilter = (resource, params, lojaId) => {
  const filter = { where: {} };

  if (!RESOURCES_ADMIN.includes(resource)) {
    filter.where.lojaId = getLojaId();
  }

  if (lojaId) {
    filter.where.lojaId = lojaId;
  }

  if (params) {
    // console.log("params", params);

    // getManyReference => params.target
    if (params.target) {
      filter.where[params.target] = params.id;
    }

    // getMany => params.ids
    else if (params.ids) {
      filter.where.id = { inq: params.ids };
    }

    if (params.filter) {
      filter.where = {
        ...filter.where,
        ...parseWhere(params.filter),
      };
    }

    if (params.pagination) {
      filter.skip = (params.pagination.page - 1) * params.pagination.perPage;
      filter.limit = params.pagination.perPage;
    }

    if (params.sort) {
      filter.order = `${params.sort.field} ${params.sort.order}`;
    }

    if (params.fields) {
      filter.fields = params.fields;
    }

    if (params.include) {
      filter.include = params.include;
    }

    if (params.limit) {
      filter.limit = params.limit;
    }
  }

  // console.log("filter", filter);
  // console.log("where", filter.where);

  return filter;
};

const dataProvider = {
  getOne: (resource, params) => {
    const filter = { where: { id: params.id } };
    if (!RESOURCES_ADMIN.includes(resource)) {
      filter.where.lojaId = getLojaId();
    }

    const query = { filter: JSON.stringify(filter) };
    const url =
      API_URL + "/" + resource + "/findInclude?" + queryString.stringify(query);

    return fetchCache(resource, url).then((response) => {
      return { data: orderArrayAfterGet(response.json[0]) };
    });
  },
  getList: (resource, params) => getList(resource, params),
  getMany: (resource, params) => dataProvider.getList(resource, params),
  getManyReference: (resource, params) =>
    dataProvider.getList(resource, params),

  create: (resource, params) => {
    delete cached[resource];
    return save(resource, params.data).then((json) => {
      return { data: json };
    });
  },
  delete: (resource, params) => {
    delete cached[resource];
    return del(resource, params.id, getLojaId()).then((response) => ({
      data: null,
    }));
  },
  update: (resource, params) => {
    if (params.onlyUpdateLocalStore) {
      return Promise.resolve({ data: params.data });
    }

    delete cached[resource];

    if (params.updateAttributes) {
      return updateAttributes(resource, params.data).then((json) => {
        return { data: json };
      });
    }

    return dataProvider.create(resource, params);
  },
  updateMany: (resource, params) => {
    delete cached[resource];
    const url = API_URL + "/" + resource + "/updateMany";
    const body = {
      lojaId: getLojaId(),
      where: { id: { inq: params.ids } },
      data: params.data,
    };
    return fetchJson(url, {
      method: "POST",
      body: JSON.stringify(body),
    }).then((response) => ({
      data: response.json,
    }));
  },
  deleteMany: (resource, params) => {
    delete cached[resource];
    return new Promise(async (resolve) => {
      const deletedIds = [];
      for (const id of params.ids) {
        console.log("del", id);
        await del(resource, id, getLojaId())
          .then((response) => deletedIds.push(id))
          .catch((err) =>
            console.log(
              `Error on ${resource} deleteMany ${JSON.stringify(err)}`
            )
          );
      }

      resolve({ data: deletedIds });
    });
  },
};

async function fetchCache(resource, url, noCache) {
  cached[resource] = cached[resource] || {};
  if (!noCache) {
    const cachedPromise = cached[resource][url];
    if (cachedPromise) {
      return cachedPromise;
    }
  }

  const promise = fetchJson(url);
  if (RESOURCES_NO_CACHE.includes(resource)) {
    return promise;
  }

  cached[resource][url] = promise;
  return promise.catch((err) => {
    if (promise === cached[resource][url]) {
      delete cached[resource][url];
      throw err;
    }
  });
}

async function del(resource, id, lojaId) {
  const url = API_URL + "/" + resource + "/del";
  const options = {
    method: "DELETE",
    body: JSON.stringify({
      id,
      lojaId,
    }),
  };

  return fetchJson(url, options);
}

async function updateAttributes(resource, data) {
  removeBelongsTo(data);

  const url = API_URL + "/" + resource + "/updateAttributes";
  const options = {
    method: "POST",
    body: JSON.stringify(data),
  };

  return fetchJson(url, options).then((response) => response.json);
}

async function save(resource, data) {
  removeBelongsTo(data);
  orderArrayBeforeSave(data);

  if (!data.id) {
    data.lojaId = getLojaId();
  }

  const uploadImagesPromise = uploadImages(resource, data);

  const url = API_URL + "/" + resource + "/save";
  const options = {
    method: "POST",
    body: JSON.stringify(data),
  };

  return Promise.all([fetchJson(url, options), uploadImagesPromise]).then(
    (resps) => {
      console.log(resps[0].json);
      return resps[0].json;
    }
  );
}

async function getList(resource, params, lojaId, noCache) {
  const filter = getFilter(resource, params, lojaId);
  const query = { filter: JSON.stringify(filter) };
  const url =
    API_URL + "/" + resource + "/list?" + queryString.stringify(query);

  return fetchCache(resource, url, noCache).then((response) => {
    const count = response.headers.get("content-count");
    return {
      data: response.json,
      total: count ? parseInt(count, 10) : 0,
    };
  });
}

async function fetchJSON(uri, options, params) {
  if (params) {
    if (params.where) {
      params.where = JSON.stringify(params.where);
    }

    if (params.filter) {
      params.filter = JSON.stringify(params.filter);
    }
  }

  if (options && options.body && typeof options.body !== "string") {
    options.body = JSON.stringify(options.body);
  }

  const query = params ? `?${queryString.stringify(params)}` : "";
  const url = `${API_URL}/${uri}${query}`;

  return fetchJson(url, options).then((resp) => {
    return resp.json;
  });
}

function removeBelongsTo(obj) {
  if (obj instanceof Object) {
    for (var key in obj) {
      const v = obj[key];
      if (v instanceof Array) {
        v.forEach((i) => removeBelongsTo(i));
      } else if (v instanceof Object) {
        const relationsToRemove = ["centro_custo"];
        if (relationsToRemove.includes(key)) {
          delete obj[key];
        } else {
          const objId = `${key}Id`;
          if (obj.hasOwnProperty(objId)) {
            delete obj[key];
          }
        }
      }
    }
  }
}

function orderArrayBeforeSave(obj) {
  for (var key in obj) {
    const v = obj[key];
    if (v instanceof Array && v.length > 0) {
      if (v[0].hasOwnProperty("ordem")) {
        for (let index = 0; index < v.length; index++) {
          v[index].ordem = index;
        }
      }
    }
  }
}

function orderArrayAfterGet(obj) {
  for (var key in obj) {
    const v = obj[key];
    if (v instanceof Array && v.length > 0) {
      if (v[0].hasOwnProperty("ordem")) {
        v.sort((a, b) => a.ordem - b.ordem);
      }
    }
  }
  return obj;
}

function cleanCache(resource) {
  delete cached[resource];
}

export {
  dataProvider,
  save,
  updateAttributes,
  del,
  getList,
  fetchJSON,
  cleanCache,
};
