import React from "react";
import {
  SimpleForm,
  FormDataConsumer,
  AutocompleteInput,
  required,
  Button,
} from "react-admin";
import { Grid, Box, Typography } from "@mui/material";
import Decimal from "decimal.js";
import { useForm, useFormState } from "react-final-form";
import { withUser } from "../../common/util/hocs";
import { NFeTipoField } from "./NFeTipo";
import NFeEmitente from "./NFeEmitente";
import NFeValorData from "./NFeValorData";
import NFeStatus from "./NFeStatus";
import LazyReferenceInput from "../../common/form/LazyReferenceInput";
import NFeProdutosGrid from "./NFeProdutosGrid";
import NFeContasGrid from "./NFeContasGrid";

const validateReq = [required()];

const ButtonAddConta = () => {
  const form = useForm();
  const formState = useFormState();

  const onAddConta = () => {
    let contas = formState.values["contas"];
    form.change(`contas`, [
      ...contas,
      {
        ...contas[0],
        id: null,
        valor: 0,
        observacao: "",
        index: contas.length,
      },
    ]);
  };

  return (
    <Button onClick={onAddConta} label="Adicionar Conta" variant="outlined" />
  );
};

const NFeForm = (props) => {
  const { record, loja } = props;

  const validateNFe = (values) => {
    const errors = {};
    const total_contas = values.contas
      .filter((c) => c && c.valor && !c.deletar)
      .reduce((t, c) => t.plus(c.valor), new Decimal(0));
    const totaisIguais = total_contas.minus(values.vNF).equals(0);
    if (!totaisIguais) {
      errors.contas = [
        { valor: "Total dos pagamentos deve ser igual ao total do pedido" },
      ];
    }
    return errors;
  };

  return (
    <SimpleForm {...props} submitOnEnter={false} validate={validateNFe}>
      <Box ml={2} mt={3} fullWidth>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{
            borderWidth: 2,
            borderRadius: 2,
            borderColor: "#eeeeee",
            borderStyle: "dashed",
            outline: "none",
          }}
          fullWidth
        >
          <Grid item xs>
            <NFeTipoField record={record} />
          </Grid>
          <Grid item xs={6}>
            <NFeEmitente record={record} />
          </Grid>
          <Grid item xs={4}>
            <NFeValorData record={record} />
          </Grid>
          <Grid item xs>
            <NFeStatus record={record} />
          </Grid>
        </Grid>
      </Box>

      <Box mt={10} mb={2} ml={1} fullWidth>
        <Typography variant="h4">
          <strong>Produtos</strong>
        </Typography>
      </Box>

      <FormDataConsumer label="Produto do estoque">
        {({ formData }) => <NFeProdutosGrid formData={formData} loja={loja} />}
      </FormDataConsumer>

      <Box mt={10} mb={2} ml={1} fullWidth>
        <Typography variant="h4">
          <strong>Contas</strong>
        </Typography>
      </Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <LazyReferenceInput
            label="Classificação"
            source="classificacaoId"
            reference="Conta_classificacoes"
            validate={validateReq}
            addCreateButton={true}
            required
          >
            <AutocompleteInput optionText="nome" />
          </LazyReferenceInput>
        </Grid>
        <Grid item>
          <LazyReferenceInput
            label="Centro de custo"
            source="centroCustoId"
            reference="Conta_centros_custos"
            validate={validateReq}
            addCreateButton={true}
            required={true}
          >
            <AutocompleteInput optionText="nome" />
          </LazyReferenceInput>
        </Grid>
        <Grid item>
          <ButtonAddConta />
        </Grid>
      </Grid>
      <FormDataConsumer label="Produto do estoque">
        {({ formData }) => <NFeContasGrid formData={formData} loja={loja} />}
      </FormDataConsumer>
    </SimpleForm>
  );
};

export default withUser(NFeForm);
