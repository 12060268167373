import React from "react";
import { Create } from "react-admin";

import LojaForm from "./LojaForm";
import LojaToolbar from "./LojaToolbar";

const LojaCreate = props => (
  <Create {...props}>
    <LojaForm toolbar={<LojaToolbar />} />
  </Create>
);

export default LojaCreate;
