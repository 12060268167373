import React from "react";
import { SimpleForm } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import { format } from "date-fns";
import { Grid, Chip, Box, Typography } from "@material-ui/core";
import { JsonField } from "react-admin-json-view";
import { IdDates } from "../../common/form/ConditionalInputs";

const useStyles = makeStyles((theme) => ({
  box: {
    display: "inline-flex",
  },
  grid: {
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
  },
}));

const IFoodOauthForm = (props) => {
  const classes = useStyles();
  const record = props.record;
  const updatedAt = new Date(record.updatedAt);
  const tokenJSON = record.tokenJson ? JSON.parse(record.tokenJson) : null;
  const expiresIn = tokenJSON ? tokenJSON.expiresIn : null;
  const expiresAt = expiresIn
    ? new Date(updatedAt.getTime() + expiresIn * 1000)
    : null;
  const vencido = expiresAt ? new Date() > expiresAt : null;

  return (
    <SimpleForm {...props} redirect='list'>
      <IdDates />

      {tokenJSON && (
        <Box my={3} className={classes.box} fullWidth>
          <Grid
            container
            direction='row'
            alignItems='center'
            justify='center'
            className={classes.grid}
          >
            <Grid item>
              <Box m={3}>
                <Typography variant='body1' color='textSecondary'>
                  Instalação concluída
                </Typography>
                <Typography variant='body1' color='textSecondary'>
                  {`Válido até ${format(expiresAt, "dd/MM/yyyy HH:mm")}`}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box m={3}>
                {vencido && (
                  <Chip
                    variant='outlined'
                    size='small'
                    label='Vencido'
                    color='error'
                    icon={<CloseIcon />}
                  />
                )}
                {!vencido && (
                  <Chip
                    variant='outlined'
                    size='small'
                    label='Válido'
                    color='primary'
                    icon={<DoneIcon />}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      <JsonField
        source='userCodeJson'
        addLabel={true}
        jsonString={true}
        reactJsonOptions={{
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
      <JsonField
        source='tokenJson'
        addLabel={true}
        jsonString={true}
        reactJsonOptions={{
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
    </SimpleForm>
  );
};

export default IFoodOauthForm;
