import React from "react";
import { SelectInput, SelectField } from "react-admin";
import { PermissoesChoices } from "../../authProvider";

const UsuarioTipoInput = (props) => {
  return <SelectInput {...props} choices={PermissoesChoices.slice(1)} />;
};

const UsuarioTipoField = (props) => {
  return <SelectField {...props} choices={PermissoesChoices} />;
};

export { UsuarioTipoInput, UsuarioTipoField };
