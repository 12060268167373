import React from "react";
import { SimpleForm, TextInput } from "react-admin";
import { JsonField } from "react-admin-json-view";
import { IdDates } from "../../common/form/ConditionalInputs";
import CopyPropButton from "../../common/form/CopyPropButton";

function LogsForm(props) {
  const { record } = props;
  return (
    <SimpleForm {...props} toolbar={props.toolbar} redirect="list">
      <IdDates />
      <TextInput source="shopId" label="Loja Id" />
      <CopyPropButton source="stack" />
      <TextInput source="stack" fullWidth multiline />
      <CopyPropButton source="data" />
      {record &&
        record.data &&
        ["[", "{"].includes(record.data.substring(0, 1)) && (
          <JsonField
            source="data"
            addLabel={true}
            jsonString={true}
            reactJsonOptions={{
              name: null,
              collapsed: false,
              enableClipboard: false,
              displayDataTypes: false,
            }}
          />
        )}
      <TextInput source="data" fullWidth multiline />
    </SimpleForm>
  );
}

export default LogsForm;
