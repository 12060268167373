import React from "react";
import { Create } from "react-admin";

import FidelidadeForm from "./FidelidadeForm";
// import FidelidadeToolbar from "./FidelidadeToolbar";

const FidelidadeCreate = (props) => (
  <Create {...props} title="Novo Programa de Fidelidade">
    <FidelidadeForm />
  </Create>
);

export default FidelidadeCreate;
