import { contasEnable } from "../../permissionsHelper";
import CentroCreate from "./CentroCreate";
import CentroEdit from "./CentroEdit";
import CentroList from "./CentroList";

const centro_custo = {
  getResource: (p, a) => {
    return contasEnable(p, a)
      ? {
          name: "Conta_centros_custos",
          list: CentroList,
          create: CentroCreate,
          edit: CentroEdit,
        }
      : null;
  },
  getMenu: (p, a) => {
    return contasEnable(p, a)
      ? {
          key: "Conta_centros_custos",
          to: "/Conta_centros_custos",
          primaryText: "Centro de custo",
          leftIcon: null,
        }
      : null;
  },
};

export default centro_custo;
