import React from "react";
import { SelectInput, SelectField } from "react-admin";

export const OPCOES = [
  { id: "PDV", name: "PDV Mobile" },
  { id: "MERCADO", name: "Mercado Autônomo" },
  { id: "CARDAPIO_DIGITAL", name: "Food Cardápio Digital" },
  { id: "NUMERO_PEDIDO", name: "Food Número do Pedido" },
  { id: "AGUARDA_TRANSACAO", name: "Aguarda Transação" },
];

const TotemOperacaoInput = (props) => {
  return (
    <SelectInput
      {...props}
      choices={OPCOES}
      allowEmpty
      emptyValue={null}
      emptyText={"Nenhum"}
    />
  );
};

const TotemOperacaoField = (props) => {
  return <SelectField {...props} choices={OPCOES} allowEmpty />;
};

export { TotemOperacaoInput, TotemOperacaoField };
