import React, { useState } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import LoadingButton from "../../../common/LoadingButton";
import { fetchJSON } from "../../../dataProvider";
import { useNotify } from "react-admin";

const NFeDownloadXMLButton = ({ record }) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  if (!record || !record.xmlUrl) return null;

  const downloadXmlFile = async () => {
    try {
      setLoading(true);
      const xml = await fetchJSON("NFe_emissaos/downloadXML", null, {
        lojaId: record.lojaId,
        nfeId: record.id,
      });
      const element = document.createElement("a");
      const file = new Blob([xml], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = `${record.chave}.xml`;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    } catch (err) {
      console.log(JSON.stringify(err));
      notify("Ocorreu um erro ao baixar o XML da NFe");
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingButton
      variant="contained"
      text="Download XML"
      loading={loading}
      icon={<GetAppIcon />}
      onClick={downloadXmlFile}
    />
  );
};

export default NFeDownloadXMLButton;
