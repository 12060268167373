import React from "react";
import {
  Datagrid,
  List,
  TextField,
  NumberField,
  EditButton,
  BooleanField,
} from "react-admin";

import MoedaFilter from "./MoedaFilter";
import { CodigoSATField } from "./CodigoSATInput";

const MoedaList = (props) => (
  <List {...props} filters={<MoedaFilter />} exporter={false}>
    <Datagrid>
      <TextField source="nome" label="Nome" />
      <CodigoSATField source="cod_sat" label="Código Fiscal" />
      <NumberField source="taxa" label="Taxa" />
      <NumberField source="dias" label="Dias" />
      <BooleanField source="status" label="Ativo" />
      <EditButton />
    </Datagrid>
  </List>
);

export default MoedaList;
