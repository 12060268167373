import React from "react";
import { Edit, useNotify } from "react-admin";
import LojaForm from "./LojaForm";
import LojaToolbar from "./LojaToolbar";

function LojaEdit(props) {
    const notify = useNotify();
  
    const onSuccess = () => {
        notify('Perfil da loja atualizado com sucesso! A página vai recarregar', 'info', { smart_count: 1 }, false);
        setTimeout(() => window.location.reload(), 2000);
      };

    return (
        <Edit onSuccess={onSuccess} undoable={false} {...props}>
            <LojaForm toolbar={<LojaToolbar />}/>
        </Edit>
    );
};

export default LojaEdit;
