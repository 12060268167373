import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

const modes = [
  { value: "simple", text: "Simples" },
  { value: "details", text: "Detalhado" },
];

function VisualizationMode({ onChange }) {
  const handleChange = (event) => onChange(event.target.value === "simple");

  return (
    <FormControl variant="standard" sx={{ minWidth: 100 }}>
      <InputLabel>Visualização</InputLabel>
      <Select onChange={handleChange}>
        {modes.map((o) => (
          <MenuItem key={o.value} value={o.value}>
            {o.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default VisualizationMode;
