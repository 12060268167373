import React, { useState } from "react";
import { get } from "lodash";
import { useForm } from "react-final-form";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@mui/material";
import ParceiroLazyReferenceInput from "../../parceiro/ParceiroLazyReferenceInput";
import { NFeIdentificadorIEInput } from "../fields/NFeIdentificadorIE";
import { useDataProvider, useNotify } from "react-admin";
import ParceiroForm from "../../parceiro/ParceiroForm";
import { formatCNPJCPF, formatPhone } from "../../../common/util/formatter";
import { SmallCircularProgress } from "../../../common/CircularProgress";
import EditButton from "../../../common/form/EditButton";
import { NFeConsumidorInput } from "../fields/NFeConsumidor";
import { NFeIdentificadorOperacaoInput } from "../fields/NFeIdentificadorOperacao";

const useStyles = makeStyles({
  dest: {
    width: "51em",
    marginRight: "1em",
  },
});

export default function TabDestinatario({ record, loja }) {
  const form = useForm();
  const classes = useStyles();
  const [destinatario, setDestinatario] = useState(null);
  const [endereco, setEndereco] = useState(null);
  const [validation, setValidation] = useState(null);
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleChangeDestinatario = async (parceiroId) => {
    try {
      setLoading(true);
      const { data: parceiro } = await dataProvider.getOne("parceiros", {
        id: parceiroId,
      });
      setDestinatario(parceiro);
      const end = get(parceiro, "enderecos[0]");
      setEndereco(end);
      setValidation(getDestValidation(parceiro, end));

      changeIdeIE(parceiro);
      changeIdeOperacao(parceiro);
    } catch (error) {
      notify("Erro ao carregar os dados do parceiro.", "warning");
      console.log(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  const changeIdeIE = (parceiro) => {
    const { cnpj, ie } = parceiro;
    if (
      cnpj &&
      cnpj.length === 14 &&
      ie &&
      ie.toUpperCase().includes("ISENTO")
    ) {
      form.change("identificador_ie_dest", 1);
    } else {
      form.change("identificador_ie_dest", 9);
    }
  };

  const changeIdeOperacao = (parceiro) => {
    if (parceiro.uf === "EX") {
      form.change("identificador_operacao", 3);
    } else if (parceiro.uf === loja.uf) {
      form.change("identificador_operacao", 2);
    } else {
      form.change("identificador_operacao", 1);
    }
  };

  const handleEditParceiro = (parceiro) => {
    handleChangeDestinatario(parceiro.id);
  };

  const getDestValidation = (par, end) => {
    const { cnpj } = par;
    const erros = [];
    if (!cnpj) {
      erros.push("Preencha o CNPJ ou CPF");
    }

    if (!end) {
      erros.push("Preencha o endereço completo");
    } else {
      const { uf, numero, codigo_municipio } = end;
      if (!uf) {
        erros.push("Preencha o estado");
      }

      if (!numero) {
        erros.push("Preencha o número do endereço");
      }

      if (!codigo_municipio) {
        erros.push("Preencha o código municipio");
      }
    }

    return erros.length > 0 ? erros : null;
  };

  return (
    <Grid>
      <Typography variant="h5" gutterBottom sx={{ my: 4, ml: 1 }}>
        Destinatário da NFe
      </Typography>
      <Grid item>
        <Grid container align="center" alignItems="center">
          <Grid item>
            <Box mr={2}>
              <ParceiroLazyReferenceInput
                record={record}
                label="Destinatário"
                source="destinatarioId"
                nameSource="destinatario"
                addCreateButton={true}
                CreateForm={ParceiroForm}
                className={classes.dest}
                onChange={handleChangeDestinatario}
                fullWidth
                required
              />
            </Box>
          </Grid>
          {loading && (
            <Grid item>
              <SmallCircularProgress />
            </Grid>
          )}
          {!loading && destinatario && (
            <Grid item>
              <EditButton
                reference="parceiros"
                onCreate={handleEditParceiro}
                label="Destinatário da NFe"
                CreateForm={ParceiroForm}
                record={destinatario}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      {destinatario && (
        <Grid item sx={{ ml: 2 }}>
          <Typography>
            {`Razao Social: ${destinatario.razao_social || ""}`} <br />
            {`CNPJ: ${formatCNPJCPF(destinatario.cnpj || "")}`} <br />
            {`IE: ${destinatario.ie || ""}`} <br />
            <br />
            {`Telefone: ${formatPhone(destinatario.telefone1 || "")}`}
            <br />
            {`Email: ${destinatario.email1 || ""}`}
          </Typography>
        </Grid>
      )}
      {endereco && (
        <Grid item sx={{ ml: 2 }}>
          <Typography>
            <br />
            {`${endereco.logradouro}, ${endereco.numero} - ${
              endereco.complemento || ""
            }`}
            <br />
            {`${endereco.bairro}, ${endereco.cidade} - ${endereco.uf}`} <br />
            {`CEP ${endereco.cep}`} <br />
            {`Código Municipio: ${endereco.codigo_municipio}`}
          </Typography>
        </Grid>
      )}
      {validation && (
        <Grid item sx={{ ml: 2, mt: 3 }}>
          <Typography color="red">Erros:</Typography>
          {validation.map((v) => (
            <Typography color="red">{v}</Typography>
          ))}
        </Grid>
      )}
      <Grid item sx={{ mt: 3 }}>
        <NFeIdentificadorIEInput className={classes.dest} fullWidth />
      </Grid>
      <Grid item sx={{ mt: 3 }}>
        <NFeIdentificadorOperacaoInput className={classes.dest} fullWidth />
      </Grid>
      <Grid item sx={{ mt: 3 }}>
        <NFeConsumidorInput className={classes.dest} fullWidth />
      </Grid>
    </Grid>
  );
}
