import React from 'react';
import PropTypes from 'prop-types';
import { NumberField } from 'react-admin';

function CurrencyField(props) {
  return (
    <NumberField options={{ style: 'currency', currency: 'BRL' }} {...props } />
  );
}

CurrencyField.propTypes = {
    addLabel: PropTypes.bool,
};

CurrencyField.defaultProps = {
    addLabel: true,    
    textAlign: 'left',
};

export default CurrencyField;