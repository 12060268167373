import React from 'react';
import NumberFormat from 'react-number-format';

function NumberInput(props) {
  const { inputRef, onChange, ...rest } = props;

  return <NumberFormat
    {...rest}
    getInputRef={inputRef}
    onValueChange={values => {
      onChange({
        target: {
          value: values.value,
        },
      });
    }}
    isNumericString
  />
}

function CEPInput(props) {
    return <NumberInput {...props} format="#####-###" />
}

function EnderecoNumeroInput(props) {
  return <NumberInput {...props} thousandSeparator={false} />
}

function TelefoneInput(props) {
  const digit3 = props.value.length > 2
      ? props.value.substring(2, 3)
      : null;
  const format = digit3 === "9"
      ? "(##) #####-####"
      : "(##) ####-####";
  return <NumberInput {...props} format={format} />
}

function CNPJInput(props) {
  return <NumberInput {...props} format="##.###.###/####-##" />
}

function CPFInput(props) {
  return <NumberInput {...props} format="###.###.###-###" />
}

function CPFCNPJInput(props) {
  const format = props.value.length <= 11
      ? "###.###.###-###"
      : "##.###.###/####-##";
  return <NumberInput {...props} format={format} />
}

export {
  CPFCNPJInput,
  CPFInput,
  CNPJInput,
  TelefoneInput,
  EnderecoNumeroInput,
  CEPInput,
};