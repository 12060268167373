import React from "react";
import GradeIcon from "@material-ui/icons/Grade";
import FidelidadeList from "./FidelidadeList";
import FidelidadeCreate from "./FidelidadeCreate";
import FidelidadeEdit from "./FidelidadeEdit";
import { cardapioDigitalEnable } from "../../permissionsHelper";

const fidelidade = {
  getResource: (p, a) => {
    return cardapioDigitalEnable(p, a)
      ? {
          name: "AppFidelidades",
          list: FidelidadeList,
          create: FidelidadeCreate,
          edit: FidelidadeEdit,
        }
      : null;
  },
  getMenu: (p, a) => {
    return cardapioDigitalEnable(p, a)
      ? {
          key: "AppFidelidades",
          to: "/AppFidelidades",
          primaryText: "Fidelidade",
          leftIcon: iconFidelidade,
        }
      : null;
  },
};

export default fidelidade;
export const iconFidelidade = <GradeIcon />;
