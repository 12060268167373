import React from "react";
import { SimpleForm, TextInput, required } from "react-admin";

import { IdDates } from "../../common/form/ConditionalInputs";
import { CodigoSATInput } from "../moeda/CodigoSATInput";

const validateReq = [required()];

function MoedaTiposForm(props) {
  return (
    <SimpleForm {...props} toolbar={props.toolbar} redirect="list">
      <IdDates />
      <TextInput source="nome" label="Tipo da moeda" validate={validateReq} />
      <CodigoSATInput
        source="cod_sat"
        label="Código SAT"
        validate={validateReq}
      />
    </SimpleForm>
  );
}

export default MoedaTiposForm;
