import React from "react";
import { Create } from "react-admin";

import UnidadeForm from "./UnidadeForm";
import UnidadeToolbar from "./UnidadeToolbar";

const UnidadeCreate = props => (
  <Create {...props}>
    <UnidadeForm toolbar={<UnidadeToolbar />} />
  </Create>
);

export default UnidadeCreate;
