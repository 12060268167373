import React from "react";
import { EditButton, Button as RAButton } from "react-admin";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import GetAppIcon from "@material-ui/icons/GetApp";
import NFeCancelarEntradaButton from "./NFeCancelarEntradaButton";
import { fetchJSON } from "../../dataProvider";
import NFeManifestarOperacaoButton from "./NFeManifestarOperacaoButton";
import { withUser } from "../../common/util/hocs";
import ProdutoMatrizFilialNFeButton from "../matriz_filial/ProdutoMatrizFilialNFeButton";

const NFeActions = ({ record, usuario }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  if (!record) return null;

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const downloadXmlFile = async () => {
    const xml = await fetchJSON("nfes/downloadXML", null, {
      lojaId: record.lojaId,
      nfeId: record.id,
    });
    const element = document.createElement("a");
    const file = new Blob([xml], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `${record.chNFe}.xml`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const acoes = [];

  acoes.push(
    <MenuItem onClick={handleClose}>
      <NFeManifestarOperacaoButton record={record} />
    </MenuItem>
  );

  if (record.entrada && usuario.lojas.length > 1) {
    acoes.push(
      <MenuItem onClick={handleClose}>
        <ProdutoMatrizFilialNFeButton nfeTomada={record} />
      </MenuItem>
    );
  }

  if (record.xmlUrl) {
    acoes.push(
      <MenuItem onClick={handleClose}>
        <EditButton label="Editar entrada" record={record} />
      </MenuItem>
    );

    acoes.push(
      <MenuItem onClick={handleClose}>
        <RAButton onClick={downloadXmlFile} label="Download XML">
          <GetAppIcon />
        </RAButton>
      </MenuItem>
    );
  }

  if (record.entrada) {
    acoes.push(
      <MenuItem onClick={handleClose}>
        <NFeCancelarEntradaButton record={record} />
      </MenuItem>
    );
  }

  if (acoes.length === 0) return null;

  return (
    <div>
      <Button onClick={handleClick}>
        <MoreVertIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {acoes.map((a) => a)}
      </Menu>
    </div>
  );
};

export default withUser(NFeActions);
