import React from "react";
import ICMSInput from "../../../common/form/ICMSInput";
import TabPanel from "../../../common/form/TabPanel";
import ICMSNormal from "../icms/ICMSNormal";
import ICMSST from "../icms/ICMSST";
import ICMSFCP from "../icms/ICMSFCP";
import ICMSFCPST from "../icms/ICMSFCPST";
import ICMSCredSN from "../icms/ICMSCredSN";
import { calculaICMS } from "../calculos";
import useChangeCalc from "../useChangeCalc";

export default function TabICMS({
  index,
  tabValue,
  loja,
  itemData,
  getSource,
}) {
  const changeCalc = useChangeCalc();
  const { icmsRedBCEnable, icmsEnable, icmsSTEnable, icmsCredSNEnable } =
    calculaICMS(itemData.icms_cst);

  return (
    <TabPanel value={tabValue} index={index}>
      <ICMSInput
        source={getSource("icms_cst")}
        label="ICMS CST"
        regime={loja.regime_tributario}
        required
        fullWidth
        onChange={changeCalc}
      />
      <ICMSNormal
        itemData={itemData}
        getSource={getSource}
        icmsEnable={icmsEnable}
        icmsRedBCEnable={icmsRedBCEnable}
      />
      <ICMSST
        itemData={itemData}
        getSource={getSource}
        icmsSTEnable={icmsSTEnable}
      />
      <ICMSFCP
        itemData={itemData}
        getSource={getSource}
        icmsEnable={icmsEnable}
      />
      <ICMSFCPST
        itemData={itemData}
        getSource={getSource}
        icmsSTEnable={icmsSTEnable}
      />
      <ICMSCredSN
        itemData={itemData}
        getSource={getSource}
        icmsCredSNEnable={icmsCredSNEnable}
      />
    </TabPanel>
  );
}
