import React from "react";
import { TextInput, BooleanInput, DateTimeInput, Filter } from "react-admin";

const LojaEntregaFilter = (props) => (
  <Filter {...props}>
    <TextInput source="bairro_like" label="Bairro" alwaysOn />
    <BooleanInput source="status" label="Entrega" />
    <DateTimeInput source="criado" />
    <DateTimeInput source="atualizado" />
  </Filter>
);

export default LojaEntregaFilter;
