import React from "react";
import { Title, Button, useRedirect } from "react-admin";
import ListIcon from "@material-ui/icons/List";
import { Box, Card, Stack } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../app/assets/adquirentes/pagbank.svg";

const useStyles = makeStyles((theme) => ({
  cover: {
    minHeight: "260px",
    height: "100%",
    backgroundColor: "#f5de3e",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
  },
  image: {},
}));

export function PagBankLogo() {
  const classes = useStyles();

  return (
    <div className={classes.cover}>
      <img width={200} src={logo} className={classes.image} alt="Comprovante" />
    </div>
  );
}

function PagBank(props) {
  const redirect = useRedirect();

  const handleGoToListApp = (event) => redirect(`/Transaction_pos`);

  return (
    <Card sx={{ display: "flex", p: 3 }}>
      <Title title="Integração com o PagBank" />
      <Box sx={{ width: 1 / 4 }}>
        <PagBankLogo />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", width: 3 / 4 }}>
        <Stack direction="row" mx={3} spacing={3}>
          <Button
            variant="contained"
            label="Lista de Transações"
            size="big"
            onClick={handleGoToListApp}
          >
            <ListIcon />
          </Button>
        </Stack>
      </Box>
    </Card>
  );
}

export default PagBank;
