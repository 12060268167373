import React from "react";
import { SelectInput, SelectField } from "react-admin";

const tipos = [
  { id: 0, name: "0 - Contratação do Frete por conta do Remetente (CIF)" },
  { id: 1, name: "1 - Contratação do Frete por conta do Destinatário (FOB)" },
  {
    id: 2,
    name: "2 - Contratação do Frete por conta de Terceiros",
  },
  { id: 3, name: "3 - Transporte Próprio por conta do Remetente" },
  { id: 4, name: "4 - Transporte Próprio por conta do Destinatário" },
  { id: 9, name: "9 - Sem Ocorrência de Transporte" },
];

function NFeModoFreteInput(props) {
  return (
    <SelectInput
      label="Identificador do Frete"
      source="modo_frete"
      choices={tipos}
      initialValue={1}
      {...props}
    />
  );
}

function NFeModoFreteField(props) {
  return (
    <SelectField
      label="Identificador do Frete"
      source="modo_frete"
      choices={tipos}
      {...props}
    />
  );
}

export { NFeModoFreteInput, NFeModoFreteField };
