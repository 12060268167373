import React, { useState } from "react";
import { Title, useNotify } from "react-admin";
import { Field, withTypes } from "react-final-form";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import TextInput from "../common/form/TextInput";
import { fetchJSON } from "../dataProvider";

const useStyles = makeStyles((theme) => ({
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
    width: "15em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
}));

const validate = (values) => {
  const errors = {};
  if (!values.senhaAtual) {
    errors.senhaAtual = "Obrigatório";
  }

  if (!values.novaSenha) {
    errors.novaSenha = "Obrigatório";
  } else if (values.novaSenha.length < 6) {
    errors.novaSenha = "Mínimo de 6 caracteres";
  }

  if (!values.novaSenhaConf) {
    errors.novaSenhaConf = "Obrigatório";
  } else if (values.novaSenha !== values.novaSenhaConf) {
    errors.novaSenhaConf = "As senhas não são iguais";
  }

  return errors;
};

const { Form } = withTypes();

function AlterarSenha(props) {
  const classes = useStyles();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e) => {
    setLoading(true);

    const options = {
      method: "POST",
      body: JSON.stringify({
        oldPassword: e.senhaAtual,
        newPassword: e.novaSenha,
      }),
    };

    fetchJSON("/users/change-password", options)
      .then(() => {
        notify("Senha alterada com sucesso!", "info");
      })
      .catch((err) => {
        console.log(err);
        notify("Senha atual incorreta!", "warning");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Card>
            <Title title="Alterar senha" />
            <div className={classes.form}>
              <Field
                fullWidth
                required
                name="senhaAtual"
                component={TextInput}
                type="password"
                label="Senha atual"
                divStyle={classes.input}
              />
              <Field
                fullWidth
                required
                name="novaSenha"
                component={TextInput}
                type="password"
                label="Nova senha"
                divStyle={classes.input}
              />
              <Field
                fullWidth
                required
                name="novaSenhaConf"
                component={TextInput}
                type="password"
                label="Confirmação da nova senha"
                divStyle={classes.input}
              />
            </div>
            <CardActions className={classes.actions}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
              >
                {loading && <CircularProgress size={25} thickness={2} />}
                Salvar nova senha
              </Button>
            </CardActions>
          </Card>
        </form>
      )}
    />
  );
}

export default AlterarSenha;
