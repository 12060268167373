import React from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  Chip,
} from "@mui/material";

function CdpModes({ value, onChange, id }) {
  const modes = [
    { id: "DELIVERY", nome: "Entrega" },
    { id: "TAKEOUT", nome: "Retirar" },
    { id: "INDOOR", nome: "Mesa" },
    { id: "TOTEM", nome: "Totem" },
  ];

  const handleChange = (event) => onChange(id, event.target.value);

  return (
    <FormControl sx={{ minWidth: 120 }}>
      <InputLabel>Tipo</InputLabel>
      <Select
        multiple
        value={value}
        onChange={handleChange}
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => (
          <div>
            {selected.map((value) => (
              <Chip
                key={value}
                label={modes.find((m) => m.id === value).nome}
              />
            ))}
          </div>
        )}
      >
        {modes.map((m) => (
          <MenuItem key={m.id} value={m.id}>
            {m.nome}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CdpModes;
