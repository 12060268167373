import React from "react";
import { TextInput, Filter } from "react-admin";

const MoedaAdquirenteFilter = props => (
  <Filter {...props}>
    <TextInput source="nome_like" label="Nome do MoedaAdquirente" alwaysOn />
  </Filter>
);

export default MoedaAdquirenteFilter;
