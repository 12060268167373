import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core";
import Decimal from "decimal.js";
import React from "react";
import { Line } from "react-chartjs-2";

import { useStyles } from "./styles";
import { formatCurrency } from "../../common/util/formatter";

const OrdersByMonthDayChart = ({ chartData, ...props }) => {
  const classes = useStyles();

  const labels = chartData.map(
    ({ day, month }) =>
      `${String(day).padStart(2, "0")}/${String(month).padStart(2, "0")}`
  );
  const totalPerDay = chartData.map((data) => data.total);
  const totalInDateRange = chartData.reduce(
    (total, data) => Decimal(total).plus(data.total).toFixed(2),
    "0"
  );
  const ordersInDateRange = chartData.reduce(
    (qtt, data) => Decimal(qtt).plus(data.qtt).toFixed(0),
    "0"
  );
  const ordersQuantity = chartData.map((data) => data.qtt);
  const data = {
    labels,
    datasets: [
      {
        label: "Valor total dos pedidos",
        data: totalPerDay,
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgb(255, 99, 132)",
        yAxisID: "y",
      },
      {
        label: "Total de pedidos",
        data: ordersQuantity,
        backgroundColor: "rgba(0, 0, 0, 0.54)",
        borderColor: "rgba(0, 0, 0, 0.54)",
        yAxisID: "y1",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
        scaleLabel: {
          display: false,
        },
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
      },
    },
  };

  return (
    <Card className={classes.card}>
      <CardHeader className={classes.cardHeader} title={"Pedidos"} />
      <CardContent>
        <Box marginBottom={2}>
          <Grid container spacing={3}>
            <Grid item className={classes.totalCard}>
              <Typography variant='body2' color='textSecondary'>
                Valor total do período
              </Typography>
              <Typography className={classes.typographyImportant} variant='h5'>
                {formatCurrency(totalInDateRange)}
              </Typography>
            </Grid>
            <Grid item className={classes.totalOrdersCard}>
              <Typography variant='body2' color='textSecondary'>
                Total de pedidos no período
              </Typography>
              <Typography className={classes.typographyImportant} variant='h5'>
                {ordersInDateRange}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box height={250}>
          <Line data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default OrdersByMonthDayChart;
