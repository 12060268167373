import React from "react";
import { Create } from "react-admin";
import CentroForm from "./CentroForm";

const CentroCreate = (props) => (
  <Create {...props}>
    <CentroForm />
  </Create>
);

export default CentroCreate;
