import * as React from "react";
import { cloneElement } from "react";
import { useListContext, TopToolbar, sanitizeListRestProps } from "react-admin";
import ButtonUploadXML from "./ButtonUploadXML";

const NFeListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, showFilter } =
    useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <ButtonUploadXML />
    </TopToolbar>
  );
};

export default NFeListActions;
