import React from "react";
import { TextInput } from "react-admin";

export const UFs = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];

const validateUF = (uf) => {
  return !uf || UFs.includes(uf) ? undefined : "UF inválida";
};

const UFInput = (props) => {
  return <TextInput source="uf" validate={validateUF} {...props} />;
};

export default UFInput;
