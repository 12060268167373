import React from "react";
import { Edit } from "react-admin";
import PermissionToolbar from "../../common/form/PermissionToolbar";
import PerguntaForm from "./PerguntaForm";

const PerguntaEdit = (props) => (
  <Edit {...props}>
    <PerguntaForm
      toolbar={<PermissionToolbar canDelete="cardapio_excluir" />}
    />
  </Edit>
);

export default PerguntaEdit;
