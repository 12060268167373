import { Grid } from "@material-ui/core";
import React from "react";
import HourPerformanceChart from "./HourPerformanceChart";
import OrdersByMonthDayChart from "./OrdersByMonthDayChart";
import TotalByPaymentMethodsChart from "./TotalByPaymentMethodsChart";
import WeekdayPerformanceChart from "./WeekdayPerformanceChart";

const InvoicingsCharts = ({ chartData }) => (
  <Grid container direction='column' spacing={5}>
    <Grid item>
      <OrdersByMonthDayChart chartData={chartData.general} />
    </Grid>
    <Grid container item spacing={5}>
      <Grid item xs={6} md={6}>
        <HourPerformanceChart chartData={chartData.perHours} />
      </Grid>
      <Grid item xs={6} md={6}>
        <WeekdayPerformanceChart chartData={chartData.perWeekDays} />
      </Grid>
      <Grid item xs={6} md={6}>
        <TotalByPaymentMethodsChart chartData={chartData.perPaymentMethods} />
      </Grid>
    </Grid>
  </Grid>
);

export default InvoicingsCharts;
