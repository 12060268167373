import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
  root: {
    minHeight: "100%",
  },
  card: {
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
  },
  cardHeader: {
    fontWeight: "bold",
    paddingBottom: "8px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.24)",
  },
  totalCard: {
    maxWidth: 400,
    color: "rgb(255, 99, 132)",
  },
  totalOrdersCard: {
    maxWidth: 400,
    color: "rgba(0, 0, 0, 0.54)",
  },
  typographyImportant: {
    fontWeight: "bold",
  },
  toolbarContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  cardsContainer: {
    marginBottom: 20,
  },
});
