import React from "react";
import { TextInput, NumberInput, Filter } from "react-admin";

const NcmFilter = props => (
  <Filter {...props}>
    <TextInput source="id" label="Código ncm" alwaysOn />
    <TextInput source="q" label="Descrição" alwaysOn />
    <NumberInput source="ex" label="Ex" />
    <NumberInput source="Tipo" label="Tipo" />
  </Filter>
);

export default NcmFilter;
