import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import Decimal from "decimal.js";
import React from "react";
import { Title, useAuthenticated, useNotify } from "react-admin";
import isEmpty from "lodash.isempty";
import get from "lodash.get";
import { formatCurrency } from "../../common/util/formatter";
import { fetchJSON } from "../../dataProvider";
import UXDelay from "../../dataProvider/uxdelay";
import { useStyles } from "./styles";
import Search from "../Search";
import { reportContasEnable } from "../../permissionsHelper";
import BillsTable from "./BillsTable";

const BoxTotal = ({ label, value, color }) => (
  <Box display="flex" flexDirection="column" alignItems="flex-end" marginX={4}>
    <Typography variant="body1">{label}</Typography>
    <Typography variant="h6" color={color} fontWeight="bold">
      {formatCurrency(value)}
    </Typography>
  </Box>
);

const ReportBillsByPartners = () => {
  useAuthenticated();
  const [reportData, setReportData] = React.useState({});
  const [reportTable, setReportTable] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [simpleMode, setSimpleMode] = React.useState(false);
  const classes = useStyles();
  const notify = useNotify();

  async function retrieveReportData({
    lojaId,
    startDateNoTime,
    endDateNoTime,
    billType,
  }) {
    try {
      setLoading(true);
      const retrievedReportData = await UXDelay(
        fetchJSON("relatorio/contas/por_parceiro", null, {
          lojaId,
          startDate: startDateNoTime,
          endDate: endDateNoTime,
          billType,
        })
      );

      const allBillsArray = retrievedReportData.contasPorParceiro
        .map((billByPartner) =>
          billByPartner.contas.map((c) => ({
            ...c,
            parceiro: billByPartner.parceiro,
          }))
        )
        .reduce((acc, array) => [...acc, ...array], []);

      setReportData(retrievedReportData);
      setReportTable(allBillsArray);
    } catch (error) {
      console.error(error);
      notify("Erro ao buscar os dados.", "warning");
    } finally {
      setLoading(false);
    }
  }

  async function visualizationModeChange(simpleMode) {
    setSimpleMode(simpleMode);
  }

  const dataEmpty = isEmpty(reportData.contasPorParceiro);

  const isReceivingBill =
    get(reportData, "total.tipo", "RECEBER") === "RECEBER";

  return (
    <Card className={classes.root}>
      <Title title="Contas por parceiro" />
      <CardContent>
        <Search
          fields={["dateRange", "billType"]}
          onSearch={retrieveReportData}
          onVisualizationModeChange={visualizationModeChange}
          loading={loading}
          dataEmpty={dataEmpty}
        />
        {!dataEmpty && (
          <Grid container spacing={5}>
            <Grid item md={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h4" fontWeight="bold">
                  Totais
                </Typography>
                <Box display="flex">
                  <BoxTotal
                    label="Em atraso"
                    color="error"
                    value={reportData.total.emAtraso}
                  />
                  <BoxTotal
                    label={isReceivingBill ? "A receber" : "A pagar"}
                    color="primary.dark"
                    value={reportData.total.emAberto}
                  />
                  {isReceivingBill && (
                    <BoxTotal
                      label="Perda"
                      color="warning.main"
                      value={reportData.total.perda}
                    />
                  )}
                  <BoxTotal
                    label={isReceivingBill ? "Recebido" : "Pago"}
                    color="success.main"
                    value={reportData.total.pago}
                  />
                  <BoxTotal
                    label="Total do perído"
                    value={Decimal(reportData.total.pago)
                      .plus(reportData.total.emAberto)
                      .toFixed(2)}
                  />
                </Box>
              </Box>
              <Divider sx={{ mt: 3 }} />
            </Grid>

            {simpleMode && (
              <Grid item md={12}>
                <BillsTable
                  data={reportTable}
                  partnerTitle={isReceivingBill ? "Cliente" : "Fornecedor"}
                />
              </Grid>
            )}

            {!simpleMode &&
              reportData.contasPorParceiro.map((partnerInfo) => (
                <Grid
                  item
                  md={12}
                  key={partnerInfo.total.parceiroId}
                  sx={{ my: 3 }}
                >
                  <Typography variant="h4" fontWeight="bold">
                    {`${isReceivingBill ? "Cliente" : "Fornecedor"}: ${
                      partnerInfo.parceiro
                    }`}
                  </Typography>
                  <Card
                    sx={{
                      border: "1px solid #c5c5c5",
                      mt: 1,
                    }}
                  >
                    <CardContent>
                      <Box
                        display="flex"
                        justifyContent="space-around"
                        marginBottom={2}
                      >
                        <BoxTotal
                          label="Em atraso"
                          color="error"
                          value={partnerInfo.total.emAtraso}
                        />
                        <BoxTotal
                          label={isReceivingBill ? "A receber" : "A pagar"}
                          color="primary.dark"
                          value={partnerInfo.total.emAberto}
                        />
                        {isReceivingBill && (
                          <BoxTotal
                            label="Perda"
                            color="warning.main"
                            value={partnerInfo.total.perda}
                          />
                        )}
                        <BoxTotal
                          label={isReceivingBill ? "Recebido" : "Pago"}
                          color="success.main"
                          value={partnerInfo.total.pago}
                        />
                        <BoxTotal
                          label="Total do perído"
                          value={Decimal(partnerInfo.total.pago)
                            .plus(partnerInfo.total.emAberto)
                            .toFixed(2)}
                        />
                      </Box>
                      <Divider />
                      <BillsTable data={partnerInfo.contas} />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default ReportBillsByPartners;

export const reportBillsByPartners = {
  getMenu: (p, a) => {
    return reportContasEnable(p, a)
      ? {
          key: "ReportBillsByPartners",
          to: "/Conta/contas-por-parceiro",
          primaryText: "Por Parceiro",
        }
      : null;
  },
};
