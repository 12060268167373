import React from "react";
import { Grid, Typography } from "@mui/material";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  EditButton,
} from "react-admin";
import ContaFilterSidebar from "./ContaFilterSidebar";
import ContaFilter from "./ContaFilter";
import { ContaTipoField } from "./ContaTipo";
import ContaStatus from "./ContaStatus";
import { formatCurrency } from "../../common/util/formatter";

const ContaValorVencimento = ({ record }) => {
  if (!record) return null;
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="body1">
          <strong>{formatCurrency(record.valor)}</strong>
        </Typography>
      </Grid>
      <Grid item>
        <DateField
          record={record}
          source="vencimento"
          options={{ timeZone: "UTC" }}
        />
      </Grid>
    </Grid>
  );
};

const ContaValorPagoDataPagamento = ({ record }) => {
  if (!record || !record.pago) return null;
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="body1">
          <strong>{formatCurrency(record.valor_pago)}</strong>
        </Typography>
      </Grid>
      <Grid item>
        <DateField
          record={record}
          source="pagamento"
          options={{ timeZone: "UTC" }}
        />
      </Grid>
    </Grid>
  );
};

const ContaParceiroDesc = ({ record }) => {
  if (!record) return null;
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <ReferenceField
          record={record}
          source="parceiroId"
          reference="parceiros"
          link={false}
        >
          <TextField source="nome_fantasia" />
        </ReferenceField>
      </Grid>
      <Grid item>
        <Typography variant="body2">
          {record.descricao
            ? `${record.parcela}/${record.ocorrencias} - ${record.descricao}`
            : `${record.parcela}/${record.ocorrencias}`}
        </Typography>
      </Grid>
    </Grid>
  );
};

const ContaTipoStatus = ({ record }) => {
  if (!record) return null;
  return (
    <Grid container direction="column" spacing={1} sx={{ my: 1 }}>
      <Grid item>
        <ContaTipoField record={record} label="Tipo" />
      </Grid>
      <Grid item>
        <ContaStatus record={record} label="Status" />
      </Grid>
    </Grid>
  );
};

function Contas(props) {
  return (
    <List
      {...props}
      exporter={false}
      filterDefaultValues={{
        conta_vencimento: "atrasadas",
        pago: false,
        perda: false,
      }}
      aside={<ContaFilterSidebar />}
      filters={<ContaFilter />}
      sort={{ field: "vencimento", order: "ASC" }}
    >
      <Datagrid>
        <ContaTipoStatus label="Tipo e Status" />
        <ContaParceiroDesc label="Parceiro e Descrição" />
        <ContaValorVencimento label="Valor e Vencimento" />
        <ContaValorPagoDataPagamento label="Pago" />
        <EditButton />
      </Datagrid>
    </List>
  );
}

function ContasParcelas({ record }) {
  const idsVinculo = [record.id, record.contaId].filter((id) => id > 0);

  return (
    <List
      basePath="/Conta"
      actions={false}
      exporter={false}
      bulkActionButtons={false}
      pagination={false}
      filterDefaultValues={{
        or: [{ id: { inq: idsVinculo } }, { contaId: { inq: idsVinculo } }],
      }}
      sort={{ field: "id", order: "ASC" }}
    >
      <Datagrid>
        <ContaTipoStatus label="Tipo e Status" />
        <ContaParceiroDesc label="Parceiro e Descrição" />
        <ContaValorVencimento label="Valor e Vencimento" />
        <ContaValorPagoDataPagamento label="Pago" />
        <EditButton />
      </Datagrid>
    </List>
  );
}

export default Contas;

export { ContasParcelas };
