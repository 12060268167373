import React from "react";
import PaymentIcon from "@material-ui/icons/Payment";
import LojaMoedaAppList from "./LojaMoedaAppList";
import { cardapioDigitalEnable } from "../../permissionsHelper";

const lojaMoeda = {
  getResource: (p, a) => {
    return cardapioDigitalEnable(p, a)
      ? {
          name: "Loja_moeda_apps",
          list: LojaMoedaAppList,
        }
      : null;
  },
  getMenu: (p, a) => {
    return cardapioDigitalEnable(p, a)
      ? {
          key: "Loja_moeda_apps",
          to: "/Loja_moeda_apps",
          primaryText: "Moedas",
          leftIcon: iconLojaMoedaApp,
        }
      : null;
  },
};

export default lojaMoeda;
export const iconLojaMoedaApp = <PaymentIcon />;
