import React from "react";
import { Show } from "react-admin";
import GrupoForm from "./GrupoForm";

const GrupoEdit = (props) => (
  <Show {...props}>
    <GrupoForm toolbar={false} />
  </Show>
);

export default GrupoEdit;
