import React from "react";
import Contas from "./ContasList";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ContasCreate from "./ContasCreate";
import ContasEdit from "./ContasEdit";
import { contasEnable } from "../../permissionsHelper";

const contas = {
  getResource: (p, a) => {
    return contasEnable(p, a)
      ? {
          name: "Conta",
          list: Contas,
          create: ContasCreate,
          edit: ContasEdit,
        }
      : null;
  },
  getMenu: (p, a) => {
    return contasEnable(p, a)
      ? {
          key: "Conta",
          to: "/Conta",
          primaryText: "Contas",
          leftIcon: null,
        }
      : null;
  },
};

export const iconConta = <ReceiptIcon />;
export default contas;
