import React, { useState } from "react";
import { Title } from "react-admin";
import { fetchJSON } from "../dataProvider";
import UXDelay from "../dataProvider/uxdelay";
import { useAuthenticated } from "react-admin";
import isEmpty from "lodash.isempty";
import Search from "./Search";
import { Box, Card, CardContent, CardHeader, Grid } from "@mui/material";
import { Bar } from "react-chartjs-2";
import Decimal from "decimal.js";
import { useStyles } from "./ReportDailyInvoicings/styles";
import { reportPDVEnable } from "../permissionsHelper";

const BarChart = ({ title, data, options }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardHeader className={classes.cardHeader} title={title} />
      <CardContent>
        <Box height={300}>
          <Bar data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

const Report10Products = (props) => {
  useAuthenticated();

  const [loading, setLoading] = useState(false);
  const [array, setArray] = useState([]);

  async function retrieveReportData({
    lojaId,
    startDate,
    endDate,
    salesChannels,
    serviceTypes,
    groups,
    units,
  }) {
    try {
      setLoading(true);
      const retrievedReportData = await UXDelay(
        fetchJSON("relatorio/pdv/curva_abc", null, {
          lojaId,
          startDate,
          endDate,
          channels: salesChannels,
          types: serviceTypes,
          groups,
          units,
          order: "lucro",
        })
      );

      const data = retrievedReportData.array.map((p) => ({
        descricao: p.descricao,
        valor_final: Decimal(p.valor_final).toNumber(),
        lucro: Decimal(p.lucro).toNumber(),
        qtd: Decimal(p.qtd).toNumber(),
      }));

      setArray(data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  /*
    descricao: "Carne Fria Kg"
    qtd: 0.55
    valor_final: 38.5
    valor_custo: 0
    valor_unitario_medio: 0
    lucro: 38.5
    qtd_p: 0
    qtd_abc: 0.01
    venda_p: 0.09
    venda_abc: 0.19
    lucro_p: 0.12
    lucro_abc: 0.26
    classe: "A"
  */

  const dataEmpty = isEmpty(array);

  const currencyOptions = {
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
          callback: function (value) {
            return "R$ " + value;
          },
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          callback: function (value) {
            return this.getLabelForValue(value).substring(0, 20);
          },
        },
      },
    },
    indexAxis: "y",
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        anchor: "end",
        align: "left",
        color: "white",
        formatter: function (value, context) {
          return new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(value);
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.x !== null) {
              label += new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(context.parsed.x);
            }
            return label;
          },
        },
      },
    },
  };

  const numberOptions = {
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          callback: function (value) {
            return this.getLabelForValue(value).substring(0, 20);
          },
        },
      },
    },
    indexAxis: "y",
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        anchor: "end",
        align: "left",
        color: "white",
      },
    },
  };

  const top10ByValue = array.top("valor_final", 10);
  const top10ByValueData = {
    labels: top10ByValue.map((p) => p.descricao),
    datasets: [
      {
        label: "Faturamento",
        data: top10ByValue.map((p) => p.valor_final),
        backgroundColor: "#2e7d32",
        yAxisID: "y",
      },
    ],
  };

  const top10ByProfit = array.top("lucro", 10);
  const top10ByProfitData = {
    labels: top10ByProfit.map((p) => p.descricao),
    datasets: [
      {
        label: "Lucro",
        data: top10ByProfit.map((p) => p.lucro),
        backgroundColor: "#0d47a1",
        yAxisID: "y",
      },
    ],
  };

  const top10ByQtt = array.top("qtd", 10);
  const top10ByQttData = {
    labels: top10ByQtt.map((p) => p.descricao),
    datasets: [
      {
        label: "Quantidade",
        data: top10ByQtt.map((p) => p.qtd),
        backgroundColor: "#bf360c",
        yAxisID: "y",
      },
    ],
  };

  const bottom10ByValue = array
    .filter((p) => p.valor_final > 0)
    .bottom("valor_final", 10);
  const bottom10ByValueData = {
    labels: bottom10ByValue.map((p) => p.descricao),
    datasets: [
      {
        label: "Faturamento",
        data: bottom10ByValue.map((p) => p.valor_final),
        backgroundColor: "#4caf50",
        yAxisID: "y",
      },
    ],
  };

  const bottom10ByProfit = array.filter((p) => p.lucro > 0).bottom("lucro", 10);
  const bottom10ByProfitData = {
    labels: bottom10ByProfit.map((p) => p.descricao),
    datasets: [
      {
        label: "Lucro",
        data: bottom10ByProfit.map((p) => p.lucro),
        backgroundColor: "#2196f3",
        yAxisID: "y",
      },
    ],
  };

  const bottom10ByQtt = array.filter((p) => p.qtd > 0).bottom("qtd", 10);
  const bottom10ByQttData = {
    labels: bottom10ByQtt.map((p) => p.descricao),
    datasets: [
      {
        label: "Quantidade",
        data: bottom10ByQtt.map((p) => p.qtd),
        backgroundColor: "#ff5722",
        yAxisID: "y",
      },
    ],
  };

  return (
    <Card sx={{ minHeight: "100%" }}>
      <Title title="10 Produtos Mais Vendidos" />
      <CardContent>
        <Search
          fields={[
            "dateRange",
            "timeRange",
            "salesChannels",
            "serviceTypes",
            "groups",
            "units",
          ]}
          onSearch={retrieveReportData}
          loading={loading}
          dataEmpty={dataEmpty}
        />
        {!dataEmpty && (
          <Grid container item spacing={5}>
            <Grid item xs={6} md={6}>
              <BarChart
                title={"10+ Por Faturamento"}
                data={top10ByValueData}
                options={currencyOptions}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <BarChart
                title={"10- Por Faturamento"}
                data={bottom10ByValueData}
                options={currencyOptions}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <BarChart
                title={"10+ Por Lucro"}
                data={top10ByProfitData}
                options={currencyOptions}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <BarChart
                title={"10- Por Lucro"}
                data={bottom10ByProfitData}
                options={currencyOptions}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <BarChart
                title={"10+ Por Quantidade"}
                data={top10ByQttData}
                options={numberOptions}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <BarChart
                title={"10- Por Quantidade"}
                data={bottom10ByQttData}
                options={numberOptions}
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default Report10Products;

export const productsTop = {
  getMenu: (p, a) =>
    reportPDVEnable(p, a)
      ? {
          key: "produtctsTop",
          to: "/relatorio/produtos-mais-vendidos",
          primaryText: "Produtos 10+ / 10-",
        }
      : null,
};
