import { Card, CardContent } from "@material-ui/core";
import React, { useState } from "react";
import { Title, useAuthenticated, useNotify } from "react-admin";

import { withUser } from "../../common/util/hocs";
import { reportCardapioDigitalEnable } from "../../permissionsHelper";
import { getLast2WeeksData } from "./services";
import { useStyles } from "./styles";
import WeeklyPerformanceChart from "./WeeklyPerformanceChart";
import Search from "../Search";
import { get, isEmpty } from "lodash";

const ReportWeeklyPerformance = (props) => {
  useAuthenticated();
  const classes = useStyles();
  const notify = useNotify();
  const [last2WeeksData, setLast2WeeksData] = useState({});
  const [days, setDays] = useState(true);
  const [loading, setLoading] = useState(false);

  async function retrieveReportData({
    lojaId,
    startDate,
    endDate,
    cdpModes,
    equipamentos,
    days,
  }) {
    try {
      setLoading(true);
      setDays(days);
      const data = await getLast2WeeksData(
        lojaId,
        startDate,
        endDate,
        cdpModes,
        equipamentos,
        days
      );
      setLast2WeeksData(data);
    } catch (error) {
      console.error(error);
      notify("Erro ao buscar os dados.", "warning");
    } finally {
      setLoading(false);
    }
  }

  const dataEmpty =
    isEmpty(get(last2WeeksData, "week1.general")) &&
    isEmpty(get(last2WeeksData, "week2.general"));

  return (
    <Card className={classes.root}>
      <Title title="Desempenho Semanal do Cardápio Digital" />
      <CardContent>
        <Search
          fields={["dateRange", "cdpModes", "equipamentos"]}
          onSearch={retrieveReportData}
          loading={loading}
          dataEmpty={dataEmpty}
        />
        {!dataEmpty && (
          <WeeklyPerformanceChart chartData={last2WeeksData} days={days} />
        )}
      </CardContent>
    </Card>
  );
};

export default withUser(ReportWeeklyPerformance);

export const reportWeeklyPerformance = {
  getMenu: (p, a) => {
    return reportCardapioDigitalEnable(p, a)
      ? {
          key: "ReportWeeklyPerformance",
          to: "/relatorio/weekly_performance",
          primaryText: "Desempenho Semanal",
        }
      : null;
  },
};
