import React from "react";
import {
  Title,
  Error,
  useGetList,
  Create,
  Toolbar,
  SaveButton,
  Loading,
} from "react-admin";
import { Box, Card } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import ImpressaoConfigForm from "../resources/impressao_config/ImpressaoConfigForm";
import logo from "../static/media/printer.png";

const useStyles = makeStyles((theme) => ({
  cover: {
    minHeight: "260px",
    height: "100%",
    backgroundColor: "#CCC",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
  },
  image: {},
}));

export function ComprovanteLogo() {
  const classes = useStyles();

  return (
    <div className={classes.cover}>
      <img src={logo} className={classes.image} alt="Comprovante" />
    </div>
  );
}

function ImpressaoConfig(props) {
  const resource = "impressao_configs";
  const basePath = "/impressao_configs";
  const { data, isLoading, error } = useGetList(resource, { limit: 1 });

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  const keys = Object.keys(data);
  const record = keys.length > 0 ? data[keys[0]] : null;

  return (
    <Card sx={{ display: "flex", p: 3 }}>
      <Title title="Configuração da Impressão" />
      <Box sx={{ width: 1 / 4 }}>
        <ComprovanteLogo />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", width: 3 / 4 }}>
        <Create
          basePath={basePath}
          resource={resource}
          record={record}
          component="div"
          title={" "}
        >
          <ImpressaoConfigForm
            redirect={false}
            toolbar={
              <Toolbar {...props}>
                <SaveButton />
              </Toolbar>
            }
          />
        </Create>
      </Box>
    </Card>
  );
}

export default ImpressaoConfig;
