import React from "react";
import {
  Datagrid,
  List,
  TextField,
  TextInput,
  Filter,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";

import CurrencyField from "../../common/form/CurrencyField";
import ButtonStatusVenda from "./ButtonStatusVenda";
import LinkToPergunta from "./LinkToPergunta";
import ListImage from "../../common/ListImage";

const RespostaFilter = (props) => (
  <Filter {...props}>
    <TextInput source="nome_like" label="Resposta" alwaysOn />

    <ReferenceInput
      label="Pergunta"
      source="perguntaId"
      reference="pergunta"
      alwaysOn
    >
      <AutocompleteInput optionText="descricao" />
    </ReferenceInput>
  </Filter>
);

const RespostaList = (props) => (
  <List
    {...props}
    filters={<RespostaFilter />}
    bulkActionButtons={false}
    exporter={false}
  >
    <Datagrid>
      <ListImage label="Imagem" />
      <TextField source="nome" label="Resposta" />
      <ReferenceField source="produtoId" reference="produtos" label="Produto">
        <TextField source="desc_prod" />
      </ReferenceField>
      <CurrencyField source="valor" label="R$ Valor" />
      <ButtonStatusVenda label="Ativo" />
      <LinkToPergunta />
    </Datagrid>
  </List>
);

export default RespostaList;
