import React, { useState } from "react";
import {
  Toolbar,
  SimpleForm,
  TextInput,
  NumberInput,
  AutocompleteInput,
  required,
  maxLength,
  minLength,
  minValue,
} from "react-admin";
import { useForm } from "react-final-form";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import LazyReferenceInput from "../../common/form/LazyReferenceInput";
import useProdutoValidateCod from "../produto/useProdutoValidateCod";
import ProdutoProximoCodigo from "../produto/ProdutoProximoCodigo";

const validateReq = [required()];
const validateNome = [required(), minLength(1), maxLength(1000)];

const filter_NCM_CEST = (searchText) => {
  if (searchText.match(/^\d+$/)) {
    return { id: { like: `${searchText}%` } };
  } else {
    return { q: searchText };
  }
};

const match_NCM_CEST = (filter, choice) => true;

const useStyles = makeStyles({
  formGroup: {
    display: "inline-block",
    marginRight: 32,
  },
  mr96: {
    display: "inline-block",
    marginRight: 96,
  },
  buttonIcon: {
    margin: 0,
    padding: 0,
  },
});

function DialogProduto({ produto, loja, member, handleClose }) {
  const classes = useStyles();
  const form = useForm();

  const { uniq: uniqCod } = useProdutoValidateCod(produto, loja.id, "cod_prod");
  const { uniq: uniqAux } = useProdutoValidateCod(produto, loja.id, "cod_aux");

  const validateCodProd = [required(), minValue(1), uniqCod];
  const validateCodAux = [minLength(1), uniqAux];

  const handleSave = (data) => {
    form.change(`${member}.produto`, data);
    handleClose();
  };

  return (
    <Dialog maxWidth="xl" open={true} onClose={handleClose}>
      <DialogTitle>Criar produto</DialogTitle>
      <DialogContent>
        <SimpleForm
          toolbar={<Toolbar alwaysEnableSaveButton />}
          save={handleSave}
          record={produto}
        >
          <NumberInput
            source="cod_prod"
            label="Código do produto"
            validate={validateCodProd}
            formClassName={classes.formGroup}
            InputProps={{
              endAdornment: <ProdutoProximoCodigo lojaId={loja.id} />,
            }}
          />
          <TextInput
            source="cod_aux"
            label="Código auxiliar do produto"
            validate={validateCodAux}
            formClassName={classes.formGroup}
          />
          <div />

          <TextInput
            source="desc_prod"
            label="Nome do produto"
            validate={validateNome}
            fullWidth
          />

          <LazyReferenceInput
            label="Grupo"
            source="grupoId"
            reference="grupos"
            nameSource="grupo"
            addCreateButton={true}
            getDataFromStore={true}
            formClassName={classes.formGroup}
          >
            <AutocompleteInput optionText="nome" />
          </LazyReferenceInput>

          <LazyReferenceInput
            label="Unidade"
            source="unidadeId"
            reference="unidades"
            nameSource="unidade"
            addCreateButton={true}
            getDataFromStore={true}
            formClassName={classes.formGroup}
          >
            <AutocompleteInput optionText="nome" />
          </LazyReferenceInput>

          <LazyReferenceInput
            label="Impressora"
            source="impressoraId"
            reference="impressoras"
            nameSource="impressora"
            getDataFromStore={true}
            allowEmpty
            formClassName={classes.formGroup}
          >
            <AutocompleteInput optionText="nome" />
          </LazyReferenceInput>
          <div></div>

          <LazyReferenceInput
            label="Imposto"
            source="impostoId"
            reference="impostos"
            nameSource="imposto"
            getDataFromStore={true}
            formClassName={classes.mr96}
          >
            <AutocompleteInput optionText="nome" />
          </LazyReferenceInput>

          <LazyReferenceInput
            label="NCM"
            source="ncmId"
            reference="ncms"
            nameSource="ncm"
            loadCompleted={true}
            getDataFromStore={true}
            validate={validateReq}
            filterToQuery={filter_NCM_CEST}
            minLength={3}
            formClassName={classes.mr96}
          >
            <AutocompleteInput
              matchSuggestion={match_NCM_CEST}
              optionText={(i) => (i && i.id ? `${i.id} - ${i.descricao}` : "")}
            />
          </LazyReferenceInput>

          <LazyReferenceInput
            label="CEST"
            source="cestId"
            reference="cests"
            nameSource="cest"
            loadCompleted={true}
            getDataFromStore={true}
            allowEmpty
            filterToQuery={filter_NCM_CEST}
            minLength={3}
            formClassName={classes.formGroup}
          >
            <AutocompleteInput
              matchSuggestion={match_NCM_CEST}
              optionText={(i) =>
                i && i.id ? `${i.id} - ${i.descricao}` : "Nenhum"
              }
            />
          </LazyReferenceInput>
        </SimpleForm>
      </DialogContent>
    </Dialog>
  );
}

function CriaProdutoButton({ item, member, loja }) {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const produtoToEdit = item.produto.id ? item.produtoNovo : item.produto;
  const handleClose = () => setShowDialog(false);
  const handleClick = () => setShowDialog(true);

  return (
    <>
      <Button
        onClick={handleClick}
        color="primary"
        className={classes.buttonIcon}
      >
        <AddIcon />
      </Button>
      {showDialog && produtoToEdit && (
        <DialogProduto
          loja={loja}
          member={member}
          produto={produtoToEdit}
          handleClose={handleClose}
        />
      )}
    </>
  );
}

export default CriaProdutoButton;
