import React from "react";
import {
  TextField,
} from "react-admin";
import { Grid } from "@material-ui/core";
import get from "lodash/get";
import PaymentMethodIcon from "../../reports/ReportDailyInvoicings/PaymentMethodIcon";

const MoedaAppIconText = ({record, source}) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing="3">
      <Grid item>
        <PaymentMethodIcon paymentLabel={get(record, source, "")} />
      </Grid>
      <Grid item>
        <TextField record={record} source={source} />
      </Grid>
    </Grid>
  )
}

export default MoedaAppIconText;
