import React, { Fragment } from "react";
import { AutocompleteInput, required, minValue } from "react-admin";
import { useForm } from "react-final-form";
import { InputAdornment } from "@material-ui/core";

import LazyReferenceInput from "../../common/form/LazyReferenceInput";
import NumberInputSimple from "../../common/form/NumberInputSimple";

const validateTaxa = [required(), minValue(0)];
const validateDias = [required(), minValue(0)];

const styles = {
  formGroup: {
    display: "inline-block",
    marginRight: 32,
  },
};

function MoedasForm({ formData, ...rest }) {
  const form = useForm();

  if (!formData.moedaAdquirenteId) {
    form.change("taxa", 0);
    form.change("dias", 0);
  }

  return (
    <Fragment>
      <div style={styles.formGroup}>
        <LazyReferenceInput
          label="Adquirente"
          source="moedaAdquirenteId"
          reference="Moeda_adquirentes"
          nameSource="adquirente"
          allowEmpty
          {...rest}
        >
          <AutocompleteInput optionText="nome" />
        </LazyReferenceInput>
      </div>

      {formData.moedaAdquirenteId && (
        <NumberInputSimple
          source="taxa"
          label="Taxa da adquirente"
          style={styles.formGroup}
          initialValue={0}
          validate={validateTaxa}
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          {...rest}
        />
      )}

      {formData.moedaAdquirenteId && (
        <NumberInputSimple
          source="dias"
          label="Tempo para recebimento"
          style={styles.formGroup}
          initialValue={0}
          validate={validateDias}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">Dias</InputAdornment>
            ),
          }}
          {...rest}
        />
      )}
    </Fragment>
  );
}

export default MoedasForm;
