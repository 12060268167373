import React from "react";
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  FunctionField,
} from "react-admin";

import ImpostoFilter from "./ImpostoFilter";

const ImpostoField = ({ field }) => {
  const cstField = `${field}_cst`;
  const alqField = `${field}_alq`;
  return (
    <FunctionField
      render={(record) => {
        const cst = record[cstField];
        const alq = record[alqField];

        const alqText = alq === null || alq === undefined ? "" : `${alq}%`;

        return `${cst} - ${alqText}`;
      }}
    />
  );
};

const ImpostoList = (props) => (
  <List {...props} filters={<ImpostoFilter />} exporter={false}>
    <Datagrid>
      <TextField source="nome" label="Nome" />
      <ImpostoField field="icms" label="ICMS" />
      <ImpostoField field="pis" label="PIS" />
      <ImpostoField field="cofins" label="COFINS" />
      <TextField source="cfop_estadual" label="CFOP Estadual" />
      <ImpostoField field="ipi" label="IPI" />
      <EditButton />
    </Datagrid>
  </List>
);

export default ImpostoList;
