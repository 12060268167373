import React from 'react';
import { useCreate, useNotify, useRefresh, Button } from 'react-admin';

//https://marmelab.com/react-admin/Actions.html#using-a-custom-action-creator

const SincronizarButton = ({ record }) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const [sincronizar, { loading }] = useCreate(
        'equipamento_eventos',
        { equipamentoId: record.id },
        {
            action: 'sincronizar',
            undoable: true,
            onSuccess: ({ data }) => {
                refresh();
                notify('Sincronização iniciada', 'info', {}, true);
            },
            onFailure: (error) => notify(`Erro: ${error.message}`, 'warning'),
        }
    );

    return <Button label="Sincronizar" onClick={sincronizar} disabled={loading} />;
};

export default SincronizarButton;