import React from "react";
import LanguageIcon from "@material-ui/icons/Language";
import NFeResumoList from "./NFeList";
import NFeEdit from "./NFeEdit";
import { estoqueEnable } from "../../permissionsHelper";

const nfe = {
  getResource: (p, a) => {
    return estoqueEnable(p, a)
      ? {
          name: "nfes",
          list: NFeResumoList,
          edit: NFeEdit,
        }
      : null;
  },
  getMenu: (p, a) => {
    return estoqueEnable(p, a)
      ? {
          key: "nfes",
          to: "/nfes",
          primaryText: "NFe Tomadas",
          leftIcon: iconNFeResumo,
        }
      : null;
  },
};

export default nfe;
export const iconNFeResumo = <LanguageIcon />;
