import React from "react";
import {
  TextInput,
  NumberInput,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
  DateTimeInput,
} from "react-admin";
import QuickFilter from "../../common/form/QuickFilter";

export const produto_filters = {
  produto_promocao_ativa: {
    promocao_desconto: { gt: 0 },
    promocao_de: { lt: new Date().toISOString() },
    promocao_ate: { gt: new Date().toISOString() },
  },
};

const ProdutoFilter = (props) => (
  <Filter {...props}>
    <TextInput source="q" label="Nome" alwaysOn />
    <QuickFilter
      source="produto_promocao_ativa"
      label="Promoção ativa"
      defaultValue={true}
    />
    <NumberInput source="cod_prod" />
    <TextInput source="cod_aux" />
    <NumberInput source="preco" />
    <DateTimeInput source="updatedAt_gte" label="Atualizado de" />
    <DateTimeInput source="updatedAt_lte" label="Atualizado até" />

    <ReferenceInput
      source="grupoId"
      reference="grupos"
      sort={{ field: "id", order: "ASC" }}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput>

    <ReferenceInput
      source="unidadeId"
      reference="unidades"
      sort={{ field: "id", order: "ASC" }}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput>

    <ReferenceInput
      source="impressoraId"
      reference="impressoras"
      sort={{ field: "id", order: "ASC" }}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput>

    <ReferenceInput
      source="impostoId"
      reference="impostos"
      sort={{ field: "id", order: "ASC" }}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput>

    <BooleanInput source="stt_prod" label="Ativo" />
    <TextInput source="appNome_like" label="Nome App Delivery" />
    <BooleanInput source="appDelivery" label="Delivery" />
    <BooleanInput source="appBalcao" label="Balcão" />
    <BooleanInput source="appMesa" label="Na mesa" />
    <BooleanInput source="appDestaque" label="Destaque" />
    <BooleanInput source="appPdvMobile" label="PDV Mobile" />

    <BooleanInput source="balanca_prod" label="Envia para balança" />
    <BooleanInput source="paga_servico_prod" label="Paga serviço do garçom" />
    <BooleanInput source="adicional" label="Adicional" />
    <BooleanInput source="materia_prima_prod" label="Matéria prima" />
    <BooleanInput source="alcoolico" label="Alcoólico" />
  </Filter>
);

export default ProdutoFilter;
