import React, { useEffect, useState } from "react";
import {
  Confirm,
  Edit,
  Toolbar,
  SaveButton,
  TextField,
  FunctionField,
  Datagrid,
  useDataProvider,
  useNotify,
  useRedirect,
} from "react-admin";
import Decimal from "decimal.js";
import { keyBy } from "lodash";
import { red, green } from "@material-ui/core/colors";
import { Box, DialogContentText } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { fetchJSON } from "../../dataProvider";
import CurrencyField from "../../common/form/CurrencyField";
import NFeForm from "./NFeForm";
import { calculaEstoqueCustoVendaItens } from "./calculos";

const cores = [
  "#ffcc80",
  "#fff59d",

  "#e6ee9c",
  "#a5d6a7",

  "#80cbc4",
  "#81d4fa",

  "#90caf9",
  "#b39ddb",

  "#ce93d8",
  "#ef9a9a",
];

const NFeToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const DialogSave = ({ showDialog, formData, save, handleCloseDialog }) => {
  if (!showDialog) return null;
  const itensMudaVenda = formData.itens
    .filter((i) => i.produto.id)
    .filter((i) => !Decimal(i.venda).equals(i.produto.preco_vnd_prod))
    .map((i) => {
      const {
        nItem,
        produto: { desc_prod, preco_vnd_prod },
        venda: vendaStr,
      } = i;
      const venda = Decimal(vendaStr);
      const diff = venda.minus(preco_vnd_prod).toNumber();

      return {
        nItem,
        desc: desc_prod,
        vendaAnterior: preco_vnd_prod,
        vendaProxima: venda.toNumber(),
        diff:
          diff > 0 ? (
            <ArrowUpwardIcon style={{ color: green[500] }} />
          ) : (
            <ArrowDownwardIcon style={{ color: red[500] }} />
          ),
      };
    });

  const handleSave = (e) => {
    handleCloseDialog();
    save(formData);
  };

  return (
    <Confirm
      isOpen={showDialog}
      title={"Alterar os preços de venda?"}
      maxWidth="md"
      fullWidth={true}
      content={
        <>
          <DialogContentText>
            Você alterou o preço de venda dos seguintes produtos:
          </DialogContentText>
          <Box my={6}>
            <Datagrid
              data={keyBy(itensMudaVenda, "nItem")}
              ids={itensMudaVenda.map(({ nItem }) => nItem)}
              currentSort={{ field: "nItem", order: "ASC" }}
              fullWidth
            >
              <TextField source="desc" label="Produto" />
              <CurrencyField source="vendaAnterior" label="R$ de" />
              <FunctionField label="" render={(record) => record.diff} />
              <CurrencyField source="vendaProxima" label="R$ para" />
            </Datagrid>
          </Box>
        </>
      }
      confirm={"Alterar preços e salvar NFe"}
      onConfirm={handleSave}
      onClose={handleCloseDialog}
    />
  );
};

const WrappedNFeForm = ({ save, record, ...rest }) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [saving, setSaving] = useState(false);
  const [formData, setFormData] = useState(null);
  const [arrays, setArrays] = useState({
    itens: [],
    contas: [],
  });

  function isItemRepetido(item1, item2) {
    if (item1.cEANTrib === "SEM GTIN") {
      return item1.cProd === item2.cProd;
    }

    return item1.cEANTrib === item2.cEANTrib;
  }

  useEffect(() => {
    const coresOpcoes = [...cores];
    if (arrays.itens.length === 0 && record.itens instanceof Array) {
      const itens = record.itens.map((i, index) => {
        i.index = index;
        i.indexRepetidos = record.itens
          .map((j, idx) => (isItemRepetido(i, j) ? idx : null))
          .filter((idx) => idx !== null);
        i.repetido = i.indexRepetidos.length > 1;
        i.color =
          i.repetido && i.index === i.indexRepetidos[0]
            ? coresOpcoes.pop()
            : null;

        return i;
      });
      calculaEstoqueCustoVendaItens(itens);

      const contas = record.contas.map((c, index) => ({ ...c, index }));
      setArrays({
        itens,
        contas,
      });
    }
  }, [arrays, record]);

  const transform = (data) => {
    const { classificacaoId, centroCustoId, contas } = data;
    const novasContas = contas.map((c) =>
      c.id
        ? c
        : {
            ...c,
            classificacaoId,
            centroCustoId,
          }
    );

    delete data.classificacaoId;
    delete data.centroCustoId;

    return {
      ...data,
      contas: novasContas,
    };
  };

  const customSave = async (values) => {
    if (saving) return;
    try {
      setSaving(true);
      const json = await fetchJSON("nfes/entrada", {
        method: "POST",
        body: JSON.stringify(transform(values)),
      });

      await dataProvider.update("nfes", {
        onlyUpdateLocalStore: true,
        data: json,
      });

      notify("NFe salva com sucesso");
      redirect("list", "/nfes");
    } catch (err) {
      console.log(err);
      notify("Erro ao salvar NFe", "warning");
      setSaving(false);
    }
  };

  const handleCloseDialog = () => setFormData(null);

  const saveWithConfirmation = async (values) => {
    const produtosMudaVenda = values.itens
      .filter((i) => i.produto.id)
      .filter((i) => !Decimal(i.venda).equals(i.produto.preco_vnd_prod));

    if (produtosMudaVenda.length > 0) {
      setFormData(values);
      return;
    }

    return customSave(values);
  };

  return (
    <>
      <NFeForm
        save={saveWithConfirmation}
        {...rest}
        record={{ ...record, ...arrays }}
        saving={saving}
      />
      <DialogSave
        formData={formData}
        showDialog={!!formData}
        save={customSave}
        handleCloseDialog={handleCloseDialog}
      />
    </>
  );
};

const NFeEdit = (props) => (
  <Edit {...props}>
    <WrappedNFeForm toolbar={<NFeToolbar />} />
  </Edit>
);

export default NFeEdit;
