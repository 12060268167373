import React from "react";
import { BooleanInput, Button } from "react-admin";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

import { useForm } from "react-final-form";
import { Box } from "@mui/material";

export default function ArrayInputRemoveButton({
  label,
  formData,
  itemData,
  getSource,
  ...rest
}) {
  const form = useForm();

  const handleRemove = (e) => {
    const source = getSource("");
    const idx = source.lastIndexOf("[");
    const arrayName = source.substring(0, idx);
    const itemIndex = parseInt(
      source.substring(idx + 1, source.length - 2),
      10
    );
    const newArray = [...formData[arrayName].filter((_, i) => i !== itemIndex)];
    form.change(arrayName, newArray);
  };

  return itemData && itemData.id ? (
    <BooleanInput
      source={getSource("deletar")}
      label={label || "Excluir"}
      {...rest}
    />
  ) : (
    <Box mb={2}>
      <Button label="Remover" onClick={handleRemove} {...rest}>
        <RemoveCircleOutlineIcon />
      </Button>
    </Box>
  );
}
