import React from "react";
import { stringify } from "query-string";
import Star from "@material-ui/icons/Star";
import Done from "@material-ui/icons/Done";
import Close from "@material-ui/icons/Close";
import CaixaEdit from "./CaixaEdit";
import CaixaList from "./CaixaList";

const isEnable = (permissao, admin_user) =>
  permissao.caixa_conferir && admin_user.nuvem;

const caixa = {
  getResource: (p, a) => {
    return isEnable(p, a)
      ? {
          name: "caixas",
          list: CaixaList,
          edit: CaixaEdit,
        }
      : null;
  },
};

export const caixaTodos = {
  getMenu: (p, a) => {
    return isEnable(p, a)
      ? {
          key: "caixaTodos",
          to: "/caixas",
          primaryText: "Todos",
          leftIcon: iconCaixaTodos,
        }
      : null;
  },
};

export default caixa;

export const caixaConferidos = {
  getMenu: (p, a) => {
    return isEnable(p, a)
      ? {
          key: "caixasConferidos",
          to: {
            pathname: `/Caixas`,
            search: stringify({
              page: 1,
              perPage: 10,
              sort: "abertura",
              order: "DESC",
              filter: JSON.stringify({ status: 2 }),
            }),
          },
          primaryText: "Conferidos",
          leftIcon: iconCaixaConferidos,
        }
      : null;
  },
};

export const caixaNaoConferidos = {
  getMenu: (p, a) => {
    return isEnable(p, a)
      ? {
          key: "caixasNaoConferidos",
          to: {
            pathname: `/Caixas`,
            search: stringify({
              page: 1,
              perPage: 10,
              sort: "abertura",
              order: "DESC",
              filter: JSON.stringify({ status: 1 }),
            }),
          },
          primaryText: "Não conferidos",
          leftIcon: iconCaixaNaoConferidos,
        }
      : null;
  },
};

export const iconCaixaTodos = <Star />;
export const iconCaixaConferidos = <Done />;
export const iconCaixaNaoConferidos = <Close />;
