import React, { useState } from "react";
import { useNotify, useDataProvider } from "react-admin";
import PanToolIcon from "@material-ui/icons/PanTool";
import { fetchJSON } from "../../dataProvider";
import LoadingButton from "../../common/LoadingButton";
import { yellow } from "@material-ui/core/colors";
import { styled } from "@mui/material/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";

const ColorButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: yellow[700],
  "&:hover": {
    backgroundColor: yellow[900],
  },
}));

function NFeOperacaoNaoRealizadaButton({ record }) {
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const [justificativa, setJustificativa] = useState("");
  const [helper, setHelper] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleOpenClick = () => setOpen(true);
  const handleCloseClick = () => setOpen(false);

  const minLen = 10;

  const handleChangeText = (event) => {
    setJustificativa(event.target.value);
    if (event.target.value.length > minLen) {
      setHelper("");
    }
  };

  const handleOpNaoRealizadaClick = async () => {
    if (justificativa.length < minLen) {
      const msg = `Preencha a justificativa com no mínimo ${minLen} caracteres!`;
      setHelper(msg);
      notify(msg);
      return;
    }

    setLoading(true);
    try {
      const resp = await fetchJSON("Sefaz/operacaoNaoRealizada", {
        method: "POST",
        body: JSON.stringify({
          eventoId: record.id,
          lojaId: record.lojaId,
          justificativa,
        }),
      });

      await dataProvider.update("nfes", {
        onlyUpdateLocalStore: true,
        data: {
          ...record,
          ...resp.nfeInstance,
        },
      });

      notify("Ciência da operação manifestada com sucesso");
    } catch (exception) {
      notify("Erro ao manifestar ciência da operação", "warning");
      console.log(JSON.stringify(exception));
      setErrorMessage(exception.body.error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ColorButton
        text="Manifestar"
        onClick={handleOpenClick}
        icon={<PanToolIcon fontSize="large" />}
        variant="contained"
        size="large"
      />
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={handleCloseClick}
      >
        <DialogTitle>Manifestar operação não realizada</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom sx={{}}>
            Justificativa:
          </Typography>

          <TextField
            fullWidth
            multiline
            rows={4}
            value={justificativa}
            onChange={handleChangeText}
            helperText={helper}
            error={!!helper}
            inputProps={{ maxLength: 512 }}
          />

          {errorMessage && (
            <Typography
              variant="body1"
              gutterBottom
              sx={{ my: 3, color: "red" }}
            >
              {errorMessage}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <ColorButton
            text="Manifestar operação não realizada"
            onClick={handleOpNaoRealizadaClick}
            loading={loading}
            icon={<PanToolIcon fontSize="large" />}
            variant="contained"
            size="large"
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NFeOperacaoNaoRealizadaButton;
