import React from "react";
import { SimpleForm, TextInput, DateTimeInput } from "react-admin";
import { Card, CardContent, Box, Typography } from "@material-ui/core";
import MovimentoDetalhes from "./MovimentoDetalhes";
import MovimentoNFCe from "./MovimentoNFCe";

function MovimentoForm(props) {
  const { record } = props;

  return (
    <Card>
      <SimpleForm {...props} toolbar={props.toolbar} redirect="list">
        <CardContent fullWidth>
          <Box display={{ md: "block", lg: "flex" }}>
            <Box flex={2} mr={{ md: 0, lg: "1em" }}>
              <Typography variant="h6" gutterBottom>
                Movimento
              </Typography>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="id" label="Id" fullWidth />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="nsp" label="NSP" fullWidth />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="mov" label="MOV" fullWidth />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <DateTimeInput source="recebido_em" label="Data" fullWidth />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="comanda"
                    label="Comanda / Mesa"
                    fullWidth
                  />
                </Box>
              </Box>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="descricao" label="Descrição" fullWidth />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="observacao" label="Observação" fullWidth />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="identificador"
                    label="Identificador"
                    fullWidth
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="canal_venda" label="Canal" fullWidth />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="tipo_venda" label="Tipo" fullWidth />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="cpf_cnpj" label="CPF / CNPJ" fullWidth />
                </Box>
              </Box>

              <MovimentoDetalhes record={record} />

              <MovimentoNFCe record={record} />
            </Box>
          </Box>
        </CardContent>
      </SimpleForm>
    </Card>
  );
}

export default MovimentoForm;
