import React from "react";
import deepOrange from "@material-ui/core/colors/deepOrange";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { green, pink, red, purple, blue, yellow } from "@mui/material/colors";

const situacao = {
  1: (
    <Chip
      key="nfe-status-autorizada"
      label="Autorizada"
      size="small"
      style={{ margin: "2px" }}
    />
  ),
  2: (
    <Chip
      key="nfe-status-denegada"
      label="Denegada"
      size="small"
      style={{
        margin: "2px",
        backgroundColor: deepOrange[200],
        borderColor: deepOrange[200],
      }}
    />
  ),
  3: (
    <Chip
      key="nfe-status-cancelada"
      label="Cancelada"
      size="small"
      style={{
        margin: "2px",
        backgroundColor: deepOrange[200],
        borderColor: deepOrange[200],
      }}
    />
  ),
};

const NFeStatus = ({ record }) => {
  if (!record) return null;
  const chips1 = [];
  const chips2 = [];

  const operacoes = [
    {
      operacao: "Ciência",
      data: record.cienciaOperacaoData,
      cor: blue,
    },
    {
      operacao: "Confirmação",
      data: record.confirmacaoOperacaoData,
      cor: green,
    },
    {
      operacao: "Não Realizada",
      data: record.operacaoNaoRealizadaData,
      cor: yellow,
    },
    {
      operacao: "Desconhecimento",
      data: record.desconhecimentoOperacaoData,
      cor: red,
    },
  ];

  const ultimaOperacao = operacoes
    .filter((o) => !!o.data)
    .sort((a, b) => new Date(a.data) - new Date(b.data))
    .last();

  if (situacao[record.cSitNFe]) {
    chips1.push(situacao[record.cSitNFe]);
  }

  if (ultimaOperacao) {
    chips1.push(
      <Chip
        key="nfe-operacao"
        label={ultimaOperacao.operacao}
        size="small"
        style={{
          margin: "2px",
          backgroundColor: ultimaOperacao.cor[200],
          borderColor: ultimaOperacao.cor[200],
        }}
      />
    );
  }

  if (record.xmlUrl) {
    chips2.push(
      <Chip
        key="nfe-status-xml"
        label="XML"
        size="small"
        style={{
          margin: "2px",
          color: "white",
          backgroundColor: pink[600],
          borderColor: pink[600],
        }}
      />
    );
  }

  if (record.cienciaOperacao && !record.xmlUrl) {
    chips2.push(
      <Chip
        key="nfe-status-aguardando-xml"
        label="Aguardando XML"
        size="small"
        style={{
          margin: "2px",
          color: "white",
          backgroundColor: pink[600],
          borderColor: pink[600],
        }}
      />
    );
  }

  if (record.entrada) {
    chips2.push(
      <Chip
        key="nfe-status-entrada"
        label="Entrada"
        size="small"
        style={{
          margin: "2px",
          color: "white",
          backgroundColor: purple[600],
          borderColor: purple[600],
        }}
      />
    );
  }

  return (
    <Box sx={{ maxWidth: "200px" }}>
      <Box mb={1}>{chips1.map((c) => c)}</Box>
      <Box>{chips2.map((c) => c)}</Box>
    </Box>
  );
};

export default NFeStatus;
