import React from "react";
import { TextInput, Filter } from "react-admin";

const MoedaFilter = props => (
  <Filter {...props}>
    <TextInput source="nome_like" label="Nome da moeda" alwaysOn />
  </Filter>
);

export default MoedaFilter;
