import Decimal from "decimal.js";

export function calculateProfitMargin(sellingPrice, costPrice, decimals = 2) {
  const cost = Decimal(costPrice);
  if (cost.equals(0)) {
    return "0";
  }

  return Decimal(sellingPrice)
    .dividedBy(costPrice)
    .minus(1)
    .times(100)
    .toFixed(decimals);
}

export function calculateSellingPrice(profitMargin, costPrice) {
  return Decimal(costPrice)
    .times(profitMargin)
    .dividedBy(100)
    .plus(costPrice)
    .toFixed(2);
}
