import moment from "moment";
import { fetchJSON } from "../../dataProvider";
import UXDelay from "../../dataProvider/uxdelay";

const fetchData = (lojaId, startDate, endDate, cdpModes, equipamentos) =>
  UXDelay(
    fetchJSON("Relatorio/cdp/faturamento_diario", null, {
      lojaId,
      startDate,
      endDate,
      modes: cdpModes,
      equipamentos,
    })
  );

export const getLast2WeeksData = async (
  lojaId,
  startDate,
  endDate,
  cdpModes,
  equipamentos,
  days
) => {
  const startDate2 = moment(startDate).subtract(days, "days");
  const endDate2 = moment(endDate).subtract(days, "days");

  const [week1, week2] = await Promise.all([
    fetchData(lojaId, startDate, endDate, cdpModes, equipamentos),
    fetchData(
      lojaId,
      startDate2.toISOString(),
      endDate2.toISOString(),
      cdpModes,
      equipamentos
    ),
  ]);
  return { week1, week2, params: { startDate, endDate, startDate2, endDate2 } };
};
