import React, { cloneElement } from "react";
import {
  Datagrid,
  List,
  TextField,
  NumberField,
  EditButton,
  Button,
  useRedirect,
  TopToolbar,
  sanitizeListRestProps,
  useListContext,
  CreateButton,
} from "react-admin";

import NcmFilter from "./NcmFilter";

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, showFilter } =
    useListContext();
  const redirect = useRedirect();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <Button
        onClick={() => {
          redirect("/ImportarNCM");
        }}
        label="Importar"
      />
      <CreateButton />
    </TopToolbar>
  );
};

const NcmList = (props) => (
  <List
    {...props}
    filters={<NcmFilter />}
    actions={<ListActions />}
    exporter={false}
  >
    <Datagrid>
      <TextField source="id" label="Código NCM" />
      <NumberField source="ex" label="Ex" />
      <NumberField source="tipo" label="Tipo" />
      <TextField source="descricao" label="Descrição" />
      <NumberField source="nacional" label="Nacional" />
      <NumberField source="importado" label="Importado" />
      <NumberField source="estadual" label="Estadual" />
      <NumberField source="municipal" label="Municipal" />
      <EditButton />
    </Datagrid>
  </List>
);

export default NcmList;
