import React, { useState } from "react";
import PropTypes from "prop-types";
import * as ReactColor from "react-color";
import { useInput } from "react-admin";
import { TextInput } from "react-admin";

require("./ColorInput.css");

function ColorInput(props) {
  const [show, setShow] = useState(false);

  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleChange = ({ hex }) => {
    input.onChange(hex);
  };

  const { options, picker } = props;

  const {
    input,
    meta: { touched, error },
  } = useInput(props);

  const Picker = ReactColor[`${picker}Picker`];

  return (
    <div style={props.style}>
      <TextInput
        {...props}
        onFocus={handleOpen}
        error={!!(touched && error)}
        helperText={touched && error}
        back
        style={{ background: input.value }}
      />
      {show ? (
        <div className="ColorInput-popup">
          <div className="ColorInput-cover" onClick={handleClose} />
          <Picker {...options} color={input.value} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
}

ColorInput.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object,
  source: PropTypes.string,
  input: PropTypes.object,
  helperText: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  className: PropTypes.string,
  picker: (props, propName, componentName) =>
    !ReactColor[`${props[propName]}Picker`] &&
    new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`.`),
};

ColorInput.defaultProps = {
  picker: "Chrome",
  options: {
    disableAlpha: true,
  },
};

export default ColorInput;
