import { Autocomplete, TextField } from "@mui/material";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import React, { useEffect, useRef, useState, useContext } from "react";
import { useDataProvider, useNotify } from "react-admin";

import { SmallCircularProgress } from "../../common/CircularProgress";
import ProductMirrorContext from "../produto/ProductMirrorContext";
import "./styles/EditableTextField.css";

const EditableAutocompleteField = ({
  reference,
  optionsKeys,
  required = false,
  editablePosition,
  setEditablePosition,
  minimumInputLenght,
  filterToQuery,
  ...props
}) => {
  const { source, record } = props;
  const positionedData = useContext(ProductMirrorContext).current;
  const dataProvider = useDataProvider();
  const textFieldRef = useRef();
  const notify = useNotify();
  const [selectedOption, setSelectedOption] = useState({});
  const [options, setOptions] = useState([]);
  const [fieldPosition, setFieldPosition] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [textValue, setTextValue] = useState("");

  useEffect(() => {
    const shouldRetrieveCurrentValue =
      isEmpty(selectedOption) && !editablePosition && record[source];
    if (shouldRetrieveCurrentValue) {
      (async () => {
        try {
          const currentData = await dataProvider.getOne(reference, {
            id: record[source],
          });
          const { grupos_add, ...optionValue } = currentData.data;
          setSelectedOption(optionValue);
        } catch (error) {
          console.log(error);
          notify(
            `Error ao carregar valor atual de ${reference} do produto ${record["desc_prod"]}.`,
            "warning"
          );
        }
      })();
    }
  }, [
    dataProvider,
    record,
    source,
    selectedOption,
    editablePosition,
    reference,
    notify,
  ]);

  useEffect(() => {
    if (minimumInputLenght > 0 && textValue.length < minimumInputLenght) {
      return;
    }

    if (fieldPosition && fieldPosition === editablePosition) {
      (async () => {
        try {
          setLoading(true);
          const textFilter =
            typeof filterToQuery === "function" ? filterToQuery(textValue) : {};
          const finalFilter = {
            ...textFilter,
            lojaId: record.lojaId,
          };
          const optionsData = await dataProvider.getList(reference, {
            filter: finalFilter,
          });
          const optionsList = required
            ? optionsData.data
            : [{ id: null, [optionsKeys[0]]: "Nenhum" }, ...optionsData.data];
          setOptions(optionsList);
        } catch (error) {
          console.log(error);
          notify(`Error ao carregar opções de ${reference}.`, "warning");
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [
    dataProvider,
    editablePosition,
    fieldPosition,
    reference,
    record.lojaId,
    optionsKeys,
    required,
    textValue,
    minimumInputLenght,
    filterToQuery,
    notify,
  ]);

  useEffect(() => {
    if (!isEmpty(positionedData)) {
      const value = Object.values(positionedData).find(
        (value) => value.id === record.id && value.source === source
      );
      if (value) {
        const { x, y } = value;
        setFieldPosition(`${x}.${y}`);
      }
    }
  }, [positionedData, record.id, source]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        textFieldRef.current &&
        !textFieldRef.current.contains(event.target) &&
        event.target.className !== "MuiAutocomplete-option Mui-focused" &&
        fieldPosition === editablePosition
      ) {
        setEditablePosition("");
      }
    }
    function handleArrowsKeydown(event) {
      if (!editablePosition) {
        return;
      }
      const [x, y] = editablePosition.split(".");
      const xPosition = Number(x);
      const yPosition = Number(y);
      switch (event.key) {
        case "ArrowUp":
          event.preventDefault();
          setEditablePosition(`${xPosition}.${yPosition - 1}`);
          break;
        case "ArrowDown":
          event.preventDefault();
          setEditablePosition(`${xPosition}.${yPosition + 1}`);
          break;
        case "ArrowLeft":
          event.preventDefault();
          setEditablePosition(`${xPosition - 1}.${yPosition}`);
          break;
        case "ArrowRight":
          event.preventDefault();
          setEditablePosition(`${xPosition + 1}.${yPosition}`);
          break;
        default:
          return;
      }
    }
    setShowOptions(true);
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleArrowsKeydown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleArrowsKeydown);
    };
  }, [textFieldRef, fieldPosition, editablePosition, setEditablePosition]);

  const handleEditablePosition = () => {
    setEditablePosition(fieldPosition);
  };

  const debouncedEditAutocompleteRef = useRef(
    debounce(async (value, record, source) => {
      try {
        setLoading(true);
        setShowOptions(false);
        setTextValue("");
        await dataProvider.update("produtos", {
          updateAttributes: true,
          data: {
            id: record.id,
            lojaId: record.lojaId,
            [source]: value.id,
          },
        });
      } catch (error) {
        notify("Erro ao atualizar os dados.", "warning");
        console.log(JSON.stringify(error));
      } finally {
        setLoading(false);
      }
    }, 100)
  );

  const handleChangeTextField = (record, source) => (event, value) => {
    if (isEmpty(value)) {
      return;
    }

    const sameValueSelected =
      value && selectedOption && value.id === selectedOption.id;
    if (sameValueSelected) {
      setShowOptions(false);
      return;
    }

    setSelectedOption(value);
    debouncedEditAutocompleteRef.current(value, record, source);
  };

  const getOptionLabel = (optionsKeys) => (option) =>
    buildOptionLabel(option, optionsKeys);

  const debouncedTextChangeRef = useRef(
    debounce((newText) => {
      setTextValue(newText);
    }, 300)
  );

  const handleTextChange = (event) => {
    const newText = event.target.value;

    if (!minimumInputLenght || newText.length < minimumInputLenght) {
      return;
    }

    debouncedTextChangeRef.current(newText);
  };

  return (
    <div
      className='container'
      ref={textFieldRef}
      onClick={handleEditablePosition}
    >
      {fieldPosition && fieldPosition === editablePosition ? (
        <Autocomplete
          noOptionsText={"Nenhuma opção"}
          className='autocomplete'
          value={selectedOption}
          onChange={handleChangeTextField(record, source)}
          options={options}
          getOptionLabel={getOptionLabel(optionsKeys)}
          getOptionSelected={(option, value) => option.id === value.id}
          open={showOptions}
          renderInput={(params) => (
            <TextField {...params} autoFocus onChange={handleTextChange} />
          )}
        />
      ) : (
        <span
          className='text-field'
          title={buildOptionLabel(selectedOption, optionsKeys)}
        >
          {buildOptionLabel(selectedOption, optionsKeys)}
        </span>
      )}
      {loading && <SmallCircularProgress />}
    </div>
  );
};

function buildOptionLabel(option, values) {
  if (isEmpty(option) || isEmpty(values)) return "";
  if (values.length === 1) return option[values[0]];

  return values.reduce((text, value, index) => {
    const isFirstValue = index === 0;
    const isLastValue = index === values.length - 1;
    const isValidValue = get(option, value, null);

    if (isFirstValue && isValidValue) {
      return `${option[value]} - `;
    }

    if (isLastValue && isLastValue) {
      return `${text}${option[value]}`;
    }

    return `${text} ${option[value]} -`;
  }, "");
}

export default EditableAutocompleteField;
