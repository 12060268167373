import React from "react";
import { SelectInput } from "react-admin";

export const OPCOES = [
  { id: "Sim", name: "Sim" },
  { id: "Não", name: "Não" }
];

const IndEscalaInput = props => {
  return <SelectInput 
    {...props} 
    choices={OPCOES} 
    allowEmpty 
    emptyValue={null} 
    emptyText={'Nenhum'}
  />;
};

export default IndEscalaInput;
