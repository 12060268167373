import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Datagrid,
  List,
  TextField,
  useListContext,
  ResourceContextProvider,
  Pagination,
} from "react-admin";

import CurrencyFieldEditable from "../../common/form/CurrencyFieldEditable";
import ListActions from "../../common/ListActions";
import { fetchJSON } from "../../dataProvider";
import { withUser } from "../../common/util/hocs";
import { calculateProfitMargin } from "../../common/util/util";
import EditableAutocompleteField from "../utils/EditableAutocompleteField";
import EditableProfitMarginField from "../utils/EditableProfitMarginField";
import EditableTextField from "../utils/EditableTextField";
import EditableListButton from "./EditableListButton";
import ProductMirrorContext from "./ProductMirrorContext";
import ProdutoFilter from "./ProdutoFilter";

const ProdutoPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />
);

const SourceMirror = () => {
  const { data, ids } = useListContext();
  const ref = useContext(ProductMirrorContext);
  useEffect(() => {
    const positionedData = {};
    const sources = [
      "cod_prod",
      "cod_aux",
      "desc_prod",
      "preco_cst_prod",
      "profit_margin",
      "preco_vnd_prod",
      "appPreco",
      "grupoId",
      "unidadeId",
      "impostoId",
      "impressoraId",
      "ncmId",
      "cestId",
    ];
    ids.forEach((id, y) => {
      const product = data[id];
      sources.forEach((source, x) => {
        positionedData[x + "." + y] = {
          id: product.id,
          source, // exemplo: name
          value:
            source === "profit_margin"
              ? calculateProfitMargin(
                  product["preco_vnd_prod"],
                  product["preco_cst_prod"]
                )
              : product[source], // exemplo: product.name
          x,
          y,
        };
      });
    });

    ref.current = positionedData;
  }, [data, ref, ids]);
  return null;
};

const actionsList = [
  <EditableListButton
    key="produtos-list-modo-simples"
    to="/produtos"
    label="Modo Simples"
  />,
];

const filter_NCM_CEST = (searchText) => {
  if (searchText.match(/^\d+$/)) {
    return { id: { like: `${searchText}%` } };
  } else {
    return { q: searchText };
  }
};

const ProdutoList = ({ loja }) => {
  const productMirror = useRef({});
  const [editablePosition, setEditablePosition] = useState("");

  const validationCodigo = async (value, allValues, field) => {
    if (field === "cod_aux" && value === "") {
      return undefined;
    }

    if (field === "cod_aux" && value.includes(" ")) {
      return "Remova os espaços";
    }

    if (
      (field === "cod_aux" && allValues.cod_prod.toString() === value) ||
      (field === "cod_prod" && allValues.cod_aux === value)
    ) {
      return "Os códigos devem ser diferentes";
    }

    return fetchJSON("produtos/uniqCodigo", null, {
      lojaId: loja.id,
      value,
      id: allValues.id,
    }).then((count) => (count > 0 ? "Código já utilizado" : undefined));
  };

  return (
    <ResourceContextProvider resource="produtos">
      <ProductMirrorContext.Provider value={productMirror}>
        <h1>Alteração em massa</h1>
        <List
          basePath="/produtos"
          resource="produtos"
          perPage={10}
          pagination={<ProdutoPagination />}
          actions={<ListActions create={false} actionsList={actionsList} />}
          filters={<ProdutoFilter />}
          exporter={false}
        >
          <>
            <SourceMirror />
            <Datagrid>
              <EditableTextField
                source="cod_prod"
                label="Código"
                required
                editablePosition={editablePosition}
                setEditablePosition={setEditablePosition}
                validation={validationCodigo}
              >
                <TextField />
              </EditableTextField>
              <EditableTextField
                source="cod_aux"
                label="Cód.Aux."
                editablePosition={editablePosition}
                setEditablePosition={setEditablePosition}
                validation={validationCodigo}
              >
                <TextField />
              </EditableTextField>
              <EditableTextField
                source="desc_prod"
                label="Nome"
                required
                editablePosition={editablePosition}
                setEditablePosition={setEditablePosition}
              >
                <TextField />
              </EditableTextField>
              <CurrencyFieldEditable
                className="currency-field"
                source="preco_cst_prod"
                label="Custo"
                required
                editablePosition={editablePosition}
                setEditablePosition={setEditablePosition}
              />
              <EditableProfitMarginField
                className="currency-field"
                label="Margem"
                editablePosition={editablePosition}
                setEditablePosition={setEditablePosition}
              />
              <CurrencyFieldEditable
                className="currency-field"
                source="preco_vnd_prod"
                label="Venda"
                required
                editablePosition={editablePosition}
                setEditablePosition={setEditablePosition}
              />
              <CurrencyFieldEditable
                className="currency-field"
                source="appPreco"
                label="Delivery"
                required
                editablePosition={editablePosition}
                setEditablePosition={setEditablePosition}
              />
              <EditableAutocompleteField
                source="grupoId"
                reference="grupos"
                optionsKeys={["nome"]}
                label="Grupo"
                required
                editablePosition={editablePosition}
                setEditablePosition={setEditablePosition}
              />
              <EditableAutocompleteField
                source="unidadeId"
                reference="unidades"
                optionsKeys={["nome"]}
                label="Unidade"
                required
                editablePosition={editablePosition}
                setEditablePosition={setEditablePosition}
              />
              <EditableAutocompleteField
                source="impostoId"
                reference="impostos"
                optionsKeys={["nome"]}
                label="Imposto"
                required
                editablePosition={editablePosition}
                setEditablePosition={setEditablePosition}
              />
              <EditableAutocompleteField
                source="impressoraId"
                reference="impressoras"
                optionsKeys={["nome"]}
                label="Impressora"
                editablePosition={editablePosition}
                setEditablePosition={setEditablePosition}
              />
              <EditableAutocompleteField
                source="ncmId"
                reference="ncms"
                optionsKeys={["id", "descricao"]}
                label="NCM"
                required
                minimumInputLenght={3}
                filterToQuery={filter_NCM_CEST}
                editablePosition={editablePosition}
                setEditablePosition={setEditablePosition}
              />
              <EditableAutocompleteField
                source="cestId"
                reference="cests"
                optionsKeys={["id", "descricao"]}
                label="CEST"
                minimumInputLenght={3}
                filterToQuery={filter_NCM_CEST}
                editablePosition={editablePosition}
                setEditablePosition={setEditablePosition}
              />
            </Datagrid>
          </>
        </List>
      </ProductMirrorContext.Provider>
    </ResourceContextProvider>
  );
};

export default withUser(ProdutoList);
