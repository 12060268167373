import React from "react";
import { DateInput } from "react-admin";

const DateInputUTC = (props) => (
  <DateInput
    {...props}
    format={(v) =>
      v ? (typeof v === "string" ? v.substring(0, 10) : v.toISOString()) : v
    }
  />
);

export default DateInputUTC;
