import React from "react";
import {
  Datagrid,
  List,
  TextField,
  EditButton
} from "react-admin";

import CestFilter from "./CestFilter";

const CestList = props => (
  <List {...props} filters={<CestFilter />} exporter={false}>
    <Datagrid>
      <TextField 
        source="id" 
        label="Código Cest" />

      <TextField 
        source="descricao" 
        label="Descrição" />
        
      <EditButton />
    </Datagrid>
  </List>
);

export default CestList;
