import * as React from "react";
import { FilterList, FilterListItem } from "react-admin";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOnOutlined";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import {
  Card as MuiCard,
  CardContent,
  Typography,
  withStyles,
} from "@material-ui/core";
import { addDays } from "date-fns";

export const conta_filters = {
  conta_vencimento: {
    atrasadas: {
      vencimento: {
        lt: new Date().toISOString().substring(0, 10),
      },
    },
    hoje: {
      vencimento: new Date().toISOString().substring(0, 10),
    },
    proximos_7_dias: {
      vencimento: {
        between: [
          addDays(new Date(), 1).toISOString().substring(0, 10),
          addDays(new Date(), 7).toISOString().substring(0, 10),
        ],
      },
    },
    proximos_30_dias: {
      vencimento: {
        between: [
          addDays(new Date(), 1).toISOString().substring(0, 10),
          addDays(new Date(), 30).toISOString().substring(0, 10),
        ],
      },
    },
    proximos_60_dias: {
      vencimento: {
        between: [
          addDays(new Date(), 1).toISOString().substring(0, 10),
          addDays(new Date(), 60).toISOString().substring(0, 10),
        ],
      },
    },
  },
};

const TipoFilter = () => (
  <FilterList label="Tipo" icon={<ImportExportIcon />}>
    <FilterListItem label="Contas a pagar" value={{ tipo: "PAGAR" }} />
    <FilterListItem label="Contas a receber" value={{ tipo: "RECEBER" }} />
  </FilterList>
);

const TempoFilter = () => (
  <FilterList label="Vencimento em" icon={<AccessTimeIcon />}>
    <FilterListItem
      label="Anteriores"
      value={{ conta_vencimento: "atrasadas" }}
    />
    <FilterListItem label="Hoje" value={{ conta_vencimento: "hoje" }} />
    <FilterListItem
      label="Próximos 7 dias"
      value={{ conta_vencimento: "proximos_7_dias" }}
    />
    <FilterListItem
      label="Próximos 30 dias"
      value={{ conta_vencimento: "proximos_30_dias" }}
    />
    <FilterListItem
      label="Próximos 60 dias"
      value={{ conta_vencimento: "proximos_60_dias" }}
    />
  </FilterList>
);

const PagoFilter = () => (
  <FilterList label="Pago" icon={<MonetizationOnIcon />}>
    <FilterListItem
      label="Sim"
      value={{
        pago: true,
      }}
    />
    <FilterListItem
      label="Não"
      value={{
        pago: false,
      }}
    />
  </FilterList>
);

const PerdaFilter = () => (
  <FilterList label="Perda" icon={<MonetizationOnIcon />}>
    <FilterListItem
      label="Sim"
      value={{
        perda: true,
      }}
    />
    <FilterListItem
      label="Não"
      value={{
        perda: false,
      }}
    />
  </FilterList>
);

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1, // display on the left rather than on the right of the list
      width: "15em",
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(MuiCard);

const ContaFilterSidebar = () => (
  <Card>
    <CardContent>
      <Typography variant="h6">Filtros</Typography>
      <TipoFilter />
      <TempoFilter />
      <PagoFilter />
      <PerdaFilter />
    </CardContent>
  </Card>
);

export default ContaFilterSidebar;
