import React from "react";
import { SaveButton, Toolbar } from "react-admin";

const MoedaAppToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export default MoedaAppToolbar;
