import React from "react";
import { Create } from "react-admin";
import ContasForm from "./ContasForm";
import { trasnformPago } from "./ContaPagamento";

const ContasCreate = (props) => {
  const transform = (data) => {
    try {
      const { repete, vencimentos } = data;
      const ocorrencias = repete ? vencimentos.length : 1;
      const parcelas = repete
        ? vencimentos.slice(1).map((vencimento, index) => ({
            vencimento: vencimento,
            parcela: index + 2,
            ocorrencias,

            tipo: data.tipo,
            valor: data.valor,
            classificacaoId: data.classificacaoId,
            centroCustoId: data.centroCustoId,
            parceiroId: data.parceiroId,
            descricao: data.descricao,
            observacao: data.observacao,
          }))
        : [];

      return {
        ...trasnformPago(data),
        parcela: 1,
        ocorrencias,
        parcelas,
      };
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Create {...props} transform={transform}>
      <ContasForm />
    </Create>
  );
};

export default ContasCreate;
