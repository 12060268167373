import React from "react";
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

const chipStyle = {
  backgroundColor: 'inherit'
}

const CertificadoDigitalValidadeChip = ({record}) => {
  const ini = new Date(record.validoDe);
  const fim = new Date(record.validoAte);
  const agora = new Date();
  const valido = agora >= ini && agora <= fim;
  return valido
    ? <Chip size="small" label="Válido" color="primary" avatar={<DoneIcon style={chipStyle} />} />
    : <Chip size="small" label="Vencido" color="error" avatar={<CloseIcon style={chipStyle} />} />;
}

export default CertificadoDigitalValidadeChip;