import React from "react";
import { Box, Chip, Stack, TextField, Typography, Button } from "@mui/material";

function FieldChip({ col }) {
  const handleClick = (title) => (e) => {
    navigator.clipboard.writeText(title).catch((err) => console.error(err));
  };

  return (
    <Chip
      onClick={handleClick(col.title)}
      color={col.color}
      key={col.field}
      label={
        <span>
          {col.title}
          <br />
          {col.help}
        </span>
      }
    />
  );
}

function DownloadButton({ delimiter, columns, fileName }) {
  const [name, setName] = React.useState("");

  const filteredColumns = columns
    .filter((c) => c.title.toLowerCase().includes(name.toLowerCase()))
    .map((c) => {
      let help = c.help;
      let color = "default";

      if (c.type === "boolean") {
        color = "secondary";
        help = help || "1 ou Vazio";
      } else if (c.type === "numeric") {
        color = "success";
        help = help || "123";
      } else {
        color = "primary";
        help = help || "Texto";
      }

      return { ...c, help, color };
    });

  const requiredColumns = filteredColumns.filter(
    (c) => c.required && !c.hasOwnProperty("default")
  );

  const optionalColumns = filteredColumns.filter(
    (c) => !c.required || c.hasOwnProperty("default")
  );

  const downloadFile = () => {
    const header = columns.map((c) => c.title).join(delimiter);
    const example = columns
      .map((c) => {
        if (c.type === "boolean") {
          return "1 ou Vazio";
        } else if (c.type === "numeric") {
          return "123";
        } else {
          return "Texto";
        }
      })
      .join(delimiter);

    const element = document.createElement("a");
    const file = new Blob([`${header}\r${example}`], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `${fileName}.csv`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <Box
      sx={{
        mt: 5,
        ml: 1,
        maxWidth: "xl",
      }}
    >
      <Stack direction="row" spacing={3} sx={{ my: 5, alignItems: "center" }}>
        <Typography variant="h5" component="h2">
          Campos para importação
        </Typography>
        <TextField
          label="Filtro"
          value={name}
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
        <Button
          variant="contained"
          component="label"
          color="primary"
          onClick={downloadFile}
        >
          Download exemplo
        </Button>
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        sx={{ my: 5, flexWrap: "wrap", gap: 1 }}
      >
        <Typography variant="body1" component="h2">
          Obrigatórios:
        </Typography>
        {requiredColumns.map((c) => (
          <FieldChip col={c} />
        ))}
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        sx={{ my: 5, flexWrap: "wrap", gap: 1 }}
      >
        <Typography variant="body1" component="h2">
          Opcionais
        </Typography>
        {optionalColumns.map((c) => (
          <FieldChip col={c} />
        ))}
      </Stack>
    </Box>
  );
}

export default DownloadButton;
