import React from "react";
import { EditButton, ShowButton } from "react-admin";
import { withUser } from "../util/hocs";

function PermissionEditButton({ permissao, canEdit, canShow, ...rest }) {
  if (permissao[canEdit]) {
    return <EditButton {...rest} />;
  }

  if (canShow && permissao[canShow]) {
    return <ShowButton {...rest} />;
  }

  return null;
}

export default withUser(PermissionEditButton);
