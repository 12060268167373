import React from "react";
import {
  SimpleForm,
  AutocompleteInput,
  FormDataConsumer,
  NumberInput,
  TextInput,
  required,
  minValue,
  maxValue,
} from "react-admin";
import { Link } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ContaPagamento from "./ContaPagamento";
import ContaRepete from "./ContaRepete";
import { ContaTipoInput } from "./ContaTipo";
import LazyReferenceInput from "../../common/form/LazyReferenceInput";
import { IdDates } from "../../common/form/ConditionalInputs";
import NumberInputRemoveArrowsCSS from "../../common/form/NumberInputRemoveArrowsCSS.json";
import ContaStatus from "./ContaStatus";
import DateInputUTC from "../../common/form/DateInputUTC";
import ParceiroForm from "../parceiro/ParceiroForm";
import ParceiroLazyReferenceInput from "../parceiro/ParceiroLazyReferenceInput";
import { validateDecimalPlaces } from "../../common/util/ra-validations";
import { ContasParcelas } from "./ContasList";

const useStyles = makeStyles({
  tipoInput: {
    width: "14em",
  },
  numberInput: {
    width: "8em",
    marginRight: "1em",
    ...NumberInputRemoveArrowsCSS,
  },
  classificacaoInput: {
    width: "16.5em",
  },
  textInput: {
    width: "36em",
  },
});

const validateReq = [required()];
const validateValor = [
  required(),
  minValue(0.01),
  maxValue(9999999999.99),
  validateDecimalPlaces(2),
];

const ContasForm = (props) => {
  const classes = useStyles();
  const { record } = props;

  return (
    <SimpleForm {...props} redirect="list">
      <IdDates />
      <Grid container display="flex" spacing={3}>
        <Grid item>
          <ContaTipoInput
            className={classes.tipoInput}
            validate={validateReq}
            defaultValue="PAGAR"
            fullWidth
            record={record}
          />
        </Grid>
        <Grid item>
          <DateInputUTC
            source="vencimento"
            validate={validateReq}
            defaultValue={new Date().toISOString().substring(0, 10)}
            fullWidth
            record={record}
          />
        </Grid>
        <Grid item xs={1}>
          <NumberInput
            className={classes.numberInput}
            label="Valor"
            source="valor"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              ),
            }}
            validate={validateValor}
            record={record}
          />
        </Grid>
      </Grid>
      <Grid container display="flex" direction="column" spacing={0}>
        <Grid item container display="flex" spacing={2}>
          <Grid item>
            <LazyReferenceInput
              className={classes.classificacaoInput}
              label="Classificação"
              source="classificacaoId"
              reference="Conta_classificacoes"
              nameSource="classificacao"
              validate={validateReq}
              record={record}
              addCreateButton={true}
              fullWidth
            >
              <AutocompleteInput optionText="nome" />
            </LazyReferenceInput>
          </Grid>
          <Grid item>
            <LazyReferenceInput
              className={classes.classificacaoInput}
              label="Centro de custo"
              source="centroCustoId"
              reference="Conta_centros_custos"
              nameSource="centro_custo"
              validate={validateReq}
              record={record}
              addCreateButton={true}
              fullWidth
            >
              <AutocompleteInput optionText="nome" />
            </LazyReferenceInput>
          </Grid>
        </Grid>
        <Grid item container display="flex">
          <Grid item>
            <ParceiroLazyReferenceInput
              label="Parceiro"
              source="parceiroId"
              record={record}
              addCreateButton={true}
              CreateForm={ParceiroForm}
              className={classes.textInput}
              fullWidth
            />
          </Grid>
          {record.id && record.nfeId && (
            <Link to={`/nfes/${record.nfeId}`} rel="noopener noreferrer">
              <Typography sx={{ ml: 2, mt: 2 }}>{`Ver NFe`}</Typography>
            </Link>
          )}
          {record.id && record.pedidoId && (
            <Link to={`/pedidos/${record.pedidoId}`} rel="noopener noreferrer">
              <Typography sx={{ ml: 2, mt: 2 }}>{`Ver Pedido`}</Typography>
            </Link>
          )}
        </Grid>

        <Grid item container display="flex">
          <Grid item>
            <TextInput
              className={classes.textInput}
              label="Descrição"
              source="descricao"
              record={record}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container display="flex">
          <Grid item>
            <TextInput
              className={classes.textInput}
              label="Observação"
              source="observacao"
              multiline
              fullWidth
              record={record}
            />
          </Grid>
        </Grid>
      </Grid>
      {record.id && (
        <Box mr={1} mt={5} mb={3} fullWidth>
          <Grid container display="flex" spacing={1} alignItems="center">
            <Grid item>
              <Typography variant="h4">
                {record.ocorrencias === 1
                  ? "Parcela única"
                  : `Parcela ${record.parcela}/${record.ocorrencias}`}
              </Typography>
            </Grid>
            <Grid item>
              <ContaStatus record={record} />
            </Grid>
          </Grid>
        </Box>
      )}
      {!record.id && (
        <FormDataConsumer>
          {(formDataProps) => <ContaRepete {...formDataProps} />}
        </FormDataConsumer>
      )}

      <FormDataConsumer>
        {(formDataProps) => <ContaPagamento {...formDataProps} />}
      </FormDataConsumer>

      {record.id && record.ocorrencias > 1 && (
        <>
          <Typography variant="h4" sx={{ mt: 5, mb: 3 }}>
            Demais Parcelas
          </Typography>
          <ContasParcelas record={record} />
        </>
      )}
    </SimpleForm>
  );
};

export default ContasForm;
