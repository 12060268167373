import React from "react";
import Print from "@material-ui/icons/Print";
import ImpressoraCreate from "./ImpressoraCreate";
import ImpressoraEdit from "./ImpressoraEdit";
import ImpressoraList from "./ImpressoraList";

const impressora = {
  getResource: (p) => {
    return {
      name: "impressoras",
      create: p.cadastros_ver ? ImpressoraCreate : null,
      list: p.cadastros_ver ? ImpressoraList : null,
      edit: p.cadastros_ver ? ImpressoraEdit : null,
    };
  },
  getMenu: (p) =>
    p.cadastros_ver
      ? {
          key: "impressoras",
          to: "/impressoras",
          primaryText: "Impressoras",
          leftIcon: iconImpressora,
        }
      : null,
};

export default impressora;
export const iconImpressora = <Print />;
