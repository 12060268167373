import React, { useState } from "react";
import LoopIcon from "@material-ui/icons/Loop";
import { useNotify, useRefresh } from "react-admin";
import { NFeAutorizada } from "../calculos";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { cleanCache, fetchJSON } from "../../../dataProvider";
import AlertDialog from "../../../common/AlertDialog";
import LoadingButton from "../../../common/LoadingButton";

const NFeCartaCorrecaoButton = ({ record }) => {
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [motivo, setMotivo] = React.useState("");
  const [helper, setHelper] = React.useState("");
  const [nfe, setNFe] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const notify = useNotify();
  const refresh = useRefresh();

  if (!NFeAutorizada(record)) return null;

  const minLen = 10;
  const handleOpenClick = () => {
    setMotivo(record.cce_correcao);
    setShowDialog(true);
  };
  const handleCloseClick = () => setShowDialog(false);

  const handleChangeText = (event) => {
    setMotivo(event.target.value);
    if (event.target.value.length > minLen) {
      setHelper("");
    }
  };

  const handleSave = async () => {
    if (motivo.length < 10) {
      const msg = `Preencha a correção com no mínimo ${minLen} caracteres!`;
      setHelper(msg);
      notify(msg);
      return;
    }

    try {
      setLoading(true);
      const nfe = await fetchJSON("Sefaz/corrigeNFe", {
        method: "POST",
        body: { lojaId: record.lojaId, nfeId: record.id, correcao: motivo },
      });

      setNFe(nfe);
    } catch (exception) {
      console.log(JSON.stringify(exception));
      setLoading(false);
      setErrorMessage(exception.body.error.message);
    }
  };

  const handleCloseAlert = () => {
    setNFe(null);
    setShowDialog(false);
    cleanCache("NFe_emissaos");
    refresh();
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleOpenClick}
        startIcon={<LoopIcon />}
      >
        Carta de Correção
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>Carta de Correção</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Correção:
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={motivo}
            onChange={handleChangeText}
            helperText={helper}
            error={!!helper}
            inputProps={{ maxLength: 512 }}
          />

          {errorMessage && (
            <Typography
              variant="body1"
              gutterBottom
              sx={{ my: 3, color: "red" }}
            >
              {errorMessage}
            </Typography>
          )}

          <DialogActions>
            <LoadingButton
              variant="contained"
              text="Enviar CCe"
              onClick={handleSave}
              loading={loading}
              icon={<LoopIcon />}
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
      <AlertDialog
        title="Aviso"
        message="Carta de Correção gerada com sucesso!"
        open={!!nfe}
        handleClose={handleCloseAlert}
      />
    </>
  );
};

export default NFeCartaCorrecaoButton;
