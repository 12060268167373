import React from "react";
import {
  Filter,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import { PedidoTipoInput } from "./PedidoTipo";
import { PedidoStatusInput } from "./PedidoStatus";

const PedidosFilters = (props) => (
  <Filter {...props}>
    <PedidoTipoInput source="status" alwaysOn />
    <PedidoStatusInput source="status" alwaysOn />
    <ReferenceInput
      label="Parceiro"
      source="parceiroId"
      reference="parceiros"
      alwaysOn
    >
      <AutocompleteInput
        optionText="nome_fantasia"
      />
    </ReferenceInput>
  </Filter>
);

export default PedidosFilters;
