import React from "react";
import {
  SimpleForm,
  BooleanInput,
  TextInput,
  FormDataConsumer,
  required,
  maxLength,
} from "react-admin";
import { IdDates } from "../../common/form/ConditionalInputs";
import SitefFuncaoInput from "./SitefFuncaoInput";
import MoedaAdquirente from "./MoedaAdquirente";
import { InputAdornment } from "@mui/material";
import NumberInputSimple from "../../common/form/NumberInputSimple";
import { CodigoSATInput } from "./CodigoSATInput";

const validateNome = [required(), maxLength(20)];
const validateReq = [required()];

function MoedasForm(props) {
  return (
    <SimpleForm {...props} toolbar={props.toolbar} redirect="list">
      <IdDates />

      <BooleanInput source="status" label="Ativo" initialValue={true} />

      <TextInput source="nome" label="Nome no PDV" validate={validateNome} />

      <CodigoSATInput
        source="cod_sat"
        label="Código Fiscal"
        validate={validateReq}
      />

      <FormDataConsumer>
        {(formDataProps) => <MoedaAdquirente {...formDataProps} />}
      </FormDataConsumer>

      <SitefFuncaoInput source="sitef_funcao" label="SiTEF função" />

      <NumberInputSimple
        source="alteracao"
        label="Alteração no Caixa"
        initialValue={0}
        InputProps={{
          endAdornment: <InputAdornment position="start">%</InputAdornment>,
        }}
      />
    </SimpleForm>
  );
}

export default MoedasForm;
