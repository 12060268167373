import React from "react";
import {
  List,
  Datagrid,
  DateField,
  TextField,
  ReferenceField,
  EditButton,
  NumberField,
  FunctionField,
} from "react-admin";
import NFeEmissaoFilters from "./NFeEmissaoFilters";
import NFeStatus from "./fields/NFeStatus";
import NFeEmissaoListActions from "./NFeEmissaoListActions";
import { Inutilizacao } from "./calculos";
import CurrencyField from "../../common/form/CurrencyField";

function DestinatarioField(props) {
  if (Inutilizacao(props.record)) {
    return <span>Inutilização</span>;
  }

  return (
    <ReferenceField
      label="Destinatário"
      source="destinatarioId"
      reference="parceiros"
      {...props}
    >
      <TextField source="nome_fantasia" />
    </ReferenceField>
  );
}

function NFeEmissaos(props) {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      exporter={false}
      filters={<NFeEmissaoFilters />}
      actions={<NFeEmissaoListActions />}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid>
        <NumberField source="serie" label="Série" />
        <FunctionField
          render={(record) =>
            Inutilizacao(record)
              ? `${record.consumidor} até ${record.numero}`
              : record.numero
          }
          label="Número"
        />
        <NFeStatus label="Status" />
        <TextField source="natureza_operacao" label="Natureza Op." />
        <DestinatarioField label="Destinatário" />
        <DateField source="createdAt" label="Criado em" />
        <CurrencyField source="total_nota" label="R$ Total" />
        <EditButton />
      </Datagrid>
    </List>
  );
}

export default NFeEmissaos;
