import React from "react";
import { Create } from "react-admin";

import EquipamentoForm from "./EquipamentoForm";
import EquipamentoToolbar from "./EquipamentoToolbar";

const EquipamentoCreate = props => (
  <Create {...props}>
    <EquipamentoForm toolbar={<EquipamentoToolbar />} />
  </Create>
);

export default EquipamentoCreate;
