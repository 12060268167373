import React from "react";
import {
  Button,
  Card,
  CardContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useDataProvider } from "react-admin";
import { withUser } from "../../common/util/hocs";
import { Box } from "@mui/system";

function NpsSurvey({ usuario, loja }) {
  const dataProvider = useDataProvider();
  const [lastSurveyDate, setLastSurveyDate] = React.useState(
    localStorage.getItem("lastSurveyDate") || "2000-01-01"
  );
  const [surveyList, setSurveyList] = React.useState(null);
  const [answered, setAnswered] = React.useState(false);

  const afterThreeMonths = (lastDate) => {
    const diff = new Date() - new Date(lastDate);
    const threeMonths = 1000 * 60 * 60 * 24 * 90;
    return diff > threeMonths;
  };

  const cacheDate = (date) => {
    localStorage.setItem("lastSurveyDate", date);
    setLastSurveyDate(date);
  };

  React.useEffect(() => {
    if (lastSurveyDate && afterThreeMonths(lastSurveyDate)) {
      dataProvider
        .getList("Nps", {
          filter: { userId: usuario.id },
          limit: 1,
          sort: { field: "id", order: "desc" },
        })
        .then((json) => {
          setSurveyList(json.data);
          if (json.data.length > 0) {
            cacheDate(json.data[0].createdAt);
          }
        });
    }
  }, [lastSurveyDate, dataProvider, usuario.id]);

  const saveSurvey = (score, feedback) => {
    dataProvider
      .create("Nps", {
        data: { userId: usuario.id, lojaId: loja.id, score, feedback },
      })
      .then((json) => {
        cacheDate(json.data.createdAt);
      });
    setAnswered(true);
  };

  const handleClose = (e) => setSurveyList(null);

  return afterThreeMonths(usuario.createdAt) &&
    surveyList &&
    (surveyList.length === 0 || afterThreeMonths(surveyList[0].createdAt)) ? (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Card
        variant="outlined"
        sx={{
          width: "fit-content",
          minWidth: 600,
          display: "flex",
          my: 8,
          pb: 3,
          px: 3,
          boxShadow: 12,
        }}
      >
        {answered ? (
          <Answered handleClose={handleClose} />
        ) : (
          <NpsInput saveSurvey={saveSurvey} />
        )}
      </Card>
    </Box>
  ) : null;
}

function Answered({ handleClose }) {
  const [seconds, setSeconds] = React.useState(5);

  if (seconds === 0) handleClose();

  React.useEffect(() => {
    const id = setInterval(() => setSeconds((v) => v - 1), 1000);
    return () => clearInterval(id);
  }, []);

  return (
    <CardContent>
      <Typography
        variant="h5"
        sx={{
          paddingTop: "40px",
          paddingBottom: "10px",
          textAlign: "center",
          width: 600,
        }}
      >
        <strong>Muito obrigado!</strong>
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          textAlign: "center",
        }}
      >
        Sua opinião é muito importante para nós.
      </Typography>
      <Box display="flex" justifyContent="center" mt={2}>
        <Button onClick={handleClose}>Fechar {seconds}</Button>
      </Box>
    </CardContent>
  );
}

function NpsInput({ saveSurvey }) {
  const [score, setScore] = React.useState(null);
  const [feedback, setFeedback] = React.useState("");
  const scale = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const colors = [
    "#b71c1c",
    "#c62828",
    "#d32f2f",
    "#e53935",
    "#e53935",
    "#f44336",
    "#f44336",
    "#039be5",
    "#0288d1",
    "#2e7d32",
    "#1b5e20",
  ];

  const handleClick = (i) => (e) => {
    setScore(i);
  };

  const handleBack = (e) => setScore(null);

  const handleSend = (e) => saveSurvey(score, feedback);

  const handleChange = (e) => setFeedback(e.target.value);

  if (score == null) {
    return (
      <CardContent>
        <Typography variant="h5" sx={{ paddingY: "40px", textAlign: "center" }}>
          Em uma escala de 0 a 10, o quanto você recomendaria o
          <strong> Sistema Pallas</strong>
          <br /> a um amigo ou colega de trabalho?
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          {scale.map((i) => (
            <Button
              key={i}
              variant="contained"
              sx={{
                width: 70,
                height: 70,
                fontSize: 50,
                textAlign: "center",
                justifyContent: "center",
                background: colors[i],
              }}
              onClick={handleClick(i)}
            >
              <Typography variant="h4" sx={{ paddingTop: "8px" }}>
                {i}
              </Typography>
            </Button>
          ))}
        </Stack>
      </CardContent>
    );
  }

  const title = score < 9 ? "Como podemos melhorar?" : "O que você mais gosta?";
  return (
    <CardContent>
      <Typography
        variant="h5"
        sx={{ paddingTop: "40px", paddingBottom: "10px", textAlign: "center" }}
      >
        <strong>Nota {score}. </strong> {title}
      </Typography>
      <Box display="flex">
        <Box m="auto" width={600}>
          <TextField
            label=""
            variant="outlined"
            multiline
            autoFocus
            fullWidth
            inputProps={{ maxLength: 2048 }}
            rows={5}
            value={feedback}
            onChange={handleChange}
          />
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button onClick={handleBack}>Voltar</Button>
            <Button variant="contained" onClick={handleSend}>
              Enviar
            </Button>
          </Box>
        </Box>
      </Box>
    </CardContent>
  );
}

export default withUser(NpsSurvey);
