import React from "react";
import {
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  DateField,
  NumberInput,
  BooleanInput,
  DateTimeInput,
} from "react-admin";

import { Card, CardContent, Box, Typography } from "@mui/material";
import { JsonField } from "react-admin-json-view";
import CurrencyCentsField from "../../common/form/CurrencyCentsField";
import MoedaAppIconText from "../moeda_app/MoedaAppIconText";
import { keyBy } from "lodash";
import { TransactionAcquireIcon } from "../transaction_pos/TransactionAquire";
import TransactionPosStatus from "../transaction_pos/TransactionPosStatus";

function AppPedidossForm(props) {
  const { record } = props;
  return (
    <Card>
      <SimpleForm {...props} toolbar={props.toolbar} redirect="list">
        <CardContent fullWidth>
          <Box display={{ md: "block", lg: "flex" }}>
            <Box flex={2} mr={{ md: 0, lg: "1em" }}>
              <Typography variant="h6" gutterBottom>
                Pedido
              </Typography>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="id" label="Id" fullWidth />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <DateTimeInput source="createdAt" label="Data" fullWidth />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="shortReference"
                    label="Referência Cliente"
                    fullWidth
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="mode" label="Tipo" fullWidth />
                </Box>
              </Box>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="lojaNome" label="Loja" fullWidth />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="tableId" label="Mesa" fullWidth />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="isTakingHome" label="Viagem" fullWidth />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="referencePDV"
                    label="Referência PDV"
                    fullWidth
                  />
                </Box>
              </Box>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <DateTimeInput
                    source="preparationStartDateTime"
                    label="Inicio do preparo"
                    fullWidth
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <DateTimeInput
                    source="deliveryDateTime"
                    label="Entregar em"
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <BooleanInput
                    source="isScheduledOrder"
                    label="Agendado"
                    fullWidth
                  />
                </Box>
              </Box>

              <Box mt="1em" />
              <Typography variant="h6" gutterBottom>
                Cliente
              </Typography>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="customerName" label="Cliente" fullWidth />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="customerPhone"
                    label="Telefone"
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="customerEmail" label="Email" fullWidth />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="customerTaxPayerIdentificationNumber"
                    label="CPF / CNPJ"
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <NumberInput
                    source="appUserId"
                    label="Fidelidade id"
                    fullWidth
                  />
                </Box>
              </Box>

              <Box mt="1em" />
              <Typography variant="h6" gutterBottom>
                Endereço
              </Typography>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="deliveryStreetName"
                    label="Endereço"
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="deliveryStreetNumber"
                    label="Número"
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="deliveryComplement"
                    label="Complemento"
                    fullWidth
                  />
                </Box>
              </Box>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="deliveryNeighborhood"
                    label="Bairro"
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="deliveryCity" label="Cidade" fullWidth />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="deliveryState" label="Estado" fullWidth />
                </Box>
              </Box>
              <TextInput source="deliveryPostalCode" label="CEP" />

              <Box mt="1em" />
              <Typography variant="h6" gutterBottom>
                Pagamento
              </Typography>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="paymentName" label="Pagamento" fullWidth />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="paymentPrepaid"
                    label="Pagamento Online"
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="paymentChangeFor"
                    label="Troco"
                    fullWidth
                  />
                </Box>
              </Box>

              <Box mt="1em" />
              <Typography variant="h6" gutterBottom>
                Totais
              </Typography>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <NumberInput source="subTotal" label="Sub Total" fullWidth />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <NumberInput
                    source="deliveryFee"
                    label="Taxa Entrega"
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <NumberInput source="totalPrice" label="Total" fullWidth />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <NumberInput
                    source="discount"
                    label="Desconto Cupom"
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <NumberInput
                    source="takeoutDiscount"
                    label="Desconto Retirar"
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <NumberInput
                    source="fidelityDiscount"
                    label="Desconto Fidelidade"
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <NumberInput
                    source="totalDisplay"
                    label="Total Pago"
                    fullWidth
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>

        <CardContent fullWidth>
          <JsonField
            record={record}
            source="items"
            addLabel={true}
            jsonString={true}
            reactJsonOptions={{
              name: null,
              collapsed: true,
              enableClipboard: false,
              displayDataTypes: false,
            }}
          />

          <Typography variant="h6" sx={{ pt: 5 }}>
            Eventos
          </Typography>
          <GridEventos record={record} />

          <Typography variant="h6" sx={{ pt: 5 }}>
            Stone
          </Typography>
          <GridTransactionPOS record={record} />

          <Typography variant="h6" sx={{ pt: 5 }}>
            Mercado Pago
          </Typography>
          <GridMercadoPagoPreference record={record} />
        </CardContent>
      </SimpleForm>
    </Card>
  );
}

function GridEventos({ record }) {
  if (!record || !record.eventos) {
    return <Typography variant="body2">Nenhum registro</Typography>;
  }

  const data = keyBy(record.eventos, "id");
  const ids = record.eventos.map(({ id }) => id);

  return (
    <Datagrid
      loaded={true}
      data={data}
      ids={ids}
      currentSort={{ field: "id", order: "ASC" }}
    >
      <TextField source="id" label="#" />
      <TextField source="code" />
      <TextField source="ack" />
      <DateField source="createdAt" showTime locales="pt-BR" />
    </Datagrid>
  );
}

function GridTransactionPOS({ record }) {
  if (!record || !record.transactions) {
    return <Typography variant="body2">Nenhum registro</Typography>;
  }
  const data = keyBy(record.transactions, "id");
  const ids = record.transactions.map(({ id }) => id);

  return (
    <Datagrid
      loaded={true}
      data={data}
      ids={ids}
      currentSort={{ field: "id", order: "ASC" }}
    >
      <TextField source="equipamentoId" label="Equipamento id" />
      <TextField source="movimentoId" label="Movimento id" />
      <TransactionAcquireIcon label="Adquirente" />
      <TextField source="nsu" label="NSU" m />
      <DateField source="date" label="Data" showTime={true} />
      <CurrencyCentsField source="amount" label="Valor" />
      <TextField source="type" label="Tipo" />
      <TextField source="cardHolderNumber" label="Cartão" />
      <MoedaAppIconText source="cardBrand" label="Bandeira" />
      <TextField source="authorizationCode" label="Cod.Aut." />
      <TransactionPosStatus label="Status" />
    </Datagrid>
  );
}

function GridMercadoPagoPreference({ record }) {
  if (!record || !record.mercadoPagoTransactions) {
    return <Typography variant="body2">Nenhum registro</Typography>;
  }
  const data = keyBy(record.mercadoPagoTransactions, "id");
  const ids = record.mercadoPagoTransactions.map(({ id }) => id);

  return (
    <Datagrid
      loaded={true}
      data={data}
      ids={ids}
      currentSort={{ field: "id", order: "ASC" }}
    >
      <TextField source="id" label="#" />
      <TextField source="mpId" />
      <TextField source="status" />
      <TextField source="origin" />
      <TextField source="tipo" />
      <JsonField
        source="requestJson"
        addLabel={true}
        jsonString={true}
        reactJsonOptions={{
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
      <JsonField
        source="ipnJson"
        addLabel={true}
        jsonString={true}
        reactJsonOptions={{
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
      <DateField source="createdAt" showTime locales="pt-BR" />
      <DateField source="updatedAt" showTime locales="pt-BR" />
    </Datagrid>
  );
}

export default AppPedidossForm;
