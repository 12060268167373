import React from "react";
import PaymentIcon from "@material-ui/icons/Payment";
import TransactionPosList from "./TransactionPosList";

const transaction_pos = {
  getResource: (p) => {
    return {
      name: "Transaction_pos",
      list: TransactionPosList,
    };
  },
  getMenu: (p) => {
    return {
      key: "Transaction_pos",
      to: "/Transaction_pos",
      primaryText: "Transações",
      leftIcon: iconTransacoes,
    };
  },
};

export default transaction_pos;
export const iconTransacoes = <PaymentIcon />;
