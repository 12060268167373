import React, { useState, Fragment } from "react";
import { Create, SaveButton, Toolbar, useCreate } from "react-admin";
import { Field, withTypes } from "react-final-form";
import IconContentAdd from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import IconCancel from "@material-ui/icons/Cancel";
import IconSave from "@material-ui/icons/Save";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextInput from "../../common/form/TextInput";
import { Button, IconButton } from "@material-ui/core";

const { Form } = withTypes();

const useStyles = makeStyles((theme) => ({
  form: {
    padding: "0 1em 1em 1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonIcon: {
    padding: 0,
  },
  icon: {
    marginBottom: 10,
  },
}));

const required = (value) => (value ? undefined : "Obrigatório");

function CreateButton({ reference, onCreate, label, CreateForm }) {
  const [showDialog, setShowDialog] = useState(false);
  const classes = useStyles();
  const [create, { loading }] = useCreate(reference);

  const handleClick = () => setShowDialog(true);

  const handleCloseClick = () => setShowDialog(false);

  const onSubmit = (values) => {
    create(
      {
        payload: { data: values },
      },
      {
        onSuccess: ({ data: newRecord }) => {
          onCreate(newRecord);
          setShowDialog(false);
        },
      }
    );
  };

  return (
    <Fragment>
      <IconButton
        onClick={handleClick}
        color="primary"
        className={classes.buttonIcon}
      >
        <IconContentAdd className={classes.icon} />
      </IconButton>
      <Dialog
        fullWidth
        maxWidth={CreateForm ? "lg" : "sm"}
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>Criar {label}</DialogTitle>
        <DialogContent>
          {CreateForm ? (
            <Create
              basePath={`/${reference}`}
              resource={reference}
              component="div"
              title={" "}
            >
              <CreateForm
                toolbar={
                  <Toolbar>
                    <SaveButton onSave={onSubmit} />
                  </Toolbar>
                }
              />
            </Create>
          ) : (
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit, form }) => {
                window.finalForm = window.finalForm ?? form;

                return (
                  <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.form}>
                      <Field
                        fullWidth
                        required
                        validate={required}
                        name="nome"
                        component={TextInput}
                        type="text"
                        label="Nome"
                      />
                    </div>
                    <div className={classes.actions}>
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={loading}
                        className={classes.button}
                        startIcon={<IconSave />}
                      >
                        {loading && (
                          <CircularProgress size={25} thickness={2} />
                        )}
                        Salvar
                      </Button>
                      <Button
                        color="default"
                        onClick={handleCloseClick}
                        className={classes.button}
                        startIcon={<IconCancel />}
                      >
                        Cancelar
                      </Button>
                    </div>
                  </form>
                );
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

export default CreateButton;
