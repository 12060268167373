import React, { Component } from "react";
import {
  SimpleForm,
  TextInput,
  BooleanInput,
  NumberInput,
  required,
  maxLength,
  minValue,
} from "react-admin";
import withStyles from "@material-ui/core/styles/withStyles";
import { IdDates } from "../../common/form/ConditionalInputs";
import { ImpressoraInput } from "../../common/form/ImpressoraInput";

const styles = {
  formGroup: { display: "inline-block", marginRight: 32 },
};

const validateNome = [required(), maxLength(20)];
const validateEndereco = [required(), maxLength(50)];
const validateColunas = [required(), minValue(20)];

class ImpressorasForm extends Component {
  constructor(props) {
    super(props);
    this.state = { choices: [] };
  }

  render() {
    const { classes } = this.props;
    return (
      <SimpleForm {...this.props} toolbar={this.props.toolbar} redirect="list">
        <IdDates />
        <TextInput
          source="nome"
          label="Nome da impressora"
          validate={validateNome}
          formClassName={classes.formGroup}
        />
        <ImpressoraInput
          source="modelo"
          label="Modelo da impressora"
          validate={required()}
          formClassName={classes.formGroup}
        />
        <div></div>

        <TextInput
          source="endereco"
          label="Endereco da impressora"
          validate={validateEndereco}
          formClassName={classes.formGroup}
        />
        <TextInput
          source="velocidade"
          label="Velocidade da porta"
          formClassName={classes.formGroup}
        />
        <BooleanInput
          source="raw"
          label="RAW"
          formClassName={classes.formGroup}
        />
        <div></div>

        <BooleanInput
          source="cortaPapel"
          label="Corta papel"
          formClassName={classes.formGroup}
        />
        <BooleanInput
          source="abreGaveta"
          label="Abre gaveta"
          formClassName={classes.formGroup}
        />
        <BooleanInput
          source="beep"
          label="Beep"
          formClassName={classes.formGroup}
        />
        <div></div>

        <NumberInput
          source="colunas"
          label="Número de colunas"
          initialValue={48}
          validate={validateColunas}
          formClassName={classes.formGroup}
        />
        <NumberInput
          source="colunasCondensado"
          label="Número de colunas condensado"
          initialValue={64}
          validate={validateColunas}
          formClassName={classes.formGroup}
        />
        <div></div>

        <NumberInput
          source="linhasPular"
          label="Número de linhas para pular"
          formClassName={classes.formGroup}
        />
        <TextInput
          source="impressoraReplica"
          label="Impressoras para replicar"
          formClassName={classes.formGroup}
        />
        <div></div>
      </SimpleForm>
    );
  }
}

export default withStyles(styles)(ImpressorasForm);
