import React, { useState } from "react";
import { Card, CardContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Title } from "react-admin";
import { fetchJSON } from "../../dataProvider";
import UXDelay from "../../dataProvider/uxdelay";
import Table from "../../common/table/TableReport";
import { useAuthenticated } from "react-admin";
import Total from "./ReportTotal";
import Search from "../Search";
import isEmpty from "lodash.isempty";
import { reportCardapioDigitalEnable } from "../../permissionsHelper";

const styles = {
  root: {
    minHeight: "100%",
  },
  classeA: {
    backgroundColor: "#C8E6C9",
  },
  classeB: {
    backgroundColor: "#FFF9C4",
  },
  classeC: {
    backgroundColor: "#FFAB91",
  },
};

const ReportCurvaABC = (props) => {
  useAuthenticated();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ array: [] });
  const [columns, setColumns] = useState([]);

  async function retrieveReportData({
    lojaId,
    startDate,
    endDate,
    cdpModes,
    equipamentos,
  }) {
    try {
      const orderABC = "valor_final";
      setLoading(true);
      setColumns([...defaultColumns, ...columnsABC[orderABC]]);
      const retrievedReportData = await UXDelay(
        fetchJSON("Relatorio/cdp/curva_abc", null, {
          lojaId,
          inicio: startDate,
          fim: endDate,
          ordenacao: orderABC,
          modes: cdpModes,
          equipamentos,
        })
      );
      setData(retrievedReportData);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  /*
    descricao: "Carne Fria Kg"
    qtd: 0.55
    valor_final: 38.5
    valor_custo: 0
    valor_unitario_medio: 0
    lucro: 38.5
    qtd_p: 0
    qtd_abc: 0.01
    venda_p: 0.09
    venda_abc: 0.19
    lucro_p: 0.12
    lucro_abc: 0.26
    classe: "A"
  */

  const defaultColumns = [
    {
      title: "Produto",
      field: "descricao",
      sorting: false,
      width: "40%",
    },
    {
      title: "R$ Unit. Médio",
      field: "valor_unitario_medio",
      sorting: false,
      render: (rowData) =>
        Number(rowData.valor_unitario_medio).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
    },

    {
      title: "Quant.",
      field: "qtd",
      sorting: false,
      render: (rowData) => Number(rowData.qtd).toLocaleString("pt-BR"),
    },
    {
      title: "R$ Venda",
      field: "valor_final",
      sorting: false,
      render: (rowData) =>
        Number(rowData.valor_final).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
    },
  ];

  const columnsABC = {
    valor_final: [
      {
        title: "% Venda",
        field: "venda_p",
        sorting: false,
        render: (rowData) => `${rowData.venda_p}%`,
      },
      {
        title: "ABC Venda",
        field: "venda_abc",
        sorting: false,
        render: (rowData) => `${rowData.venda_abc}%`,
      },
    ],
    qtd: [
      {
        title: "% Qtd",
        field: "qtd_p",
        sorting: false,
        render: (rowData) => `${rowData.qtd_p}%`,
      },
      {
        title: "ABC QTD",
        field: "qtd_abc",
        sorting: false,
        render: (rowData) => `${rowData.qtd_abc}%`,
      },
    ],
  };

  const options = {
    rowStyle: (rowData) => {
      return styles[`classe${rowData.classe}`];
    },
  };

  const dataEmpty = isEmpty(data.array);

  return (
    <Card className={props.classes.root}>
      <Title title="Curva ABC do Cardápio Digital" />
      <CardContent>
        <Search
          fields={["dateRange", "cdpModes", "equipamentos"]}
          onSearch={retrieveReportData}
          loading={loading}
          dataEmpty={dataEmpty}
        />
        {!dataEmpty && (
          <>
            <Total data={data} />
            <Table
              data={data.array}
              columns={columns}
              options={options}
              containerClassName={props.classes.root}
              isLoading={loading}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(ReportCurvaABC);

export const reportCurvaABC = {
  getMenu: (p, a) => {
    return reportCardapioDigitalEnable(p, a)
      ? {
          key: "reportCurvaABC",
          to: "/relatorio/cdp_curva_abc",
          primaryText: "Curva ABC",
        }
      : null;
  },
};
