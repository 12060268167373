import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NumberInput } from "react-admin";
import NumberInputRemoveArrowsCSS from "./NumberInputRemoveArrowsCSS.json";

const useStyles = makeStyles((theme) => ({
  NumberInputRemoveArrowsCSS,
}));

function NumberInputSimple({ className, ...rest }) {
  const classes = useStyles();
  return (
    <NumberInput
      {...rest}
      className={
        className
          ? `${className} ${classes.NumberInputRemoveArrowsCSS}`
          : classes.NumberInputRemoveArrowsCSS
      }
      onWheel={(e) => e.target.blur()}
    />
  );
}

export default NumberInputSimple;
