import React from "react";
import { Edit } from "react-admin";
import UsuarioLojaForm from "./UsuarioLojaForm";

const UsuarioLojaEdit = (props) => (
  <Edit title="Editar Usuário" {...props}>
    <UsuarioLojaForm />
  </Edit>
);

export default UsuarioLojaEdit;
