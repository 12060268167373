import React from "react";
import yellow from "@material-ui/core/colors/yellow";
import lightGreen from "@material-ui/core/colors/lightGreen";
import Chip from "@mui/material/Chip";

const TransactionStoneStatus = ({ record }) => {
  if (!record) return null;

  if (record.cancelled) {
    return (
      <Chip
        key="stone-status-refund"
        label="Estornado"
        size="small"
        style={{
          margin: "2px",
          backgroundColor: yellow[300],
          borderColor: yellow[300],
        }}
      />
    );
  }

  if (record.approved) {
    return (
      <Chip
        key="stone-status-entrada"
        label="Aprovado"
        size="small"
        style={{
          margin: "2px",
          backgroundColor: lightGreen[300],
          borderColor: lightGreen[300],
        }}
      />
    );
  }

  return (
    <Chip
      key="stone-status-default"
      label={`Erro ${record.status}`}
      size="small"
      style={{
        margin: "2px",
      }}
    />
  );
};

export default TransactionStoneStatus;
