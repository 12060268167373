import React, { Fragment } from "react";
import { useForm } from "react-final-form";
import {
  SimpleForm,
  TextInput,
  NumberInput,
  FormDataConsumer,
  required,
  minLength,
  maxLength,
  Title,
} from "react-admin";
import { InputAdornment } from "@material-ui/core";
import { IdDates } from "../../common/form/ConditionalInputs";
import CepInput from "../../common/form/CEPInput";
import { RadioButtonGroupInput } from "../../common/form/RadioButton";

const styles = {
  text: {
    display: "inline-block",
    marginRight: 32,
  },
  number: {
    display: "inline-block",
    marginRight: 32,
    width: "10em",
  },
};

const validateReq = [required()];
const validateBairro = [required(), minLength(5), maxLength(512)];

function DistanciaInputs() {
  const form = useForm();
  form.change("bairro", null);
  form.change("cep", null);
  form.change("status", true);

  return (
    <Fragment>
      <Title title=" (Distância)" />
      <NumberInput
        source="distancia"
        label="Até"
        fullWidth
        validate={validateReq}
        InputProps={{
          endAdornment: <InputAdornment position="start">Km</InputAdornment>,
        }}
        style={styles.number}
      />
      <div></div>

      <NumberInput
        source="valor"
        label="Taxa"
        fullWidth
        validate={validateReq}
        InputProps={{
          startAdornment: <InputAdornment position="start">R$</InputAdornment>,
        }}
        style={styles.number}
      />

      <NumberInput
        source="minutos"
        label="Tempo"
        fullWidth
        validate={validateReq}
        InputProps={{
          endAdornment: <InputAdornment position="start">min</InputAdornment>,
        }}
        style={styles.number}
      />
    </Fragment>
  );
}

function BairroInputs({ formData }) {
  const form = useForm();
  form.change("distancia", null);

  return (
    <Fragment>
      <Title title=" (Bairro)" />

      <CepInput source="cep" label="CEP" style={styles.text} />
      <div />

      <TextInput
        source="bairro"
        label="Bairro"
        validate={validateBairro}
        style={styles.text}
      />
      <div />

      <RadioButtonGroupInput
        label="Entrega no bairro?"
        source="status"
        initialValue={true}
        choices={[
          { id: true, name: "Entrega" },
          { id: false, name: "Não entrega" },
        ]}
      />
      <div></div>

      {formData.status && (
        <NumberInput
          source="valor"
          label="Taxa"
          fullWidth
          validate={validateReq}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
          style={styles.number}
        />
      )}

      {formData.status && (
        <NumberInput
          source="minutos"
          label="Tempo"
          fullWidth
          validate={validateReq}
          InputProps={{
            endAdornment: <InputAdornment position="start">min</InputAdornment>,
          }}
          style={styles.number}
        />
      )}
    </Fragment>
  );
}

function LojaEntregasForm(props) {
  return (
    <SimpleForm {...props} toolbar={props.toolbar} redirect="list">
      <IdDates />

      <FormDataConsumer>
        {(formDataProps) =>
          formDataProps.formData.porDistancia || props.record.distancia > 0 ? (
            <DistanciaInputs />
          ) : (
            <BairroInputs {...formDataProps} />
          )
        }
      </FormDataConsumer>
    </SimpleForm>
  );
}

export default LojaEntregasForm;
