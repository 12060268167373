import React from "react";
import { useNotify, useRedirect, Confirm, Title } from "react-admin";
import queryString from "query-string";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Typography,
  Switch,
  Chip,
  Button,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Delete from "@material-ui/icons/Delete";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { fetchJSON } from "../dataProvider";
import mpLogo from "../static/media/mercado-pago-logo.svg";
import LoadingButton from "../common/LoadingButton";
import UXDelay from "../dataProvider/uxdelay";
import { withUser } from "../common/util/hocs";

const useStyles = makeStyles((theme) => ({
  cover: {
    minHeight: "260px",
    height: "100%",
    backgroundColor: "rgb(57, 158, 227)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
  },
  image: {
    padding: 10,
  },
  deleteButton: {
    color: theme.palette.error.main,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
}));

export function MercadoPagoLogo() {
  const classes = useStyles();

  return (
    <div className={classes.cover}>
      <img src={mpLogo} className={classes.image} alt="Mercado pago" />
    </div>
  );
}

function MercadoPago(props) {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();

  const { loja, location } = props;
  const params = queryString.parse(location.search);
  const lojaId = loja.id;
  const code = params.code;

  const [oauth, setOauth] = React.useState(null);
  const [erro, setErro] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [updating, setUpdating] = React.useState(false);
  const [goToProfile, setGoToProfile] = React.useState(false);
  const [points, setPoints] = React.useState(null);

  React.useEffect(() => {
    if (code) {
      UXDelay(
        fetchJSON(`mercadoPago/oauth`, {
          method: "POST",
          body: JSON.stringify({ lojaId, code }),
        })
      )
        .then((json) => {
          setOauth(json);
          notify("Checkout do Mercado Pago instalado com sucesso!");
        })
        .catch((err) => {
          setErro(err);
          notify("Erro ao instalar checkout do Mercado Pago", "warning");
        })
        .finally(() => setLoading(false));
    } else {
      UXDelay(fetchJSON(`mercadoPago/oauth`, null, { lojaId }))
        .then((json) => setOauth(json))
        .catch((err) => {
          if (err.status === 404) return;
          setErro(err);
          notify("Erro ao recuperar configurações do Mercado Pago", "warning");
        })
        .finally(() => setLoading(false));
    }
  }, [lojaId, code, notify]);

  React.useEffect(() => {
    if (lojaId && oauth) {
      fetchJSON(`mercadoPago/points`, null, { lojaId }).then(setPoints);
    }
  }, [lojaId, oauth]);

  const handleInstalar = () => {
    if (
      !loja.telefone ||
      !loja.cep ||
      !loja.logradouro ||
      !loja.numero ||
      !loja.bairro ||
      !loja.cidade ||
      !loja.uf
    ) {
      setGoToProfile(true);
      return;
    }

    setLoading(true);
    fetchJSON(`MercadoPago/installParams`)
      .then((json) => {
        const params = queryString.stringify(json);
        window.location.href = `https://auth.mercadopago.com.br/authorization?${params}`;
      })
      .catch((err) => {
        setErro(err);
        notify(
          "Erro ao recuperar parametros de instalação do Mercado Pago",
          "warning"
        );
      })
      .finally(() => setLoading(false));
  };

  const handleDesinstalar = () => {
    setLoading(true);
    UXDelay(
      fetchJSON(`mercado_pago_oauth/del`, {
        method: "DELETE",
        body: JSON.stringify({
          id: oauth.id,
          lojaId,
        }),
      })
    )
      .then((json) => {
        setOauth(null);
        notify("Checkout do Mercado Pago desinstalado com sucesso!");
      })
      .catch((err) => {
        setErro(err);
        notify("Erro ao desinstalar checkout do Mercado Pago", "warning");
      })
      .finally(() => setLoading(false));
  };

  const onChangeCheckoutPRO = (event) => {
    const status = event.target.checked ? "ativar" : "desativar";
    setUpdating(true);
    UXDelay(
      fetchJSON(`MercadoPago/oauth`, {
        method: "PATCH",
        body: JSON.stringify({
          id: oauth.id,
          lojaId,
          login: event.target.checked,
        }),
      })
    )
      .then((json) => setOauth(json))
      .catch((err) => {
        setErro(err);
        notify(`Erro ao ${status} Checkout PRO!`, "warning");
      })
      .finally(() => setUpdating(false));
  };

  const onChangeCheckoutCartao = (event) => {
    const status = event.target.checked ? "ativar" : "desativar";
    setUpdating(true);
    UXDelay(
      fetchJSON(`MercadoPago/oauth`, {
        method: "PATCH",
        body: JSON.stringify({
          id: oauth.id,
          lojaId,
          cartao: event.target.checked,
        }),
      })
    )
      .then((json) => setOauth(json))
      .catch((err) => {
        setErro(err);
        notify(`Erro ao ${status} Checkout Transparente!`, "warning");
      })
      .finally(() => setUpdating(false));
  };

  const onChangeCheckoutPix = (event) => {
    const status = event.target.checked ? "ativar" : "desativar";
    setUpdating(true);
    UXDelay(
      fetchJSON(`MercadoPago/oauth`, {
        method: "PATCH",
        body: JSON.stringify({
          id: oauth.id,
          lojaId,
          pix: event.target.checked,
        }),
      })
    )
      .then((json) => setOauth(json))
      .catch((err) => {
        setErro(err);
        notify(`Erro ao ${status} Checkout Transparente!`, "warning");
      })
      .finally(() => setUpdating(false));
  };

  const handleGoToProfile = (event) => {
    redirect(`/loja_perfil/${lojaId}`);
  };

  const handleGoToList = (event) => {
    redirect(`/Mercado_Pago_Preference`);
  };

  const handleDialogClose = (event) => {
    setGoToProfile(false);
  };

  const handlePointOperating = (point) => async (event) => {
    try {
      const json = await fetchJSON(`MercadoPago/pointOperation`, {
        method: "POST",
        body: JSON.stringify({
          lojaId,
          pointId: point.id,
        }),
      });
      const newPoints = points.map((p) =>
        p.id === point.id ? { ...p, ...json } : p
      );
      setPoints(newPoints);
    } catch (err) {
      notify(`Erro ao mudar operação da point!`, "warning");
    }
  };

  return (
    <Card sx={{ display: "flex", p: 3 }}>
      <Title title="Integração do Mercado Pago" />
      <Confirm
        isOpen={goToProfile}
        title="Atenção"
        content="Antes de instalar o Mercado Pago é necessário preencher o telefone e endereço completo da loja."
        confirm="Preencher os dados"
        onConfirm={handleGoToProfile}
        onClose={handleDialogClose}
      />
      <Box sx={{ width: 1 / 4 }}>
        <MercadoPagoLogo />
      </Box>
      <Box sx={{ width: 3 / 4, display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 10 auto" }}>
          <Typography variant="h5" color="textPrimary" sx={{ mb: 2 }}>
            Mercado Pago Checkout
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ mb: 2, maxWidth: 700 }}
          >
            Ofereça mais comodidade para seu cliente! Pagamento online via PIX
            integrado ao Cardápio digital do Pallas. Receba os pedidos após a
            confirmação do pagamento, o entregador não precisa levar a
            maquininha de cartão.
          </Typography>
          {erro && (
            <Typography variant="body2" color="error" sx={{ my: 2 }}>
              {erro.message}
            </Typography>
          )}
          {oauth && oauth.mpLogin && (
            <Box sx={{ mb: 4, maxWidth: 700 }}>
              <FormControlLabel
                control={<Switch />}
                checked={oauth.login}
                onChange={onChangeCheckoutPRO}
                label="Botão Mercado Pago (Checkout PRO)"
                disabled={updating}
              />
              <Typography variant="body2" color="textSecondary">
                O cliente é encaminhado para o site do Mercado Pago onde realiza
                o login e faz o pagamento. Habilita todos as formas de
                pagamento(Saldo, Cartões e PIX).
              </Typography>
            </Box>
          )}
          {oauth && oauth.mpCartao && (
            <Box sx={{ mb: 4, maxWidth: 700 }}>
              <FormControlLabel
                control={<Switch />}
                checked={oauth.cartao}
                onChange={onChangeCheckoutCartao}
                label="Cartão de crédito e débito (Checkout Transparente)"
                disabled={updating}
              />
              <Typography variant="body2" color="textSecondary">
                O cliente realiza o pagamento na tela do Cardápio Digital
                digitando os dados do cartão.
              </Typography>
            </Box>
          )}
          {oauth && (
            <Box sx={{ mb: 4, maxWidth: 700 }}>
              <FormControlLabel
                control={<Switch />}
                checked={oauth.pix}
                onChange={onChangeCheckoutPix}
                label="PIX"
                disabled={updating}
              />
              <Typography variant="body2" color="textSecondary">
                O Cardápio Digital apresenta o "QR Code" / "PIX Copia e cola"
                para o cliente realizar o pagamento
              </Typography>
              {!oauth.mpPix && (
                <Typography variant="body2" color="red">
                  Para habilitar o PIX é necessário cadastrar uma chave PIX no
                  app do Mercado Pago.
                </Typography>
              )}
            </Box>
          )}

          {oauth && points && points.length > 0 && (
            <Box>
              <Typography variant="body2" color="textSecondary">
                Mquininhas Points:
              </Typography>
              {points.map((p) => (
                <Typography
                  key={p.id}
                  variant="body2"
                  color="textSecondary"
                  sx={{ my: 1 }}
                >
                  {p.id}
                  <Chip label={p.operating_mode} sx={{ mx: 1 }} />
                  <Button onClick={handlePointOperating(p)}>
                    {p.operating_mode === "PDV"
                      ? "Desativar PDV"
                      : "Ativar PDV"}
                  </Button>
                </Typography>
              ))}
            </Box>
          )}
        </CardContent>
        <CardActions sx={{ px: 2, py: 0, justifyContent: "space-between" }}>
          {!oauth && (
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleInstalar}
              text="Instalar"
              loading={loading}
            />
          )}
          <Button variant="contained" onClick={handleGoToList}>
            Lista de transações
          </Button>
          {oauth && (
            <LoadingButton
              variant="outlined"
              color="error"
              className={classes.deleteButton}
              onClick={handleDesinstalar}
              icon={<Delete />}
              text="Desinstalar"
              loading={loading}
            />
          )}
        </CardActions>
      </Box>
    </Card>
  );
}

export default withUser(MercadoPago);
