import React, { useState } from "react";
import { useNotify } from "react-admin";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconContentAdd from "@material-ui/icons/Add";
import { useForm } from "react-final-form";
import { fetchJSON } from "../../dataProvider";

function ProdutoProximoCodigo(props) {
  const form = useForm();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const proximoCodigo = () => {
    setLoading(true);
    fetchJSON(`produtos/proximoCodigo`, null, { lojaId: props.lojaId })
      .then((proximo) => form.change("cod_prod", proximo))
      .catch(() =>
        notify("Ocorreu um erro ao encontrar o próximo código.", "warning")
      )
      .finally(() => setLoading(false));
  };

  return loading ? (
    <CircularProgress size={25} thickness={2} />
  ) : (
    <IconButton onClick={proximoCodigo} color="primary" style={props.style}>
      <IconContentAdd />
    </IconButton>
  );
}

export default ProdutoProximoCodigo;
