import React from "react";
import { TextInput, Filter } from "react-admin";

const ImpostoFilter = props => (
  <Filter {...props}>
    <TextInput source="nome_like" label="Nome do imposto" alwaysOn />
  </Filter>
);

export default ImpostoFilter;
