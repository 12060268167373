import React from "react";
import {
  Datagrid,
  DateField,
  FormDataConsumer,
  NumberField,
} from "react-admin";
import { keyBy } from "lodash";
import DataGridRemoveButton from "../../common/form/DataGridRemoveButton";
import PedidosContasNovo from "./PedidosContasNovo";
import { Typography } from "@mui/material";
import PagamentoTotal from "./PagamentoTotal";
import DatagridEmpty from "../../common/form/Datagrid/DatagridEmpty";

export default function PedidosContasGrid({ loaded, canEdit, formData }) {
  const data = keyBy(formData.contas, "index");
  const ids = formData.contas.map(({ index }) => index);

  return (
    <>
      {canEdit && <PedidosContasNovo formData={formData} />}
      <Typography variant="h5" gutterBottom sx={{ mt: 4, ml: 1 }}>
        Lista de Pagamentos
      </Typography>
      <Datagrid
        loaded={loaded}
        data={data}
        ids={ids}
        currentSort={{ field: "index", order: "ASC" }}
        empty={<DatagridEmpty message="Nenhum pagamento no pedido" />}
      >
        <DateField source="vencimento" label="Vencimento" />
        <NumberField source="valor" label="Valor" />

        {canEdit && (
          <FormDataConsumer>
            {({ formData, record: itemData }) => (
              <DataGridRemoveButton
                formData={formData}
                record={itemData}
                arrayName="contas"
              />
            )}
          </FormDataConsumer>
        )}
      </Datagrid>
      <PagamentoTotal formData={formData} />
    </>
  );
}
