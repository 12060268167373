import { TextField } from "@material-ui/core";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useRef, useState, useContext } from "react";
import { useDataProvider, useNotify } from "react-admin";

import { SmallCircularProgress } from "../../common/CircularProgress";
import {
  calculateProfitMargin,
  calculateSellingPrice,
} from "../../common/util/util";
import ProductMirrorContext from "../produto/ProductMirrorContext";
import "./styles/EditableTextField.css";

const EditableProfitMarginField = ({
  className,
  editablePosition,
  setEditablePosition,
  ...props
}) => {
  const {
    record,
    record: {
      id: recordId,
      preco_vnd_prod: sellingPrice,
      preco_cst_prod: costPrice,
    },
  } = props;
  const positionedData = useContext(ProductMirrorContext).current;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const textFieldRef = useRef();
  const [loading, setLoading] = useState(false);
  const [profitMargin, setProfitMargin] = useState(
    calculateProfitMargin(sellingPrice, costPrice) || ""
  );
  const [fieldPosition, setFieldPosition] = useState("");
  const [isEmptyField, setIsEmptyField] = useState(false);

  const currentProftMargin = calculateProfitMargin(sellingPrice, costPrice);

  useEffect(() => {
    if (!isEmpty(positionedData)) {
      const value = Object.values(positionedData).find(
        (value) => value.id === recordId && value.source === "profit_margin"
      );
      if (value) {
        const { x, y } = value;
        setFieldPosition(`${x}.${y}`);
      }
    }
  }, [positionedData, recordId]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        textFieldRef.current &&
        !textFieldRef.current.contains(event.target) &&
        fieldPosition === editablePosition
      ) {
        setEditablePosition("");
      }
    }
    function handleArrowsKeydown(event) {
      if (!editablePosition) {
        return;
      }
      const [x, y] = editablePosition.split(".");
      const xPosition = Number(x);
      const yPosition = Number(y);
      switch (event.key) {
        case "ArrowUp":
          event.preventDefault();
          setEditablePosition(`${xPosition}.${yPosition - 1}`);
          break;
        case "ArrowDown":
          event.preventDefault();
          setEditablePosition(`${xPosition}.${yPosition + 1}`);
          break;
        case "ArrowLeft":
          event.preventDefault();
          setEditablePosition(`${xPosition - 1}.${yPosition}`);
          break;
        case "ArrowRight":
          event.preventDefault();
          setEditablePosition(`${xPosition + 1}.${yPosition}`);
          break;
        default:
          return;
      }
    }

    if (fieldPosition === editablePosition) {
      setIsEmptyField(false);
      setProfitMargin(currentProftMargin);
    }

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleArrowsKeydown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleArrowsKeydown);
    };
  }, [
    textFieldRef,
    fieldPosition,
    currentProftMargin,
    editablePosition,
    setEditablePosition,
  ]);

  const handleEditablePosition = () => {
    setIsEmptyField(false);
    setEditablePosition(fieldPosition);
  };

  const debouncedEditProfitMarginRef = useRef(
    debounce(async (value, record) => {
      try {
        setLoading(true);
        await dataProvider.update("produtos", {
          updateAttributes: true,
          data: {
            id: record.id,
            lojaId: record.lojaId,
            preco_vnd_prod: value,
          },
        });
      } catch (error) {
        notify("Erro ao atualizar os dados.", "warning");
        setProfitMargin(calculateProfitMargin(sellingPrice, costPrice));
        console.log(JSON.stringify(error));
      } finally {
        setLoading(false);
      }
    }, 700)
  );

  const handleChangeProfitMargin = (record) => (event) => {
    const { value } = event.target;
    setProfitMargin(value);
    if (!value.trim().length) {
      setIsEmptyField(true);
      notify("Campo obrigatório.", "warning");
      return;
    }

    if (!costPrice) {
      notify("Preço de custo igual a zero.", "warning");
      return;
    }

    const newSellingPrice = calculateSellingPrice(value || 0, costPrice);
    setIsEmptyField(false);
    debouncedEditProfitMarginRef.current(newSellingPrice, record);
  };

  return (
    <div
      className="container"
      ref={textFieldRef}
      onClick={handleEditablePosition}
    >
      {fieldPosition && fieldPosition === editablePosition ? (
        <TextField
          type="number"
          className={className}
          name="profitMargin"
          value={profitMargin}
          autoFocus
          error={isEmptyField}
          onChange={handleChangeProfitMargin(record)}
        />
      ) : (
        <span className={className}>{currentProftMargin}%</span>
      )}
      {loading && <SmallCircularProgress />}
    </div>
  );
};

export default EditableProfitMarginField;
