import React from "react";
import PaymentIcon from "@material-ui/icons/Payment";
import ConnectStoneList from "./ConnectStoneList";

const stone = {
  getResource: (p) => {
    return {
      name: "ConnectStone",
      list: ConnectStoneList,
    };
  },
  getMenu: (p) => {
    return {
      key: "ConnectStone",
      to: "/ConnectStone",
      primaryText: "Connect Stone",
      leftIcon: iconStone,
    };
  },
};

export default stone;
export const iconStone = <PaymentIcon />;
