import React from "react";
import {
  Datagrid,
  List,
  EditButton
} from "react-admin";
import MoedaAppFilter from "./MoedaAppFilter";
import MoedaAppIconText from "./MoedaAppIconText";

const MoedaAppList = props => (
  <List {...props} filters={<MoedaAppFilter />} exporter={false}>
    <Datagrid>
      <MoedaAppIconText label="Nome" source="nome" />
      <EditButton />
    </Datagrid>
  </List>
);

export default MoedaAppList;
