import React from "react";
import { SelectInput, SelectField } from "react-admin";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { green, red } from "@mui/material/colors";
import { Grid, Box, Avatar } from "@mui/material";

//
//  tpNF
//  0 - Entrada
//  1 - Saida
//

const ENTRADA = "Entrada";
const SAIDA = "Saída";

const tipos = [
  {
    id: 0,
    name: ENTRADA,
    icon: (
      <Avatar sx={{ width: 30, height: 30, bgcolor: green[500] }}>
        <ArrowUpwardIcon />
      </Avatar>
    ),
  },
  {
    id: 1,
    name: SAIDA,
    icon: (
      <Avatar sx={{ width: 30, height: 30, bgcolor: red[500] }}>
        <ArrowDownwardIcon />
      </Avatar>
    ),
  },
];

const NFeTipoIcon = ({ record }) => {
  return (
    <Grid container align='center' alignItems='center'>
      <Grid item>
        <Box mr={2}>{record.icon}</Box>
      </Grid>
      <Grid item>{record.name}</Grid>
    </Grid>
  );
};

function NFeTipoInput(props) {
  return (
    <SelectInput
      label='Tipo'
      source='tpNF'
      choices={tipos}
      optionText={<NFeTipoIcon />}
      {...props}
    />
  );
}

function NFeTipoField(props) {
  return (
    <SelectField
      label='Tipo'
      source='tpNF'
      choices={tipos}
      optionText={<NFeTipoIcon />}
      {...props}
    />
  );
}

export { NFeTipoInput, NFeTipoField };
