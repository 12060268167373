export function validateDecimalPlaces(d) {
  const pattern = `\\.\\d{${d + 1},}$`;
  const regex = new RegExp(pattern);
  return (value) => {
    if (value === null || value === undefined) {
      return undefined;
    }

    const valueString = value.toString();
    if (d === 0) {
      return valueString.includes(".")
        ? "Apenas números sem casas decimais."
        : undefined;
    }

    return regex.test(valueString)
      ? `No máximo ${d} casas decimais.`
      : undefined;
  };
}

export function validateInscricaoEstadual(value) {
  if (!value) {
    return undefined;
  }

  const onlyDigits = /^\d+$/.test(value);

  if (onlyDigits || value === "ISENTO") {
    return undefined;
  }

  return "Preencha com apenas números ou 'ISENTO'";
}

export function validateSaboresQtd(value) {
  if (value) {
    const regExSabores = /^[1-9](\s*,\s*[1-9])*$/;
    const valid = regExSabores.test(value);
    if (!valid) {
      return `Apenas números separados por virgulas. Ex 1, 2, 3, 4`;
    }
  }
  return undefined;
}
