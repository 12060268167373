import React from "react";
import {
  SimpleForm,
  TextInput,
  required,
  minLength,
  maxLength,
} from "react-admin";

import { IdDates } from '../../common/form/ConditionalInputs';

const validateCest = [required(), minLength(7), maxLength(7)];
const validateReq = [required()];

function CestsForm(props) {
  return (
    <SimpleForm { ...props} toolbar={props.toolbar} redirect="list">
      <IdDates />

      <TextInput 
        source="id" 
        label="Código Cest" 
        validate={validateCest} />

      <TextInput 
        source="descricao" 
        label="Descrição" 
        validate={validateReq}
        fullWidth
        multiline />

    </SimpleForm>
  );
}

export default CestsForm;