import React from "react";
import { TextInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, InputAdornment } from "@mui/material";
import NumberInputSimple from "../../../common/form/NumberInputSimple";
import TabPanel from "../../../common/form/TabPanel";
import useChangeCalc from "../useChangeCalc";
import NcmLazyReferenceInput from "../../ncm/NcmLazyReferenceInput";
import CestLazyReferenceInput from "../../cest/CestLazyReferenceInput";
import NumberInputReadonly from "../../../common/form/NumberInputReadonly";

const useStyles = makeStyles({
  codigo: {
    width: "10em",
    marginRight: "1em",
  },
  desc: {
    width: "40em",
    marginRight: "1em",
  },
  numberInput: {
    width: "10em",
    marginRight: "1em",
  },
  total: {
    width: "14em",
    marginRight: "1em",
  },
  ncm: {
    width: "25em",
    marginRight: "1em",
  },
  num_ped: {
    width: "14em",
    marginRight: "1em",
  },
});

export default function TabProduto({ record, index, tabValue, getSource }) {
  const classes = useStyles();
  const changeCalc = useChangeCalc();

  return (
    <TabPanel value={tabValue} index={index}>
      <Grid container align="center" alignItems="center">
        <Grid item>
          <TextInput
            className={classes.codigo}
            label="Código do produto"
            source={getSource("codigo")}
          />
        </Grid>
        <Grid item>
          <TextInput
            className={classes.desc}
            label="Descrição do produto"
            source={getSource("descricao")}
          />
        </Grid>
      </Grid>
      <Grid container align="center" alignItems="center">
        <Grid item>
          <NumberInputSimple
            className={classes.numberInput}
            label="Valor unit."
            source={getSource("valor_unitario")}
            InputProps={{
              startAdornment: (
                <InputAdornment sx={{ mt: 2, mr: 1 }}>R$</InputAdornment>
              ),
            }}
            onChange={changeCalc}
          />
        </Grid>
        <Grid item>
          <NumberInputSimple
            className={classes.numberInput}
            label="Quantidade"
            source={getSource("quantidade")}
            onChange={changeCalc}
          />
        </Grid>
        <Grid item>
          <NumberInputReadonly
            className={classes.total}
            label="Total"
            source={getSource("valor_total")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Grid container alignItems="center" sx={{ pb: 0 }}>
        <Grid item>
          <TextInput
            className={classes.numberInput}
            label="Unidade"
            source={getSource("unidade")}
          />
        </Grid>
        <Grid item>
          <NumberInputSimple
            className={classes.numberInput}
            label="CFOP"
            source={getSource("cfop")}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ pb: 0 }}>
        <Grid item>
          <NcmLazyReferenceInput
            loadCompleted={true}
            record={record}
            source={getSource("ncmId")}
            nameSource={getSource("ncm")}
            className={classes.ncm}
            label="NCM"
            fullWidht
          />
        </Grid>
        <Grid item>
          <CestLazyReferenceInput
            loadCompleted={true}
            record={record}
            source={getSource("cestId")}
            nameSource={getSource("cest")}
            className={classes.ncm}
            label="CEST"
            fullWidht
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ pb: 0 }}>
        <Grid item>
          <TextInput
            className={classes.num_ped}
            label="Número Pedido"
            source={getSource("num_ped")}
          />
        </Grid>
        <Grid item>
          <TextInput
            className={classes.num_ped}
            label="Número Item"
            source={getSource("num_item")}
          />
        </Grid>
        <Grid item></Grid>
      </Grid>
    </TabPanel>
  );
}
