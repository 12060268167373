import React from "react";
import { List, Datagrid, TextField, EditButton } from "react-admin";

function CentroList(props) {
  return (
    <List {...props} exporter={false}>
      <Datagrid>
        <TextField source="nome" />
        <EditButton />
      </Datagrid>
    </List>
  );
}

export default CentroList;
