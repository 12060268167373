import React, { useCallback } from "react";
import { useNotify, Title } from "react-admin";
import {
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Delete from "@material-ui/icons/Delete";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { fetchJSON } from "../dataProvider";
import LoadingButton from "../common/LoadingButton";
import UXDelay from "../dataProvider/uxdelay";
import logo from "../static/media/bee-delivery.png";
import { withUser } from "../common/util/hocs";

const useStyles = makeStyles((theme) => ({
  cover: {
    height: "100%",
    backgroundColor: "#fd0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
  },
  image: {
    maxHeight: "150px",
    padding: 100,
  },
  deleteButton: {
    color: theme.palette.error.main,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  fullWidth: {
    width: "100%",
  },
}));

export function BeeDeliveryLogo() {
  const classes = useStyles();

  return (
    <div className={classes.cover}>
      <img src={logo} className={classes.image} alt="BeeDelivery" />
    </div>
  );
}

function BeeDelivery({ loja }) {
  const classes = useStyles();
  const notify = useNotify();

  const [credentials, setCredentials] = React.useState(null);
  const [erro, setErro] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const fetchBeeDeliveryCredentials = useCallback(async (lojaId) => {
    return fetchJSON(`BeeDelivery`, null, {
      filter: { where: { lojaId } },
    }).then((list) => list[0] || null);
  }, []);

  React.useEffect(() => {
    setLoading(true);
    UXDelay(fetchBeeDeliveryCredentials(loja.id))
      .then((response) => {
        if (!response) {
          return;
        }

        setCredentials((credentials) => ({
          ...credentials,
          id: response.id,
          appKey: response.app_key,
          externalId: response.external_id,
        }));
      })
      .catch((err) => {
        notify("Erro ao recuperar configurações do BeeDelivery", "warning");
      })
      .finally(() => setLoading(false));
  }, [loja, notify, fetchBeeDeliveryCredentials]);

  const handleInstall = () => {
    setLoading(true);
    UXDelay(
      fetchJSON(
        `BeeDelivery/upsertWithWhere`,
        {
          method: "POST",
          body: JSON.stringify({
            lojaId: loja.id,
            app_key: credentials?.appKey || "",
            external_id: credentials?.externalId || "",
          }),
        },
        {
          where: { lojaId: loja.id },
        }
      )
    )
      .then((response) => {
        setCredentials((credentials) => ({ ...credentials, id: response.id }));
        notify("BeeDelivery instalado com sucesso!", "success");
      })
      .catch((err) => {
        setErro(err);
        notify("Erro ao instalar o BeeDelivery", "warning");
      })
      .finally(() => setLoading(false));
  };

  const handleUninstall = () => {
    setLoading(true);
    UXDelay(
      fetchJSON(`BeeDelivery/del`, {
        method: "DELETE",
        body: JSON.stringify({
          id: credentials.id,
          lojaId: loja.id,
        }),
      })
    )
      .then(() => {
        setCredentials(null);
        notify("BeeDelivery desinstalado com sucesso!", "success");
      })
      .catch((err) => {
        setErro(err);
        notify("Erro ao desinstalar BeeDelivery", "warning");
      })
      .finally(() => setLoading(false));
  };

  const testIntegration = () => {
    setLoading(true);
    UXDelay(
      fetchJSON(`BeeDelivery/integrationTest`, {
        method: "POST",
        body: JSON.stringify({
          lojaId: loja.id,
          appKey: credentials?.appKey,
        }),
      })
    )
      .then(() => {
        notify("Configurações válidas", "success");
      })
      .catch(() => {
        notify(
          "Erro ao testar. Por favor, verifique se as configurações estão corretas",
          "warning"
        );
      })
      .finally(() => setLoading(false));
  };

  const handleChangeAppKey = (event) =>
    setCredentials((credentials) => ({
      ...credentials,
      appKey: event.target.value,
    }));

  const handleChangeExternalId = (event) =>
    setCredentials((credentials) => ({
      ...credentials,
      externalId: event.target.value,
    }));

  return (
    <Card sx={{ display: "flex", p: 3 }}>
      <Title title="Integração do BeeDelivery" />
      <Box sx={{ width: 1 / 4 }}>
        <BeeDeliveryLogo />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography variant="h5" color="textPrimary" sx={{ mb: 2 }}>
            BeeDelivery
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            Envie seus pedidos usando o BeeDelivery.
          </Typography>
          {erro && (
            <Typography variant="body2" color="error" sx={{ my: 2 }}>
              {erro.message}
            </Typography>
          )}
          <Box display="flex">
            <Grid
              item
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              sx={{ mb: 2 }}
            >
              <Grid item>
                <Typography variant="h6" color="textPrimary" sx={{ mb: 2 }}>
                  Configurações
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  label="App Key"
                  variant="filled"
                  value={credentials?.appKey || ""}
                  disabled={loading}
                  onChange={handleChangeAppKey}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="ID da empresa"
                  variant="filled"
                  value={credentials?.externalId || ""}
                  disabled={loading}
                  onChange={handleChangeExternalId}
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        {!!credentials && (
          <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              loading={loading}
              disabled={!credentials?.appKey || !credentials?.externalId}
              onClick={testIntegration}
            >
              Testar configurações
            </Button>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            pl: 1,
          }}
        >
          {!credentials?.id && (
            <LoadingButton
              variant="contained"
              color="primary"
              className={classes.button}
              text="Instalar"
              loading={loading}
              disabled={!credentials?.appKey || !credentials?.externalId}
              onClick={handleInstall}
            />
          )}
          {credentials?.id && (
            <LoadingButton
              variant="contained"
              color="secondary"
              className={classes.deleteButton}
              icon={<Delete />}
              text="Desinstalar"
              loading={loading}
              onClick={handleUninstall}
            />
          )}
        </Box>
      </Box>
    </Card>
  );
}

export default withUser(BeeDelivery);
