import IFoodOauthEdit from "./IFoodOauthEdit";
import IFoodOauthList from "./IFoodOauthList";

const IFoodOauth = {
  getResource: (p) => {
    if (p.ADMIN) {
      return {
        name: "IFood_oauth",
        list: IFoodOauthList,
        edit: IFoodOauthEdit,
      };
    }
  },
  getMenu: (p) => {
    if (p.ADMIN) {
      return {
        key: "IFood_oauth",
        to: "/IFood_oauth",
        primaryText: "IFood",
        leftIcon: null,
      };
    }
  },
};

export default IFoodOauth;
