import React from "react";
import StorefrontIcon from "@material-ui/icons/Storefront";
import LojaEditPerfil from "./LojaEditPerfil";
import LojaCreate from "./LojaCreate";
import LojaList from "./LojaList";
import LojaEdit from "./LojaEdit";

const loja = {
  getResource: (p, a) => {
    if (p.ADMIN || p.PROPRIETARIO) {
      return {
        name: "loja_perfil",
        edit: LojaEditPerfil,
      };
    }
  },
};

export const lojaAdmin = {
  getResource: (p) => {
    if (p.ADMIN) {
      return {
        name: "lojas",
        create: LojaCreate,
        list: LojaList,
        edit: LojaEdit,
      };
    }
  },
  getMenu: (p) => {
    if (p.ADMIN) {
      return {
        key: "lojas",
        to: "/lojas",
        primaryText: "Lojas",
        leftIcon: <StorefrontIcon />,
      };
    }
  },
};

export default loja;
export const IconLoja = StorefrontIcon;
