import React from 'react';
import TotalCard from './ReportTotalCard';
import DollarIcon from '@material-ui/icons/AttachMoney';


const ReportTotalLucroCard = props => {
    return (
        <TotalCard icon={DollarIcon} title="Lucro" color="#ff9800" type="currency" {...props}/>
    );
};

ReportTotalLucroCard.propTypes = {
    
};

export default ReportTotalLucroCard;