import React from "react";
import LocalPizzaIcon from "@material-ui/icons/LocalPizza";
import AppPedidosEdit from "./AppPedidosEdit";
import AppPedidosList from "./AppPedidostList";

const appPedido = {
  getResource: (p) => {
    return {
      name: "AppPedidos",
      list: AppPedidosList,
      edit: AppPedidosEdit,
    };
  },
  getMenu: (p) => {
    if (p.ADMIN) {
      return {
        key: "AppPedidos",
        to: "/AppPedidos",
        primaryText: "AppPedidos",
        leftIcon: iconAppPedido,
      };
    }
  },
};

export default appPedido;
export const iconAppPedido = <LocalPizzaIcon />;
