import React, { useState } from "react";
import { Title, useAuthenticated } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";

import Search from "./Search";
import { Scatter } from "react-chartjs-2";
import TotalFaturamentoCard from "./ReportTotalFaturamentoCard";
import TotalValorUnitarioMedioCard from "./ReportTotalValorUnitarioMedioCard";
import TotalCard from "./ReportTotalCard";
import TotalQuantidadeCard from "./ReportTotalQuantidadeCard";
import * as formatter from "../common/util/formatter";
import { fetchJSON } from "../dataProvider";
import UXDelay from "../dataProvider/uxdelay";
import isEmpty from "lodash.isempty";
import { Card, CardContent, Grid } from "@mui/material";
import { reportPDVEnable } from "../permissionsHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
  },
  chartContainer: {
    width: "100%",
    height: "600px",
  },
  toolbarContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  totalContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

const ReportEngenhariaCardapio = () => {
  useAuthenticated();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ array: [] });

  async function retrieveReportData({
    lojaId,
    startDate,
    endDate,
    groups,
    units,
  }) {
    try {
      setLoading(true);
      const data = await UXDelay(
        fetchJSON("relatorio/pdv/engenharia_cardapio", null, {
          lojaId,
          startDate,
          endDate,
          groups,
          units,
        })
      );
      setData(data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  const chartData = {
    datasets: [
      {
        label: "Média Lucro Unitário",
        data: [
          {
            x: data.mediaLucro,
            y: data.mediaLucroMin,
          },
          {
            x: data.mediaLucro,
            y: data.mediaLucroMax,
          },
        ],
        borderWidth: 3,
        borderColor: "red",
        showLine: true,
        fill: false,
      },
      {
        label: "Média Popularidade",
        data: [
          {
            x: data.mediaQtdMin,
            y: data.mediaQtd,
          },
          {
            x: data.mediaQtdMax,
            y: data.mediaQtd,
          },
        ],
        borderWidth: 3,
        borderColor: "black",
        showLine: true,
        fill: false,
      },
      {
        label: "Produto",
        data: data.array.map((values) => ({
          x: values.lucro_unitario,
          y: values.qtd_p,
        })),
        pointBackgroundColor: "#81C784",
        pointBorderColor: "#66BB6A",
        borderColor: "#66BB6A",
        backgroundColor: "#81C784",
        pointRadius: 7,
        pointHoverRadius: 10,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    tooltip: {
      mode: "label",
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: false,
        ticks: {
          callback: function (value, index, ticks) {
            return `R$ ${value}`;
          },
        },
        title: {
          display: true,
          text: "Lucro unitário",
        },
      },
      y: {
        beginAtZero: false,
        ticks: {
          callback: function (value, index, ticks) {
            return `${value}%`;
          },
        },
        title: {
          display: true,
          text: "Popularidade",
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (items) => {
            const itemData = data.array[items[0].dataIndex];
            if (
              itemData.lucro_unitario === items[0].raw.x &&
              itemData.qtd_p === items[0].raw.y
            ) {
              return `${itemData.descricao} (${itemData.classificacao})`;
            }
          },
          afterTitle: (items) => {
            const itemData = data.array[items[0].dataIndex];
            if (
              itemData.lucro_unitario === items[0].raw.x &&
              itemData.qtd_p === items[0].raw.y
            ) {
              return `
    Lucro unitário: ${formatter.formatCurrency(itemData.lucro_unitario)}
    Popularidade: ${formatter.formatNumber(itemData.qtd_p)}%
  
    Quantidade: ${formatter.formatNumber(itemData.qtd)}
    Faturamento: ${formatter.formatCurrency(itemData.valor_final)}
    Lucro total: ${formatter.formatCurrency(itemData.lucro)}
    `;
            }
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
  };

  const classes = useStyles();
  const dataEmpty = isEmpty(data.array);

  return (
    <Card className={classes.root}>
      <Title title="Engenharia de Cardápio" />
      <CardContent>
        <Search
          fields={["dateRange", "groups", "units"]}
          onSearch={retrieveReportData}
          loading={loading}
          dataEmpty={dataEmpty}
        />
        {!dataEmpty && (
          <>
            <Grid container spacing={3} className={classes.toolbarContainer}>
              <Grid item xs={3}>
                <TotalFaturamentoCard total={data.total_venda} />
              </Grid>
              <Grid item xs={3}>
                <TotalQuantidadeCard
                  total={data.numeroProdutos}
                  title="Número de Produtos"
                />
              </Grid>
              <Grid item xs={3}>
                <TotalValorUnitarioMedioCard
                  total={data.valor_unitario_medio}
                />
              </Grid>
              <Grid item xs={3}>
                <TotalCard
                  total={data.total_custo}
                  percentage={data.total_custo_p}
                  type="currency"
                  icon={MoneyOffIcon}
                  title="Total Custo"
                  color="rgb(255, 193, 7)"
                />
              </Grid>
            </Grid>
            <div className={classes.chartContainer}>
              {data.array.length > 0 && (
                <Scatter data={chartData} options={chartOptions} />
              )}
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ReportEngenhariaCardapio;

export const engenhariaCardapio = {
  getMenu: (p, a) =>
    reportPDVEnable(p, a)
      ? {
          key: "engCardapio",
          to: "/relatorio/engenharia-cardapio",
          primaryText: "Eng. Cardápio",
        }
      : null,
};
