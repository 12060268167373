import React from "react";
import { SimpleForm, RadioButtonGroupInput } from "react-admin";

export default function EtiquetaConfigForm(props) {
  return (
    <SimpleForm {...props}>
      <RadioButtonGroupInput
        row={false}
        translateChoice={false}
        label="Tamanho do código do produto no código de barras"
        source="digitos_codigo_produto"
        choices={[
          { id: 4, name: "4 digitos" },
          { id: 5, name: "5 digitos" },
          { id: 6, name: "6 digitos" },
        ]}
      />
      <RadioButtonGroupInput
        row={false}
        translateChoice={false}
        label="Últimos 6 números do código de barras"
        source="etiqueta_preco"
        choices={[
          { id: false, name: "Peso do produto no código de barras" },
          {
            id: true,
            name: "Preço do produto no código de barras",
          },
        ]}
      />
    </SimpleForm>
  );
}
