import { TextField } from "@material-ui/core";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useRef, useState, useContext } from "react";
import { useDataProvider, useNotify, useRecordContext } from "react-admin";

import { SmallCircularProgress } from "../../common/CircularProgress";
import ProductMirrorContext from "../produto/ProductMirrorContext";
import "./styles/EditableTextField.css";

const EditableTextField = (props) => {
  return props.record ? ___EditableTextField(props) : null;
};

const ___EditableTextField = ({
  children,
  required = false,
  editablePosition,
  setEditablePosition,
  validation,
  type = "text",
  ...props
}) => {
  const { source, record } = props;
  const { basePath, resource } = useRecordContext(props);
  const positionedData = useContext(ProductMirrorContext).current;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const textFieldRef = useRef();
  const [loading, setLoading] = useState(false);
  const [textValue, setTextValue] = useState(record[source] || "");
  const [fieldPosition, setFieldPosition] = useState("");
  const [isEmptyField, setIsEmptyField] = useState(false);

  useEffect(() => {
    if (!isEmpty(positionedData)) {
      const value = Object.values(positionedData).find(
        (value) => value.id === record.id && value.source === source
      );
      if (value) {
        const { x, y } = value;
        setFieldPosition(`${x}.${y}`);
      }
    }
  }, [positionedData, record.id, source]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        textFieldRef.current &&
        !textFieldRef.current.contains(event.target) &&
        fieldPosition === editablePosition
      ) {
        setEditablePosition("");
      }
    }
    function handleArrowsKeydown(event) {
      if (!editablePosition) {
        return;
      }
      const [x, y] = editablePosition.split(".");
      const xPosition = Number(x);
      const yPosition = Number(y);
      switch (event.key) {
        case "ArrowUp":
          event.preventDefault();
          setEditablePosition(`${xPosition}.${yPosition - 1}`);
          break;
        case "ArrowDown":
          event.preventDefault();
          setEditablePosition(`${xPosition}.${yPosition + 1}`);
          break;
        case "ArrowLeft":
          event.preventDefault();
          setEditablePosition(`${xPosition - 1}.${yPosition}`);
          break;
        case "ArrowRight":
          event.preventDefault();
          setEditablePosition(`${xPosition + 1}.${yPosition}`);
          break;
        default:
          return;
      }
    }

    if (fieldPosition === editablePosition) {
      setIsEmptyField(false);
      setTextValue(record[source]);
    }

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleArrowsKeydown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleArrowsKeydown);
    };
  }, [
    textFieldRef,
    fieldPosition,
    record,
    source,
    editablePosition,
    setEditablePosition,
  ]);

  const handleEditablePosition = () => {
    setIsEmptyField(false);
    setEditablePosition(fieldPosition);
  };

  const debouncedEditTextFieldRef = useRef(
    debounce(async (value, record, source) => {
      try {
        setLoading(true);
        if (validation instanceof Function) {
          const error = await validation(value, record, source);
          if (typeof error === "string" && error.length > 0) {
            notify(error, "warning");
            setTextValue(record[source]);
            return;
          }
        }

        await dataProvider.update("produtos", {
          updateAttributes: true,
          data: {
            id: record.id,
            lojaId: record.lojaId,
            [source]: value,
          },
        });
      } catch (error) {
        notify("Erro ao atualizar os dados.", "warning");
        setTextValue(record[source]);
        console.log(JSON.stringify(error));
      } finally {
        setLoading(false);
      }
    }, 700)
  );

  const handleChangeTextField = (record, source) => (event) => {
    const { value } = event.target;
    setTextValue(value);
    if (required && !value.trim().length) {
      setIsEmptyField(true);
      notify("Campo obrigatório.", "warning");
      return;
    }
    setIsEmptyField(false);
    debouncedEditTextFieldRef.current(value, record, source);
  };

  return (
    <div
      className="container"
      ref={textFieldRef}
      onClick={handleEditablePosition}
    >
      {fieldPosition && fieldPosition === editablePosition ? (
        <TextField
          type={type}
          name={source}
          value={textValue}
          autoFocus
          error={isEmptyField}
          onChange={handleChangeTextField(record, source)}
        />
      ) : (
        React.cloneElement(children, {
          ...props,
          record,
          basePath,
          resource,
        })
      )}
      {loading && <SmallCircularProgress />}
    </div>
  );
};

export default EditableTextField;
