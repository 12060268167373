import * as React from "react";
import { useListContext, useNotify } from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import fetchJson from "../../dataProvider/fetchJson";
import { API_URL } from "../../config/constants";
import { withUser } from "../../common/util/hocs";
import LoadingButtonReactAdmin from "../../common/LoadingButtonReactAdmin";

const ButtonUploadXML = ({ loja }) => {
  const [loading, setLoading] = React.useState(false);
  const inputFile = React.useRef(null);
  const notify = useNotify();
  const { refetch } = useListContext();

  const handleOpenFileDialog = () => {
    inputFile.current.click();
  };

  const handleChoseFile = () => {
    var file = inputFile.current.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onload = function (evt) {
        uploadXML(evt.target.result);
      };
      reader.onerror = function (evt) {
        console.error(evt);
      };
    }
  };

  const uploadXML = async (fileContent) => {
    try {
      setLoading(true);
      inputFile.current.value = null;
      await fetchJson(`${API_URL}/sefaz/uploadXML`, {
        method: "POST",
        body: JSON.stringify({
          lojaId: loja.id,
          xml: fileContent,
        }),
      });

      refetch();

      notify("XML enviado com sucesso!");
    } catch (err) {
      console.log(JSON.stringify(err));
      if (err.status === 500) {
        notify("Erro ao enviar XML. Verifique se este é um arquivo válido.");
      } else {
        notify(err.body.error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <LoadingButtonReactAdmin
        text="enviar xml"
        icon={<AddIcon />}
        onClick={handleOpenFileDialog}
        loading={loading}
      />
      <input
        type="file"
        accept=".xml"
        id="file"
        ref={inputFile}
        style={{ display: "none" }}
        onChange={handleChoseFile}
      />
    </>
  );
};

export default withUser(ButtonUploadXML);
