import React, { useState } from "react";
import { useDataProvider, useNotify } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import ProdutoLazyReferenceInput from "../produto/ProdutoLazyReferenceInput";
import { useForm } from "react-final-form";
import { calculosNFeTomada } from "./calculos";
import { set } from "lodash";
import { SmallCircularProgress } from "../../common/CircularProgress";
import { Grid } from "@mui/material";
import uxdelay from "../../dataProvider/uxdelay";

const useStyles = makeStyles({
  buttonIcon: {
    margin: 0,
    padding: 0,
  },
  produto: {
    width: "40em",
    marginRight: "1em",
  },
});

function ProcuraProdutoButton({ formData, member }) {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const form = useForm();

  const handleClick = () => setShowDialog(true);
  const handleCloseClick = () => setShowDialog(false);

  const handleChangeProduct = async (productId) => {
    try {
      setLoading(true);
      const { data: produto } = await uxdelay(
        dataProvider.getOne("produtos", {
          id: productId,
        })
      );

      set(formData, `${member}.produto`, produto);
      set(formData, `${member}.venda`, produto.preco_vnd_prod);
      calculosNFeTomada(form, formData);

      setShowDialog(false);
    } catch (error) {
      notify("Erro ao carregar os dados do produto.", "warning");
      console.log(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        onClick={handleClick}
        color="primary"
        className={classes.buttonIcon}
      >
        <SearchIcon />
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>Procura produto</DialogTitle>
        <DialogContent>
          <Grid container alignItems="center">
            <Grid item>
              <ProdutoLazyReferenceInput
                label="Produto"
                source={`${member}.produtoId`}
                nameSource={`${member}.produto`}
                onChange={handleChangeProduct}
                className={classes.produto}
                fullWidth
                required
              />
            </Grid>
            {loading && (
              <Grid item>
                <SmallCircularProgress />
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ProcuraProdutoButton;
