import React from "react";
import { Filter, BooleanInput, TextInput } from "react-admin";
import { CPFCNPJInput } from "../../common/form/NumberMaskInput";
import { NFeTipoInput } from "./NFeTipo";

const NFeFilter = (props) => (
  <Filter {...props}>
    <TextInput source="xNome_like" label="Nome emitente" alwaysOn />
    <BooleanInput
      source="entrada"
      label="Entrada realizada"
      defaultValue={true}
    />

    <BooleanInput source="cienciaOperacao" label="Ciência da operação" />

    <BooleanInput
      source="confirmacaoOperacao"
      label="Confirmação da operação"
    />

    <BooleanInput
      source="operacaoNaoRealizada"
      label="Operação não realizada"
    />

    <BooleanInput
      source="desconhecimentoOperacao"
      label="Desconhecimento da operação"
    />

    <CPFCNPJInput source="CNPJ_like" label="CNPJ emitente" />
    <TextInput source="chNFe_like" label="Chave de acesso" />
    <NFeTipoInput source="tpNF" label="Tipo" />
  </Filter>
);

export default NFeFilter;
