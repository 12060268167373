import React from "react";
import {
  Datagrid,
  List,
  TextField,
  NumberField,
  EditButton
} from "react-admin";

import ImpressoraFilter from "./ImpressoraFilter";
import { ImpressoraField } from "../../common/form/ImpressoraInput"

const ImpressoraList = props => (
  <List {...props} filters={<ImpressoraFilter />} exporter={false}>
    <Datagrid>
      <TextField source="nome" label="Nome" /> 
      <ImpressoraField source="modelo" label="Modelo" /> 
      <TextField source="endereco" label="Endereço" /> 
      <NumberField source="colunas" label="Número de colunas" /> 
      <NumberField source="colunasCondensado" label="Número de colunas condensado" /> 
      <EditButton />
    </Datagrid>
  </List>
);

export default ImpressoraList;
