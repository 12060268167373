import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, InputAdornment, Typography } from "@mui/material";
import NumberInputSimple from "../../../common/form/NumberInputSimple";
import useChangeCalc from "../useChangeCalc";
import NumberInputReadonly from "../../../common/form/NumberInputReadonly";

const useStyles = makeStyles({
  numberInput: {
    width: "10em",
    marginRight: "1em",
  },
});

function NumberReadOnly(props) {
  const classes = useStyles();
  return (
    <NumberInputReadonly
      className={classes.numberInput}
      InputProps={{
        startAdornment: <InputAdornment sx={{ mr: 1 }}>R$</InputAdornment>,
      }}
      {...props}
    />
  );
}

export default function TabTotais() {
  const classes = useStyles();
  const changeCalc = useChangeCalc();

  return (
    <Grid>
      <Typography variant="h5" gutterBottom sx={{ my: 4, ml: 1 }}>
        Totais da NFe
      </Typography>
      <Grid container align="center" alignItems="center" sx={{ pb: 4 }}>
        <Grid item>
          <NumberReadOnly
            label="Total dos produtos"
            source={"total_produtos"}
          />
        </Grid>
        <Grid item>
          <NumberReadOnly
            label="Total de Crédito SN"
            source={"total_cred_sn_v"}
          />
        </Grid>
      </Grid>
      <Grid container align="center" alignItems="center" sx={{ pb: 4 }}>
        <Grid item>
          <NumberReadOnly label="Total PIS" source={"total_pis_v"} />
        </Grid>
        <Grid item>
          <NumberReadOnly label="Total COFINS" source={"total_cofins_v"} />
        </Grid>
        <Grid item>
          <NumberReadOnly label="Total do IPI" source={"total_ipi_v"} />
        </Grid>
      </Grid>
      <Grid container align="center" alignItems="center" sx={{ pb: 4 }}>
        <Grid item>
          <NumberReadOnly label="Total ICMS BC" source={"total_icms_bc"} />
        </Grid>
        <Grid item>
          <NumberReadOnly label="Total ICMS" source={"total_icms_v"} />
        </Grid>
        <Grid item>
          <NumberReadOnly label="Total FCP" source={"total_fcp_v"} />
        </Grid>
      </Grid>
      <Grid container align="center" alignItems="center" sx={{ pb: 4 }}>
        <Grid item>
          <NumberReadOnly
            label="Total ICMS BC ST"
            source={"total_icms_st_bc"}
          />
        </Grid>
        <Grid item>
          <NumberReadOnly label="Total ICMS ST" source={"total_icms_st_v"} />
        </Grid>
        <Grid item>
          <NumberReadOnly label="Total FCP ST" source={"total_fcp_st_v"} />
        </Grid>
      </Grid>
      <Grid container align="center" alignItems="center" sx={{ pb: 4 }}>
        <NumberInputSimple
          className={classes.numberInput}
          label="Total Desconto"
          source={"total_desconto"}
          onChange={changeCalc}
          InputProps={{
            startAdornment: (
              <InputAdornment sx={{ mt: 2, mr: 1 }}>R$</InputAdornment>
            ),
          }}
        />
        <NumberInputSimple
          className={classes.numberInput}
          label="Total Frete"
          source={"total_frete"}
          onChange={changeCalc}
          InputProps={{
            startAdornment: (
              <InputAdornment sx={{ mt: 2, mr: 1 }}>R$</InputAdornment>
            ),
          }}
        />
        <NumberInputSimple
          className={classes.numberInput}
          label="Total Seguro"
          source={"total_seguro"}
          onChange={changeCalc}
          InputProps={{
            startAdornment: (
              <InputAdornment sx={{ mt: 2, mr: 1 }}>R$</InputAdornment>
            ),
          }}
        />
        <NumberInputSimple
          className={classes.numberInput}
          label="Total Outros"
          source={"total_outro"}
          onChange={changeCalc}
          InputProps={{
            startAdornment: (
              <InputAdornment sx={{ mt: 2, mr: 1 }}>R$</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid container align="center" alignItems="center" sx={{ pb: 4 }}>
        <Grid item>
          <NumberReadOnly label="Total Nota" source={"total_nota"} />
        </Grid>
      </Grid>
    </Grid>
  );
}
