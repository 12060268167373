import React from "react";
import { SelectInput, SelectField } from "react-admin";

export const OPCOES = [
  { id: 0, name: "Windows_Add_GenericText" },
  { id: 1, name: "Windows_Add_Drive" },
  { id: 2, name: "MP20_CI" },
  { id: 3, name: "MP20_MI" },
  { id: 4, name: "MP20_H" },
  { id: 5, name: "MP2000_CI" },
  { id: 6, name: "MP2000_TH" },
  { id: 7, name: "MP2100_TH" },
  { id: 8, name: "MP2500_TH" },
  { id: 9, name: "MP4000_TH" },
  { id: 10, name: "MP4200_TH" },
  { id: 11, name: "Epson" },
  { id: 12, name: "Daruma" },
  { id: 13, name: "Diebold" },
  { id: 14, name: "Sweda" },
  { id: 15, name: "Elgin" },
  { id: 16, name: "MP100S_TH" },
  { id: 17, name: "ATM202" },
  { id: 18, name: "Virtual" },
  { id: 19, name: "Imagem" },
  { id: 20, name: "Control iD" },
  { id: 21, name: "Tanca" },
  { id: 22, name: "CIS" },
  { id: 23, name: "Custom" },
];

export const ImpressoraInput = (props) => {
  return <SelectInput {...props} choices={OPCOES} />;
};

export const ImpressoraField = (props) => {
  return <SelectField {...props} choices={OPCOES} />;
};
