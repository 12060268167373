import React from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import UsuarioLogList from "./UsuarioLogList";
import { isHighLevel } from "../../permissionsHelper";

const usuarios_log = {
  getResource: (p, a) => {
    return {
      name: "user_logs",
      list: isHighLevel(p, a) ? UsuarioLogList : null,
    };
  },
  // getMenu: (p, a) =>
  //   isHighLevel(p, a)
  //     ? {
  //         key: "user_logs",
  //         to: "/user_logs",
  //         primaryText: "Logs",
  //         leftIcon: iconUsuarioLog,
  //       }
  //     : null,
};

export default usuarios_log;
export const iconUsuarioLog = <VisibilityIcon />;
