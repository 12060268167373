import React from "react";
import {
  BooleanInput,
  TextInput,
  NumberInput,
  RadioButtonGroupInput,
  maxLength,
  required,
  minValue,
  Labeled,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { InputAdornment } from "@material-ui/core";
import ImageInputPreview from "../../common/form/ImageInputPreview";
import { Box } from "@mui/material";
import ColorInput from "../../common/form/ColorInput";

const validateAppDeliveryUri = [maxLength(50)];
const validateRS = [required(), minValue(0)];
const validateAgendamentoDias = [required(), minValue(1)];

const styles = {
  pedidoMinimo: {
    display: "inline-block",
    marginRight: 32,
    width: "10em",
  },
  inline: {
    display: "inline-block",
    marginRight: 32,
  },
  link: {
    display: "inline-block",
    marginTop: 32,
    marginBottom: 16,
    marginLeft: 32,
  },
  pixHelp: {
    display: "inline-block",
    marginTop: 16,
    marginLeft: 32,
  },
  boxConfig: {
    marginBottom: "1em",
    paddingTop: "1em",
    paddingLeft: "1em",
    borderWidth: 2,
    borderRadius: 10,
    borderColor: "#eeeeee",
    borderStyle: "double",
  },
};

const forbiddenChars = [" ", ".", "#", "$", "[", "]", "?"];

const removeComments = (value) => {
  return value && value.replace(/<!--[\S\s]*?-->/g, "").trim();
};

function LojaFormApp({ record, formData, permissao }) {
  const appUrl = encodeURI(
    `https://delivery.sistemapallas.com.br/${formData.appDeliveryUri}`
  );

  const uriHandleKeyDown = (e) => {
    if (forbiddenChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <ImageInputPreview record={record} formData={formData} />

      <Box style={styles.boxConfig} sx={{ mt: 2 }}>
        <TextInput
          source="appDeliveryUri"
          label="App delivery nome"
          validate={validateAppDeliveryUri}
          disabled={!!record.appDeliveryUri && !permissao.ADMIN}
          onKeyDown={uriHandleKeyDown}
        />
        {formData.appDeliveryUri && (
          <a
            style={styles.link}
            href={appUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {appUrl}
          </a>
        )}
      </Box>

      <Box style={styles.boxConfig}>
        <BooleanInput
          source="appRetirada"
          label="Aceita retirada"
          style={styles.inline}
        />

        {formData.appRetirada && (
          <NumberInput
            source="appDescontoRetirada"
            label="Desconto retirada"
            style={styles.pedidoMinimo}
            validate={validateRS}
            initialValue={0}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
          />
        )}
      </Box>

      <Box style={styles.boxConfig}>
        <BooleanInput
          source="appEntrega"
          label="Aceita entrega"
          style={styles.inline}
        />

        {formData.appEntrega && (
          <NumberInput
            source="appPedidoMinimo"
            label="Pedido mínimo"
            style={styles.pedidoMinimo}
            validate={validateRS}
            initialValue={0}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              ),
            }}
          />
        )}

        {formData.appEntrega && (
          <RadioButtonGroupInput
            row={false}
            translateChoice={false}
            label="Taxa de entrega"
            source="appEnderecoManual"
            choices={[
              { id: false, name: "Taxa de entrega por distância" },
              { id: true, name: "Taxa de entrega por bairro" },
            ]}
            style={styles.inline}
          />
        )}
      </Box>

      <Box style={styles.boxConfig}>
        <BooleanInput
          source="appAgendamento"
          label="Aceita agendamento"
          style={styles.inline}
        />

        {formData.appAgendamento && (
          <NumberInput
            source="appAgendamentoDias"
            label="Número de dias a frente"
            validate={validateAgendamentoDias}
            initialValue={1}
          />
        )}
      </Box>

      <Box style={styles.boxConfig}>
        <BooleanInput
          source="appMesa"
          label="Aceita pedidos na mesa"
          style={styles.inline}
        />

        {formData.appMesa && (
          <RadioButtonGroupInput
            row={false}
            translateChoice={false}
            label="Cardápio digital na mesa"
            source="appMesaPedidos"
            choices={[
              { id: false, name: "Permite apenas visualizar os produtos" },
              { id: true, name: "Permite realiazar os pedidos" },
            ]}
            style={styles.inline}
          />
        )}

        <div></div>
        {formData.appMesa && formData.appMesaPedidos && (
          <BooleanInput
            source="appMesaExigeToken"
            label="Exige liberação do garçom antes de fazer o primeiro pedido"
            style={styles.inline}
          />
        )}

        {formData.appMesa && formData.appMesaPedidos && (
          <BooleanInput
            source="appMesaComanda"
            label="Trabalha com mesa e comandas individuais"
            style={styles.inline}
          />
        )}

        <div></div>
        {formData.appMesa && formData.appMesaPedidos && (
          <BooleanInput
            source="appMesaPagamento"
            label="Exige pagamento"
            style={styles.inline}
          />
        )}

        {formData.appMesa &&
          formData.appMesaPedidos &&
          formData.appMesaPagamento && (
            <BooleanInput
              source="appMesaPagamentoPos"
              label="Permite pagamento no totem mobile"
              style={styles.inline}
            />
          )}
      </Box>

      <BooleanInput
        source="appEnviaWhatsapp"
        label="Envia pedidos no whatsapp"
      />
      <div />

      <Box style={styles.boxConfig}>
        <TextInput source="chave_pix" label="Chave PIX Manual" />
        <small style={styles.pixHelp}>
          Chave PIX não integrada, conferência manual no app do banco.
          <br></br> Para o PIX com confirmação de recebimento automática utilize
          o{" "}
          <a href="http://localhost:3000/#/integracoes/mercadoPago">
            Mercado Pago
          </a>
          .
        </small>
      </Box>

      <NumberInput
        source="appKiloIncremento"
        label="Incremento do kilo"
        initialValue={100}
        InputProps={{
          endAdornment: <InputAdornment position="start">g</InputAdornment>,
        }}
      />
      <div></div>

      <Box style={styles.boxConfig}>
        <RadioButtonGroupInput
          row={false}
          translateChoice={false}
          label="Layout da tela inicial"
          source="appGrupoLayout"
          choices={[
            { id: false, name: "Todos os grupos e produtos" },
            { id: true, name: "Apenas os grupos" },
          ]}
          style={styles.inline}
        />
        <ColorInput
          source="appBackgroudColor"
          label="Cor do fundo"
          style={styles.inline}
        />
        <ColorInput
          source="appTextColor"
          label="Cor do texto"
          style={styles.inline}
        />
      </Box>

      <Box style={styles.boxConfig}>
        <RichTextInput source="appInfoCabecalho" label="Cabeçalho" />
      </Box>
      <Box style={styles.boxConfig}>
        <RichTextInput source="appInfoRodape" label="Rodapé" />
      </Box>

      <Box style={styles.boxConfig}>
        <RichTextInput
          source="appMsgPedRealizado"
          label="Mensagem após o pedido realizado"
        />
      </Box>

      <Box style={styles.boxConfig}>
        <Labeled label="Script Head" fullWidth>
          <TextInput
            source="appScriptHead"
            label=""
            multiline
            spellCheck="false"
            parse={removeComments}
          />
        </Labeled>
      </Box>

      <Box style={styles.boxConfig}>
        <Labeled label="Script Body" fullWidth>
          <TextInput
            source="appScriptBody"
            label=""
            multiline
            spellCheck="false"
            parse={removeComments}
          />
        </Labeled>
      </Box>
    </>
  );
}

export default LojaFormApp;
