import React from "react";
import { TextField, DateField, FunctionField, Labeled } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  id: {
    width: "5em",
    marginRight: "1em",
  },
  date: {
    width: "11em",
    marginRight: "1em",
  },
});

export const IdField = ({ record, ...rest }) => {
  const classes = useStyles();
  return record && record.id ? (
    <Labeled label="Id" className={classes.id}>
      <TextField source="id" record={record} {...rest} />
    </Labeled>
  ) : null;
};

export const CreatedField = ({ record, ...rest }) => {
  const classes = useStyles();
  return record && record.createdAt ? (
    <Labeled label="Criado em" className={classes.date}>
      <CreatedFieldNL record={record} {...rest} />
    </Labeled>
  ) : null;
};

export const CreatedFieldNL = ({ record, ...rest }) => {
  return (
    <DateField
      source="createdAt"
      showTime
      locales="pt-BR"
      record={record}
      {...rest}
    />
  );
};

export const UpdatedField = ({ record, ...rest }) => {
  const classes = useStyles();
  return record && record.updatedAt ? (
    <Labeled label="Atualizado em" className={classes.date}>
      <UpdatedFieldNL record={record} {...rest} />
    </Labeled>
  ) : null;
};

export const UpdatedFieldNL = ({ record, ...rest }) => {
  return record.updatedAt === record.createdAt ? (
    <FunctionField render={() => "Nunca"} />
  ) : (
    <DateField
      source="updatedAt"
      showTime
      locales="pt-BR"
      record={record}
      {...rest}
    />
  );
};

export const IdDates = ({ record, ...rest }) => {
  return record ? (
    <div
      style={{
        marginBottom: "1em",
        paddingTop: "1em",
        paddingLeft: "1em",
      }}
    >
      <IdField record={record} {...rest} />
      <CreatedField record={record} {...rest} />
      <UpdatedField record={record} {...rest} />
    </div>
  ) : null;
};
