import * as React from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useQuickFilterStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(1),
    }
}));

const QuickFilter = ({ label }) => {
    const classes = useQuickFilterStyles();

    return (
        <Chip
            className={classes.root}
            label={label}
        />
    );
};

export default QuickFilter;