import React from "react";
import { FunctionField as FF } from "react-admin";
import { parseISO, differenceInMinutes } from 'date-fns'

function DuracaoField(props) {
  const { source, getSource, inicio, fim, apenasHora, ...rest } = props;

  return <FF {...rest} render={record => {
    let MT = 0;
    if (source) {
      MT = record[source];
    }
    else if (getSource) {
      MT = getSource(record);
    }
    else if (inicio && fim) {
      let i = record[inicio];
      let f = record[fim];
  
      if (!i || !f) {
        return '';
      }
  
      i = parseISO(i);
      f = parseISO(f);
  
      if (apenasHora) {
        i.setDate(1)
        i.setMonth(1)
        i.setYear(2000)
        f.setDate(1)
        f.setMonth(1)
        f.setYear(2000)
      }

      MT = differenceInMinutes(f, i);  
    }
    else {
      throw new Error('DuracaoField error: nenhuma das propriedades informadas(source, getSource, inicio, fim)');
    }

    const d = Math.floor(MT / 1440);
    MT -= Math.round(d * 1440);
    const h = Math.floor(MT / 60);
    MT -= Math.round(h * 60);

    return (d > 0 ? `${d}d ` : '') +
           (h > 0 || (d > 0 && MT > 0) ? `${h}h ` : '') + 
           (MT > 0 ? `${MT}m ` : '');
  }} />
}

export default DuracaoField;
