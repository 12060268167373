import React from "react";
import TotalCard from "./ReportTotalCard";
import ScoreIcon from "@material-ui/icons/Score";

const ReportTotalQuantidadeCard = (props) => {
  return (
    <TotalCard
      icon={ScoreIcon}
      type='number'
      title='Quantidade'
      color='rgb(255, 87, 34)'
      {...props}
    />
  );
};

ReportTotalQuantidadeCard.propTypes = {};

export default ReportTotalQuantidadeCard;
