import React from "react";
import {
  SimpleForm,
  TextInput,
  required
} from "react-admin";

import { IdDates } from '../../common/form/ConditionalInputs';

const validateNome = [required()];

function MoedaAdquirentesForm(props) {
  return (
    <SimpleForm { ...props} toolbar={props.toolbar} redirect="list">
        <IdDates />
        <TextInput 
          source="nome" 
          label="Nome da adquirente" 
          validate={validateNome} />      
    </SimpleForm>
    );
  }

export default MoedaAdquirentesForm;