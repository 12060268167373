import React from "react";
import PeopleIcon from "@material-ui/icons/People";
import ParceiroCreate from "./ParceiroCreate";
import ParceiroEdit from "./ParceiroEdit";
import ParceiroList from "./ParceiroList";

const parceiro = {
  getResource: (p) => {
    return {
      name: "parceiros",
      create: p.cadastros_ver ? ParceiroCreate : null,
      list: p.cadastros_ver ? ParceiroList : null,
      edit: p.cadastros_ver ? ParceiroEdit : null,
    };
  },
  getMenu: (p) =>
    p.cadastros_ver
      ? {
          key: "parceiros",
          to: "/parceiros",
          primaryText: "Parceiros",
          leftIcon: <PeopleIcon />,
        }
      : null,
};

export default parceiro;
export const iconParceiro = <PeopleIcon />;
