import React from "react";
import { v4 as uuidv4 } from "uuid";
import { SaveButton, Toolbar } from "react-admin";
import { COMPRA } from "./PedidoTipo";
import { PAGAR, RECEBER } from "../conta/ContaTipo";

const PedidoToolbar = (props) => {
  const transform = transformPedido(props);
  return (
    <Toolbar {...props}>
      <SaveButton transform={transform} />
    </Toolbar>
  );
};

function transformPedido({ record }) {
  function transformClosure(data) {
    return transformData(data, record);
  }
  return transformClosure;
}

function transformData(data, previous) {
  data.uuid = previous.uuid || uuidv4();
  if (previous.status !== data.status) {
    data.historico_status = [{ status: data.status }];
  }

  data.contas = (data.contas || []).map((c, i) => ({
    ...c,
    tipo: data.tipo === COMPRA ? PAGAR : RECEBER,
    parceiroId: data.parceiroId,
    classificacaoId: data.classificacaoId || c.classificacaoId,
    centroCustoId: data.centroCustoId || c.centroCustoId,
    parcela: i + 1,
    ocorrencias: data.contas.length,
  }));

  return data;
}

export default PedidoToolbar;
