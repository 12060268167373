import React, { useState } from 'react';
import Switch from '@material-ui/core/Switch';
import { useNotify } from 'react-admin';
import { save } from '../../dataProvider';
import { withUser } from '../../common/util/hocs';

function ButtonTrocar({ record, loja }) {
    const [status, setStatus] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const notify = useNotify();

    const getStatus = () => {
        return status === undefined ? record.status : status
    }

    const handleChange = () => {
        const m = { ...record }
        m.id = m.id_persisted;
        m.lojaId = loja.id;
        m.status = !getStatus();
        setLoading(true);
        save('Loja_moeda_apps', m)
            .then(data => setStatus(data.status))
            .catch(err => {
                notify("Erro do alterar moeda", 'warning');
                console.log(JSON.stringify(err));
            })
            .finally(() => setLoading(false));
    }

    return <Switch
      checked={getStatus()}
      onChange={handleChange}
      color="primary"
      disabled={loading}
    />
}

export default withUser(ButtonTrocar);