import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import NumberInputReadonly from "../../../common/form/NumberInputReadonly";
import { Typography } from "@mui/material";

const useStyles = makeStyles({
  chave: {
    width: "27em",
    marginRight: `1em`,
  },
  protocolo: {
    width: "10em",
    marginRight: `1em`,
  },
});

export default function TabAutorizacao(props) {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h5" gutterBottom sx={{ mt: 4, mb: 4, ml: 1 }}>
        Autorização da NFe
      </Typography>

      <NumberInputReadonly
        label="Chave de Acesso"
        source="chave"
        className={classes.chave}
      />
      <NumberInputReadonly
        label="Recibo"
        source="recibo"
        className={classes.protocolo}
      />
      <NumberInputReadonly
        label="Protocolo de Uso"
        source="protocolo_uso"
        className={classes.protocolo}
      />
      <div />

      <NumberInputReadonly
        variant="outlined"
        label="Sequencia CCe"
        source="cce_sequencia"
      />
      <NumberInputReadonly
        variant="outlined"
        label="Carta de correção"
        source="cce_correcao"
        multiline
        fullWidth
      />

      <NumberInputReadonly
        variant="outlined"
        label="Motivo do Cancelamento"
        source="cancelamento"
        multiline
        fullWidth
      />
    </>
  );
}
