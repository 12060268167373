import React from "react";
import { Create } from "react-admin";
import KdsForm from "./KdsForm";
import KdsToolbar from "./KdsToolbar";

const KdsCreate = (props) => (
  <Create {...props}>
    <KdsForm toolbar={<KdsToolbar />} />
  </Create>
);

export default KdsCreate;
