import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

const ReportTotalSimple = ({ label, value, color = "#283593" }) => {
  return (
    <Card
      sx={{
        border: "1px solid #c5c5c5",
        borderBottom: `7px solid ${color}`,
        pl: 2,
        pr: 2,
      }}
    >
      <CardContent>
        <Typography variant="subtitle1" color="textPrimary">
          {label}
        </Typography>
        <Typography sx={{ fontWeight: "bold" }} variant="h5">
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ReportTotalSimple;
