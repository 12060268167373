import React from "react";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { reportEstoqueEnable } from "../../permissionsHelper";

const pdv_local = {
  getMenu: (p, a) => {
    return reportEstoqueEnable(p, a)
      ? {
          key: "Vendas Abertas",
          to: "/pdv-vendas-abertas",
          primaryText: "Vendas Abertas",
          leftIcon: iconPDVLocal,
        }
      : null;
  },
};

export default pdv_local;
export const iconPDVLocal = <LocationOnIcon />;
