import React from "react";
import {
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { IdDates } from "../../common/form/ConditionalInputs";

const validateReq = [required()];

const ClassificacaoForm = (props) => (
  <SimpleForm {...props} redirect="list">
    <IdDates />
    <TextInput source="nome" validate={validateReq} />
    <SelectInput
      label="tipo"
      source="pago"
      choices={[
        { id: "RECEITA", name: "Receita" },
        { id: "DESPESA", name: "Despesa" },
      ]}
      allowEmpty
    />
  </SimpleForm>
);

export default ClassificacaoForm;
