import React from "react";
import { TextInput, Filter, NumberInput, DateInput } from "react-admin";

const FidelidadeFilter = (props) => (
  <Filter {...props}>
    <TextInput source="nome_like" label="Nome" />
    <NumberInput source="telefone" label="Telefone" />
    <DateInput source="nascido_em" label="Data Nascimento" />
  </Filter>
);

export default FidelidadeFilter;
