import React, { useEffect } from "react";
import {
  useNotify,
  FormWithRedirect,
  Button,
  SaveButton,
  PasswordInput,
  FileInput,
  FileField,
  Title,
  required,
  useRedirect,
} from "react-admin";
import {
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  CardActions,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ListIcon from "@material-ui/icons/List";
import Delete from "@material-ui/icons/Delete";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { parseISO, format } from "date-fns";
import { fetchJSON } from "../dataProvider";
import LoadingButton from "../common/LoadingButton";
import UXDelay from "../dataProvider/uxdelay";
import CertificadoDigitalValidadeChip from "../resources/certificado_digital/CertificadoDigitalValidadeChip";
import { withUser } from "../common/util/hocs";

const useStyles = makeStyles((theme) => ({
  fileText: {
    lineHeight: 1.5,
  },
  file: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  grid: {
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
  },
  coverMargin: {
    padding: "2em",
  },
  cover: {
    height: "100%",
    backgroundColor: "#8e24aa",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
    color: "white",
    textAlign: "center",
    fontWeight: "bold",
  },
  ecnpj: {
    fontSize: "3em",
  },
  a1: {
    fontSize: "10em",
  },
  deleteButton: {
    color: theme.palette.error.main,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
}));

export function CertificadoDigitalLogo() {
  const classes = useStyles();

  return (
    <div className={classes.cover}>
      <div className={classes.coverMargin}>
        <span className={classes.ecnpj}>e-CNPJ</span>
        <br />
        <span className={classes.a1}>A1</span>
      </div>
    </div>
  );
}

const validateReq = [required()];

function CertificadoDigital({ loja }) {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();

  const [certificado, setCertificado] = React.useState(null);
  const [erro, setErro] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    UXDelay(
      fetchJSON(`Loja_certificados`, null, {
        filter: { where: { lojaId: loja.id } },
      })
    )
      .then((json) => json && json.length > 0 && setCertificado(json[0]))
      .catch((err) => {
        setErro(err);
        notify("Erro ao recuperar o certificado digital", "warning");
      })
      .finally(() => setLoading(false));
  }, [loja, notify]);

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleSave = async (values) => {
    return fetchJSON(`Sefaz/uploadCertificado`, {
      method: "POST",
      body: JSON.stringify({
        lojaId: loja.id,
        senha: values.senha,
        certificado: await getBase64(values.certificado.rawFile),
      }),
    })
      .then((json) => {
        setCertificado(json);
        notify("Certificado digital salvo com sucesso!");
      })
      .catch((err) => {
        setErro(err);
        notify("Erro ao salvar certificado digital", "warning");
      });
  };

  const handleDesinstalar = () => {
    setLoading(true);
    UXDelay(
      fetchJSON(`Loja_certificados/del`, {
        method: "DELETE",
        body: JSON.stringify({
          id: certificado.id,
          lojaId: loja.id,
        }),
      })
    )
      .then((json) => {
        setCertificado(null);
        notify("Certificado digital desinstalado com sucesso!");
      })
      .catch((err) => {
        setErro(err);
        notify("Erro ao desinstalar certificado digital", "warning");
      })
      .finally(() => setLoading(false));
  };

  const handleGoToListNFCe = (event) => redirect(`/NFCes`);

  return (
    <Card sx={{ display: "flex", p: 3 }}>
      <Title title="Integração do Certificado Digital" />
      <Box sx={{ width: 1 / 4 }}>
        <CertificadoDigitalLogo />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography variant="h5" color="textPrimary" sx={{ mb: 2 }}>
            {`Certificado Digital e-CNPJ A1 `}
          </Typography>
          {loading && <CircularProgress />}
          {!loading && certificado && (
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="center"
              className={classes.grid}
            >
              <Grid item>
                <Box m={3}>
                  <Typography variant="body1" color="textSecondary">
                    Certificado instalado
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    {`Válido até ${format(
                      parseISO(certificado.validoAte),
                      "dd/MM/yyyy HH:mm"
                    )}`}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box m={3}>
                  <CertificadoDigitalValidadeChip record={certificado} />
                </Box>
              </Grid>
            </Grid>
          )}
          {!loading && !certificado && (
            <FormWithRedirect
              save={handleSave}
              record={certificado}
              toolbar={null}
              submitOnEnter={false}
              render={(formProps) => (
                <Grid container direction="column">
                  <Grid item>
                    <FileInput
                      className={classes.file}
                      source="certificado"
                      accept=".pfx"
                      label=""
                      validate={validateReq}
                      placeholder={
                        <p className={classes.fileText}>
                          <AttachFileIcon />
                          <br />
                          Arraste o arquivo para enviar, ou clique para
                          selecioná-lo
                          <br />
                          Envie arquivos do tipo .PFX
                        </p>
                      }
                    >
                      <FileField source="src" title="title" />
                    </FileInput>
                  </Grid>
                  <Grid item>
                    <PasswordInput
                      label="Senha do certificado"
                      variant="filled"
                      source="senha"
                      validate={validateReq}
                    />
                  </Grid>
                  <Grid item>
                    <SaveButton
                      label="Instalar"
                      disabled={formProps.pristine}
                      {...formProps}
                    />
                  </Grid>
                </Grid>
              )}
            />
          )}
          {erro && (
            <Typography variant="body2" color="error" sx={{ my: 2 }}>
              {erro.message}
            </Typography>
          )}
        </CardContent>
        {certificado && (
          <CardActions sx={{ px: 2, py: 0, justifyContent: "space-between" }}>
            <Button
              variant="contained"
              label="Lista de NFCe"
              size="big"
              onClick={handleGoToListNFCe}
            >
              <ListIcon />
            </Button>
            <LoadingButton
              variant="outlined"
              color="error"
              className={classes.deleteButton}
              onClick={handleDesinstalar}
              icon={<Delete />}
              text="Desinstalar"
              loading={loading}
            />
          </CardActions>
        )}
      </Box>
    </Card>
  );
}

export default withUser(CertificadoDigital);
