import React from "react";
import TextField from "@material-ui/core/TextField";

function TimeRange({ value, onChange, id }) {
  const handleChangeStart = (event) =>
    onChange(id, { startTime: event.target.value, endTime: value.endTime });
  const handleChangeEnd = (event) =>
    onChange(id, { endTime: event.target.value, startTime: value.startTime });

  return (
    <div>
      <TextField
        type="time"
        label="Das"
        value={value.startTime}
        onChange={handleChangeStart}
      />
      <TextField
        type="time"
        label="Ate"
        value={value.endTime}
        onChange={handleChangeEnd}
      />
    </div>
  );
}

export default TimeRange;
