import React from "react";
import {
  SimpleForm,
  TextInput,
  NumberInput,
  required,
  maxLength,
  minValue,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { IdDates } from "../../common/form/ConditionalInputs";
import NumberInputRemoveArrowsCSS from "../../common/form/NumberInputRemoveArrowsCSS.json";
import ProdutoLazyReferenceInput from "../produto/ProdutoLazyReferenceInput";
import ArrayInputRemoveButton from "../../common/form/ArrayInputRemoveButton";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  produto: {
    width: "35em",
  },
  quantidade: {
    width: "7em",
    ...NumberInputRemoveArrowsCSS,
  },
});

const validateNome = [required(), maxLength(100)];
const validateQtd = [required(), minValue(0)];

function ProdutoItem({ record, formData, itemData, getSource }) {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" spacing={3}>
      <Grid item>
        <ProdutoLazyReferenceInput
          record={record}
          source={getSource("produtoId")}
          nameSource={getSource("produto")}
          label="Produto"
          className={classes.produto}
          required
          fullWidth
        />
      </Grid>
      <Grid item>
        <NumberInput
          record={record}
          source={getSource("quantidade_estoque")}
          label="Quantidade"
          validate={validateQtd}
          className={classes.quantidade}
          fullWidth
        />
      </Grid>
      <Grid item>
        <ArrayInputRemoveButton
          formData={formData}
          itemData={itemData}
          getSource={getSource}
        />
      </Grid>
    </Grid>
  );
}

const InventariosForm = (props) => {
  const { record } = props;
  return (
    <SimpleForm {...props} redirect="list">
      <IdDates />
      <TextInput
        source="nome"
        label="Nome do inventario"
        validate={validateNome}
      />

      <ArrayInput source="itens" label="Lista de produtos" fullWidth>
        <SimpleFormIterator
          disableReordering
          TransitionProps={{ enter: false, exit: false }}
          disableRemove
        >
          <FormDataConsumer>
            {({ formData, scopedFormData, getSource }) => (
              <ProdutoItem
                record={record}
                formData={formData}
                itemData={scopedFormData}
                getSource={getSource}
              />
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
};

export default InventariosForm;
