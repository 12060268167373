import React from "react";
import { Edit } from "react-admin";

import MoedaTipoForm from "./MoedaTipoForm";
import MoedaTipoToolbar from "./MoedaTipoToolbar";

const MoedaTipoEdit = props => (
  <Edit {...props}>
    <MoedaTipoForm toolbar={<MoedaTipoToolbar />} />
  </Edit>
);

export default MoedaTipoEdit;
