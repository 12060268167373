import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Button as RAButton } from "react-admin";
import CircularProgress from "../../common/CircularProgress";
import LoadingButton from "../../common/LoadingButton";
import { fetchJSON } from "../../dataProvider";
import { withUser } from "../../common/util/hocs";

const styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  body: {
    width: "70%",
    margin: "auto",
  },
  collapse: {
    paddingLeft: 30,
  },
  checkbox: {
    padding: 0,
    paddingRight: 9,
  },
  actions: {
    padding: "1em",
  },
};

const VincularPerguntaButton = ({ permissao, record, loja }) => {
  const [erro, setErro] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [grupos, setGrupos] = React.useState([]);
  const [produtosPergunta, setProdutosPergunta] = React.useState([]);
  const [produtosSel, setProdutosSel] = React.useState([]);

  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    loadData();
    setOpen(true);
  };

  const handleSave = async () => {
    setSaving(true);
    const produtosNovos = produtosSel.filter(
      (p) => !produtosPergunta.find((pp) => pp.produtoId === p.id)
    );
    const delecao = produtosPergunta.filter(
      (pp) => !produtosSel.find((p) => pp.produtoId === p.id)
    );

    const newData = produtosNovos.map((p) => ({
      produtoId: p.id,
      perguntaId: record.id,
      lojaId: loja.id,
    }));

    try {
      if (newData.length > 0) {
        await fetchJSON("produto_pergunta", {
          method: "POST",
          body: JSON.stringify(newData),
        });
      }

      await Promise.all(
        delecao.map((pp) =>
          fetchJSON(`produto_pergunta/del`, {
            method: "DELETE",
            body: JSON.stringify({
              id: pp.id,
              lojaId: loja.id,
            }),
          })
        )
      );
    } catch (err) {
      setErro(err);
    } finally {
      setSaving(false);
      setOpen(false);
    }
  };

  const loadData = async () => {
    setLoading(true);
    await Promise.all([
      fetchJSON("grupos", null, {
        filter: {
          where: {
            lojaId: loja.id,
          },
          fields: ["id", "nome"],
        },
      }),
      fetchJSON("produto_pergunta", null, {
        filter: {
          where: {
            perguntaId: record.id,
            lojaId: loja.id,
          },
          fields: ["id", "produtoId", "perguntaId"],
          include: [
            {
              relation: "produto",
              scope: {
                fields: ["id", "desc_prod", "grupoId"],
              },
            },
          ],
        },
      }),
    ])
      .then((data) => {
        const prodPerg = data[1];
        const produtos = data[1].map((pp) => pp.produto);
        const grupos = data[0];
        grupos.sort((a, b) => (a.nome > b.nome ? 1 : -1));

        setProdutosSel(produtos);
        setProdutosPergunta(prodPerg);
        setGrupos(grupos);
      })
      .catch((err) => setErro(err))
      .finally(() => setLoading(false));
  };

  const handleChangeExpansionPanel = (grupo) => () => {
    if (!grupo.fetch) {
      fetchJSON("produtos", null, {
        filter: {
          where: {
            grupoId: grupo.id,
            lojaId: loja.id,
          },
          fields: ["id", "desc_prod", "grupoId"],
        },
      }).then((json) => {
        const ng = grupos.map((g) =>
          g.id === grupo.id ? { ...g, fetch: true, produtos: json } : g
        );
        setGrupos(ng);
      });
    }
  };

  const handleChangeProduto = (produto) => (event) => {
    const s = [...produtosSel];
    if (event.target.checked) {
      s.push(produto);
    } else {
      const index = s.findIndex((p) => p.id === produto.id);
      s.splice(index, 1);
    }
    setProdutosSel(s);
  };

  const handleChangeGrupo = (grupo) => (event) => {
    if (grupo.fetch) {
      const produtos = [...produtosSel];
      grupo.produtos.forEach((gp) => {
        const prod = produtos.find((p) => p.id === gp.id);
        if (event.target.checked) {
          if (!prod) {
            produtos.push(gp);
          }
        } else {
          if (prod) {
            const index = produtos.indexOf(prod);
            produtos.splice(index, 1);
          }
        }
      });
      setProdutosSel(produtos);
    }
  };

  const getSelectedCount = (grupo) => {
    return produtosSel.filter((p) => p.grupoId === grupo.id).length;
  };

  if (!record || !permissao.cardapio_editar) {
    return null;
  }

  return (
    <>
      <RAButton label="Vincular" onClick={handleOpen} />
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle>{record.descricao}</DialogTitle>
        <DialogContent>
          {erro && "Ops, algo de errado aconteceu."}
          {loading && <CircularProgress />}
          {grupos.map((grupo) => {
            const selecionados = getSelectedCount(grupo);
            const todos = grupo.produtos ? grupo.produtos.length : 0;
            const selString = selecionados > 0 ? ` - ${selecionados}` : "";

            return (
              <ExpansionPanel
                key={grupo.id}
                onChange={handleChangeExpansionPanel(grupo)}
              >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Checkbox
                    indeterminate={
                      todos > 0
                        ? selecionados > 0 && selecionados < todos
                        : selecionados > 0
                    }
                    checked={todos > 0 && selecionados === todos}
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    onChange={handleChangeGrupo(grupo)}
                    style={styles.checkbox}
                  />
                  <Typography>{`${grupo.nome}${selString}`}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div style={styles.collapse}>
                    {!grupo.fetch && <CircularProgress />}
                    {grupo.fetch &&
                      grupo.produtos.length === 0 &&
                      "Grupo sem produto"}
                    {grupo.fetch &&
                      grupo.produtos.map((produto) => (
                        <FormControlLabel
                          key={produto.id}
                          label={produto.desc_prod}
                          control={
                            <Checkbox
                              checked={
                                produtosSel.find((p) => produto.id === p.id)
                                  ? true
                                  : false
                              }
                              onChange={handleChangeProduto(produto)}
                            />
                          }
                        />
                      ))}
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          })}
        </DialogContent>
        <DialogActions style={styles.actions}>
          <Button onClick={handleClose} variant="contained" color="secondary">
            Cancelar
          </Button>
          <LoadingButton
            loading={saving}
            onClick={handleSave}
            variant="contained"
            color="primary"
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withUser(VincularPerguntaButton);
