import React, { useState, useEffect } from "react";
import { Confirm } from "react-admin";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
  Box,
  Grid,
  CircularProgress,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import StoreIcon from "@material-ui/icons/Store";
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";
import PlaceIcon from "@material-ui/icons/Place";
import AirlineSeatReclineExtraIcon from "@material-ui/icons/AirlineSeatReclineExtra";

import { parseISO, format } from "date-fns";

import { fetchJSON } from "../dataProvider";
import PageCircularProgress from "../common/CircularProgress";

function Status({ label, status, icon }) {
  const getDate = (source) => {
    if (status[source] && status[source].length === 24) {
      return format(parseISO(status[source]), "dd/MM/yyyy HH:mm");
    }

    return status[source];
  };

  const stt = status.aberto ? "aberta" : "fechada";
  const ini = getDate("inicio");
  const fim = getDate("fim");
  return (
    <>
      <Box mb={3}>
        <Grid container align="center" alignItems="center" spacing={1}>
          <Grid item>
            <Avatar
              sx={{
                width: 40,
                height: 40,
                bgcolor: status.aberto ? green[500] : red[500],
              }}
            >
              {icon}
            </Avatar>
          </Grid>
          <Grid item>
            <Typography variant="h5">{`${label} ${stt}`}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Typography variant="body2" component="p">
        Início: {ini}
        <br />
        Fim: {fim}
        <br />
        {status.motivo}
      </Typography>
    </>
  );
}

function AppStatus({ loja }) {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(undefined);
  const [alterando, setAlterando] = useState(false);
  const [erro, setErro] = useState(undefined);

  const refreshStatus = () => {
    fetchJSON(`Loja_perfil/appStatus`, null, { lojaId: loja.id }).then(
      (status) => setStatus(status)
    );
  };

  useEffect(refreshStatus, [loja.id]);

  const handleDialogClose = () => setOpen(false);
  const handleDialogOpen = () => setOpen(true);

  const handleAlterar = () => {
    setErro(undefined);
    setAlterando(true);
    fetchJSON("Apps/pausaEmergencia", {
      method: "POST",
      body: JSON.stringify({
        lojaId: loja.id,
        status: !status.aberto,
        appDeliveryUri: loja.appDeliveryUri,
      }),
    })
      .then((status) => refreshStatus())
      .catch((err) => setErro(err.body.error.message))
      .finally(() => {
        setOpen(false);
        setAlterando(false);
      });
  };

  if (!status) {
    return <PageCircularProgress />;
  }

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
        >
          <Grid item>
            <Status label="Loja" status={status} icon={<StoreIcon />} />
          </Grid>

          {status.testaHorarioPorOperacao && (
            <>
              <Grid item>
                <Status
                  label="Entrega"
                  status={status.operacao.entrega}
                  icon={<DirectionsBikeIcon />}
                />
              </Grid>
              <Grid item>
                <Status
                  label="Retirada"
                  status={status.operacao.retirada}
                  icon={<PlaceIcon />}
                />
              </Grid>
              <Grid item>
                <Status
                  label="Mesa"
                  status={status.operacao.mesa}
                  icon={<AirlineSeatReclineExtraIcon />}
                />
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
      <CardActions style={{ textAlign: "right" }}>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={alterando || status.aberto || !status.emergencia}
          onClick={handleAlterar}
        >
          {alterando && <CircularProgress size={25} thickness={2} />}
          Abrir loja
        </Button>
        <Button
          color="error"
          variant="contained"
          type="submit"
          disabled={alterando || !status.aberto}
          onClick={handleDialogOpen}
        >
          {alterando && <CircularProgress size={25} thickness={2} />}
          Fechar loja
        </Button>
        <Confirm
          isOpen={open}
          loading={alterando}
          title="Fechar loja"
          content="Tem certeza que deseja fechar a loja? Isso impedirá os clientes de realizarem novos pedidos."
          confirm="Fechar loja"
          onConfirm={handleAlterar}
          onClose={handleDialogClose}
        />
        {erro && (
          <Typography variant="body2" component="p">
            {erro}
          </Typography>
        )}
      </CardActions>
    </Card>
  );
}

export default AppStatus;
