import React from "react";
import {
  SimpleForm,
  BooleanInput,
  TextInput,
  NumberInput,
  required,
  minValue,
} from "react-admin";
import { DateTimeInput } from "react-admin-date-inputs";
import { InputAdornment } from "@material-ui/core";
import { IdDates } from "../../common/form/ConditionalInputs";

const validateReq = [required()];
const validateRS = [required(), minValue(0)];

function PromocaosForm(props) {
  return (
    <SimpleForm {...props} toolbar={props.toolbar} redirect='list'>
      <IdDates />

      <BooleanInput label='Status' source='status' initialValue={true} />

      <TextInput label='Cupom' source='cupom' validate={validateReq} />

      <DateTimeInput
        label='Inicio'
        source='inicio'
        validate={validateReq}
        options={{ format: "dd/MM/yyyy HH:mm", ampm: false, clearable: true }}
      />

      <DateTimeInput
        label='Fim'
        source='fim'
        validate={validateReq}
        options={{ format: "dd/MM/yyyy HH:mm", ampm: false, clearable: true }}
      />

      <NumberInput
        source='descontoValor'
        label='Desconto R$'
        validate={validateRS}
        initialValue={0}
        InputProps={{
          startAdornment: <InputAdornment position='start'>R$</InputAdornment>,
        }}
      />

      <NumberInput
        source='descontoPorcentagem'
        label='Desconto %'
        validate={validateRS}
        initialValue={0}
        InputProps={{
          endAdornment: <InputAdornment position='start'>%</InputAdornment>,
        }}
      />

      <NumberInput
        source='pedidoMinimo'
        label='Pedido mínimo'
        validate={validateRS}
        initialValue={0}
        InputProps={{
          startAdornment: <InputAdornment position='start'>R$</InputAdornment>,
        }}
      />
    </SimpleForm>
  );
}

export default PromocaosForm;
