import React, { useState } from "react";
import { useNotify } from "react-admin";
import StoreIcon from "@material-ui/icons/Store";
import { withUser } from "../../common/util/hocs";
import { fetchJSON } from "../../dataProvider";
import ProdutoMatrizFilialModalPreco from "./ProdutoMatrizFilialModalPreco";
import LoadingButtonReactAdmin from "../../common/LoadingButtonReactAdmin";

function ProdutoMatrizFilialNFeButton({ loja, usuario, nfeTomada }) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [produtosIds, setProdutosIds] = useState(false);

  const notify = useNotify();

  const handleOpen = async () => {
    setLoading(true);
    try {
      const response = await fetchJSON(`NFe_itens`, null, {
        filter: {
          where: {
            lojaId: loja.id,
            nfeId: nfeTomada.id,
          },
          fields: ["produtoId"],
        },
      });
      if (response.length > 0) {
        setProdutosIds(response.map((item) => item.produtoId));
        setOpen(true);
      } else {
        notify("Nenhum produto encontrado nesta nota fiscal", "warning");
      }
    } catch (err) {
      console.log(err);
      notify("Erro ao recuperar produtos da nota fiscal", "warning");
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      <LoadingButtonReactAdmin
        onClick={handleOpen}
        color="primary"
        text="Enviar para Filiais"
        loading={loading}
        icon={<StoreIcon />}
      />
      <ProdutoMatrizFilialModalPreco
        produtosIds={produtosIds}
        openDialog={open}
        handleClose={handleClose}
        loja={loja}
        usuario={usuario}
      />
    </>
  );
}

export default withUser(ProdutoMatrizFilialNFeButton);
