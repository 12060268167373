import React from "react";
import { BooleanInput, Filter } from "react-admin";

const LojaHorarioFilter = props => (
  <Filter {...props}>
    <BooleanInput source="aberto" label="Aberto" />
  </Filter>
);

export default LojaHorarioFilter;
