import MercadoPagoPreferenceList from "./MercadoPagoPreferenceList";

const unidade = {
    getResource: (p) => {
        return {
            name: "Mercado_Pago_Preference",
            list: MercadoPagoPreferenceList,
        }
    }
}

export default unidade;