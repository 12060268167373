import { Box, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useState } from "react";
import Search from "../../reports/Search";
import { fetchJSON } from "../../dataProvider";
import { formatNumber } from "../../common/util/formatter";
import Decimal from "decimal.js";
import { useNotify } from "react-admin";
import isEmpty from "lodash.isempty";

const ParceiroHistoricoCorrentista = ({ record }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [bankStatement, setBankStatement] = useState("extrato");
  const notify = useNotify();

  async function retrieveReportData({
    lojaId,
    bankStatement,
    startDate,
    endDate,
  }) {
    try {
      setData([]);
      setLoading(true);
      setBankStatement(bankStatement);
      if (bankStatement === "extrato") {
        await fetchExtrato(lojaId, startDate, endDate);
      } else {
        await fetchProdutos(lojaId, startDate, endDate);
      }
    } catch (error) {
      console.error(error);
      notify("Erro ao buscar os dados.", "warning");
    } finally {
      setLoading(false);
    }
  }

  const dataEmpty = isEmpty(data);

  const fetchExtrato = async (lojaId, startDate, endDate) => {
    const data = await fetchJSON("Relatorio/correntista/extrato", null, {
      lojaId,
      parceiroId: record.id,
      startDate,
      endDate,
    });
    setData(data);
  };

  const fetchProdutos = async (lojaId, startDate, endDate) => {
    const data = await fetchJSON("Relatorio/correntista/produtos", null, {
      lojaId,
      parceiroId: record.id,
      startDate,
      endDate,
    });
    setData(data);
  };

  return (
    <Box sx={{ minHeight: 600 }}>
      <Search
        fields={["dateRange", "bankStatement"]}
        onSearch={retrieveReportData}
        loading={loading}
        dataEmpty={dataEmpty}
      />
      <Typography variant="h4" sx={{ mb: 3 }}>
        {record.nome_fantasia}
      </Typography>
      {bankStatement === "extrato" && !dataEmpty && (
        <TableExtrato data={data} />
      )}
      {bankStatement === "produtos" && !dataEmpty && (
        <TableProdutos data={data} />
      )}
    </Box>
  );
};

const TableProdutos = ({ data }) => (
  <TableContainer>
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Descrição</TableCell>
          <TableCell align="right">Qtd</TableCell>
          <TableCell align="right">R$ Total</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((r) => (
          <TableRow key={r.descricao}>
            <TableCell component="th" scope="row">
              {r.descricao}
            </TableCell>
            <TableCell align="right">{r.qtd}</TableCell>
            <TableCell align="right">{formatNumber(r.valor_final)}</TableCell>
          </TableRow>
        ))}
        <TableRow
          key="item-total"
          sx={{
            "td, th": { border: 0 },
          }}
        >
          <TableCell></TableCell>
          <TableCell align="right">
            <strong>Total</strong>
          </TableCell>
          <TableCell align="right">
            <strong>
              {formatNumber(
                data.reduce(
                  (acc, item) => acc.plus(item.valor_final),
                  Decimal(0)
                )
              )}
            </strong>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

const TableExtrato = ({ data }) => (
  <TableContainer>
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Data</TableCell>
          <TableCell>Tipo Mov</TableCell>
          <TableCell>Descrição</TableCell>
          <TableCell align="right">Qtd</TableCell>
          <TableCell align="right">R$ Total</TableCell>
          <TableCell align="right">R$ Saldo</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((m) => {
          return m.map((r) => (
            <TableRow
              key={r.id}
              sx={r.ultimo ? null : { "td, th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {r.data}
              </TableCell>
              <TableCell>{r.tipo}</TableCell>
              <TableCell>{r.descricao}</TableCell>
              <TableCell align="right">{r.qtd}</TableCell>
              <TableCell
                align="right"
                sx={{ color: r.valor < 0 ? "red" : "blue" }}
              >
                {formatNumber(r.valor)}
              </TableCell>
              {r.ultimo && (
                <TableCell
                  align="right"
                  sx={{ color: r.saldo < 0 ? "red" : "blue" }}
                >
                  <strong>{formatNumber(r.saldo)}</strong>
                </TableCell>
              )}
            </TableRow>
          ));
        })}
      </TableBody>
    </Table>
  </TableContainer>
);

export default ParceiroHistoricoCorrentista;
