import React from "react";
import { Edit } from "react-admin";

import MoedaForm from "./MoedaForm";
import MoedaToolbar from "./MoedaToolbar";

const MoedaEdit = props => (
  <Edit {...props}>
    <MoedaForm toolbar={<MoedaToolbar />} />
  </Edit>
);

export default MoedaEdit;
