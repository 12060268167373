import React from "react";
import { TextInput, Filter, DateTimeInput } from "react-admin";

const PerguntaFilter = props => (
  <Filter {...props}>
    <TextInput source="descricao_like" label="Pergunta" alwaysOn />
    <DateTimeInput source="updatedAt_gte" label="Atualizado de" />
    <DateTimeInput source="updatedAt_lte" label="Atualizado até" />
  </Filter>
);

export default PerguntaFilter;
