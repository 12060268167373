import React from "react";
import { green, red, yellow } from "@mui/material/colors";
import { formatNumber } from "../../common/util/formatter";
import Table from "../../common/table/Table";
import { Typography } from "@mui/material";
import { Link } from "react-admin";

export default function BillsTable({ data, partnerTitle }) {
  const getColor = (item) => {
    if (item.pago) {
      return green[500];
    }

    if (item.perda) {
      return yellow[500];
    }

    if (new Date(item.vencimento) < Date.now()) {
      return red[500];
    }

    return null;
  };

  const columns = [
    {
      title: "Parcela",
      render: (item) => (
        <Link
          to={`/conta/${item.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography>{`${item.parcela}/${item.ocorrencias}`}</Typography>
        </Link>
      ),
    },
    { title: "Descrição", field: "descricao" },
    {
      title: "Vencimento",
      render: (item) =>
        new Date(item.vencimento).toLocaleDateString("pt-BR", {
          timeZone: "UTC",
        }),
      field: "vencimento",
    },
    {
      title: "Pagamento",
      render: (item) =>
        item.pago
          ? new Date(item.pagamento).toLocaleDateString("pt-BR", {
              timeZone: "UTC",
            })
          : null,
      field: "pagamento",
    },
    {
      title: "R$ Valor",
      render: (item) =>
        item.pagamento
          ? formatNumber(item.valor_pago)
          : formatNumber(item.valor),
      field: "valor",
    },
  ];

  if (partnerTitle) {
    columns.unshift({
      title: partnerTitle,
      field: "parceiro",
    });
  }

  return (
    <Table
      options={{
        toolbar: false,
        paging: false,
        tableLayout: "fixed",
        rowStyle: (item) => ({
          borderLeftColor: getColor(item),
          borderLeftWidth: 5,
          borderLeftStyle: "solid",
        }),
      }}
      title=""
      columns={columns}
      data={data}
    />
  );
}
