import React from "react";
import { useQuery, LinearProgress, Error, FunctionField } from "react-admin";

function CaixaSincField({ record }) {
  const { loaded, error, data } = useQuery({
    type: "getList",
    resource: "movimentos",
    payload: { filter: { caixaId: record.id, mov: record.ultimo_mov } },
  });

  if (!loaded) {
    return <LinearProgress />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <FunctionField
      render={(record) =>
        record.ultimoMovimentoId > 0 && data.length === 0
          ? "Pendente"
          : "Completo"
      }
    />
  );
}

export default CaixaSincField;
