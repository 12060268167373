import React from "react";
import {
  Datagrid,
  List,
  BooleanField,
  TextField,
  NumberField,
  EditButton
} from "react-admin";

import FunctionField from '../../common/form/FunctionField';
import DuracaoField from '../../common/form/DuracaoField';

const PromocaoList = props => (
  <List {...props} exporter={false}>
    <Datagrid>
      <TextField label="Cupom" source="cupom" />
      <FunctionField label="Inicio" source="inicio" formatDate="dd/MM HH:mm" />
      <FunctionField label="Fim" source="fim" formatDate="dd/MM HH:mm" />
      <DuracaoField label="Duração" inicio="inicio" fim="fim" />
      <NumberField source="descontoValor" label="Desconto R$" />
      <NumberField source="descontoPorcentagem" label="Desconto %" />
      <NumberField source="pedidoMinimo" label="Pedido mínimo" />
      <BooleanField label="Status" source="status" />
      <EditButton />
    </Datagrid>
  </List>
);

export default PromocaoList;
