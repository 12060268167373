import React from "react";
import {
  SimpleForm,
  TextInput,
  NumberInput,
  required,
  maxLength,
  minValue,
  maxValue,
} from "react-admin";
import { InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { IdDates } from "../../common/form/ConditionalInputs";
import ICMSInput from "../../common/form/ICMSInput";
import IPIInput from "../../common/form/IPIInput";
import PISInput from "../../common/form/PISInput";
import COFINSInput from "../../common/form/COFINSInput";
import { withUser } from "../../common/util/hocs";
import { validateDecimalPlaces } from "../../common/util/ra-validations";
import NumberInputSimple from "../../common/form/NumberInputSimple";

const useStyles = makeStyles({
  formGroup: { display: "inline-block", marginRight: 32 },
});

const validateReq = [required()];
const validateAlq = [minValue(0), maxValue(999.9999), validateDecimalPlaces(4)];
const validateCFOPEst = [required(), maxLength(5)];
const validateCFOPFed = [maxLength(5)];

function ImpostosForm(props) {
  const classes = useStyles();

  return (
    <SimpleForm {...props} toolbar={props.toolbar} redirect="list">
      <IdDates />
      <TextInput source="nome" label="Nome do imposto" validate={validateReq} />

      <ICMSInput
        source="icms_cst"
        label="ICMS"
        regime={props.loja.regime_tributario}
        validate={validateReq}
        fullWidth
      />
      <NumberInputSimple
        source="icms_alq"
        label="ICMS Alíquota"
        validate={validateAlq}
        formClassName={classes.formGroup}
        InputProps={{
          endAdornment: <InputAdornment position="start">%</InputAdornment>,
        }}
      />
      <NumberInputSimple
        source="icms_st_mva"
        label="MVA"
        validate={validateAlq}
        formClassName={classes.formGroup}
        InputProps={{
          endAdornment: <InputAdornment position="start">%</InputAdornment>,
        }}
      />
      <NumberInputSimple
        source="icms_red_bc"
        label="Redução BC"
        validate={validateAlq}
        formClassName={classes.formGroup}
        InputProps={{
          endAdornment: <InputAdornment position="start">%</InputAdornment>,
        }}
      />
      <div />

      <IPIInput source="ipi_cst" label="IPI" validate={validateReq} fullWidth />
      <NumberInput
        source="ipi_alq"
        label="IPI Alíquota"
        validate={validateAlq}
        formClassName={classes.formGroup}
        InputProps={{
          endAdornment: <InputAdornment position="start">%</InputAdornment>,
        }}
      />
      <div />

      <PISInput source="pis_cst" label="PIS" validate={validateReq} fullWidth />
      <NumberInput
        source="pis_alq"
        label="PIS Alíquota"
        validate={validateAlq}
        formClassName={classes.formGroup}
        InputProps={{
          endAdornment: <InputAdornment position="start">%</InputAdornment>,
        }}
      />
      <div />

      <COFINSInput
        source="cofins_cst"
        label="COFINS"
        validate={validateReq}
        fullWidth
      />
      <NumberInput
        source="cofins_alq"
        label="COFINS Alíquota"
        validate={validateAlq}
        formClassName={classes.formGroup}
        InputProps={{
          endAdornment: <InputAdornment position="start">%</InputAdornment>,
        }}
      />
      <div />

      <TextInput
        source="cfop_estadual"
        label="CFOP Estadual"
        validate={validateCFOPEst}
        formClassName={classes.formGroup}
      />
      <TextInput
        source="cfop_federal"
        label="CFOP Federal"
        validate={validateCFOPFed}
        formClassName={classes.formGroup}
      />
      <div />
    </SimpleForm>
  );
}

export default withUser(ImpostosForm);
