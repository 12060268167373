import React from "react";
import { TextInput, Filter } from "react-admin";

const InventarioFilter = (props) => (
  <Filter {...props}>
    <TextInput source='nome_like' label='Nome do inventario' alwaysOn />
  </Filter>
);

export default InventarioFilter;
