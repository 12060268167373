import React from "react";
import { green, red, yellow, blue } from "@mui/material/colors";
import { Chip } from "@mui/material";

function ContaStatus({ record }) {
  if (!record) return null;

  const { vencimento, pago, perda } = record;

  if (pago) {
    return (
      <Chip
        size="small"
        label="Pago"
        style={{
          backgroundColor: green[200],
          borderColor: green[200],
        }}
      />
    );
  }

  if (perda) {
    return (
      <Chip
        size="small"
        label="Perda"
        style={{
          backgroundColor: yellow[200],
          borderColor: yellow[200],
        }}
      />
    );
  }

  const nowWithoutTime = new Date(new Date().toISOString().substring(0, 10));
  const dueWithoutTime = new Date(vencimento.substring(0, 10));
  const dias = (dueWithoutTime - nowWithoutTime) / (1000 * 60 * 60 * 24);
  if (dias === 0) {
    return (
      <Chip
        size="small"
        label="Vence Hoje"
        style={{
          backgroundColor: blue[200],
          borderColor: blue[200],
        }}
      />
    );
  }

  if (dias < 0) {
    return (
      <Chip
        size="small"
        label="Atrasado"
        style={{
          backgroundColor: red[200],
          borderColor: red[200],
        }}
      />
    );
  }

  return null;
}

export default ContaStatus;
