import React from "react";
import {
  Datagrid,
  List,
  TextField,
  EditButton
} from "react-admin";

import MoedaAdquirenteFilter from "./MoedaAdquirenteFilter";

const MoedaAdquirenteList = props => (
  <List {...props} filters={<MoedaAdquirenteFilter />} exporter={false} >
    <Datagrid>
      <TextField source="nome" label="Nome"/> 
      <EditButton />
    </Datagrid>
  </List>
);

export default MoedaAdquirenteList;
