import { Box, Card } from "@mui/material";
import React from "react";
import { Title } from "react-admin";
// import NpsSurvey from "../resources/nps/NpsSurvey";

function Dashboard() {
  return (
    <Box height="100%">
      <Title title="Sistema Pallas" />
      {/* <NpsSurvey /> */}
      <Card sx={{ height: "100%" }}>
        <iframe
          title="novidades"
          src="https://sites-estaticos-f604a.web.app/pallas-retaguarda-novidades.html"
          style={{
            width: "100%",
            height: "100%",
            borderWidth: "0px",
          }}
        />
      </Card>
    </Box>
  );
}

export default Dashboard;
