import React from "react";
import { Grid } from "@mui/material";
import TotalFaturamentoCard from "./ReportTotalFaturamentoCard";
import TotalLucroCard from "./ReportTotalLucroCard";
import TotalQuantidadeCard from "./ReportTotalQuantidadeCard";
import TotalValorUnitarioMedioCard from "./ReportTotalValorUnitarioMedioCard";

const ReportTotal = (props) => {
  return (
    <Grid container justifyContent="space-between" spacing={3} sx={{ my: 5 }}>
      <Grid item xs={3}>
        <TotalFaturamentoCard total={props.data.total_venda} />
      </Grid>
      <Grid item xs={3}>
        <TotalLucroCard total={props.data.total_lucro} />
      </Grid>
      <Grid item xs={3}>
        <TotalQuantidadeCard total={props.data.total_qtd} />
      </Grid>
      <Grid item xs={3}>
        <TotalValorUnitarioMedioCard total={props.data.valor_unitario_medio} />
      </Grid>
    </Grid>
  );
};

ReportTotal.propTypes = {};

ReportTotal.defaultProps = {
  data: {},
};
export default ReportTotal;
