const Decimal = require("decimal.js");
Decimal.set({ rounding: Decimal.ROUND_HALF_EVEN });

/* eslint-disable no-extend-native */

String.prototype.onlyNumbers = function () {
  return this.replace(/\D/g, "");
};

String.prototype.removeAccentsToLower = function () {
  return this.normalize("NFD") // decomposes the letters and diacritics.
    .replace(/\p{Diacritic}/gu, "") // removes all the diacritics.
    .toLowerCase();
};

String.prototype.equalsIgnoringCase = function (other) {
  return this.localeCompare(other, undefined, { sensitivity: "base" }) === 0;
};

Date.prototype.addHours = function (hours, minutes = 0, seconds = 0) {
  const miliseconds =
    seconds * 1000 + minutes * 60 * 1000 + hours * 60 * 60 * 1000;

  return new Date(this.getTime() + miliseconds);
};

Array.prototype.extract = function (prop) {
  return this.reduce(function (arr, item) {
    const val = item[prop];
    if (val) {
      return arr.concat(val);
    }

    return arr;
  }, []);
};

Array.prototype.groupByDecimal = function (prop, props_sum) {
  var groups = this.reduce(function (groups, item) {
    const val = item[prop]
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    if (groups[val]) {
      groups[val].count = groups[val].count.plus(1);
    } else {
      groups[val] = {
        count: new Decimal(1),
        [prop]: item[prop],
      };
    }

    if (props_sum) {
      props_sum.forEach((p) => {
        if (groups[val][p]) groups[val][p] = groups[val][p].plus(item[p]);
        else groups[val][p] = new Decimal(item[p]);
      });
    }

    return groups;
  }, {});

  var arr = [];
  for (var g in groups) {
    arr.push(groups[g]);
  }
  return arr;
};

Array.prototype.orderBy = function (prop, desc) {
  if (this.length === 0) return this;

  const firstValue = this[0][prop];
  if (firstValue instanceof Decimal) {
    if (desc) {
      return this.sort(function (a, b) {
        return b[prop].minus(a[prop]).toNumber();
      });
    }

    return this.sort(function (a, b) {
      return a[prop].minus(b[prop]).toNumber();
    });
  }

  if (firstValue instanceof Date) {
    if (desc) {
      return this.sort(function (a, b) {
        return b[prop] - a[prop];
      });
    } else {
      return this.sort(function (a, b) {
        return a[prop] - b[prop];
      });
    }
  }

  const t = typeof firstValue;
  if (t === "string") {
    if (desc) {
      return this.sort(function (a, b) {
        return a[prop] < b[prop] ? 1 : a[prop] > b[prop] ? -1 : 0;
      });
    } else {
      return this.sort(function (a, b) {
        return a[prop] < b[prop] ? -1 : a[prop] > b[prop] ? 1 : 0;
      });
    }
  }

  if (t === "number") {
    if (desc) {
      return this.sort(function (a, b) {
        return b[prop] - a[prop];
      });
    } else {
      return this.sort(function (a, b) {
        return a[prop] - b[prop];
      });
    }
  }

  throw new Error(`Array orderBy: tipo desconhecido ${t}`);
};

Array.prototype.propToArray = function (prop) {
  return this.map((value) => {
    return value[prop];
  });
};

Array.prototype.limit = function (limit) {
  return this.slice(0, limit);
};

Array.prototype.top = function (prop, limit) {
  return this.orderBy(prop, true).limit(limit);
};

Array.prototype.bottom = function (prop, limit) {
  return this.orderBy(prop, false).limit(limit);
};

Array.prototype.sum = function (prop) {
  if (prop === undefined) {
    return this.reduce(function (total, item) {
      return total.plus(item);
    }, new Decimal(0)).toNumber();
  } else if (typeof prop === "object") {
    var totais = {};

    prop.forEach((i) => {
      totais[i] = new Decimal(0);
    });

    this.forEach((i) => {
      prop.forEach((j) => {
        totais[j] = totais[j].plus(i[j]);
      });
    });

    return prop.reduce(
      (hash, p) => ({
        ...hash,
        [p]: totais[p].toNumber(),
      }),
      {}
    );
  } else if (typeof prop === "string") {
    return this.reduce(function (total, item) {
      return total.plus(item[prop]);
    }, new Decimal(0)).toNumber();
  } else {
    throw new Error(`Tipo ${typeof prop} não suportado no metodo Array.sum`);
  }
};

Array.prototype.first = function () {
  return this[0];
};

Array.prototype.firstOrDefault = function (defaultValue) {
  return this.length > 0 ? this[0] : defaultValue;
};

Array.prototype.last = function () {
  return this[this.length - 1];
};

Array.prototype.distinct = function () {
  return [...new Set(this)];
};

Array.prototype.max = function () {
  return Math.max(...this);
};
/* eslint-enable no-extend-native */
