import NpsList from "./NpsList";

const nps = {
  getResource: (p) => {
    if (p.ADMIN) {
      return {
        name: "Nps",
        list: NpsList,
      };
    }
  },
  getMenu: (p) =>
    p.ADMIN
      ? {
          key: "Nps",
          to: "/Nps",
          primaryText: "NPS",
        }
      : null,
};

export default nps;
