import React from "react";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import { reportEstoqueEnable } from "../../permissionsHelper";

const estoque = {
  getMenu: (p, a) => {
    return reportEstoqueEnable(p, a)
      ? {
          key: "estoque",
          to: "/estoque",
          primaryText: "Estoque Atual",
          leftIcon: iconEstoque,
        }
      : null;
  },
};

export default estoque;
export const iconEstoque = <ViewModuleIcon />;
