import React from "react";
import { Datagrid, EditButton, List, TextField } from "react-admin";
import KdsVisitorFilter from "./KdsFilter";
import KdsPublicarButton from "./KdsPublicarButton";
import KdsAbrirButton from "./KdsAbrirButton";
import ListActions from "../../common/ListActions";
import KdsLimparButton from "./KdsLimparButton";
import KdsLimparTudoButton from "./KdsLimparTudoButton";

const KdsList = (props) => (
  <List
    {...props}
    filters={<KdsVisitorFilter />}
    actions={
      <ListActions
        actionsList={[<KdsLimparTudoButton />, <KdsLimparButton />]}
      />
    }
    exporter={false}
  >
    <Datagrid>
      <TextField source="nome" label="Nome" />
      <EditButton />
      <KdsPublicarButton />
      <KdsAbrirButton />
    </Datagrid>
  </List>
);

export default KdsList;
