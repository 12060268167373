import { Box, Card, CardContent, CardHeader } from "@material-ui/core";
import React from "react";
import { Bar } from "react-chartjs-2";

import PaymentMethodIcon from "./PaymentMethodIcon";
import { useStyles } from "./styles";

const TotalByPaymentMethodsChart = ({ chartData }) => {
  const classes = useStyles();
  const labels = chartData.map(({ paymentName }) => paymentName);
  const totalPerPaymentMethods = chartData.map(({ total }) => total);

  const data = {
    labels,
    datasets: [
      {
        label: "Total",
        data: totalPerPaymentMethods,
        backgroundColor: "rgba(0, 0, 0, 0.54)",
        borderColor: "rgba(0, 0, 0, 0.54)",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      xAxes: {
        ticks: {
          display: false,
        },
      },
    },
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        className={classes.cardHeader}
        title={"Por forma de pagamento"}
      />
      <CardContent>
        <Box height={250}>
          <Bar data={data} options={options} />
          <Box display='flex' justifyContent='space-around' paddingLeft='3%'>
            {labels.map((label) => (
              <PaymentMethodIcon key={label} paymentLabel={label} />
            ))}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TotalByPaymentMethodsChart;
