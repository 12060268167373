import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";

function BankStatement({ value, onChange, id }) {
  const handleChange = (event) => onChange(id, event.target.value);

  return (
    <RadioGroup row onChange={handleChange} value={value}>
      <FormControlLabel value='extrato' control={<Radio />} label='Extrato' />
      <FormControlLabel value='produtos' control={<Radio />} label='Produtos' />
    </RadioGroup>
  );
}

export default BankStatement;
