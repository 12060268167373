import React from "react";
import { Edit } from "react-admin";

import LogForm from "./LogForm";

const LogEdit = props => (
  <Edit {...props}>
    <LogForm toolbar={null} />
  </Edit>
);

export default LogEdit;
