import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import { IconMovimento } from "../movimento";

const useStyles = makeStyles({
  icon: { paddingRight: "0.5em" },
  link: {
    display: "inline-flex",
    alignItems: "center",
  },
});

const LinkToMovimento = ({ record }) => {
  const classes = useStyles();
  return record ? (
    <Button
      size="small"
      color="primary"
      component={Link}
      to={{
        pathname: `/Movimentos/${record.movimentoId}`,
      }}
      className={classes.link}
    >
      <IconMovimento className={classes.icon} />
      Venda
    </Button>
  ) : null;
};

export default LinkToMovimento;
