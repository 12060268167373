import { Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link, Title, useAuthenticated, useNotify } from "react-admin";
import isEmpty from "lodash.isempty";

import { fetchJSON } from "../../dataProvider";
import UXDelay from "../../dataProvider/uxdelay";
import { useStyles } from "./styles";
import Search from "../Search";
import { reportContasEnable } from "../../permissionsHelper";
import Table from "../../common/table/Table";
import {
  formatCurrency,
  formatDateTime,
  formatNumber,
} from "../../common/util/formatter";

const ReportBillsCashier = () => {
  useAuthenticated();
  const [reportData, setReportData] = useState({});
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const notify = useNotify();

  const summaryRowStyle = {
    fontWeight: "bold",
    border: "none",
  };

  const columns = [
    {
      title: "Abertura do Caixa - Operador",
      render: (item) => (
        <Link
          to={`/caixas/${item.caixaId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography>{`${item.abertura} - ${item.nome_fantasia}`}</Typography>
        </Link>
      ),
    },
    { title: "Descrição", field: "descricao" },
    {
      title: "Pagamento",
      field: "pagamento",
      defaultSort: "asc",
      render: (item) => (item.pago ? formatDateTime(item.pagamento) : null),
      renderSummaryRow: () => {
        return {
          value: "Total",
          style: summaryRowStyle,
        };
      },
    },
    {
      title: "R$ Valor",
      field: "valor",
      render: (item) => formatNumber(item.valor),
      renderSummaryRow: () => {
        return {
          value: formatCurrency(reportData.total),
          style: summaryRowStyle,
        };
      },
    },
  ];

  async function retrieveReportData({ lojaId, startDate, endDate }) {
    try {
      setLoading(true);
      const retrievedReportData = await UXDelay(
        fetchJSON("relatorio/contas/pagas_caixa", null, {
          lojaId,
          startDate,
          endDate,
        })
      );
      setReportData(retrievedReportData);
    } catch (error) {
      console.error(error);
      notify("Erro ao buscar os dados.", "warning");
    } finally {
      setLoading(false);
    }
  }

  const dataEmpty = isEmpty(reportData.array);

  return (
    <Card className={classes.root}>
      <Title title="Contas Pagas no Caixa" />
      <CardContent>
        <Search
          fields={["dateRange"]}
          onSearch={retrieveReportData}
          loading={loading}
          dataEmpty={dataEmpty}
        />
        {!dataEmpty && (
          <Grid container spacing={5}>
            <Grid item md={12}>
              <Table
                options={{
                  paging: false,
                  tableLayout: "fixed",
                }}
                title=""
                columns={columns}
                data={reportData.array}
                renderSummaryRow={({ column }) =>
                  typeof column.renderSummaryRow === "function"
                    ? column.renderSummaryRow()
                    : null
                }
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default ReportBillsCashier;

export const reportBillsCashier = {
  getMenu: (p, a) => {
    return reportContasEnable(p, a)
      ? {
          key: "ReportBillsCashier",
          to: "/Conta/pagas-no-caixa",
          primaryText: "Pagas nos Caixas",
        }
      : null;
  },
};
