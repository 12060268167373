import React from "react";
import { Grid, Typography } from '@mui/material';
import { formatCurrency } from "../../common/util/formatter";

const NFeValorData = ({ record }) => {
  if (!record) return null;
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="body1">
          <strong>{formatCurrency(record.vNF)}</strong>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2">
          {new Date(record.dhEmi).toLocaleString()}
        </Typography>
      </Grid>
      {record.cStat && (
        <Grid item>
          <Typography variant="body2">
            {`${record.cStat} ${record.xMotivo}`}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default NFeValorData;
