import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import TotalFaturamentoCard from '../ReportTotalFaturamentoCard';
import TotalQuantidadeCard from '../ReportTotalQuantidadeCard';
import TotalValorUnitarioMedioCard from '../ReportTotalValorUnitarioMedioCard'

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: 20,
        marginBottom: 20
    }
}));

const ReportTotal = props => {
    const classes = useStyles();
    return (
        <Grid container spacing={3} className={classes.container}>
            <Grid item xs={3}>
                <TotalFaturamentoCard total={props.data.total_venda} />
            </Grid>
            <Grid item xs={3}>
                <TotalQuantidadeCard total={props.data.total_qtd} />
            </Grid>
            <Grid item xs={3}>
                <TotalValorUnitarioMedioCard total={props.data.valor_unitario_medio} />
            </Grid>
        </Grid>
    );
};

ReportTotal.propTypes = {

};

ReportTotal.defaultProps = {
    data: {}
}
export default ReportTotal;
