import React from "react";
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
import RespostaList from "./RespostaList";

const resposta = {
  getResource: (p, a) => {
    return {
      name: "pergunta_resposta",
      list: p.cardapio_ver ? RespostaList : null,
    };
  },
  getMenu: (p, a) =>
    p.cardapio_ver
      ? {
          key: "pergunta_resposta",
          to: "/pergunta_resposta",
          primaryText: "Resposta",
          leftIcon: iconResposta,
        }
      : null,
};

export default resposta;
export const iconResposta = <QuestionAnswer />;
