import React from "react";
import { SelectInput, SelectField } from "react-admin";

const tipos = [
  {
    id: 0,
    name: "0 - Não se aplica (por exemplo, Nota Fiscal complementar ou de ajuste)",
  },
  { id: 1, name: "1 - Operação presencial" },
  { id: 2, name: "2 - Operação não presencial, pela Internet" },
  { id: 3, name: "3 - Operação não presencial, Teleatendimento" },
  { id: 5, name: "5 - Operação presencial, fora do estabelecimento" },
  { id: 9, name: "9 - Operação não presencial, outros" },
];

function NFePresencaInput(props) {
  return (
    <SelectInput
      label="Presença"
      source="presenca"
      choices={tipos}
      initialValue={9}
      {...props}
    />
  );
}

function NFePresencaField(props) {
  return (
    <SelectField
      label="Presença"
      source="presenca"
      choices={tipos}
      {...props}
    />
  );
}

export { NFePresencaInput, NFePresencaField };
