import React from "react";
import { Loading, Error, Title } from "react-admin";
import {
  makeStyles,
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useFetch from "../../dataProvider/useFetch";
import { withUser } from "../../common/util/hocs";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import AdjustIcon from "@material-ui/icons/Adjust";
import { red, green, blue } from "@mui/material/colors";

const useStyles = makeStyles((theme) => ({
  cardEmFalta: {
    border: "1px solid #c5c5c5",
    borderBottom: "7px solid #f44336",
  },
  cardMinimo: {
    border: "1px solid #c5c5c5",
    borderBottom: "7px solid #f57f17",
  },
  card: {
    border: "1px solid #c5c5c5",
    borderBottom: "7px solid #283593",
  },
  typographyImportant: {
    fontWeight: "bold",
  },
  typographyEmFalta: {
    fontWeight: "bold",
    color: "#f44336",
  },
  typographyMinimo: {
    fontWeight: "bold",
    color: "#f57f17",
  },
}));

const EstoqueList = (props) => {
  const loja = props.loja;
  const produtoId = props.match.params.produtoId;
  const classes = useStyles();
  const {
    data: produto,
    isLoading,
    error,
  } = useFetch("Estoque/findInclude", {
    filter: { where: { lojaId: loja.id, id: produtoId } },
  });

  if (error) {
    return (
      <>
        <Title title="Estoque" />
        <Error error={error} />
      </>
    );
  }

  if (isLoading || !produto) {
    return (
      <>
        <Title title="Estoque" />
        <Loading />
      </>
    );
  }

  const movs = produto.movimentacao;
  const emFalta = movs.length > 0 ? movs[movs.length - 1].emFalta : false;
  const minimo = movs.length > 0 ? movs[movs.length - 1].minimo : false;
  const cardClass = emFalta
    ? classes.cardEmFalta
    : minimo
    ? classes.cardMinimo
    : classes.card;

  return (
    <>
      <Title title="Movimentação de estoque" />
      <Box my={4} ml={2}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Link to={`/produtos/${produtoId}`}>
              <Typography variant="subtitle1" color="textPrimary">
                {`Código: ${produto.cod_prod}`}
              </Typography>
            </Link>
            <Typography variant="h4" color="textPrimary">
              {produto.desc_prod}
            </Typography>
          </Grid>
          <Grid item>
            <Card className={cardClass}>
              <CardContent>
                <Grid container alignItems="center">
                  {emFalta && (
                    <Grid item>
                      <Box mx={6}>
                        <Typography
                          className={classes.typographyEmFalta}
                          variant="h5"
                        >
                          Em falta
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {minimo && (
                    <Grid item>
                      <Box mx={6}>
                        <Typography
                          className={classes.typographyMinimo}
                          variant="h5"
                        >
                          Mínimo
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  <Grid item>
                    <Box mx={6}>
                      <Typography variant="subtitle1" color="textPrimary">
                        Mínimo
                      </Typography>
                      <Typography
                        className={classes.typographyImportant}
                        variant="h5"
                      >
                        {produto.qtd_min_prod}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box mx={6}>
                      <Typography variant="subtitle1" color="textPrimary">
                        Estoque
                      </Typography>
                      <Typography
                        className={classes.typographyImportant}
                        variant="h5"
                      >
                        {produto.saldo}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Card>
        <CardContent>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <SwapVertIcon />
                  </TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Descrição</TableCell>
                  <TableCell align="right">Quantidade</TableCell>
                  <TableCell align="right">Saldo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {movs.reverse().map((m) => (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {m.qtd === null && (
                        <AdjustIcon style={{ color: blue[500] }} />
                      )}
                      {m.qtd < 0 && (
                        <ArrowDownwardIcon style={{ color: red[500] }} />
                      )}
                      {m.qtd > 0 && (
                        <ArrowUpwardIcon style={{ color: green[500] }} />
                      )}
                    </TableCell>
                    <TableCell>{new Date(m.data).toLocaleString()}</TableCell>
                    <TableCell>{m.desc}</TableCell>
                    <TableCell align="right">
                      <Typography
                        className={classes.typographyImportant}
                        variant="h5"
                      >
                        {m.qtd}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        className={
                          m.emFalta
                            ? classes.typographyEmFalta
                            : m.minimo
                            ? classes.typographyMinimo
                            : classes.typographyImportant
                        }
                        variant="h5"
                      >
                        {m.saldo}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
};

export default withUser(EstoqueList);
