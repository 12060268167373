import React from "react";
import { Datagrid, List, TextField, EditButton } from "react-admin";

import ProdutoFilter from "../produto/ProdutoFilter";
import CurrencyField from '../../common/form/CurrencyField';

const ProdutoList = props => (
  <List {...props} filters={<ProdutoFilter />} exporter={false}>
    <Datagrid>
      <TextField source="cod_prod" label="Código" />
      <TextField source="cod_aux" label="Cód.Aux." />
      <TextField source="desc_prod" label="Nome" />
      <CurrencyField source="preco_vnd_prod" label="R$ Venda" />
      <EditButton />
    </Datagrid>
  </List>
);

export default ProdutoList;
