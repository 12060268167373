import React from "react";
import { Create } from "react-admin";

import MoedaAdquirenteForm from "./MoedaAdquirenteForm";
import MoedaAdquirenteToolbar from "./MoedaAdquirenteToolbar";

const MoedaAdquirenteCreate = props => (
  <Create {...props}>
    <MoedaAdquirenteForm toolbar={<MoedaAdquirenteToolbar />} />
  </Create>
);

export default MoedaAdquirenteCreate;
