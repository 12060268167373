import React, { useEffect, useState } from "react";
import {
  TabbedForm,
  FormTab,
  TextInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  required,
  email,
  NumberInput,
} from "react-admin";
import { useForm } from "react-final-form";
import { makeStyles } from "@material-ui/core/styles";
import { InputAdornment } from "@material-ui/core";
import { IdDates } from "../../common/form/ConditionalInputs";
import CepInput from "../../common/form/CEPInput";
import { withUser } from "../../common/util/hocs";
import {
  CPFCNPJInput,
  EnderecoNumeroInput,
  TelefoneInput,
} from "../../common/form/NumberMaskInput";
import { isValidCNPJCPFOrEmpty } from "../../common/util/validations";
import ParceiroHistoricoCorrentista from "./ParceiroHistoricoCorrentista";
import { Link, Stack, Typography } from "@mui/material";
import ParceiroClienteFuncionario from "./ParceiroClienteFuncionario";
import useParceiroValidateUniq from "./useParceiroValidateUniq";
import { validateInscricaoEstadual } from "../../common/util/ra-validations";

const validateReq = [required()];
const validateEmail = [email()];
const validateIE = [validateInscricaoEstadual];

const useStyles = makeStyles({
  ativo: {
    width: "6em",
    display: "inline-block",
    marginRight: "1em",
    verticalAlign: "middle",
  },
  cnpj: {
    width: "11em",
    display: "inline-block",
    marginRight: "1em",
    verticalAlign: "middle",
  },
  ie: {
    width: "8em",
    display: "inline-block",
    marginRight: "1em",
    verticalAlign: "middle",
  },
  telefone: {
    width: "12em",
    display: "inline-block",
    marginRight: "1em",
  },
  email: {
    width: "25em",
    display: "inline-block",
    marginRight: "1em",
  },
  inline: {
    display: "inline-block",
    marginRight: 32,
  },
  logradouro: {
    width: "43em",
    display: "inline-block",
    marginRight: 32,
  },
  numero: {
    width: "7em",
    display: "inline-block",
  },
  uf: {
    width: "6em",
    display: "inline-block",
    marginRight: 32,
  },
  excluir: {
    display: "inline-block",
    marginRight: 32,
    verticalAlign: "middle",
    width: "7em",
  },
});

function ConsultaCNPJ({ formData: { cnpj, enderecos } }) {
  const [parceiro, setParceiro] = useState(null);
  const form = useForm();

  useEffect(() => {
    setParceiro(null);
    if (!cnpj || cnpj.length < 14) {
      return;
    }

    fetch(`https://brasilapi.com.br/api/cnpj/v1/${cnpj}`)
      .then((response) => response.json())
      .then((json) => setParceiro(json))
      .catch((err) => console.error(err));
  }, [cnpj]);

  const handleClick = (e) => {
    e.preventDefault();
    const { nome_fantasia, razao_social, cep, numero, complemento, email } =
      parceiro;
    if (nome_fantasia) form.change(`nome_fantasia`, nome_fantasia);
    if (razao_social) form.change(`razao_social`, razao_social);
    if (email) form.change(`email1`, email);
    const arrayEnd = enderecos || [];
    const endereco = arrayEnd.find((e) => e && e.cep === cep);
    const novoEndereco = {
      ...(endereco || {}),
      cep: "",
      numero,
      complemento,
    };
    const novosEnderencos = endereco
      ? [novoEndereco, ...arrayEnd.find((e) => e && e.cep !== cep)]
      : [novoEndereco, ...arrayEnd];
    form.change(`enderecos`, novosEnderencos);
    setTimeout(() => form.change(`enderecos[0].cep`, cep), 200);
    setParceiro(null);
  };

  if (!parceiro) {
    return null;
  }

  const text = `${parceiro.nome_fantasia}\n${parceiro.razao_social}\nCEP: ${
    parceiro.cep
  }, No: ${parceiro.numero}, ${parceiro.complemento}\n${
    parceiro.email || ""
  }`.trim();
  return (
    <Stack direction="row" spacing={3} sx={{ ml: 2, mb: 2 }}>
      <Link href="#" onClick={handleClick}>
        <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
          {text}
        </Typography>
      </Link>
    </Stack>
  );
}

function ParceiroForm(props) {
  const { loja, record } = props;
  const newRecord = !record.id;
  const classes = useStyles();

  const { uniq: uniqCNPJ } = useParceiroValidateUniq(
    loja.id,
    record,
    "cnpj",
    "CNPJ"
  );

  const validateCNPJCPF = [isValidCNPJCPFOrEmpty, uniqCNPJ];

  const validateParceiro = (values) => {
    const errors = {};

    if (!values.cliente && !values.funcionario && !values.fornecedor) {
      errors.cliente =
        errors.funcionario =
        errors.fornecedor =
          "Escolha ao menos uma das opções: Cliente, Funcionário ou Fornecedor";
    }

    return errors;
  };

  return (
    <TabbedForm {...props} validate={validateParceiro} redirect="list">
      <FormTab label="Dados do parceiro">
        <IdDates record={record} />

        <TextInput
          source="cnpj"
          label="CNPJ ou CPF"
          validate={validateCNPJCPF}
          InputProps={{
            inputComponent: CPFCNPJInput,
          }}
          formClassName={classes.cnpj}
          fullWidth
        />
        <TextInput
          source="ie"
          label="IE ou RG"
          formClassName={classes.ie}
          fullWidth
          validate={validateIE}
        />
        <BooleanInput
          source="ativo"
          formClassName={classes.ativo}
          defaultValue={true}
        />
        <NumberInput
          source="codigo"
          label="Código PDV"
          disabled={true}
          formClassName={classes.ie}
          fullWidth
        />
        <div />

        {newRecord && (
          <FormDataConsumer>
            {(formDataProps) => (
              <ConsultaCNPJ formData={formDataProps.formData} />
            )}
          </FormDataConsumer>
        )}

        <TextInput source="razao_social" label="Razão social" fullWidth />
        <TextInput
          source="nome_fantasia"
          label="Nome"
          validate={validateReq}
          fullWidth
        />

        <TextInput
          source="telefone1"
          label="Telefone 1"
          formClassName={classes.telefone}
          fullWidth
          InputProps={{
            inputComponent: TelefoneInput,
            startAdornment: (
              <InputAdornment position="start">+55</InputAdornment>
            ),
          }}
        />
        <TextInput
          source="telefone2"
          label="Telefone 2"
          formClassName={classes.telefone}
          fullWidth
          InputProps={{
            inputComponent: TelefoneInput,
            startAdornment: (
              <InputAdornment position="start">+55</InputAdornment>
            ),
          }}
        />
        <TextInput
          source="telefone3"
          label="Telefone 3"
          formClassName={classes.telefone}
          fullWidth
          InputProps={{
            inputComponent: TelefoneInput,
            startAdornment: (
              <InputAdornment position="start">+55</InputAdornment>
            ),
          }}
        />
        <TextInput
          source="telefone4"
          label="Telefone 4"
          formClassName={classes.telefone}
          fullWidth
          InputProps={{
            inputComponent: TelefoneInput,
            startAdornment: (
              <InputAdornment position="start">+55</InputAdornment>
            ),
          }}
        />
        <div></div>

        <TextInput
          label="Email 1"
          source="email1"
          type="email"
          validate={validateEmail}
          formClassName={classes.email}
          fullWidth
        />
        <TextInput
          label="Email 2"
          source="email2"
          type="email"
          validate={validateEmail}
          formClassName={classes.email}
          fullWidth
        />
        <div />

        <TextInput source="observacao" label="Observação" multiline fullWidth />

        <div />

        <FormDataConsumer>
          {(formDataProps) => (
            <ParceiroClienteFuncionario
              {...formDataProps}
              record={record}
              loja={loja}
            />
          )}
        </FormDataConsumer>

        <ArrayInput source="enderecos" label="Endereços" fullWidth>
          <SimpleFormIterator
            disableReordering
            TransitionProps={{ enter: false, exit: false }}
            disableRemove
          >
            <CepInput
              source="cep"
              label="CEP"
              formClassName={classes.excluir}
              fullWidth
            />
            <BooleanInput
              source="deletar"
              label="Excluir"
              formClassName={classes.excluir}
            />
            <div />

            <TextInput
              source="logradouro"
              label="Logradouro"
              formClassName={classes.logradouro}
              fullWidth
            />
            <TextInput
              source="numero"
              label="Número"
              formClassName={classes.numero}
              fullWidth
              InputProps={{
                inputComponent: EnderecoNumeroInput,
              }}
            />
            <div />

            <TextInput source="complemento" label="Complemento" fullWidth />
            <TextInput
              source="bairro"
              label="Bairro"
              formClassName={classes.inline}
            />
            <TextInput
              source="cidade"
              label="Cidade"
              formClassName={classes.inline}
            />
            <TextInput
              source="uf"
              label="UF"
              formClassName={classes.uf}
              fullWidth
            />
            <TextInput
              source="codigo_municipio"
              label="Código Municipio"
              formClassName={classes.inline}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      {record && record.id && (
        <FormTab label="Correntista">
          <ParceiroHistoricoCorrentista />
        </FormTab>
      )}
    </TabbedForm>
  );
}

export default withUser(ParceiroForm);
