import React from "react";
import { SelectInput, SelectField } from "react-admin";

const tipos = [
  { id: 1, name: "1 - Contribuinte ICMS (informar a IE do destinatário)" },
  {
    id: 2,
    name: "2 - Contribuinte isento de Inscrição no cadastro de Contribuintes do ICMS",
  },
  { id: 9, name: "9 - Não Contribuinte, que pode ou não possuir Inscrição" },
];

function NFeIdentificadorIEInput(props) {
  return (
    <SelectInput
      label="Identificador da IE"
      source="identificador_ie_dest"
      choices={tipos}
      initialValue={1}
      {...props}
    />
  );
}

function NFeIdentificadorIEField(props) {
  return (
    <SelectField
      label="Identificador da IE"
      source="identificador_ie_dest"
      choices={tipos}
      {...props}
    />
  );
}

export { NFeIdentificadorIEInput, NFeIdentificadorIEField };
