const Highlighted = ({ text = "", highlight = "", formatFunction }) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }

  const textParts = text.split(" ");
  const highlightParts = highlight
    .removeAccentsToLower()
    .split(" ")
    .filter((h) => h);

  return (
    <span>
      {textParts.map((part, i) =>
        highlightParts.some((h) =>
          part.removeAccentsToLower().startsWith(h)
        ) ? (
          <mark key={i}>
            {formatFunction instanceof Function ? formatFunction(part) : part}{" "}
          </mark>
        ) : (
          <span key={i}>
            {formatFunction instanceof Function ? formatFunction(part) : part}{" "}
          </span>
        )
      )}
    </span>
  );
};

export default Highlighted;
