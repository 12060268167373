import React from "react";
import LoadingButton from "../../common/LoadingButton";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { withUser } from "../../common/util/hocs";

const KdsAbrirButton = ({ record, loja }) => {
  if (!record || !record.uuid) return null;

  const kdsUrl = `https://kds.sistemapallas.com.br/${loja.id}/${loja.uuid}/${record.uuid}`;

  const handleClick = async () => {
    window.open(kdsUrl, "_blank").focus();
  };

  return (
    <LoadingButton
      text="Abrir KDS"
      icon={<OpenInNewIcon />}
      onClick={handleClick}
    />
  );
};

export default withUser(KdsAbrirButton);
