import React from "react";
import { TextInput, Filter } from "react-admin";

const KdsFilter = (props) => (
  <Filter {...props}>
    <TextInput source="nome_like" label="Nome" alwaysOn />
  </Filter>
);

export default KdsFilter;
