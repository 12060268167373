import React from "react";
import { Datagrid, List, TextField, DateField } from "react-admin";
import { red, blue, grey } from "@mui/material/colors";
import { Chip } from "@mui/material";

const Acao = ({ record }) => {
  if (!record) return null;
  const { verb, method } = record;

  if (verb === "DELETE") {
    return (
      <Chip
        size="small"
        label="Deletar"
        style={{
          backgroundColor: red[200],
          borderColor: red[200],
        }}
      />
    );
  }

  if (method === "save") {
    return (
      <Chip
        size="small"
        label="Salvar"
        style={{
          backgroundColor: blue[200],
          borderColor: blue[200],
        }}
      />
    );
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <Chip
      size="small"
      label={capitalizeFirstLetter(method)}
      style={{
        backgroundColor: grey[200],
        borderColor: grey[200],
      }}
    />
  );
};

const UsuarioLogList = (props) => (
  <List
    title="Listar Logs"
    {...props}
    exporter={false}
    bulkActionButtons={false}
    sort={{ field: "createdAt", order: "DESC" }}
  >
    <Datagrid>
      <DateField source="createdAt" label="Data" showTime="true" />
      <TextField source="email" label="Email" />
      <Acao label="Ação" />
      <TextField source="model" label="Entidade" />
      <TextField source="instanceId" label="ID Afetado" />
      <TextField source="ip" label="IP" />
    </Datagrid>
  </List>
);

export default UsuarioLogList;
