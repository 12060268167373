import React, { useState } from "react";
import { Title } from "react-admin";
import { fetchJSON } from "../dataProvider";
import UXDelay from "../dataProvider/uxdelay";
import { useAuthenticated } from "react-admin";
import isEmpty from "lodash.isempty";
import Search from "./Search";
import { Box, Card, CardContent, CardHeader, Grid } from "@mui/material";
import { useStyles } from "./ReportDailyInvoicings/styles";
import { Bar } from "react-chartjs-2";
import { reportPDVEnable } from "../permissionsHelper";

const BarChart = ({ title, data, options }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardHeader className={classes.cardHeader} title={title} />
      <CardContent>
        <Box height={500}>
          <Bar data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

const ReportSalesByChannel = (props) => {
  useAuthenticated();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  async function retrieveReportData({
    lojaId,
    startDate,
    endDate,
    serviceTypes,
  }) {
    try {
      setLoading(true);
      const retrievedReportData = await UXDelay(
        fetchJSON("relatorio/pdv/comparar_canais_venda", null, {
          lojaId,
          startDate,
          endDate,
          types: serviceTypes,
        })
      );
      setData(retrievedReportData);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  //
  //  DATA SAMPLE
  //
  // {
  //   LOJA: {
  //     numero_pessoas: 410,
  //     total_bruto: 15158.47,
  //     permanencia_minutos: 1249,
  //     ticket_medio: "36.97",
  //     permanencia_media: "3.05",
  //   },
  //   PALLAS: {
  //     numero_pessoas: 9,
  //     total_bruto: 361,
  //     permanencia_minutos: 0,
  //     ticket_medio: "40.11",
  //     permanencia_media: "0",
  //   },
  //   IFOOD: {
  //     numero_pessoas: 28,
  //     total_bruto: 1507.16,
  //     permanencia_minutos: 0,
  //     ticket_medio: "53.83",
  //     permanencia_media: "0",
  //   },
  // }

  const dataEmpty = isEmpty(data);

  const currencyOptions = {
    indexAxis: "x",
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        anchor: "end",
        align: "bottom",
        color: "white",
        formatter: function (value, context) {
          return new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(value);
        },
        font: {
          weight: "bold",
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  const clientsOptions = {
    indexAxis: "x",
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        anchor: "end",
        align: "bottom",
        color: "white",
        font: {
          weight: "bold",
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  const channels = Object.keys(data);

  const salesData = {
    labels: channels,
    datasets: [
      {
        label: "Faturamento",
        data: channels.map((c) => data[c].total_bruto),
        backgroundColor: "#2e7d32",
      },
    ],
  };

  const averageTicketData = {
    labels: channels,
    datasets: [
      {
        label: "Ticket Médio",
        data: channels.map((c) => data[c].ticket_medio),
        backgroundColor: "#0d47a1",
      },
    ],
  };

  const clientsData = {
    labels: channels,
    datasets: [
      {
        label: "Clientes",
        data: channels.map((c) => data[c].numero_pessoas),
        backgroundColor: "#bf360c",
      },
    ],
  };

  return (
    <Card sx={{ minHeight: "100%" }}>
      <Title title="Comparar Canais de Venda" />
      <CardContent>
        <Search
          fields={["dateRange", "timeRange", "serviceTypes"]}
          onSearch={retrieveReportData}
          loading={loading}
          dataEmpty={dataEmpty}
        />
        {!dataEmpty && (
          <Grid container item spacing={5}>
            <Grid item xs={4} md={4}>
              <BarChart
                title={"Faturamento"}
                data={salesData}
                options={currencyOptions}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <BarChart
                title={"Ticket Médio"}
                data={averageTicketData}
                options={currencyOptions}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <BarChart
                title={"Número de Clientes"}
                data={clientsData}
                options={clientsOptions}
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default ReportSalesByChannel;

export const salesByChannel = {
  getMenu: (p, a) =>
    reportPDVEnable(p, a)
      ? {
          key: "salesByChannel",
          to: "/relatorio/comparar-canal-vendas",
          primaryText: "Comparar Canais",
        }
      : null,
};
