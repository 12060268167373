import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";

const rowStyle = (record, index, defaultStyle = {}) => {
  if (!record) return null;
  if (record.status === 2)
    return {
      ...defaultStyle,
      borderLeftColor: green[500],
      borderLeftWidth: 5,
      borderLeftStyle: "solid",
    };

  if (record.status === 1)
    return {
      ...defaultStyle,
      borderLeftColor: red[500],
      borderLeftWidth: 5,
      borderLeftStyle: "solid",
    };

  return defaultStyle;
};

export default rowStyle;
