import React, { useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DateTimeInput, TextInput, FormDataConsumer } from "react-admin";
import { useForm } from "react-final-form";
import { NFeTipoDocumentoInput } from "../fields/NFeTipoDocumento";
import { NFePresencaInput } from "../fields/NFePresenca";
import { NFeFinalidadeInput } from "../fields/NFeFinalidade";
import { fetchJSON } from "../../../dataProvider";
import { IdDates } from "../../../common/form/ConditionalInputs";
import NumberInputSimple from "../../../common/form/NumberInputSimple";
import NumberInputReadonly from "../../../common/form/NumberInputReadonly";
import { Typography } from "@mui/material";
import template_replace_data from "../../../common/util/template_replace_data";
import FormatTextdirectionLToRIcon from "@material-ui/icons/FormatTextdirectionLToR";
import LoadingButton from "../../../common/LoadingButton";
import Decimal from "decimal.js";
import { formatNumber } from "../../../common/util/formatter";

const useStyles = makeStyles({
  cnf: {
    width: "8em",
    marginRight: `1em`,
  },
  serie: {
    width: "5em",
    marginRight: `1em`,
  },
  operacao: {
    width: "15em",
    marginRight: `1em`,
  },
  consumidor: {
    width: "15em",
    marginRight: `1em`,
  },
  presenca: {
    marginRight: `1em`,
  },
  tipo: {
    width: "6em",
    marginRight: `1em`,
  },
  finalidade: {
    width: "14em",
    marginRight: `1em`,
  },
  natureza: {
    width: "51em",
    marginRight: `1em`,
  },
  data: {
    width: "15em",
    marginRight: `1em`,
  },
});

function ButtonInfoAdd({ loja, formData }) {
  const form = useForm();

  if (formData.xmlUrl) return null;

  const handleClickInfoAdd = () => {
    const {
      razao_social,
      nfe_informacao_adicional: template,
      nfe_cred_sn_alq,
    } = loja;
    const { total_nota } = formData;

    const alq = Decimal(nfe_cred_sn_alq || 1.25).toFixed(2);
    const total = Decimal(total_nota).times(alq).dividedBy(100).toFixed(2);

    const data = {
      razao_social,
      total_cred_sn_v: formatNumber(total),
      cred_sn_alq: formatNumber(alq),
    };

    const informacao_adicional = template_replace_data(template, data);
    form.change("informacao_adicional", informacao_adicional);
  };

  return (
    <LoadingButton
      variant="outlined"
      text="Preencher Informação Adicional"
      onClick={handleClickInfoAdd}
      icon={<FormatTextdirectionLToRIcon />}
      sx={{ mt: 3 }}
    />
  );
}

export default function TabIdentificacao({ record, loja, edit }) {
  const form = useForm();
  const classes = useStyles();

  const getNextNumber = useCallback(
    (serie, form) => {
      fetchJSON("NFe_emissaos", null, {
        filter: {
          where: { lojaId: loja.id, serie },
          order: "id DESC",
          limit: 1,
        },
      })
        .then((nfes) => (nfes.length > 0 ? nfes[0].numero + 1 : 1))
        .then((numero) => form.change("numero", numero))
        .catch((err) => console.log(JSON.stringify(err)));
    },
    [loja.id]
  );

  useEffect(() => {
    if (edit) return;
    fetchJSON("NFe_emissaos", null, {
      filter: { where: { lojaId: loja.id }, order: "id DESC", limit: 1 },
    })
      .then((nfes) => (nfes.length > 0 ? nfes[0].serie : 1))
      .then((serie) => {
        form.change("serie", serie);
        getNextNumber(serie, form);
      })
      .catch((err) => console.log(JSON.stringify(err)));
  }, [edit, form, loja.id, getNextNumber]);

  return (
    <>
      <Typography variant="h5" gutterBottom sx={{ mt: 4, ml: 1 }}>
        Identificação da NFe
      </Typography>

      <IdDates record={record} />
      <div />

      <NumberInputReadonly
        label="Código"
        source="cnf"
        required
        className={classes.cnf}
      />
      <NumberInputReadonly
        label="Modelo"
        source="modelo"
        required
        className={classes.serie}
      />
      <NumberInputSimple
        label="Série"
        source="serie"
        required
        className={classes.serie}
        onChange={(event) => getNextNumber(event.target.value)}
      />
      <NumberInputSimple
        label="Número"
        source="numero"
        required
        className={classes.serie}
      />

      <DateTimeInput
        source="emitida_em"
        label="Emitida em"
        className={classes.data}
      />
      <div />

      <NFeTipoDocumentoInput className={classes.tipo} />
      <NFeFinalidadeInput className={classes.finalidade} fullWidth />
      <NFePresencaInput className={classes.presenca} />
      <div />

      <TextInput
        label="Natureza da Operação"
        source="natureza_operacao"
        required
        className={classes.natureza}
        fullWidth
      />
      <div />

      <FormDataConsumer>
        {({ formData }) => <ButtonInfoAdd loja={loja} formData={formData} />}
      </FormDataConsumer>
      <TextInput
        label="Informação Adicional"
        source="informacao_adicional"
        multiline={true}
        fullWidth
      />
    </>
  );
}
