import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { green, red } from "@material-ui/core/colors";
import Decimal from "decimal.js";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { Line } from "react-chartjs-2";

import { formatCurrency } from "../../common/util/formatter";
import EmptyChartLayout from "./EmptyChartLayout";
import { useStyles } from "./styles";
import {
  generateWeekdaysLabels,
  generateWeekTotalLabel,
  getTotalsPerWeekday,
} from "./utils";

const WeeklyPerformanceChart = ({ chartData, days }) => {
  const classes = useStyles();
  const week1Data = get(chartData, "week1.perWeekDays", []);
  const week2Data = get(chartData, "week2.perWeekDays", []);

  if (isEmpty(week1Data) && isEmpty(week2Data)) {
    return <EmptyChartLayout />;
  }

  const labels = generateWeekdaysLabels();
  const totalsWeek1 = getTotalsPerWeekday(labels, week1Data);
  const totalsWeek2 = getTotalsPerWeekday(labels, week2Data);

  const totalValuesLabels = generateWeekTotalLabel(chartData);
  const totalValueWeek1 = week1Data.reduce(
    (total, data) => total.plus(data.total),
    new Decimal(0)
  );
  const totalValueWeek2 = week2Data.reduce(
    (total, data) => total.plus(data.total),
    new Decimal(0)
  );

  const percentageCompare =
    totalValueWeek2 > 0
      ? totalValueWeek1
          .dividedBy(totalValueWeek2)
          .minus(1)
          .times(100)
          .toFixed(2)
      : 100;

  const stringCompare = percentageCompare > 0 ? "Alta" : "Queda";

  const data = {
    labels,
    datasets: [
      {
        label: "Essa semana",
        data: totalsWeek1,
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgb(255, 99, 132)",
      },
      {
        label: "Semana passada",
        data: totalsWeek2,
        backgroundColor: "rgba(0, 0, 0, 0.54)",
        borderColor: "rgba(0, 0, 0, 0.54)",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        className={classes.cardHeader}
        title={"Vendas"}
        subheader={`últimos ${days * 2} dias`}
      />
      <CardContent>
        <Box marginBottom={2}>
          <Grid container spacing={3}>
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                {`1° Período ${totalValuesLabels.week2}`}
              </Typography>
              <Typography
                variant="subtitle1"
                className={classes.typographyImportant}
              >
                {formatCurrency(totalValueWeek2)}
              </Typography>
            </Grid>
            <Grid item className={classes.totalCard}>
              <Typography variant="body2" color="textSecondary">
                {`2° Período ${totalValuesLabels.week1}`}
              </Typography>
              <Typography
                variant="subtitle1"
                className={classes.typographyImportant}
              >
                {formatCurrency(totalValueWeek1)}
              </Typography>
            </Grid>
            <Grid item className={classes.dataExplanationCard}>
              <Typography variant="body2" color="textSecondary">
                {`Aqui comparamos o resultado dos seus últimos ${days} dias com os ${days}
                dias anteriores.`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                {`${stringCompare} em relação à semana passada`}
              </Typography>
              <Grid
                container
                style={{ color: percentageCompare > 0 ? green[500] : red[500] }}
              >
                {percentageCompare > 0 && <ArrowUpwardIcon />}
                {percentageCompare < 0 && <ArrowDownwardIcon />}
                <Typography
                  variant="subtitle1"
                  className={classes.typographyImportant}
                >
                  {percentageCompare}%
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box height={300}>
          <Line data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default WeeklyPerformanceChart;
