import { parse as convertFromCSV } from "papaparse";
import lensPath from "ramda/src/lensPath";
import over from "ramda/src/over";

const setObjectValue = (object, path, value) => {
  const lensPathFunction = lensPath(path.split("."));
  return over(lensPathFunction, () => value, object || {});
};

export async function processCsvFile(file, delimiter) {
  if (!file) {
    return;
  }
  const csvData = await getCsvData(file, delimiter);
  return processCsvData(csvData);
}

export async function getCsvData(file, delimiter) {
  return new Promise((resolve, reject) =>
    convertFromCSV(file, {
      delimiter,
      skipEmptyLines: true,
      complete: (result) => resolve(result.data),
      error: (error) => reject(error),
    })
  );
}

export function processCsvData(data) {
  const topRowKeys = data[0];

  const dataRows = data.slice(1).map((row) => {
    let value = {};

    topRowKeys.forEach((key, index) => {
      value = setObjectValue(value, key, row[index]);
    });

    return value;
  });

  return dataRows;
}

export function isEmptyObject(obj) {
  const keys = Object.keys(obj);
  return keys.reduce(
    (acc, k) =>
      acc && (obj[k] === undefined || obj[k] === null || obj[k] === ""),
    true
  );
}

export function normalizeColumnsValues(values, columns) {
  const keys = Object.keys(values[0]);
  const keysLC = [];
  const cols = [];
  keys.forEach((key) => {
    const k = key.trim();
    const kLC = k.toLocaleLowerCase();
    const c = columns.find(
      (c) =>
        kLC === c.field.toLocaleLowerCase() ||
        kLC === c.title.toLocaleLowerCase()
    );
    keysLC.push(c ? c.field : k);
    cols.push(c ? c : { title: "? " + k, field: k, type: "string" });
  });

  const v = values
    .filter((v) => !isEmptyObject(v))
    .map((e) => {
      const n = {};
      keysLC.forEach(function (k, i) {
        const col = cols[i];
        const vTrim = e[keys[i]] === undefined ? "" : e[keys[i]].trim();
        if (col.type === "numeric") {
          n[k] = getFloat(vTrim);
          if (Number.isNaN(n[k])) {
            throw new Error(
              `Coluna '${col.title}' da linha ${
                i + 1
              } inválido. Não foi possível converter o valor '${vTrim}' em decimal.`
            );
          }
        } else if (col.type === "boolean") {
          n[k] = vTrim === "1" || vTrim.toLocaleLowerCase() === "true";
        } else {
          if (col.norm) {
            n[k] = col.norm.reduce((v, f) => f(v), vTrim);
          } else {
            n[k] = vTrim;
          }
        }
      });

      return n;
    });

  const sortedCols = [
    ...columns.filter((c) => cols.includes(c)),
    ...cols.filter((c) => !columns.includes(c)),
  ];

  return {
    values: v,
    columns: sortedCols,
  };
}

export function checkRequiredFields(value, columns, update) {
  columns
    .filter((c) => c.required)
    .forEach((c) => {
      if (value.hasOwnProperty(c.field)) {
        if (c.type === "string") {
          if (value[c.field].length === 0) {
            throw new Error(`Coluna ${c.title} preenchimento obrigatório.`);
          }
        }
      } else {
        if (!update) {
          if (c.hasOwnProperty("default")) {
            value[c.field] = c.default;
          } else {
            throw new Error(`Coluna ${c.title} preenchimento obrigatório.`);
          }
        }
      }
    });
}

export function getFloat(str) {
  if (!str) {
    return 0;
  }

  return parseFloat(str.replace(",", ".").replace(/[^\d.-]/g, ""));
}

export function getNumbers(str) {
  return str.replace(/^\D+/g, "");
}
