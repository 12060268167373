import React, { Fragment } from "react";
import {
  SimpleForm,
  TextField,
  FunctionField,
  TextInput,
  NumberInput,
  FormDataConsumer,
  required,
  minValue,
  Labeled,
  maxLength,
} from "react-admin";
import classnames from "classnames";
import {
  Card,
  CardContent,
  Grid,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { get, keyBy, sumBy } from "lodash";

import Datagrid from "../../common/form/Datagrid/Datagrid";
import CurrencyField from "../../common/form/CurrencyField";
import { DateField } from "../../common/form/DateFieldLocale";

import CaixaSincField from "./CaixaSincField";
import { CaixaStatusField, CaixaStatusInput } from "./CaixaStatus";
import CaixaRelatorio from "./CaixaRelatorio";
import NumberInputRemoveArrowsCSS from "../../common/form/NumberInputRemoveArrowsCSS.json";

const useStyles = makeStyles({
  corrigido: { width: "11em" },
  input: {
    margin: 0,
    padding: 0,
    width: "8em",
    ...NumberInputRemoveArrowsCSS,
  },
  faltou: {
    color: "red",
  },
  sobrou: {
    color: "blue",
  },
  formGroup: {
    display: "inline-block",
    width: 256,
    marginRight: 32,
  },
  formArr: {
    display: "inline-block",
    width: 128,
    marginRight: 8,
  },
});

function DiffField({ diff, classes }) {
  return (
    <FunctionField
      render={() =>
        diff.toLocaleString(undefined, { style: "currency", currency: "BRL" })
      }
      className={diff >= 0 ? classes.sobrou : classes.faltou}
      textAlign="right"
    />
  );
}

function CellNum({ value, classes, bold, diff, ...rest }) {
  return (
    <TableCell
      {...rest}
      className={classnames(
        diff ? (value >= 0 ? classes.sobrou : classes.faltou) : null
      )}
    >
      {bold ? (
        <strong>
          {value.toLocaleString(undefined, {
            style: "currency",
            currency: "BRL",
          })}
        </strong>
      ) : (
        value.toLocaleString(undefined, {
          style: "currency",
          currency: "BRL",
        })
      )}
    </TableCell>
  );
}

const validateRS = [required(), minValue(0)];
const validateObservacao = [maxLength(1024)];

function CaixaForm(props) {
  const classes = useStyles();
  const r = props.record;
  return (
    <SimpleForm {...props} toolbar={props.toolbar} redirect="list">
      <Grid container spacing={6} direction="row" sx={{ mb: 3 }}>
        <Grid item xs={2}>
          <Typography variant="h6" gutterBottom>
            Informações gerais
          </Typography>
          <Card>
            <CardContent>
              <Stack>
                {r.equipamento && (
                  <Labeled label="Equipamento">
                    <TextField record={r} source="equipamento.nome" />
                  </Labeled>
                )}
                <Labeled label="Operador">
                  <TextField record={r} source="operador.nome_fantasia" />
                </Labeled>
                <Labeled label="Número da abertura">
                  <TextField
                    record={r}
                    source="abertura"
                    formClassName={classes.formGroup}
                  />
                </Labeled>
                <DateField
                  record={r}
                  source="aberto_em"
                  label="Aberto em"
                  showTime
                />
                <DateField
                  record={r}
                  source="fechado_em"
                  label="Fechado em"
                  showTime
                />
                <Labeled label="Troco inicial">
                  <CurrencyField record={r} source="valor_inicial" />
                </Labeled>
                <Labeled label="Troco prróximo">
                  <CurrencyField record={r} source="valor_proximo" />
                </Labeled>
                <Labeled label="Último movimento">
                  <TextField record={r} source="ultimo_mov" />
                </Labeled>
                <Labeled label="Status">
                  <CaixaStatusField record={r} />
                </Labeled>
                <Labeled label="Status da sincronização">
                  <CaixaSincField record={r} />
                </Labeled>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6" gutterBottom>
            Resumo das moedas
          </Typography>
          <Card sx={{ minWidth: 400 }}>
            <CardContent>
              <Datagrid
                data={keyBy(r.valores, "id")}
                ids={r.valores.map(({ id }) => id)}
                currentSort={{ field: "id", order: "ASC" }}
                loaded={r.valores.length > 0}
                summary={true}
              >
                <FunctionField
                  label="Moeda"
                  render={(record) =>
                    record.moeda ? record.moeda.nome : record.moedaId
                  }
                />

                <CurrencyField
                  source="valor_contabilizado"
                  label="Valor contabilizado"
                  textAlign="right"
                />

                <FormDataConsumer label="Valor informado" textAlign="right">
                  {({ record }) => {
                    const index = r.valores.findIndex((e, i) => {
                      return e === record;
                    });
                    const source = `valores[${index}].valor_corrigido`;

                    return (
                      <NumberInput
                        variant="standard"
                        source={source}
                        label=""
                        className={classes.input}
                        validate={validateRS}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">R$</InputAdornment>
                          ),
                        }}
                      />
                    );
                  }}
                </FormDataConsumer>

                <FormDataConsumer label="Diferença" textAlign="right">
                  {({ formData, record }) => {
                    const index = r.valores.findIndex((e, i) => {
                      return e === record;
                    });
                    const source = `valores[${index}].valor_corrigido`;
                    const value = get(formData, source);

                    return (
                      <DiffField
                        diff={value - record.valor_contabilizado}
                        classes={classes}
                      />
                    );
                  }}
                </FormDataConsumer>

                <Fragment>
                  <TableRow
                    key="item-total"
                    sx={{
                      "td, th": { border: 0 },
                    }}
                  >
                    <TableCell align="right">
                      <strong>Total</strong>
                    </TableCell>
                    <CellNum
                      align="right"
                      value={sumBy(r.valores, "valor_contabilizado")}
                      classes={classes}
                      bold={true}
                    />

                    <FormDataConsumer>
                      {({ formData }) => {
                        return (
                          <Fragment>
                            <CellNum
                              align="right"
                              value={sumBy(formData.valores, "valor_corrigido")}
                              classes={classes}
                              bold={true}
                            />
                            <CellNum
                              align="right"
                              value={
                                sumBy(formData.valores, "valor_corrigido") -
                                sumBy(r.valores, "valor_contabilizado")
                              }
                              classes={classes}
                              bold={true}
                              diff={true}
                            />
                          </Fragment>
                        );
                      }}
                    </FormDataConsumer>
                  </TableRow>
                </Fragment>
              </Datagrid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom>
            Detalhes do fechamento
          </Typography>
          <CaixaRelatorio record={r} />
        </Grid>
      </Grid>

      <TextInput
        source="observacao"
        label="Observação"
        fullWidth
        multiline
        validate={validateObservacao}
      />
      <CaixaStatusInput label="Status" />
    </SimpleForm>
  );
}

export default CaixaForm;
