import React from "react";
import { NumberInput, Filter } from "react-admin";

const CertificadoDigitalFilter = (props) => (
  <Filter {...props}>
    <NumberInput source="lojaId" label="Loja id" alwaysOn />
    <NumberInput source="cStat" label="Status" alwaysOn />
  </Filter>
);

export default CertificadoDigitalFilter;
