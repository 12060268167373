import React from "react";
import { TextInput, Filter } from "react-admin";

const MoedaAppFilter = props => (
  <Filter {...props}>
    <TextInput source="nome_like" label="Nome do MoedaApp" alwaysOn />
  </Filter>
);

export default MoedaAppFilter;
