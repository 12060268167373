import { Box, Typography } from "@mui/material";
import React from "react";
import {
  SimpleForm,
  required,
  maxLength,
  BooleanInput,
  minValue,
  TextInput,
  FormDataConsumer,
  Button,
  RadioButtonGroupInput,
} from "react-admin";
import { useForm } from "react-final-form";
import ImageInputPreview from "../../common/form/ImageInputPreview";
import NumberInputSimple from "../../common/form/NumberInputSimple";
import { formatCNPJCPF, formatPhone } from "../../common/util/formatter";
import { withUser } from "../../common/util/hocs";

const validateVias = [required(), minValue(1)];
const validateText = [maxLength(500)];

function CabecalhoPadrao({ loja }) {
  const form = useForm();

  const handleClick = (e) => {
    form.change(
      `cabecalho`,
      `${loja.nome_fantasia}\n${formatPhone(loja.telefone)}\n${formatCNPJCPF(
        loja.cnpj
      )}\n${loja.logradouro}, ${loja.numero}\n${loja.bairro}, ${
        loja.cidade
      } - ${loja.uf} CEP ${loja.cep}\n`
    );
  };

  return (
    <Box sx={{ my: 3 }}>
      <Button
        variant="contained"
        label="Preencher Cabeçalho Padrão"
        onClick={handleClick}
      />
    </Box>
  );
}

function ImpressaoConfigsForm(props) {
  return (
    <SimpleForm {...props}>
      <Typography variant="h6" gutterBottom>
        Fechamento do Caixa
      </Typography>
      <RadioButtonGroupInput
        translateChoice={false}
        label=""
        source="caixa_fechamento_cego"
        choices={[
          { id: false, name: "Imprime todos os valores no fechamento" },
          {
            id: true,
            name: "Imprime apenas o valor informado pelo funcionário",
          },
        ]}
      />

      <Typography variant="h6" gutterBottom sx={{ mt: 5 }}>
        Produção
      </Typography>
      <NumberInputSimple
        source="vias_producao"
        label="Número de vias da produção"
        defaultValue={1}
        validate={validateVias}
      />
      <RadioButtonGroupInput
        translateChoice={false}
        label=""
        source="producao_individual"
        choices={[
          { id: false, name: "Imprime produção nos setores" },
          {
            id: true,
            name: "Imprime tickets para trocar pelos produtos",
          },
        ]}
      />

      <Typography variant="h6" gutterBottom sx={{ mt: 5 }}>
        Conferência
      </Typography>
      <NumberInputSimple
        source="vias_conferencia"
        label="Número de vias da conferência"
        defaultValue={1}
        validate={validateVias}
      />
      <BooleanInput
        source="imprime_produto_zero"
        label="Imprime produtos com valor zero"
        fullWidth
      />
      <BooleanInput
        source="imprime_adicional_zero"
        label="Imprime adicionais com valor zero"
        fullWidth
      />

      <FormDataConsumer>
        {(formDataProps) => (
          <ImageInputPreview
            record={props.record}
            formData={formDataProps.formData}
          />
        )}
      </FormDataConsumer>

      <CabecalhoPadrao loja={props.loja} />

      <TextInput
        source="cabecalho"
        label="Cabeçalho da impressão"
        validate={validateText}
        multiline={true}
        fullWidth
      />

      <TextInput
        source="rodape"
        label="Rodapé da impressão"
        validate={validateText}
        multiline={true}
        fullWidth
      />
      <span>
        * Cada linha do cabeçalho e rodapé será centralizada no momento da
        imrpessão.
      </span>
    </SimpleForm>
  );
}

export default withUser(ImpressaoConfigsForm);
