import React from "react";
import {
  SimpleForm,
  TextInput,
  ArrayField,
  Datagrid,
  TextField,
  required,
  useNotify,
  ReferenceManyField,
  DateField,
} from "react-admin";
import DeleteIcon from "@material-ui/icons/Delete";
import { IdDates } from "../../common/form/ConditionalInputs";
import LoadingButtonWithRecord from "../../common/LoadingButtonWithRecord";
import { UsuarioTipoField } from "./UsuarioTipoInput";
import LinkToLoja from "./LinkToLoja";
import { fetchJSON } from "../../dataProvider";

const validateReq = [required()];

function UsuariosForm(props) {
  const notify = useNotify();

  const onClickDelete = async ({ record }) => {
    try {
      await fetchJSON("user_lojas/del", {
        method: "DELETE",
        body: JSON.stringify({
          id: record.id,
          lojaId: record.lojaId,
        }),
      });
      notify(`Acesso removido com sucesso`);
    } catch (err) {
      notify("Erro ao deletar acesso", "warning");
      console.error(err);
    }
  };

  return (
    <SimpleForm {...props} redirect="list">
      <IdDates />

      <TextInput
        source="email"
        label="Email"
        validate={validateReq}
        fullWidth
      />

      <ArrayField source="lojas" label="Lojas" fullWidth>
        <Datagrid>
          <TextField source="lojaId" />
          <TextField source="loja.nome_fantasia" />
          <UsuarioTipoField source="type" />
          <LinkToLoja />
          <LoadingButtonWithRecord
            color="secondary"
            text="Deletar"
            icon={<DeleteIcon />}
            onClick={onClickDelete}
            withRecord={true}
            style={{ color: "#f44336" }}
          />
        </Datagrid>
      </ArrayField>

      <ReferenceManyField
        reference="Nps"
        target="userId"
        label="Pesquisa de satisfação"
        fullWidth
      >
        <Datagrid>
          <TextField source="id" label="#" />
          <TextField source="score" />
          <TextField source="feedback" />
          <DateField source="createdAt" showTime locales="pt-BR" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleForm>
  );
}

export default UsuariosForm;
