import {
  Box,
  Button,
  CardActions,
  CardContent,
  Typography,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import {
  FormWithRedirect,
  TextInput,
  ReferenceInput,
  SelectInput,
  RadioButtonGroupInput,
  PasswordInput,
  CheckboxGroupInput,
  SaveButton,
  required,
  email,
  minLength,
  maxLength,
  useNotify,
  Notification,
} from "react-admin";
import get from "lodash/get";
import React from "react";
import { Wizard, Steps, Step } from "react-albus";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useFormState } from 'react-final-form';
import CepInput from '../common/form/CEPInput';
import { EnderecoNumeroInput, TelefoneInput, CNPJInput, CPFInput } from "../common/form/NumberMaskInput";
import { isValidCNPJ, isValidCPF } from "../common/util/validations";
import { fetchJSON } from "../dataProvider";
import smillingPeopleUrl from "../static/media/smilling_people.jpg";
import pallasLogo from '../static/media/pallas-logo-horizontal.svg';

const validateReq = [required()];
const validateTelefone = [required(), minLength(10), maxLength(11)];
const validateEmail = [required(), email()];
const validateCPF = [required(), isValidCPF];
const validateCNPJ = [required(), isValidCNPJ];
const validateSenha = [required(), minLength(6)];

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "row",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  img: {
    display: 'none',
    minHeight: "100vh",
    width: '40%',
    backgroundImage: `url(${smillingPeopleUrl})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  wizardContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "space-evenly",
    maxWidth: '500px',
    margin: 'auto',
  },
  wizardButtons: {
    display: "flex",
    justifyContent: "space-between",
    padding: "2em 0 0",
  },
  wizardFirstStepButtons: {
    padding: "2em 0 0",
    justifyContent: "flex-end",
  },
  pallasLogo: {
    display: "flex",
    justifyContent: "center",
  },
  pallasLogoImage: {
    maxWidth: 200,
  },
  divTitle: {
    marginTop: '10%',
    marginBottom: '10%',
  },
  title: {
    fontSize: '25px'
  },
  step: {
    fontSize: '15px'
  },
  "@media (min-width: 768px)": {
    img: {
      display: 'block',
    },
    pallasLogoImage: {
      maxWidth: 270,
    },
    title: {
      fontSize: '35px'
    },
    step: {
      fontSize: '20px'
    },
  },
}));

const RegisterWizard = () => {
  const classes = useStyles();
  const notify = useNotify();
  const { search } = useLocation();

  const params = queryString.parse(search);
  const resale_pallas_code = Number(get(params, "resale_pallas_code", 0));
  const login_on_success = get(params, "login_on_success", 'false');

  const handleSubmit = async (values) => {
    try {
      await fetchJSON("Lojas/createVisitor", {
        method: "POST",
        body: JSON.stringify({ ...values, resale_pallas_code }),
      });
      notify("Cadastro efetuado com sucesso.", "success");

      if (login_on_success === 'true') {
        await fetchJSON(`users/login`, {
          method: "POST",
          body: JSON.stringify({
            email: values.email,
            password: values.password
          })
        }).then(json => {
          localStorage.setItem('token', json.id);
          window.location.href = window.location.hostname;
        });
      }
    } catch (error) {
      console.log(error);
      notify("Erro ao realizar o cadastro.", "warning");
    }
  };

  const CPFCNPJInput = props => {
    const { values } = useFormState();
    if (values.cpf_cnpj === 'cpf') {
      return (
        <TextInput
          source="cnpj"
          label="CPF"
          validate={validateCPF}
          InputProps={{
            inputComponent: CPFInput,
          }}
        />
      )
    }

    if (values.cpf_cnpj === 'cnpj') {
      return (
        <TextInput
          source="cnpj"
          label="CNPJ"
          validate={validateCNPJ}
          InputProps={{
            inputComponent: CNPJInput,
          }}
        />
      )
    }

    return null;
  };

  const EnderecoField = props => {
    const { values } = useFormState();
    const { cep, logradouro, bairro, cidade, uf } = values;

    if (cep && logradouro) {
      return (
        <Typography variant="subtitle2" align="center">
          {logradouro}
          <br />
          {`${bairro} - ${cidade} - ${uf}`}
        </Typography>
      )
    }

    return (
      <Typography variant="subtitle2" align="center">
        Após preencher o CEP, seu endereço aparece aqui. ;)
      </Typography>
    );
  };

  const AceitoTermosInput = props => {
    return (
      <span className="text-muted">
        Aceito{" "}
        <a
          href="https://www.sistemapallas.com.br/politica-de-privacidade.html"
          rel="noopener noreferrer"
          target="_blank"
        >
          Termos de Uso e Política de Privacidade
        </a>
        .
      </span>
    );
  };

  const Header = ({ title, step }) => {
    return (
      <>
        <div className={classes.pallasLogo}>
          <img
            src={pallasLogo}
            className={classes.pallasLogoImage}
            alt="Sistema Pallas"
          />
        </div>
        <div className={classes.divTitle}>
          <Typography variant="subtitle1" align="center" className={classes.step}>
            {step}
          </Typography>
          <Typography variant="h4" align="center" className={classes.title}>
            {title}
          </Typography>
        </div>
      </>
    )
  };

  return (
    <div className={classes.main}>
      <div className={classes.img} />
      <Wizard>
        <FormWithRedirect save={handleSubmit} toolbar={null} submitOnEnter={false} render={formProps => (
          <Steps>
            <Step
              id="personal-data"
              render={({ next, previous, step: { id: stepId } }) => (
                <CardContent className={classes.wizardContent}>
                  <Header
                    step="Etapa 1 de 4"
                    title="Conte-nos sobre você"
                  />
                  <TextInput
                    source="nome_contato"
                    label="Nome contato"
                    validate={validateReq}
                  />
                  <TextInput
                    source="email"
                    label="Email"
                    validate={validateEmail}
                  />
                  <TextInput
                    source="telefone_contato"
                    label="Telefone"
                    validate={validateTelefone}
                    InputProps={{
                      inputComponent: TelefoneInput,
                      startAdornment: <InputAdornment position="start">+55</InputAdornment>
                    }}
                  />
                  <StepsButton
                    next={next}
                    previous={previous}
                    stepId={stepId}
                    formProps={formProps}
                  />
                </CardContent>
              )}
            />
            <Step
              id="business-data-1"
              render={({ next, previous, step: { id: stepId } }) => (
                <CardContent className={classes.wizardContent}>
                  <Header
                    step="Etapa 2 de 4"
                    title="Conte-nos sobre seu negócio"
                  />
                  <TextInput
                    source="nome_fantasia"
                    label="Nome do estabelecimento"
                    validate={validateReq}
                  />
                  <Box mx="auto">
                    <RadioButtonGroupInput
                      source="cpf_cnpj"
                      label=""
                      validate={validateReq}
                      choices={[
                        { id: 'cpf', name: 'CPF' },
                        { id: 'cnpj', name: 'CNPJ' }
                      ]}
                    />
                  </Box>
                  <CPFCNPJInput />
                  <ReferenceInput
                    label="Segmento"
                    source="segmentoId"
                    reference="segmentos"
                    sort={{ field: 'nome', order: 'ASC' }}
                  >
                    <SelectInput
                      optionText="nome"
                      validate={validateReq}
                    />
                  </ReferenceInput>
                  <StepsButton
                    next={next}
                    previous={previous}
                    stepId={stepId}
                    formProps={formProps}
                  />
                </CardContent>
              )}
            />
            <Step
              id="business-data-2"
              render={({ next, previous, step: { id: stepId } }) => (
                <CardContent className={classes.wizardContent}>
                  <Header
                    step="Etapa 3 de 4"
                    title="Qual o endereço do seu negócio"
                  />
                  <CepInput
                    source="cep"
                    label="CEP"
                    validate={validateReq}
                  />
                  <Box mb={2}>
                    <EnderecoField />
                  </Box>
                  <TextInput
                    source="numero"
                    label="Número"
                    validate={validateReq}
                    fullWidth
                    InputProps={{
                      inputComponent: EnderecoNumeroInput,
                    }}
                  />
                  <TextInput
                    source="complemento"
                    label="Complemento"
                    fullWidth
                  />
                  <StepsButton
                    next={next}
                    previous={previous}
                    stepId={stepId}
                    formProps={formProps}
                  />
                </CardContent>
              )}
            />
            <Step
              id="credentials-data"
              render={({ next, previous, step: { id: stepId } }) => (
                <CardContent className={classes.wizardContent}>
                  <Header
                    step="Etapa 4 de 4"
                    title="Defina uma senha"
                  />
                  <PasswordInput
                    source="password"
                    label="Senha"
                    validate={validateSenha}
                  />
                  <CheckboxGroupInput
                    label=""
                    source="category"
                    choices={[
                      { id: 'aceite_termos', name: true },
                    ]}
                    validate={validateReq}
                    optionText={<AceitoTermosInput />}
                  />
                  <StepsButton
                    next={next}
                    previous={previous}
                    stepId={stepId}
                    formProps={formProps}
                  />
                </CardContent>
              )}
            />
          </Steps>
        )} />
      </Wizard>
      <Notification />
    </div>
  );
};

function StepsButton({ next, previous, stepId, formProps }) {
  const classes = useStyles();
  const isLastStep = stepId === "credentials-data";
  const isNotFirstStep = stepId !== "personal-data";
  return (
    <CardActions
      className={
        isNotFirstStep ? classes.wizardButtons : classes.wizardFirstStepButtons
      }
    >
      {isNotFirstStep && (
        <Button variant="contained" onClick={previous}>
          Voltar
        </Button>
      )}
      {isLastStep ? (
        <SaveButton
          label="Finalizar cadastro"
          disabled={formProps.invalid}
          {...formProps}
        />
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={next}
          disabled={formProps.invalid}
        >
          Próximo
        </Button>
      )}
    </CardActions>
  );
}

export default RegisterWizard;
