import React from "react";
import { NumberInput, Filter } from "react-admin";

const IFoodOauthVisitorFilter = (props) => (
  <Filter {...props}>
    <NumberInput source='lojaId' label='Loja id' alwaysOn />
  </Filter>
);

export default IFoodOauthVisitorFilter;
