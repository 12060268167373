import React from "react";
import { SelectInput, SelectField } from "react-admin";

const style = {
  minWidth: 0,
}

const diasSemana = [
  { id: 0, name: 'Domingo' },
  { id: 1, name: 'Segunda' },
  { id: 2, name: 'Terça' },
  { id: 3, name: 'Quarta' },
  { id: 4, name: 'Quinta' },
  { id: 5, name: 'Sexta' },
  { id: 6, name: 'Sábado' }
]

function DiaSemanaInput(props) {
  return <SelectInput 
    choices={diasSemana}
    style={style}
    {...props}
  />
}

function DiaSemanaField(props) {
  return <SelectField 
    choices={diasSemana}
    {...props}
  />
}

function DiaSemanaIntervaloField(props) {
  const { ini, fim, record } = props;
  const i = record[ini];
  const f = record[fim];
  const di = diasSemana.find(d => d.id === i);
  const df = diasSemana.find(d => d.id === f);

  if (i === f) {
    return <div>
      {di.name}
    </div>
  }

  return <div>
    {`${di.name} até ${df.name}`}
  </div>
}

export {
  DiaSemanaInput,
  DiaSemanaField,
  DiaSemanaIntervaloField
};
