import React from "react";
import { useForm } from "react-final-form";
import { useInput, TextInput, minLength, maxLength } from "react-admin";
import { InputAdornment } from "@material-ui/core/";
import { CEPInput } from "./NumberMaskInput";
import { SmallCircularProgress } from "../CircularProgress";
import UXDelay from "../../dataProvider/uxdelay";

const validate = [minLength(8), maxLength(8)];

function CepInput(props) {
  const form = useForm();
  const [pesquisando, setPesquisando] = React.useState(false);
  const [erro, setErro] = React.useState(undefined);

  const {
    input: { name, onChange },
  } = useInput(props);

  const onCepChange = (event) => {
    onChange(event);
    pesquisaCep(event.target.value);
  };

  const pesquisaCep = (cep) => {
    cep = cep.trim();
    if (cep.length < 8 || pesquisando) {
      return;
    }

    setPesquisando(true);

    UXDelay(
      fetch(`https://viacep.com.br/ws/${cep}/json/`).then((response) =>
        response.json()
      ),
      1000
    )
      .then((data) => {
        if (data.cep) {
          const prefix = name.replace("cep", "");
          form.change(`${prefix}logradouro`, data.logradouro);
          form.change(`${prefix}bairro`, data.bairro);
          form.change(`${prefix}cidade`, data.localidade);
          form.change(`${prefix}uf`, data.uf);
          form.change(`${prefix}codigo_municipio`, data.ibge);
          setErro(undefined);
        } else {
          setErro(`CEP ${cep} não encontrado`);
        }
      })
      .catch((error) => {
        console.error(error);
        setErro("Serviço de pesquisa de CEP indisponível no momento.");
      })
      .finally(() => setPesquisando(false));
  };

  return (
    <TextInput
      {...props}
      validate={validate}
      onChange={onCepChange}
      helperText={erro}
      InputProps={{
        inputComponent: CEPInput,
        endAdornment: pesquisando ? (
          <InputAdornment position="start">
            <SmallCircularProgress />
          </InputAdornment>
        ) : null,
      }}
    />
  );
}

export default CepInput;
