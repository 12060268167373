import React from "react";
import { Edit } from "react-admin";

import CaixaForm from "./CaixaForm";
import CaixaToolbar from "./CaixaToolbar";

const WrappedCaixaForm = ({ record, ...rest }) => {
  const valores = record.valores
    ? record.valores.map((v) => ({
        ...v,
        valor_corrigido:
          v.valor_corrigido || v.valor_corrigido === 0
            ? v.valor_corrigido
            : v.valor_informado,
      }))
    : [];

  return (
    <CaixaForm
      toolbar={<CaixaToolbar />}
      record={{ ...record, valores }}
      {...rest}
    />
  );
};

const CaixaEdit = (props) => (
  <Edit {...props}>
    <WrappedCaixaForm />
  </Edit>
);

export default CaixaEdit;
