import React from 'react'
import TextField from '@material-ui/core/TextField'

const TextInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    divStyle,
    ...props
}) => (
    <div className={divStyle}>
        <TextField
            error={!!(touched && error)}
            helperText={touched && error}
            {...inputProps}
            {...props}
            variant="filled"
        />
    </div>
);

export default TextInput;