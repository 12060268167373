import React from "react";
import { Edit } from "react-admin";
import CentroForm from "./CentroForm";

const CentroEdit = (props) => (
  <Edit {...props}>
    <CentroForm />
  </Edit>
);

export default CentroEdit;
