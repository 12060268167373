import React, { useState } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import { useNotify, useRefresh } from "react-admin";
import { NFeAutorizada } from "../calculos";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  Typography,
  Button,
  Alert,
} from "@mui/material";
import { cleanCache, fetchJSON } from "../../../dataProvider";
import AlertDialog from "../../../common/AlertDialog";
import LoadingButton from "../../../common/LoadingButton";
import { withUser } from "../../../common/util/hocs";

const NFeCancelaButton = ({ record, loja }) => {
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [motivo, setMotivo] = React.useState("");
  const [helper, setHelper] = React.useState("");
  const [nfe, setNFe] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const notify = useNotify();
  const refresh = useRefresh();

  if (!NFeAutorizada(record)) return null;

  const prazoHorasEstados = {
    AC: 24,
    AL: 24,
    AP: 24,
    AM: 24,
    BA: 24,
    CE: 24,
    DF: 24,
    ES: 24,
    GO: 24,
    MA: 24,
    MT: 2,
    MS: 24,
    MG: 24,
    PA: 24,
    PB: 24,
    PR: 168,
    PE: 24,
    PI: 1440,
    RJ: 24,
    RN: 24,
    RS: 168,
    RO: 720,
    RR: 24,
    SC: 24,
    SP: 24,
    SE: 24,
    TO: 24,
  };

  const prazoHorasEstado = prazoHorasEstados[loja.uf];
  const emitidaHa = (new Date() - new Date(record.emitida_em)) / 36e5;
  const foraPrazo = emitidaHa > prazoHorasEstado;

  const minLen = 10;
  const handleOpenClick = () => setShowDialog(true);
  const handleCloseClick = () => setShowDialog(false);

  const handleChangeText = (event) => {
    setMotivo(event.target.value);
    if (event.target.value.length > minLen) {
      setHelper("");
    }
  };

  const handleSave = async () => {
    if (motivo.length < 10) {
      const msg = `Preencha o motivo do cancelamento com no mínimo ${minLen} caracteres!`;
      setHelper(msg);
      notify(msg);
      return;
    }

    try {
      setLoading(true);
      const nfe = await fetchJSON("Sefaz/cancelaNFe", {
        method: "POST",
        body: { lojaId: record.lojaId, nfeId: record.id, motivo },
      });

      setNFe(nfe);
    } catch (exception) {
      console.log(JSON.stringify(exception));
      setLoading(false);
      setErrorMessage(exception.body.error.message);
    }
  };

  const handleCloseAlert = () => {
    setNFe(null);
    setShowDialog(false);
    cleanCache("NFe_emissaos");
    refresh();
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleOpenClick}
        startIcon={<ClearIcon />}
      >
        Cancelar
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>NFe Cancelamento</DialogTitle>
        <DialogContent>
          {foraPrazo && (
            <Alert
              sx={{ mb: 3 }}
              severity="error"
            >{`O prazo máximo para cancelamento de uma NF-e no ${loja.uf} é de ${prazoHorasEstado} horas. 
    Após o prazo regulamentar de ${prazoHorasEstado} horas, os Pedidos de Cancelamento de NF-e transmitidos à Secretaria da Fazenda serão recebidos via sistema, porém neste segundo caso o emitente fica sujeito à penalidade prevista no item z1 do Inciso IV do artigo 527 do Regulamento do ICMS.`}</Alert>
          )}

          <Typography variant="body1" gutterBottom sx={{}}>
            Motivo do Cancelamento:
          </Typography>

          <TextField
            fullWidth
            multiline
            rows={4}
            value={motivo}
            onChange={handleChangeText}
            helperText={helper}
            error={!!helper}
            inputProps={{ maxLength: 512 }}
          />

          {errorMessage && (
            <Typography
              variant="body1"
              gutterBottom
              sx={{ my: 3, color: "red" }}
            >
              {errorMessage}
            </Typography>
          )}

          <DialogActions>
            <LoadingButton
              variant="contained"
              text="Cancelar NFe"
              onClick={handleSave}
              loading={loading}
              icon={<ClearIcon />}
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
      <AlertDialog
        title="Aviso"
        message={nfe ? `${nfe.cStat} - ${nfe.xMotivo}` : ""}
        open={!!nfe}
        handleClose={handleCloseAlert}
      />
    </>
  );
};

export default withUser(NFeCancelaButton);
