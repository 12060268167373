import React from "react";
import { Create } from "react-admin";
import GrupoForm from "./GrupoForm";

const GrupoCreate = (props) => (
  <Create {...props}>
    <GrupoForm />
  </Create>
);

export default GrupoCreate;
