import React, { useState } from "react";
import { Button, useNotify, useDataProvider } from "react-admin";
import DeleteIcon from "@material-ui/icons/Delete";
import { fetchJSON } from "../../dataProvider";

function NFeCancelarEntradaButton({ record }) {
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const handleClick = async () => {
    setLoading(true);
    try {
      await fetchJSON("nfes/cancelarEntrada", {
        method: "POST",
        body: JSON.stringify({
          nfeId: record.id,
          lojaId: record.lojaId,
        }),
      });

      await dataProvider.update("nfes", {
        onlyUpdateLocalStore: true,
        data: {
          ...record,
          entrada: false,
        },
      });

      notify("Entrada da NFe cancelada com sucesso");
    } catch (err) {
      notify("Erro ao cancelar entrada da NFe", "warning");
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      label="Cancelar entrada"
      onClick={handleClick}
      disabled={loading}
      style={{ color: "#f44336" }}
    >
      <DeleteIcon />
    </Button>
  );
}

export default NFeCancelarEntradaButton;
