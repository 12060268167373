import React from "react";
import {
  Datagrid,
  List,
  TextField,
  NumberField,
  ReferenceField,
  DateField,
} from "react-admin";
import CopyPropButton from "../../common/form/CopyPropButton";

import IFoodOauthFilter from "./IFoodOauthFilter";

const IFoodOauthList = (props) => (
  <List
    {...props}
    filters={<IFoodOauthFilter />}
    sort={{ field: "id", order: "DESC" }}
    exporter={false}
  >
    <Datagrid>
      <NumberField source="lojaId" label="Loja id" />
      <ReferenceField source="lojaId" reference="lojas">
        <TextField source="nome_fantasia" />
      </ReferenceField>
      <CopyPropButton source="tokenJson" />
      <CopyPropButton source="userCodeJson" />
      <TextField source="filterMerchantIds" label="iFood Lojas" />
      <DateField source="createdAt" showTime={true} />
    </Datagrid>
  </List>
);

export default IFoodOauthList;
