import PropTypes from 'prop-types';
import React from "react";
import { Button } from "react-admin";
import { Link } from "react-router-dom";

const EditableListButton = ({ to, label }) => (
  <Button component={Link} to={to} label={label} />
);

EditableListButton.protoTypes = {
  to: PropTypes.string,
  label: PropTypes.string
}

EditableListButton.defaultProps = {
  to: "",
  label: ""
}

export default EditableListButton;