import React from "react";
import { Button, Confirm } from "react-admin";
import PeopleIcon from "@material-ui/icons/People";
import { TextField } from "@mui/material";
import { fetchJSON } from "../../dataProvider";
import { withUser } from "../../common/util/hocs";
import { mudaLoja } from "../../app/App";

const LojaConvidarButton = ({ usuario, lojaId }) => {
  const [showDialog, setShowDialog] = React.useState(false);
  const [email, setEmail] = React.useState(usuario.email);
  const [error, setError] = React.useState("");

  const handleConvidar = async () => {
    try {
      await fetchJSON(
        "users/convidar",
        { method: "POST" },
        {
          lojaId,
          email,
          type: 1,
        }
      );

      if (email === usuario.email) {
        mudaLoja(lojaId);
      } else {
        setShowDialog(false);
      }
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
  };

  return (
    <>
      <Button label="Convidar" onClick={() => setShowDialog(true)}>
        <PeopleIcon />
      </Button>
      <Confirm
        isOpen={showDialog}
        title={"Convidar Usuário"}
        maxWidth="lg"
        fullWidth={true}
        content={
          <TextField
            variant="outlined"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            error={!!error}
            helperText={error}
            fullWidth
          />
        }
        confirm={"convidar"}
        onConfirm={handleConvidar}
        onClose={() => setShowDialog(false)}
      />
    </>
  );
};

export default withUser(LojaConvidarButton);
