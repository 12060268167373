import React from "react";
import { RadioButtonGroupInput as Radio } from "react-admin";

function RadioButtonGroupInput({label, source, initialValue, choices}) {
    return <Radio 
        label={label}
        source={source}
        initialValue={initialValue}
        choices={choices} 
    />
}

function RadioButtonGroupInputNewRecord(props) {
    const { record } = props;
    const newRecord = record.id ? false : true;
    return props.newRecord || newRecord
        ? <RadioButtonGroupInput {...props} />
        : null;
}

export { 
    RadioButtonGroupInput,
    RadioButtonGroupInputNewRecord,
};