import React, { Fragment } from "react";
import {
  SimpleForm,
  FormDataConsumer,
  TextInput,
  required,
  Title,
  BooleanInput,
} from "react-admin";
import { Box, Typography } from "@mui/material";
import { TimeInput, DateTimeInput } from "react-admin-date-inputs";
import { IdDates } from "../../common/form/ConditionalInputs";
import { DiaSemanaInput } from "../../common/form/DiaSemanaInput";

const style = {
  disponibiidade: {
    display: "inline-block",
    marginRight: 32,
    width: "7em",
    minWidth: "7em",
  },
  fechado: {
    display: "block",
  },
};

const validateReq = [required()];

function AbertoInputs(props) {
  return (
    <Fragment>
      <Title title=' (Horário de funcionamento)' />
      <Box my={2}>
        <DiaSemanaInput
          source='dia_semana_ini'
          label='De'
          validate={validateReq}
          style={style.disponibiidade}
          fullWidth
        />

        <DiaSemanaInput
          source='dia_semana_fim'
          label='Até'
          validate={validateReq}
          style={style.disponibiidade}
          fullWidth
        />

        <div style={style.disponibiidade}>
          <TimeInput
            label='Das'
            source='inicio'
            validate={validateReq}
            options={{ format: "HH:mm", ampm: false, clearable: true }}
            style={style.disponibiidade}
          />
        </div>

        <div style={style.disponibiidade}>
          <TimeInput
            label='Até'
            source='fim'
            validate={validateReq}
            options={{ format: "HH:mm", ampm: false, clearable: true }}
            style={style.disponibiidade}
          />
        </div>
      </Box>

      <Typography variant='h6' gutterBottom>
        Aceita pedidos do tipo:
      </Typography>
      <BooleanInput source='retirada' label='Retirada' />
      <BooleanInput source='entrega' label='Entrega' />
      <BooleanInput source='mesa' label='Mesa' />
    </Fragment>
  );
}

function FechadoInputs(props) {
  return (
    <Fragment>
      <Title title=' (Pausa programada)' />
      <TextInput
        label='Motivo'
        source='motivo'
        validate={validateReq}
        style={style.fechado}
      />

      <DateTimeInput
        label='Início'
        source='inicio'
        validate={validateReq}
        options={{ format: "dd/MM/yyyy HH:mm", ampm: false, clearable: true }}
        style={style.fechado}
      />

      <DateTimeInput
        label='Fim'
        source='fim'
        validate={validateReq}
        options={{ format: "dd/MM/yyyy HH:mm", ampm: false, clearable: true }}
        style={style.fechado}
      />
    </Fragment>
  );
}

function LojaHorariosForm(props) {
  return (
    <SimpleForm {...props} toolbar={props.toolbar} redirect='list'>
      <IdDates />

      <FormDataConsumer>
        {(formDataProps) =>
          formDataProps.formData.aberto ? <AbertoInputs /> : <FechadoInputs />
        }
      </FormDataConsumer>
    </SimpleForm>
  );
}

export default LojaHorariosForm;
