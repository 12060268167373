import React from "react";
import red from "@material-ui/core/colors/red";
import yellow from "@material-ui/core/colors/yellow";
import lightGreen from "@material-ui/core/colors/lightGreen";
import Chip from "@mui/material/Chip";

const MercadoPagoPreferenceStatus = ({ record }) => {
  if (!record) return null;

  switch (record.status) {
    case "paid":
    case "approved":
      return (
        <Chip
          key="mpp-status-entrada"
          label="Aprovado"
          size="small"
          style={{
            margin: "2px",
            backgroundColor: lightGreen[300],
            borderColor: lightGreen[300],
          }}
        />
      );

    case "reverted":
    case "refunded":
      return (
        <Chip
          key="mpp-status-refund"
          label="Estornado"
          size="small"
          style={{
            margin: "2px",
            backgroundColor: yellow[300],
            borderColor: yellow[300],
          }}
        />
      );

    case "estorno em andamento":
      return (
        <Chip
          key="mpp-status-refund"
          label="Estorno em andamento"
          size="small"
          style={{
            margin: "2px",
            backgroundColor: yellow[300],
            borderColor: yellow[300],
          }}
        />
      );

    case "rejected":
      return (
        <Chip
          key="mpp-status-rejected"
          label="Rejeitado"
          size="small"
          style={{
            margin: "2px",
            backgroundColor: red[200],
            borderColor: red[200],
          }}
        />
      );

    case "payment_required":
    case null:
      return (
        <Chip
          key="mpp-status-aguardando"
          label="Aguardando pagamento"
          size="small"
          style={{
            margin: "2px",
          }}
        />
      );

    default:
      return (
        <Chip
          key="mpp-status-default"
          label={record.status}
          size="small"
          style={{
            margin: "2px",
          }}
        />
      );
  }
};

export default MercadoPagoPreferenceStatus;
