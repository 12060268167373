import React from "react";
import {
  SimpleForm,
  required,
  maxLength,
  TextInput,
  minLength,
} from "react-admin";
import PagarmeTestButton from "./PagarmeTestButton";

const accountIdValidation = (value) => {
  return value && value.startsWith("acc_") ? undefined : "Conteúdo inválido";
};

const secretKeyValidation = (value) => {
  return value && value.startsWith("sk_") ? undefined : "Conteúdo inválido";
};

const validateAccountId = [
  required(),
  minLength(10),
  maxLength(500),
  accountIdValidation,
];
const validateSecretKey = [
  required(),
  minLength(10),
  maxLength(500),
  secretKeyValidation,
];

function PagarmeForm(props) {
  return (
    <SimpleForm {...props}>
      <TextInput
        source="accountId"
        label="Conta ID"
        validate={validateAccountId}
        multiline={true}
        fullWidth
      />
      <TextInput
        source="secretKey"
        label="Chave Secreta"
        validate={validateSecretKey}
        multiline={true}
        fullWidth
      />
      <PagarmeTestButton />
    </SimpleForm>
  );
}

export default PagarmeForm;
