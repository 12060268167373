import React from "react";
import {
  SimpleForm,
  NumberInput,
  TextInput,
  required,
  minLength,
  maxLength,
} from "react-admin";

import { IdDates } from '../../common/form/ConditionalInputs';

const validateNcm = [required(), minLength(8), maxLength(9)];
const validateReq = [required()];

function NcmsForm(props) {
  return (
    <SimpleForm { ...props} toolbar={props.toolbar} redirect="list">
      <IdDates />

      <TextInput 
        source="id" 
        label="Código NCM" 
        validate={validateNcm} />

      <NumberInput 
        source="ex" 
        label="Ex" 
        validate={validateReq}
        defaultValue={0} />

      <NumberInput 
        source="tipo" 
        label="Tipo" 
        validate={validateReq}
        defaultValue={0} />

      <TextInput 
        source="descricao" 
        label="Descrição" 
        validate={validateReq}
        fullWidth
        multiline />

      <NumberInput 
        source="nacional" 
        label="Nacional" 
        validate={validateReq} />

      <NumberInput 
        source="importado" 
        label="Importado" 
        validate={validateReq} />

      <NumberInput 
        source="estadual" 
        label="Estadual" 
        validate={validateReq} />
        
      <NumberInput 
        source="municipal" 
        label="Municipal" 
        validate={validateReq} 
        defaultValue={0} />

    </SimpleForm>
  );
}

export default NcmsForm;