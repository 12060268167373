import React from "react";
import { TextInput, DateTimeInput, Filter, NumberInput } from "react-admin";
import { CaixaStatusInput } from "./CaixaStatus";

const CaixaFilter = (props) => (
  <Filter {...props}>
    <CaixaStatusInput source='status' label='Status' />
    <NumberInput source='aberturaId' label='Número da abertura' />
    <NumberInput source='numero' label='Número do caixa' />
    <TextInput source='operador' label='Operador do caixa' />
    <DateTimeInput source='aberto_em' label='Aberto em' />
    <DateTimeInput source='fechado_em' label='Fechado em' />
  </Filter>
);

export default CaixaFilter;
