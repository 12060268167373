import React from 'react';
import CardActions from '@material-ui/core/CardActions';
import SincronizarButton from './SincronizarButton';

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const EquipamentoEditActions = ({ data }) => (
    <CardActions style={cardActionStyle}>
        <SincronizarButton record={data} refreshPage={true}/>
    </CardActions>
);

export default EquipamentoEditActions;