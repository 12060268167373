import React, { Fragment, useEffect } from "react";
import { required, minValue, maxValue } from "react-admin";
import { useForm } from "react-final-form";
import { InputAdornment } from "@material-ui/core";

import TabPanel from "../../common/form/TabPanel";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import NumberInputSimple from "../../common/form/NumberInputSimple";
import { validateDecimalPlaces } from "../../common/util/ra-validations";

const useStyles = makeStyles((theme) => ({
  formGroup: {
    display: "inline-block",
    marginRight: 32,
    width: "10em",
  },
  box: {
    paddingTop: 16,
    paddingLeft: 16,
  },
  paper: {
    marginBottom: 24,
  },
}));

const validateCusto = [
  required(),
  minValue(0),
  maxValue(99999999.9999),
  validateDecimalPlaces(4),
];
const validateVenda = [
  required(),
  minValue(0),
  maxValue(9999999999.99),
  validateDecimalPlaces(2),
];
const validateQtdMin = [required(), minValue(0)];

const ProdutoCustoMargemVenda = ({
  formData,
  loja,
  sourcePrefix = "",
  variant = "filled",
}) => {
  const classes = useStyles();
  const form = useForm();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { preco_cst_prod, margem, preco_vnd_prod } = formData;

  useEffect(() => {
    if (!margem) {
      calculaMargem(preco_vnd_prod, preco_cst_prod);
    }
  });

  const onChangeCusto = (e) => {
    const novoCusto = e.target.value;
    calculaMargem(preco_vnd_prod, novoCusto);
  };

  const onChangeVenda = (e) => {
    const novaVenda = e.target.value;
    calculaMargem(novaVenda, preco_cst_prod);
  };

  const calculaMargem = (venda, custo) => {
    if (custo > 0 && venda > 0) {
      const novaMargem = Math.round((venda / custo) * 10000 - 10000) / 100;
      form.change(`${sourcePrefix}margem`, novaMargem);
    }
  };

  const onChangeMargem = (e) => {
    const novaMargem = parseFloat(e.target.value) + 100;
    const novaVenda = Math.round(preco_cst_prod * novaMargem) / 100;
    form.change(`${sourcePrefix}preco_vnd_prod`, novaVenda);
  };

  if (loja.tabelasPreco && loja.tabelasPreco.length > 1) {
    return (
      <Fragment>
        <Paper className={classes.paper} elevation={3}>
          <AppBar position="static" color="inherit" elevation={0}>
            <Tabs value={value} onChange={handleChange}>
              {loja.tabelasPreco.map((t) => (
                <Tab label={`R$ ${t.nome}`} />
              ))}
            </Tabs>
          </AppBar>
          <TabPanel className={classes.box} value={value} index={0}>
            <NumberInputSimple
              source={`${sourcePrefix}preco_cst_prod`}
              label="Preço de custo"
              variant={variant}
              validate={validateCusto}
              initialValue={0}
              className={classes.formGroup}
              onChange={onChangeCusto}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
            />

            <NumberInputSimple
              source={`${sourcePrefix}margem`}
              label="Margem"
              variant={variant}
              initialValue={0}
              className={classes.formGroup}
              onChange={onChangeMargem}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />

            <NumberInputSimple
              source={`${sourcePrefix}preco_vnd_prod`}
              label="Preço"
              variant={variant}
              validate={validateVenda}
              initialValue={0}
              className={classes.formGroup}
              onChange={onChangeVenda}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
            />
          </TabPanel>
          <TabPanel className={classes.box} value={value} index={1}>
            <NumberInputSimple
              source={`${sourcePrefix}preco_vnd2_prod`}
              label="Preço"
              variant={variant}
              validate={validateVenda}
              initialValue={0}
              className={classes.formGroup}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
            />
            <NumberInputSimple
              source={`${sourcePrefix}qtd_min_tab2`}
              label="Qtd mínima"
              variant={variant}
              validate={validateQtdMin}
              initialValue={0}
              className={classes.formGroup}
            />
          </TabPanel>
          <TabPanel className={classes.box} value={value} index={2}>
            <NumberInputSimple
              source={`${sourcePrefix}preco_vnd3_prod`}
              label="Preço"
              variant={variant}
              validate={validateVenda}
              initialValue={0}
              className={classes.formGroup}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
            />
            <NumberInputSimple
              source={`${sourcePrefix}qtd_min_tab3`}
              label="Qtd mínima"
              variant={variant}
              validate={validateQtdMin}
              initialValue={0}
              className={classes.formGroup}
            />
          </TabPanel>
          <TabPanel className={classes.box} value={value} index={3}>
            <NumberInputSimple
              source={`${sourcePrefix}preco_vnd4_prod`}
              label="Preço"
              variant={variant}
              validate={validateVenda}
              initialValue={0}
              className={classes.formGroup}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
            />
            <NumberInputSimple
              source={`${sourcePrefix}qtd_min_tab4`}
              label="Qtd mínima"
              variant={variant}
              validate={validateQtdMin}
              initialValue={0}
              className={classes.formGroup}
            />
          </TabPanel>
        </Paper>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <NumberInputSimple
        source={`${sourcePrefix}preco_cst_prod`}
        label="Preço de custo"
        variant={variant}
        validate={validateVenda}
        initialValue={0}
        className={classes.formGroup}
        onChange={onChangeCusto}
        InputProps={{
          startAdornment: <InputAdornment position="start">R$</InputAdornment>,
        }}
      />

      <NumberInputSimple
        source={`${sourcePrefix}margem`}
        label="Margem"
        variant={variant}
        initialValue={0}
        className={classes.formGroup}
        onChange={onChangeMargem}
        InputProps={{
          endAdornment: <InputAdornment position="start">%</InputAdornment>,
        }}
      />

      <NumberInputSimple
        source={`${sourcePrefix}preco_vnd_prod`}
        label={
          loja.tabelasPreco.length > 0 && loja.tabelasPreco[0].nome
            ? loja.tabelasPreco[0].nome
            : "Preço de venda"
        }
        variant={variant}
        validate={validateVenda}
        initialValue={0}
        className={classes.formGroup}
        onChange={onChangeVenda}
        InputProps={{
          startAdornment: <InputAdornment position="start">R$</InputAdornment>,
        }}
      />
    </Fragment>
  );
};

export default ProdutoCustoMargemVenda;
