import React from "react";
import {
  Datagrid,
  TextField,
  NumberField,
  FormDataConsumer,
  FunctionField,
} from "react-admin";
import DataGridRemoveButton from "../../common/form/DataGridRemoveButton";
import { keyBy } from "lodash";
import NFeEditarImpostosButton from "./NFeEditarImpostosButton";
import useChangeCalc from "./useChangeCalc";

export default function NFeEmissaoProdutosGrid({
  loaded,
  record,
  formData,
  loja,
}) {
  const changeCalc = useChangeCalc();
  const data = keyBy(formData.itens, "index");
  const ids = formData.itens.map(({ index }) => index);

  const handleRemoveItem = (source, newValue) => {
    const event = {
      target: {
        name: source,
        value: newValue,
      },
    };
    changeCalc(event);
  };

  return (
    <Datagrid
      loaded={loaded}
      data={data}
      ids={ids}
      currentSort={{ field: "index", order: "ASC" }}
      fullWidth
    >
      <FunctionField render={(record) => record.index + 1} label="Item" />
      <TextField source="codigo" label="Código" />
      <TextField source="descricao" label="Descrição" />
      <NumberField source="valor_unitario" label="Valor unit." />
      <NumberField source="quantidade" label="Quantidade" />
      <NumberField source="valor_total" label="Total" />

      <NumberField source="valor_desconto" label="Desc" />
      <NumberField source="valor_frete" label="Fret" />
      <NumberField source="valor_seguro" label="Segu" />
      <NumberField source="valor_outro" label="Outr" />
      <NumberField source="valor_item" label="Item" />

      <TextField source="ncmId" label="NCM" />
      <TextField source="cestId" label="CEST" />
      <TextField source="cfop" label="CFOP" />

      {!record.xmlUrl && (
        <FormDataConsumer>
          {({ record: itemData }) => (
            <NFeEditarImpostosButton
              record={record}
              loja={loja}
              itemData={itemData}
            />
          )}
        </FormDataConsumer>
      )}

      {!record.xmlUrl && (
        <FormDataConsumer>
          {({ formData, record: itemData }) => (
            <DataGridRemoveButton
              formData={formData}
              record={itemData}
              arrayName="itens"
              onClick={handleRemoveItem}
            />
          )}
        </FormDataConsumer>
      )}

      <TextField source="icms_cst" label="ICMS" />
      <NumberField source="icms_red_bc" label="% Red" />
      <NumberField source="icms_bc" label="BC" />
      <NumberField source="icms_alq" label="%" />
      <NumberField source="icms_v" label="R$" />

      <NumberField source="icms_st_mva" label="% MVA" />
      <NumberField source="icms_st_bc" label="BC" />
      <NumberField source="icms_st_alq" label="%" />
      <NumberField source="icms_st_v" label="R$" />

      <TextField source="pis_cst" label="PIS" />
      <NumberField source="pis_alq" label="%" />
      <NumberField source="pis_v" label="R$" />

      <TextField source="cofins_cst" label="COFINS" />
      <NumberField source="cofins_alq" label="%" />
      <NumberField source="cofins_v" label="R$" />

      <TextField source="ipi_cst" label="IPI" />
      <NumberField source="ipi_alq" label="%" />
      <NumberField source="ipi_v" label="R$" />
    </Datagrid>
  );
}
