import React from "react";
import {
  Grid,
  Box,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Decimal from "decimal.js";
import { makeStyles } from "@material-ui/core/styles";
import LazyReferenceInput from "../../common/form/LazyReferenceInput";
import { AutocompleteInput, required } from "react-admin";

const useStyles = makeStyles({
  input: {
    width: "8em",
    marginRight: "1em",
    cursor: "not-allowed",
  },
});

const validateReq = [required()];

export default function PagamentoTotal({ formData }) {
  const classes = useStyles();
  const contas = formData.contas || [];
  const total_pago = contas
    .filter((c) => c && c.valor && !c.deletar)
    .reduce((t, c) => t.plus(c.valor), new Decimal(0));
  const relationsRequired = contas.some((c) => !c || !c.id);
  const { total_pedido } = formData;
  const falta = total_pago.minus(total_pedido || 0);
  return (
    <Box sx={{ mt: 6 }}>
      {relationsRequired && (
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item>
            <LazyReferenceInput
              label="Classificação"
              source="classificacaoId"
              reference="Conta_classificacoes"
              validate={validateReq}
              addCreateButton={true}
              required
            >
              <AutocompleteInput optionText="nome" />
            </LazyReferenceInput>
          </Grid>
          <Grid item>
            <LazyReferenceInput
              label="Centro de custo"
              source="centroCustoId"
              reference="Conta_centros_custos"
              validate={validateReq}
              addCreateButton={true}
              required={true}
            >
              <AutocompleteInput optionText="nome" />
            </LazyReferenceInput>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2}>
        <Grid item>
          <TextField
            label="Total Pago"
            variant="outlined"
            value={total_pago.toFixed(2)}
            className={classes.input}
            disabled={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Total Pedido"
            variant="outlined"
            value={total_pedido}
            className={classes.input}
            disabled={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Falta"
            variant="outlined"
            value={falta.toFixed(2)}
            className={classes.input}
            disabled={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
        * Para mudar o status do pedido, o total dos pagamentos precisa ser
        idêntico ao total do pedido.
      </Typography>
    </Box>
  );
}
