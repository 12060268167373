import React, { useEffect, useState } from "react";
import { useNotify } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { fetchJSON } from "../../dataProvider";
import NumberInputRemoveArrowsCSS from "../../common/form/NumberInputRemoveArrowsCSS.json";
import CircularProgress from "../../common/CircularProgress";
import uxdelay from "../../dataProvider/uxdelay";
import { formatCNPJ, formatCurrency } from "../../common/util/formatter";
import { green, red, yellow } from "@mui/material/colors";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import LoadingButton from "../../common/LoadingButton";

const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-sizeSmall": {
      padding: "6px 12px 6px 12px",
    },
  },
  vendaInput: {
    width: "5em",
    ...NumberInputRemoveArrowsCSS,
  },
});

const ProdutoMatrizFilialModalPreco = ({
  usuario,
  loja,
  produtosIds,
  openDialog,
  handleClose,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [produtosPrecos, setProdutosPrecos] = useState(null);
  const notify = useNotify();

  const handleCloseDialog = () => {
    handleClose();
    setLoading(false);
    setData(null);
    setProdutosPrecos(null);
  };

  useEffect(() => {
    if (!openDialog) return;
    setLoading(true);
    setData(null);
    setProdutosPrecos(null);

    uxdelay(
      fetchJSON(`Loja_filials/filiaisProdutos`, null, {
        lojaId: loja.id,
        produtosIds: JSON.stringify(produtosIds),
      })
    )
      .then((response) => {
        const precos = [];
        for (const produto of response.produtosMatriz) {
          const newProd = {
            matrizId: produto.lojaId,
            produtoIdMatriz: produto.id,
            cod_prod: produto.cod_prod,
            desc_prod: produto.desc_prod,
            lojas: {},
          };
          precos.push(newProd);
          for (const filialId of response.lojasIds) {
            const filialProd = response.produtosLojas
              .filter(
                (p) => p.cod_prod === produto.cod_prod && p.lojaId === filialId
              )
              .firstOrDefault({
                preco_vnd_prod: null,
                preco_vnd2_prod: null,
                preco_vnd3_prod: null,
                preco_vnd4_prod: null,
                appPreco: null,
                lojaId: filialId,
              });

            newProd.lojas[filialId] = {
              servidor: { ...filialProd },
              front: { ...filialProd },
            };
          }
        }

        setData(response);

        setProdutosPrecos(precos);
      })
      .catch((err) => {
        console.log(err);
        notify("Erro ao recuperar produtos das filiais", "warning");
      })
      .finally(() => setLoading(false));
  }, [openDialog, produtosIds, loja.id, notify]);

  const filiais = data
    ? data.lojasIds.map((lid) => usuario.lojas.find((l) => l.id === lid))
    : [];

  const changePrecoProduto = (produto, lojaId, newValue) => {
    const propToChange = "preco_vnd_prod";

    const { servidor, front } = produto.lojas[lojaId];
    front[propToChange] = newValue;

    const oldValue = servidor[propToChange];

    if (servidor.id) {
      if (newValue === "") {
        front.status = {
          title: `Excluir ${formatCurrency(oldValue)}`,
          color: red[500],
          icon: "",
        };
      } else {
        if (newValue > oldValue) {
          front.status = {
            title: `Subiu ${formatCurrency(oldValue)}`,
            color: green[500],
            icon: <ArrowUpwardIcon />,
          };
        } else if (oldValue > newValue) {
          front.status = {
            title: `Baixou ${formatCurrency(oldValue)}`,
            color: red[500],
            icon: <ArrowDownwardIcon />,
          };
        } else {
          front.status = null;
        }
      }
    } else {
      if (newValue === "") {
        front.status = null;
      } else {
        front.status = {
          title: "Novo",
          color: yellow[500],
          icon: "",
        };
      }
    }
  };

  const handleChange = (produto, lojaId) => (e) => {
    const newValue = e.target.value;
    changePrecoProduto(produto, lojaId, newValue);
    setProdutosPrecos([...produtosPrecos]);
  };

  const handleCopyToAll = () => {
    for (const produto of produtosPrecos) {
      for (const filial of filiais) {
        const newValue = produto.lojas[loja.id].front.preco_vnd_prod;
        changePrecoProduto(produto, filial.id, newValue);
      }
    }

    setProdutosPrecos([...produtosPrecos]);
  };

  const handleSave = async () => {
    try {
      setLoading(true);

      const produtosData = [];
      for (const filial of filiais) {
        const produtosFilialData = produtosPrecos
          .filter((p) => !!p.lojas[filial.id].front.status)
          .map((p) => ({
            matrizId: p.matrizId,
            filialId: filial.id,
            produtoIdMatriz: p.produtoIdMatriz,
            produtoIdFilial: p.lojas[filial.id].front.id,
            preco_vnd_prod: p.lojas[filial.id].front.preco_vnd_prod,
          }));

        produtosData.push(...produtosFilialData);
      }

      await fetchJSON("Loja_filials/filiaisClonaProdutos", {
        method: "POST",
        body: {
          lojaId: loja.id,
          produtosData,
        },
      });

      handleCloseDialog();
    } catch (err) {
      console.log(err);
      notify("Erro ao enviar produtos para as filiais", "warning");
    } finally {
      setLoading(false);
    }
  };

  const produtosSelecionados = produtosPrecos ? produtosPrecos.length : 0;

  const configValid = filiais.length > 1 ? true : false;

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={openDialog}
      onClose={handleCloseDialog}
    >
      <DialogTitle>{`Envio de produtos para filiais (${produtosSelecionados} Produtos)`}</DialogTitle>
      {loading && <CircularProgress />}
      {produtosPrecos && configValid && (
        <TableContainer sx={{ m: "auto" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Button variant="contained" onClick={handleCopyToAll}>
                    Copiar preço para todas as lojas
                  </Button>
                </TableCell>
                {filiais.map((filial) => (
                  <TableCell key={`cell-header-${filial.id}`} sx={{ p: "8px" }}>
                    <Tooltip
                      title={`${filial.nome_fantasia} ${formatCNPJ(
                        filial.cnpj
                      )}`}
                    >
                      <Avatar
                        src={filial.fotoUrl}
                        sx={{ width: 80, height: 80 }}
                      />
                    </Tooltip>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {produtosPrecos.map((produto) => (
                <TableRow key={produto.id}>
                  <TableCell component="th" scope="row">
                    <Typography variant="body1">
                      <strong>{produto.desc_prod}</strong>
                    </Typography>
                    <Typography variant="body2">{produto.cod_prod}</Typography>
                  </TableCell>
                  {filiais.map((filial) => (
                    <TableCell key={`cell-body-${filial.id}-${produto.id}`}>
                      <TextField
                        type="number"
                        variant="standard"
                        className={classes.vendaInput}
                        onChange={handleChange(produto, filial.id)}
                        value={produto.lojas[filial.id].front.preco_vnd_prod}
                      />
                      {produto.lojas[filial.id].front.status && (
                        <Tooltip
                          title={produto.lojas[filial.id].front.status.title}
                        >
                          <Avatar
                            sx={{
                              display: "inline-block",
                              bgcolor:
                                produto.lojas[filial.id].front.status.color,
                              width: 24,
                              height: 24,
                            }}
                          >
                            {produto.lojas[filial.id].front.status.icon}
                          </Avatar>
                        </Tooltip>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {configValid && (
        <DialogActions>
          <LoadingButton
            variant="contained"
            loading={loading}
            text="Enviar para filiais"
            onClick={handleSave}
          />
        </DialogActions>
      )}
      {!configValid && data !== null && (
        <Alert severity="error" sx={{ m: 2 }}>
          <AlertTitle>Erro</AlertTitle>
          Lojas filiais não configuradas.
        </Alert>
      )}
    </Dialog>
  );
};

export default ProdutoMatrizFilialModalPreco;
