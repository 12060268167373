import React from "react";

const ConditionalComponent = ({record, render, ...rest}) => {
  if (!record) {
    return null;
  }

  const field = render(record);  
  return field ? React.cloneElement(field, {
    record,
    ...rest
  }) : null;
}

export default ConditionalComponent;