import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, InputAdornment } from "@mui/material";
import NumberInputSimple from "../../../common/form/NumberInputSimple";
import TabPanel from "../../../common/form/TabPanel";
import COFINSInput from "../../../common/form/COFINSInput";
import { calculaCOFINS } from "../calculos";
import useChangeCalc from "../useChangeCalc";

const useStyles = makeStyles({
  numberInput: {
    width: "7em",
    marginRight: "1em",
  },
});

export default function TabCOFINS({ index, tabValue, itemData, getSource }) {
  const classes = useStyles();
  const changeCalc = useChangeCalc();

  return (
    <TabPanel value={tabValue} index={index}>
      <COFINSInput
        source={getSource("cofins_cst")}
        label="COFINS CST"
        required
        fullWidth
        onChange={changeCalc}
      />
      {calculaCOFINS(itemData.cofins_cst) && (
        <Grid container alignItems="center" sx={{ pb: 4 }}>
          <Grid item>
            <NumberInputSimple
              className={classes.numberInput}
              label="BC"
              source={getSource("icms_bc")}
              disabled={true}
            />
          </Grid>
          <Grid item>
            <NumberInputSimple
              className={classes.numberInput}
              label="Alq"
              source={getSource("cofins_alq")}
              InputProps={{
                endAdornment: <InputAdornment sx={{ mt: 3 }}>%</InputAdornment>,
              }}
              onChange={changeCalc}
            />
          </Grid>
          <Grid item>
            <NumberInputSimple
              className={classes.numberInput}
              label="Valor"
              source={getSource("cofins_v")}
            />
          </Grid>
        </Grid>
      )}
    </TabPanel>
  );
}
