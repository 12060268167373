import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress, Stack } from "@mui/material";

export default function AlertDialog({ title, message, open, handleClose }) {
  const wait = open && message.toLowerCase().includes("aguarde");
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack direction="row" spacing={3} alignItems="center">
          {wait && <CircularProgress />}
          <DialogContentText style={{ "white-space": "pre-line" }}>
            {message}
          </DialogContentText>
        </Stack>
      </DialogContent>
      <DialogActions>
        {!wait && (
          <Button onClick={handleClose} autoFocus>
            ok
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
