import React from "react";
import { Button, useNotify } from "react-admin";

function CopyPropButton({ label, record, source }) {
  const notify = useNotify();

  const handleClick = (e) => {
    e.stopPropagation();
    window.navigator.clipboard.writeText(record[source]).then(
      function () {
        notify(`Copiado com sucesso`);
      },
      function (err) {
        console.error(err);
        notify("Erro ao copiar", "warning");
      }
    );
  };

  return <Button label={label || "Copiar"} onClick={handleClick} />;
}

export default CopyPropButton;
