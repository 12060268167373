import React from "react";
import { green, red, yellow, grey } from "@mui/material/colors";
import { Chip } from "@mui/material";
import { NFeAutorizada, NFeCancelada, NFeEmProcessamento } from "../calculos";

function NFeStatus({ record, size = "small" }) {
  if (!record) return null;

  if (NFeCancelada(record)) {
    return (
      <Chip
        size={size}
        label="Cancelada"
        style={{
          backgroundColor: red[200],
          borderColor: red[200],
        }}
      />
    );
  }

  if (NFeEmProcessamento(record)) {
    return (
      <Chip
        size={size}
        label={`Em Processamento`}
        style={{
          backgroundColor: yellow[200],
          borderColor: yellow[200],
        }}
      />
    );
  }

  if (NFeAutorizada(record)) {
    const cce = record.cce_sequencia > 0 ? ` CCe ${record.cce_sequencia}` : "";
    return (
      <Chip
        size={size}
        label={`Autorizada${cce}`}
        style={{
          backgroundColor: green[200],
          borderColor: green[200],
        }}
      />
    );
  }

  return (
    <Chip
      size={size}
      label="Em Digitação"
      style={{
        backgroundColor: grey[200],
        borderColor: grey[200],
      }}
    />
  );
}

export default NFeStatus;
