import React from "react";
import { SelectInput, SelectField } from "react-admin";
import { Grid, Box } from "@material-ui/core";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ClearIcon from "@material-ui/icons/Clear";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

const status = [
  { id: "paid", name: "Pago", icon: <AttachMoneyIcon /> },
  { id: "canceled", name: "Cancelado", icon: <ClearIcon /> },
  { id: "pending", name: "Pendente", icon: <AccessTimeIcon /> },
];

const StatusIcon = ({ record }) => {
  return (
    <Grid container align="center" alignItems="center">
      <Grid item>
        <Box mr={2}>{record.icon}</Box>
      </Grid>
      <Grid item>{record.name}</Grid>
    </Grid>
  );
};

function ConnectStoneStatusInput(props) {
  return (
    <SelectInput
      label="Status"
      source="status"
      defaultValue={status[0].id}
      choices={status}
      optionText={<StatusIcon />}
      {...props}
    />
  );
}

function ConnectStoneStatusField(props) {
  return (
    <SelectField
      label="Status"
      source="status"
      choices={status}
      optionText={<StatusIcon />}
      {...props}
    />
  );
}

export { ConnectStoneStatusInput, ConnectStoneStatusField };
