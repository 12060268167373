import React, { useState } from "react";
import BuildIcon from "@material-ui/icons/Build";
import { API_URL } from "../../config/constants";
import fetchJson from "../../dataProvider/fetchJson";
import { withUser } from "../../common/util/hocs";
import LoadingButtonReactAdmin from "../../common/LoadingButtonReactAdmin";
import { useNotify } from "react-admin";

function PagarmeTestButton({ record, loja }) {
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const handleTest = async () => {
    try {
      setLoading(true);
      await fetchJson(`${API_URL}/ConnectStone/test?lojaId=${loja.id}`, {
        method: "GET",
      });

      notify("Integração com Stone / Pagar.me funcionando!");
    } catch (err) {
      console.error(err);
      console.log(JSON.stringify(err));
      notify("Erro com a Integração com Stone / Pagar.me.", { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  return record && record.accountId ? (
    <LoadingButtonReactAdmin
      size="big"
      variant="outlined"
      text="Testar"
      icon={<BuildIcon />}
      onClick={handleTest}
      loading={loading}
    />
  ) : null;
}

export default withUser(PagarmeTestButton);
