import React from "react";
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  ReferenceField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { DateFieldNL } from "../../common/form/DateFieldLocale";
import { CaixaStatusField } from "./CaixaStatus";
import CaixaFilter from "./CaixaFilter";

import rowStyle from "./rowStyle";
import CurrencyField from "../../common/form/CurrencyField";

const useListStyles = makeStyles({
  headerRow: {
    borderLeftColor: "white",
    borderLeftWidth: 5,
    borderLeftStyle: "solid",
  },
  headerCell: {
    padding: "6px 8px 6px 8px",
  },
  rowCell: {
    padding: "6px 8px 6px 8px",
  },
});

const CaixaList = (props) => {
  const classes = useListStyles();
  return (
    <List
      {...props}
      filters={<CaixaFilter />}
      sort={{ field: "id", order: "DESC" }}
      bulkActionButtons={false}
      exporter={false}
    >
      <Datagrid
        rowStyle={rowStyle}
        classes={{
          headerRow: classes.headerRow,
          headerCell: classes.headerCell,
          rowCell: classes.rowCell,
        }}
      >
        <ReferenceField
          source="equipamentoId"
          reference="equipamentos"
          link={false}
        >
          <TextField source="nome" />
        </ReferenceField>
        <ReferenceField source="operadorId" reference="parceiros" link={false}>
          <TextField source="nome_fantasia" />
        </ReferenceField>
        <TextField source="abertura" label="Abertura" />
        <DateFieldNL source="aberto_em" label="Aberto em" showTime />
        <DateFieldNL source="fechado_em" label="Fechado em" showTime />
        <CurrencyField source="valor_inicial" label="Troco inicial" />
        <CurrencyField source="valor_proximo" label="Troco próximo" />
        <CaixaStatusField label="Status" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default CaixaList;
