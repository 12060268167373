import { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import { useSelector } from "react-redux";

const useGetCurrent = (
  getDataFromStore,
  reference,
  record,
  loadCompleted,
  currentFromServer,
  value,
  data,
  newRecord
) => {
  const dataProvider = useDataProvider();
  const [current, setCurrent] = useState(null);
  const resourceStore = useSelector(
    (state) => state.admin.resources[reference]
  );
  const storeData = resourceStore ? resourceStore.data : null;

  useEffect(() => {
    if (newRecord) {
      setCurrent(newRecord);
    }
  }, [newRecord]);

  useEffect(() => {
    if (!record || current) {
      return;
    }

    if (currentFromServer) {
      setCurrent(currentFromServer);
    } else if (getDataFromStore && storeData && value) {
      setCurrent(storeData[value]);
    }
  }, [record, value, current, currentFromServer, getDataFromStore, storeData]);

  useEffect(() => {
    if (data && data[value]) {
      setCurrent(data[value]);
      return;
    }

    if (
      value &&
      loadCompleted &&
      (!current || current.id !== value) &&
      (!currentFromServer || currentFromServer.id !== value)
    ) {
      dataProvider.getOne(reference, { id: value }).then(({ data }) => {
        setCurrent(data);
      });
    }
  }, [
    value,
    loadCompleted,
    data,
    current,
    currentFromServer,
    dataProvider,
    reference,
  ]);

  return current;
};

export default useGetCurrent;
