import React from "react";
import { SelectInput } from "react-admin";

export const OPCOES_IPI = [
  { id: "  ", name: "Não tributado" },
  { id: "00", name: "00 - Entrada com recuperação de crédito" },
  { id: "01", name: "01 - Entrada tributada com alíquota zero" },
  { id: "02", name: "02 - Entrada isenta" },
  { id: "03", name: "03 - Entrada não-tributada" },
  { id: "04", name: "04 - Entrada imune" },
  { id: "05", name: "05 - Entrada com suspensão" },
  { id: "49", name: "49 - Outras entradas" },
  { id: "50", name: "50 - Saída tributada" },
  { id: "51", name: "51 - Saída tributada com alíquota zero" },
  { id: "52", name: "52 - Saída isenta" },
  { id: "53", name: "53 - Saída não-tributada" },
  { id: "54", name: "54 - Saída imune" },
  { id: "55", name: "55 - Saída com suspensão" },
  { id: "99", name: "99 - Outras Saídas" },
];

const IPIField = (props) => {
  return <SelectInput {...props} choices={OPCOES_IPI} />;
};

export default IPIField;
