import React from "react";
import { Edit } from "react-admin";

import AppPedidosForm from "./AppPedidosForm";

const AppPedidosEdit = props => (
  <Edit {...props}>
    <AppPedidosForm toolbar={null} />
  </Edit>
);

export default AppPedidosEdit;