import React, { useState } from "react";
import { Confirm, useNotify } from "react-admin";
import LoadingButton from "../../common/LoadingButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { fetchJSON } from "../../dataProvider";
import { withUser } from "../../common/util/hocs";

const KdsLimparTudoButton = ({ loja }) => {
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    try {
      setLoading(true);
      await fetchJSON("Kds/limparTudoLoja", {
        method: "POST",
        body: {
          lojaId: loja.id,
        },
      });
      notify("Dados do KDS excluídos com sucesso!");
    } catch (err) {
      console.log(JSON.stringify(err));
      notify("Ocorreu um erro ao excluir os dados do KDS", "warning");
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <>
      <LoadingButton
        text="Excluir Tudo"
        color="error"
        icon={<DeleteForeverIcon />}
        loading={loading}
        onClick={() => setOpen(true)}
      />
      <Confirm
        isOpen={open}
        loading={loading}
        title={"Atenção"}
        content={"Confirma a exclusão de todos os pedidos do KDS?"}
        confirm={"Excluir Tudo"}
        onConfirm={handleConfirm}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default withUser(KdsLimparTudoButton);
