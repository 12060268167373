import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import {
  SimpleForm,
  TextInput,
  RadioButtonGroupInput,
  FormDataConsumer,
  BooleanInput,
  required,
  email,
} from "react-admin";

import { Permissoes } from "../../authProvider";
import ParceiroForm from "../parceiro/ParceiroForm";
import permissoes from "./permissoes";
import ParceiroLazyReferenceInput from "../parceiro/ParceiroLazyReferenceInput";

const validateEmail = [required(), email()];

function UsuarioLojaForm(props) {
  const { record } = props;
  return (
    <SimpleForm {...props} redirect="list">
      <Grid container display="flex" spacing={2}>
        <Grid item>
          <TextInput
            label="Email do Usuário"
            source="usuario.email"
            type="email"
            validate={validateEmail}
            disabled={record && record.id}
          />
        </Grid>
        <Grid item>
          <ParceiroLazyReferenceInput
            label="Vendedor"
            source="parceiroId"
            record={record}
            addCreateButton={true}
            CreateForm={ParceiroForm}
            allowEmpty
          />
        </Grid>
      </Grid>

      <FormDataConsumer>
        {({ formData }) => (
          <Stack direction="column" spacing={2} fullWidth>
            <Stack
              direction="column"
              sx={{
                padding: "16px",
                borderWidth: 2,
                borderRadius: 2,
                borderColor: "#eeeeee",
                borderStyle: "solid",
                backgroundColor: "#fafafa",
              }}
            >
              <RadioButtonGroupInput
                translateChoice={false}
                helperText={false}
                source="type"
                defaultValue={2}
                label=""
                choices={[
                  {
                    id: 1,
                    name: "Proprietário",
                  },
                  {
                    id: 2,
                    name: "Funcionário",
                  },
                ]}
                fullWidth
              />
              {formData.type === Permissoes.PROPRIETARIO && (
                <small>Acesso completo ao sistema</small>
              )}
              {formData.type === Permissoes.CAIXA && (
                <small>
                  Acesso restrito. Apenas as funcionalidades ativadas serão
                  acessíveis.
                </small>
              )}
            </Stack>

            {formData.type === Permissoes.CAIXA &&
              permissoes.map((grupo) => (
                <Stack
                  direction="column"
                  sx={{
                    padding: "16px",
                    borderWidth: 2,
                    borderRadius: 2,
                    borderColor: "#eeeeee",
                    borderStyle: "solid",
                    backgroundColor: "#fafafa",
                  }}
                >
                  <Typography variant="subtitle1" gutterBottom>
                    <strong>{grupo.label}</strong>
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    {grupo.description}
                  </Typography>

                  <Grid container spacing={2}>
                    {grupo.permissoes.map((p) => (
                      <Grid item>
                        <BooleanInput {...p} helperText={false} />
                      </Grid>
                    ))}
                  </Grid>
                </Stack>
              ))}
          </Stack>
        )}
      </FormDataConsumer>
    </SimpleForm>
  );
}

export default UsuarioLojaForm;
