import React, { Fragment } from "react";
import { NumberInput, required, minValue, BooleanInput } from "react-admin";
import { DateInput, TimeInput } from "react-admin-date-inputs";
import { InputAdornment } from "@material-ui/core";

const styles = {
  formGroup: {
    display: "inline-block",
    marginRight: 32,
    width: "12em",
  },
};

const validateRS = [required(), minValue(0)];
const hoje = new Date();
const hojeIni = new Date(
  hoje.getFullYear(),
  hoje.getMonth(),
  hoje.getDate(),
  0,
  0,
  0
);
const hojeFim = new Date(
  hoje.getFullYear(),
  hoje.getMonth(),
  hoje.getDate(),
  23,
  59,
  59
);

const ProdutoPromocao = (props) => {
  return (
    <Fragment>
      <NumberInput
        source="promocao_desconto"
        label="Desconto da promoção"
        style={styles.formGroup}
        initialValue={0}
        validate={validateRS}
        InputProps={{
          startAdornment: <InputAdornment position="start">R$</InputAdornment>,
        }}
      />
      <div />

      <div style={styles.formGroup}>
        <DateInput
          label="Data de inicio"
          source="promocao_de"
          initialValue={hojeIni}
          options={{ format: "dd/MM/yyyy", clearable: true }}
        />
      </div>
      <div style={styles.formGroup}>
        <DateInput
          label="Data de fim"
          source="promocao_ate"
          initialValue={hojeFim}
          options={{ format: "dd/MM/yyyy", clearable: true }}
        />
      </div>

      <div />

      <div style={styles.formGroup}>
        <TimeInput
          label="Hora de inicio"
          source="promocao_de"
          initialValue={hojeIni}
          options={{ format: "HH:mm", ampm: false, clearable: true }}
        />
      </div>
      <div style={styles.formGroup}>
        <TimeInput
          label="Hora de fim"
          source="promocao_ate"
          initialValue={hojeFim}
          options={{ format: "HH:mm", ampm: false, clearable: true }}
        />
      </div>

      <BooleanInput
        source="promocao_todas_condicoes"
        label="Combina período de data e dias da semana"
        fullWidth
      />

      <BooleanInput
        source="dia_segunda"
        label="Segunda"
        style={styles.formGroup}
      />
      <BooleanInput source="dia_terca" label="Terça" style={styles.formGroup} />
      <div />

      <BooleanInput
        source="dia_quarta"
        label="Quarta"
        style={styles.formGroup}
      />
      <BooleanInput
        source="dia_quinta"
        label="Quinta"
        style={styles.formGroup}
      />
      <div />

      <BooleanInput source="dia_sexta" label="Sexta" style={styles.formGroup} />
      <BooleanInput
        source="dia_sabado"
        label="Sábado"
        style={styles.formGroup}
      />
      <div />

      <BooleanInput
        source="dia_domingo"
        label="Domingo"
        style={styles.formGroup}
      />
    </Fragment>
  );
};

export default ProdutoPromocao;
