import React from "react";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import MovimentoEdit from "./MovimentoEdit";
import MovimentotList from "./MovimentotList";

const movimento = {
  getResource: (p) => {
    return {
      name: "Movimentos",
      list: MovimentotList,
      edit: MovimentoEdit,
    };
  },
  getMenu: (p) => {
    return {
      key: "Movimentos",
      to: "/Movimentos",
      primaryText: "Movimentos",
      leftIcon: <FormatListNumberedIcon />,
    };
  },
};

export default movimento;
export const IconMovimento = FormatListNumberedIcon;
