import React from "react";
import { Edit } from "react-admin";

import PromocaoForm from "./PromocaoForm";
import PromocaoToolbar from "./PromocaoToolbar";

const PromocaoEdit = props => (
  <Edit {...props}>
    <PromocaoForm toolbar={<PromocaoToolbar />} />
  </Edit>
);

export default PromocaoEdit;
