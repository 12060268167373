import React from "react";
import BugReportIcon from "@material-ui/icons/BugReport";
import LogEdit from "./LogEdit";
import LogList from "./LogList";

const log = {
  getResource: (p) => {
    if (p.ADMIN) {
      return {
        name: "Logs",
        list: LogList,
        edit: LogEdit,
      };
    }
  },
  getMenu: (p) => {
    if (p.ADMIN) {
      return {
        key: "Logs",
        to: "/Logs",
        primaryText: "Logs",
        leftIcon: iconLog,
      };
    }
  },
};

export default log;
export const iconLog = <BugReportIcon />;
