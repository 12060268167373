import React from "react";
import red from "@material-ui/core/colors/red";
import lightGreen from "@material-ui/core/colors/lightGreen";
import Chip from "@mui/material/Chip";

const ConnectStoneStatus = ({ record }) => {
  if (!record) return null;

  switch (record.status) {
    case "paid":
      return (
        <Chip
          key="stone-status-paid"
          label="Pago"
          size="small"
          style={{
            margin: "2px",
            backgroundColor: lightGreen[300],
            borderColor: lightGreen[300],
          }}
        />
      );

    case "canceled":
      return (
        <Chip
          key="stone-status-canceled"
          label="Cancelado"
          size="small"
          style={{
            margin: "2px",
            backgroundColor: red[200],
            borderColor: red[200],
          }}
        />
      );

    case "pending":
      return (
        <Chip
          key="stone-status-aguardando"
          label="Aguardando pagamento"
          size="small"
          style={{
            margin: "2px",
          }}
        />
      );

    default:
      return (
        <Chip
          key="stone-status-default"
          label={record.status}
          size="small"
          style={{
            margin: "2px",
          }}
        />
      );
  }
};

export default ConnectStoneStatus;
