import React, { useEffect, useState } from "react";
import { Edit } from "react-admin";
import PedidosForm from "./PedidosForm";
import PedidoToolbar from "./PedidoToolbar";

const WrappedPedidosForm = ({ record, toolbar, ...rest }) => {
  const [arrays, setArrays] = useState({
    loaded: false,
    updatedAt: null,
    itens: [],
    contas: [],
  });

  useEffect(() => {
    if (
      record.itens instanceof Array &&
      (arrays.loaded === false || record.updatedAt > arrays.updatedAt)
    ) {
      const itens = record.itens.map((i, index) => {
        i.index = index;
        return i;
      });

      const contas = record.contas.map((c, index) => {
        c.index = index;
        return c;
      });

      setArrays({
        loaded: true,
        updatedAt: record.updatedAt,
        itens,
        contas,
      });
    }
  }, [arrays, record]);

  return (
    <PedidosForm
      {...rest}
      record={{ ...record, ...arrays }}
      toolbar={<PedidoToolbar />}
    />
  );
};

export default function PedidosEdit(props) {
  return (
    <Edit {...props}>
      <WrappedPedidosForm />
    </Edit>
  );
}
