import React from "react";
import { Show } from "react-admin";
import PerguntaForm from "./PerguntaForm";

const PerguntaShow = (props) => (
  <Show {...props}>
    <PerguntaForm toolbar={false} />
  </Show>
);

export default PerguntaShow;
