import React from "react";
import { SelectInput, SelectField } from "react-admin";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { green, red } from "@mui/material/colors";
import { Grid, Box, Avatar } from "@mui/material";

const PAGAR = "PAGAR";
const RECEBER = "RECEBER";

const tipos = [
  {
    id: PAGAR,
    name: "Conta a pagar",
    icon: (
      <Avatar sx={{ width: 20, height: 20, bgcolor: red[500] }}>
        <ArrowDownwardIcon fontSize="small" />
      </Avatar>
    ),
  },
  {
    id: RECEBER,
    name: "Conta a receber",
    icon: (
      <Avatar sx={{ width: 20, height: 20, bgcolor: green[500] }}>
        <ArrowUpwardIcon fontSize="small" />
      </Avatar>
    ),
  },
];

const ContaTipoIcon = ({ record }) => {
  return (
    <Grid container align="center" alignItems="center">
      <Grid item>
        <Box mr={2}>{record.icon}</Box>
      </Grid>
      <Grid item>{record.name}</Grid>
    </Grid>
  );
};

function ContaTipoInput(props) {
  return (
    <SelectInput
      label="Tipo"
      source="tipo"
      choices={tipos}
      optionText={<ContaTipoIcon />}
      {...props}
    />
  );
}

function ContaTipoField(props) {
  return (
    <SelectField
      label="Tipo"
      source="tipo"
      choices={tipos}
      optionText={<ContaTipoIcon />}
      {...props}
    />
  );
}

export { PAGAR, RECEBER, ContaTipoInput, ContaTipoField };
