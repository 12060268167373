import React from "react";
import {
  Datagrid,
  TextField,
  NumberField,
  FormDataConsumer,
  FunctionField,
} from "react-admin";
import DataGridRemoveButton from "../../common/form/DataGridRemoveButton";
import { keyBy } from "lodash";
import useChangeCalc from "./useChangeCalc";
import PedidosProdutoNovo from "./PedidosProdutoNovo";
import { Typography } from "@mui/material";
import DatagridEmpty from "../../common/form/Datagrid/DatagridEmpty";

export default function PedidosProdutosGrid({ loaded, canEdit, formData }) {
  const changeCalc = useChangeCalc();
  const data = keyBy(formData.itens, "index");
  const ids = formData.itens.map(({ index }) => index);

  const handleRemoveItem = (source, newValue) => {
    const event = {
      target: {
        name: source,
        value: newValue,
      },
    };
    changeCalc(event);
  };

  return (
    <>
      {canEdit && <PedidosProdutoNovo formData={formData} />}
      <Typography variant="h5" gutterBottom sx={{ mt: 4, ml: 1 }}>
        Lista de Produtos
      </Typography>
      <Datagrid
        loaded={loaded}
        data={data}
        ids={ids}
        currentSort={{ field: "index", order: "ASC" }}
        fullWidth
        empty={<DatagridEmpty message="Nenhum produto no pedido" />}
      >
        <FunctionField render={(record) => record.index + 1} label="Item" />
        <TextField source="codigo" label="Código" />
        <TextField source="descricao" label="Descrição" />
        <NumberField source="valor_unitario" label="Valor unit." />
        <NumberField source="quantidade" label="Quantidade" />
        <NumberField source="valor_total" label="Total" />

        {/* <NumberField source="valor_desconto" label="Desc" /> */}
        <NumberField source="valor_item" label="Item" />

        {canEdit && (
          <FormDataConsumer>
            {({ formData, record: itemData }) => (
              <DataGridRemoveButton
                formData={formData}
                record={itemData}
                arrayName="itens"
                onClick={handleRemoveItem}
              />
            )}
          </FormDataConsumer>
        )}
      </Datagrid>
    </>
  );
}
