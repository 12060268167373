import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Decimal from "decimal.js";
import { formatNumber } from "../../common/util/formatter";

export default function GroupedBillsTable({ data }) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Nome</TableCell>
          <TableCell align="right">Porcentagem</TableCell>
          <TableCell align="right">R$ Valor</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item) => (
          <TableRow key={item.nome}>
            <TableCell>{item.nome}</TableCell>
            <TableCell align="right">{`${Decimal(item.porcentagem).toFixed(
              2
            )} %`}</TableCell>
            <TableCell align="right">{formatNumber(item.total)}</TableCell>
          </TableRow>
        ))}
        <TableRow
          key="item-total"
          sx={{
            "td, th": { border: 0 },
          }}
        >
          <TableCell></TableCell>
          <TableCell align="right">
            <strong>Total</strong>
          </TableCell>
          <TableCell align="right">
            <strong>
              {formatNumber(
                data.reduce((acc, item) => acc.plus(item.total), Decimal(0))
              )}
            </strong>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
