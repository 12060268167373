import React from "react";

function ListColor({ record }) {
  if (!record) return null;

  return (
    <div style={{ margin: 5, height: 40, width: 40, background: record.cor }} />
  );
}

export default ListColor;
