import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback } from "react";
import { useNotify, Button, minValue, maxValue } from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import { Grid, InputAdornment, Typography } from "@mui/material";
import { useForm, useFormState } from "react-final-form";
import NumberInputSimple from "../../common/form/NumberInputSimple";
import DateInputUTC from "../../common/form/DateInputUTC";
import { validateDecimalPlaces } from "../../common/util/ra-validations";

const validateValor = [
  minValue(0),
  maxValue(99999999.99),
  validateDecimalPlaces(2),
];

const useStyles = makeStyles({
  numberInput: {
    width: "7em",
    marginRight: "1em",
    marginLeft: "1em",
  },
});

const ButtonAdd = (props) => {
  const form = useForm();
  const formState = useFormState();
  const notify = useNotify();

  const handleAdd = () => {
    const { contas, contaNova } = formState.values;
    const { vencimento, valor } = contaNova || {};

    if (!vencimento) {
      notify(`Preencha o vencimento!`);
      return;
    }

    if (!valor) {
      notify(`Preencha o valor!`);
      return;
    }

    form.change(`contas`, [
      ...contas,
      {
        ...contaNova,
        index: contas.length,
      },
    ]);
    form.change(`contaNova`, null);
  };

  return (
    <Button onClick={handleAdd} label="Adicionar" variant="outlined">
      <AddIcon />
    </Button>
  );
};

export default function PedidosContasNovo({ record }) {
  const classes = useStyles();

  const getSource = useCallback((field) => `contaNova.${field}`, []);

  return (
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom sx={{ mt: 4, ml: 1 }}>
          Novo Pagamento
        </Typography>
      </Grid>

      <Grid item>
        <DateInputUTC
          record={record}
          label="Vencimento"
          source={getSource("vencimento")}
        />
      </Grid>
      <Grid item>
        <NumberInputSimple
          className={classes.numberInput}
          label="Valor unit."
          source={getSource("valor")}
          InputProps={{
            startAdornment: (
              <InputAdornment sx={{ mt: 2, mr: 1 }}>R$</InputAdornment>
            ),
          }}
          validate={validateValor}
        />
      </Grid>
      <Grid item sx={{ mr: 5, mb: 2 }}>
        <ButtonAdd />
      </Grid>
    </Grid>
  );
}
