import React from "react";
import { get } from "lodash";

function CurrencyCentsField({ source, record, ...rest }) {
  const value = get(record, source);
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  });
  return value ? <span>{formatter.format(value / 100)}</span> : null;
}

export default CurrencyCentsField;
