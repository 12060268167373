import React from "react";
import {
  List,
  Datagrid,
  DateField,
  TextField,
  ReferenceField,
  EditButton,
} from "react-admin";
import CurrencyField from "../../common/form/CurrencyField";
import DetailsButton from "./DetailsButton";
import PedidosFilters from "./PedidosFilters";
import { PedidoTipoField } from "./PedidoTipo";
import { PedidoStatusField } from "./PedidoStatus";

function Pedidos(props) {
  return (
    <List
      {...props}
      exporter={false}
      filters={<PedidosFilters />}
    >
      <Datagrid>
        <PedidoTipoField source="tipo" />
        <PedidoStatusField source="status" />
        <ReferenceField
          label="Parceiro"
          source="parceiroId"
          reference="parceiros"
        >
          <TextField source="nome_fantasia" />
        </ReferenceField>
        <DateField source="createdAt" label="Criado em" />
        <CurrencyField source="total_pedido" label="R$ Total" />
        <EditButton />
        <DetailsButton />
      </Datagrid>
    </List>
  );
}

export default Pedidos;
