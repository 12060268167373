import React from "react";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import KdsCreate from "./KdsCreate";
import KdsEdit from "./KdsEdit";
import KdsList from "./KdsList";

const kds = {
  getResource: (p) => {
    return {
      name: "kds",
      create: p.cadastros_ver ? KdsCreate : null,
      list: p.cadastros_ver ? KdsList : null,
      edit: p.cadastros_ver ? KdsEdit : null,
    };
  },
  getMenu: (p) =>
    p.cadastros_ver
      ? {
          key: "kds",
          to: "/kds",
          primaryText: "KDS",
          leftIcon: <IconKds />,
        }
      : null,
};

export default kds;
export const IconKds = DesktopWindowsIcon;
