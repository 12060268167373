import CestCreate from "./CestCreate";
import CestEdit from "./CestEdit";
import CestList from "./CestList";

const cest = {
  getResource: (p) => {
    if (p.ADMIN) {
      return {
        name: "cests",
        create: CestCreate,
        list: CestList,
        edit: CestEdit,
      };
    }
  },
  getMenu: (p) =>
    p.ADMIN
      ? {
          key: "cests",
          to: "/cests",
          primaryText: "CESTs",
        }
      : null,
};

export default cest;
