import React from "react";
import { Edit } from "react-admin";

import ImpressoraForm from "./ImpressoraForm";
import ImpressoraToolbar from "./ImpressoraToolbar";

const ImpressoraEdit = props => (
  <Edit {...props}>
    <ImpressoraForm toolbar={<ImpressoraToolbar />} />
  </Edit>
);

export default ImpressoraEdit;
