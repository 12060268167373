import React from "react";
import { SelectField, SelectInput } from "react-admin";

export const OPCOES = [
  { id: "01", name: "Dinheiro" },
  { id: "02", name: "Cheque" },
  { id: "03", name: "Cartão de Crédito" },
  { id: "04", name: "Cartão de Débito" },
  { id: "05", name: "Crédito Loja" },
  { id: "10", name: "Vale Alimentação" },
  { id: "11", name: "Vale Refeição" },
  { id: "12", name: "Vale Presente" },
  { id: "13", name: "Vale Combustível" },

  { id: "15", name: "Boleto Bancário" },
  { id: "16", name: "Depósito Bancário" },
  { id: "17", name: "Pagamento Instantâneo (PIX)" },
  { id: "18", name: "Transferência bancária, Carteira Digital" },
  { id: "19", name: "Programa de fidelidade, Cashback, Crédito Virtua" },
  { id: "90", name: "Sem pagamento" },

  { id: "99", name: "Outros" },
];

const optionRenderer = (choice) => `${choice.id} - ${choice.name}`;

const CodigoSATInput = (props) => {
  return (
    <SelectInput {...props} choices={OPCOES} optionText={optionRenderer} />
  );
};

const CodigoSATField = (props) => {
  return (
    <SelectField {...props} choices={OPCOES} optionText={optionRenderer} />
  );
};

export { CodigoSATInput, CodigoSATField };
