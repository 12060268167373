import React from "react";
import ReceiptIcon from "@material-ui/icons/Receipt";
import NFCetList from "./NFCetList";

const nfce = {
  getResource: (p) => {
    return {
      name: "NFCes",
      list: NFCetList,
    };
  },
  getMenu: (p) => {
    return {
      key: "NFCes",
      to: "/NFCes",
      primaryText: "NFCes",
      leftIcon: iconNFCe,
    };
  },
};

export default nfce;
export const iconNFCe = <ReceiptIcon />;
