import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, InputAdornment, Typography } from "@mui/material";
import NumberInputSimple from "../../../common/form/NumberInputSimple";
import useChangeCalc from "../useChangeCalc";

const useStyles = makeStyles({
  numberInput: {
    width: "7em",
    marginRight: "1em",
  },
});

export default function ICMSFCP({ getSource, icmsEnable }) {
  const classes = useStyles();
  const changeCalc = useChangeCalc();

  return icmsEnable ? (
    <Grid container alignItems="center" sx={{ pb: 4 }}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Fundo de Combate a Pobresa
        </Typography>
      </Grid>
      <Grid item>
        <NumberInputSimple
          className={classes.numberInput}
          label="BC FCP"
          source={getSource("fcp_bc")}
        />
      </Grid>
      <Grid item>
        <NumberInputSimple
          className={classes.numberInput}
          label="Alq FCP"
          source={getSource("fcp_alq")}
          InputProps={{
            endAdornment: <InputAdornment sx={{ mt: 3 }}>%</InputAdornment>,
          }}
          onChange={changeCalc}
        />
      </Grid>
      <Grid item>
        <NumberInputSimple
          className={classes.numberInput}
          label="Valor FCP"
          source={getSource("fcp_v")}
        />
      </Grid>
    </Grid>
  ) : null;
}
