import React from "react";
import {
  TabbedForm,
  FormTab,
  TextInput,
  NumberInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  AutocompleteInput,
  required,
  maxLength,
  minValue,
  FormDataConsumer,
} from "react-admin";
import { InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ProdutoFormApp from "../produto/ProdutoFormApp";
import ProdutoProximoCodigo from "../produto/ProdutoProximoCodigo";
import useProdutoValidateCod from "../produto/useProdutoValidateCod";
import { IdDates } from "../../common/form/ConditionalInputs";
import LazyReferenceInput from "../../common/form/LazyReferenceInput";
import ColorInput from "../../common/form/ColorInput";
import { withUser } from "../../common/util/hocs";
import { Grid } from "@mui/material";
import ArrayInputRemoveButton from "../../common/form/ArrayInputRemoveButton";

const validateReq = [required()];
const validateDesc = [required(), maxLength(100)];
const validateRS = [required(), minValue(0)];

const useStyles = makeStyles({
  formGroup: {
    display: "inline-block",
    marginRight: 32,
  },
  preco: {
    display: "inline-block",
    marginRight: 32,
    width: "7em",
  },
});

function ProdutoForm(props) {
  const { loja, record } = props;
  const { uniqCod } = useProdutoValidateCod(record, loja.id);

  const validateCodProd = [required(), minValue(1), uniqCod];

  const classes = useStyles();

  return (
    <TabbedForm {...props} toolbar={props.toolbar}>
      <FormTab label="Dados do produto">
        <IdDates />
        <NumberInput
          source="cod_prod"
          label="Código do produto"
          validate={validateCodProd}
          formClassName={classes.formGroup}
          InputProps={{
            endAdornment: <ProdutoProximoCodigo lojaId={loja.id} />,
          }}
        />
        <BooleanInput
          source="stt_prod"
          label="Ativo"
          initialValue={true}
          formClassName={classes.formGroup}
        />
        <div />

        <TextInput
          source="desc_prod"
          label="Pergunta"
          validate={validateDesc}
        />

        <LazyReferenceInput
          label="Grupo"
          source="grupoId"
          reference="grupos"
          nameSource="grupo"
          validate={validateReq}
          formClassName={classes.formGroup}
          addCreateButton={true}
        >
          <AutocompleteInput optionText="nome" />
        </LazyReferenceInput>

        <ColorInput
          source="cor"
          label="Cor"
          formClassName={classes.formGroup}
        />

        <NumberInput
          source="ordem"
          label="Ordem"
          initialValue={999}
          formClassName={classes.formGroup}
        />

        <ArrayInput source="respostas" label="Respostas" fullWidth>
          <SimpleFormIterator
            disableReordering
            TransitionProps={{ enter: false, exit: false }}
            disableRemove
          >
            <FormDataConsumer>
              {({ formData, scopedFormData, getSource }) => (
                <Grid container alignItems="center" spacing={3}>
                  <Grid item>
                    <NumberInput
                      source={getSource("cod_prod")}
                      label="Código do produto"
                      formClassName={classes.formGroup}
                    />
                  </Grid>
                  <Grid item>
                    <TextInput
                      source={getSource("nome")}
                      label="Nome da resposta"
                      validate={validateDesc}
                      formClassName={classes.formGroup}
                    />
                  </Grid>
                  <Grid item>
                    <NumberInput
                      source={getSource("valor")}
                      label="Preço"
                      fullWidth
                      formClassName={classes.preco}
                      initialValue={0}
                      validate={validateRS}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <ArrayInputRemoveButton
                      formData={formData}
                      itemData={scopedFormData}
                      getSource={getSource}
                    />
                  </Grid>
                </Grid>
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      <FormTab label="App delivery">
        <ProdutoFormApp />
      </FormTab>
    </TabbedForm>
  );
}

export default withUser(ProdutoForm);
