import Decimal from "decimal.js";

const sumTotal = (itens, field) =>
  itens.reduce((t, i) => t.plus(i[field] || 0), new Decimal(0)).valueOf();

function calculaTotais(form, formData) {
  const itensToSum = formData.itens
    .filter((i) => i && !i.deletar)
    .map((i) => ({ ...i }));

  const total_produtos = sumTotal(itensToSum, "valor_total");
  const total_pedido = Decimal(total_produtos)
    .plus(formData.total_seguro || 0)
    .plus(formData.total_frete || 0)
    .plus(formData.total_outro || 0)
    .minus(formData.total_descontos || 0)
    .valueOf();

  const itens = formData.itens.map((i) => ({ ...i }));

  form.batch(() => {
    form.change("itens", itens);
    form.change("total_produtos", total_produtos);
    form.change("total_pedido", total_pedido);
  });
}

function calculaItensValorTotal(formData) {
  const itens = formData.itens.filter((i) => i && !i.deletar);
  for (let i = 0; i < itens.length; i++) {
    const item = itens[i];

    item.valor_unitario = Decimal(item.valor_unitario || 0)
      .toDecimalPlaces(2)
      .valueOf();
    item.quantidade = Decimal(item.quantidade || 0)
      .toDecimalPlaces(3)
      .valueOf();
    item.valor_total = Decimal(item.valor_unitario)
      .times(item.quantidade)
      .toDecimalPlaces(2)
      .valueOf();
    item.valor_item = item.valor_total;
  }
}

export function calculosPedido(form, formData) {
  formData.itens = formData.itens || [];
  calculaItensValorTotal(formData);
  //rateia desconto, frete, outas despesas e seguro
  calculaTotais(form, formData);
}
