import { HttpError } from "react-admin";

const fetchJson = async (url, options) => {
  // const logUrl = decodeURI(url.replace('https://api.sistemapallas.com.br/', ''));
  // console.log('fetchJson', logUrl, options);

  options = options || {};
  const requestHeaders =
    options.headers || new Headers({ Accept: "application/json" });
  if (
    !requestHeaders.has("Content-Type") &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    requestHeaders.set("Content-Type", "application/json");
  }
  const token = localStorage.getItem("token");
  if (token) {
    requestHeaders.set("Authorization", token);
  }

  const response = await fetch(url, { ...options, headers: requestHeaders });
  const text = await response.text();
  const o = {
    status: response.status,
    statusText: response.statusText,
    headers: response.headers,
    body: text,
  };
  let status = o.status,
    statusText = o.statusText,
    headers = o.headers,
    body = o.body;
  let json;
  try {
    json = JSON.parse(body);
  } catch (e) {
    // not json, no big deal
  }

  if (status < 200 || status >= 300) {
    // console.log('fetchJson error', logUrl, options);
    return Promise.reject(
      new HttpError(
        (json && json.error && json.error.message) || statusText,
        status,
        json
      )
    );
  }
  return Promise.resolve({
    status: status,
    headers: headers,
    body: body,
    json: json,
  });
};

export default fetchJson;
