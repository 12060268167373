import React from "react";
import { Edit } from "react-admin";

import FidelidadeForm from "./FidelidadeForm";

const FidelidadeEdit = (props) => (
  <Edit {...props} title="Editar Programa de Fidelidade">
    <FidelidadeForm />
  </Edit>
);

export default FidelidadeEdit;
