import React from "react";
import {
  Datagrid,
  List,
  TextField,
  EditButton
} from "react-admin";

import UnidadeFilter from "./UnidadeFilter";

const UnidadeList = props => (
  <List {...props} filters={<UnidadeFilter />} exporter={false}>
    <Datagrid>
      <TextField source="nome" label="Nome"/> 
      <EditButton />
    </Datagrid>
  </List>
);

export default UnidadeList;
