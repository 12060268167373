import { makeStyles } from "@material-ui/core/styles";
import Decimal from "decimal.js";
import React, { useCallback, useState } from "react";
import {
  useDataProvider,
  useNotify,
  Button,
  minValue,
  maxValue,
} from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import { Grid, InputAdornment, Typography } from "@mui/material";
import { useForm, useFormState } from "react-final-form";
import { SmallCircularProgress } from "../../common/CircularProgress";
import ProdutoLazyReferenceInput from "../produto/ProdutoLazyReferenceInput";
import NumberInputReadonly from "../../common/form/NumberInputReadonly";
import NumberInputSimple from "../../common/form/NumberInputSimple";
import { calculosPedido } from "./calculos";
import { validateDecimalPlaces } from "../../common/util/ra-validations";
import { COMPRA } from "./PedidoTipo";

const validateValor = [
  minValue(0),
  maxValue(99999999.99),
  validateDecimalPlaces(2),
];
const validateQuantidade = [
  minValue(0.001),
  maxValue(99999999.999),
  validateDecimalPlaces(3),
];

const useStyles = makeStyles({
  produto: {
    width: "20em",
    marginRight: "1em",
  },
  numberInput: {
    width: "8em",
    marginRight: "1em",
  },
  total: {
    width: "10em",
    marginRight: "1em",
  },
});

const ButtonAdd = (props) => {
  const form = useForm();
  const formState = useFormState();
  const notify = useNotify();

  const onAddItem = () => {
    const formData = formState.values;
    const { itens, itemNovo } = formData;
    const {
      produtoId,
      codigo,
      descricao,
      valor_unitario,
      quantidade,
      valor_total,
    } = itemNovo || {};

    if (!produtoId) {
      notify(`Preencha o produto!`);
      return;
    }

    if (!codigo) {
      notify(`Preencha o codigo!`);
      return;
    }

    if (!descricao) {
      notify(`Preencha a descrição!`);
      return;
    }

    if (valor_unitario < 0) {
      notify(`Preencha o valor unitário positivo!`);
      return;
    }

    if (quantidade < 0) {
      notify(`Preencha a quantidade positiva!`);
      return;
    }

    if (valor_total < 0) {
      notify(`Preencha a quantidade e valor unitário!`);
      return;
    }

    formData.itemNovo = null;
    formData.itens = [
      ...itens,
      {
        ...itemNovo,
        valor_desconto: 0,
        valor_item: valor_total,
        index: itens.length,
      },
    ];

    calculosPedido(form, formData);
  };

  return (
    <Button onClick={onAddItem} label="Adicionar" variant="outlined">
      <AddIcon />
    </Button>
  );
};

export default function PedidosProdutoNovo({ formData }) {
  const [loading, setLoading] = useState(false);
  const form = useForm();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const getSource = useCallback((field) => `itemNovo.${field}`, []);

  const handleChangeProduct = async (productId) => {
    try {
      setLoading(true);
      const { data: produto } = await dataProvider.getOne("produtos", {
        id: productId,
      });

      const vUnit =
        formData.tipo === COMPRA
          ? produto.preco_cst_prod
          : produto.preco_vnd_prod;

      form.batch(() => {
        form.change(getSource("codigo"), produto.cod_prod);
        form.change(getSource("descricao"), produto.desc_prod);
        form.change(getSource("valor_unitario"), vUnit);
      });
    } catch (error) {
      notify("Erro ao carregar os dados do produto.", "warning");
      console.log(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  const mergeValue = (object, field, value) => ({ ...object, [field]: value });

  const handleChangeValorUnit = (event) =>
    calculaItemTotal(
      mergeValue(formData.itemNovo, "valor_unitario", event.target.value)
    );

  const handleChangeQuantidade = (event) =>
    calculaItemTotal(
      mergeValue(formData.itemNovo, "quantidade", event.target.value)
    );

  const calculaItemTotal = (itemNovo) => {
    const { valor_unitario, quantidade } = itemNovo;
    const valor_total = Decimal(valor_unitario || 0)
      .times(quantidade || 0)
      .toFixed(2);
    form.change(getSource("valor_total"), valor_total);
  };

  return (
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom sx={{ mt: 4, ml: 1 }}>
          Novo Produto
        </Typography>
      </Grid>
      <Grid item>
        <ProdutoLazyReferenceInput
          label="Produto"
          source={getSource("produtoId")}
          nameSource={getSource("produto")}
          className={classes.produto}
          fullWidth
          onChange={handleChangeProduct}
        />
      </Grid>
      {loading && (
        <Grid item>
          <SmallCircularProgress />
        </Grid>
      )}
      <Grid item>
        <NumberInputSimple
          className={classes.numberInput}
          label="Valor unit."
          source={getSource("valor_unitario")}
          InputProps={{
            startAdornment: (
              <InputAdornment sx={{ mt: 2, mr: 1 }}>R$</InputAdornment>
            ),
          }}
          validate={validateValor}
          disabled={loading}
          onChange={handleChangeValorUnit}
        />
      </Grid>
      <Grid item>
        <NumberInputSimple
          className={classes.numberInput}
          label="Quantidade"
          source={getSource("quantidade")}
          min={1}
          disabled={loading}
          onChange={handleChangeQuantidade}
          validate={validateQuantidade}
        />
      </Grid>
      <Grid item>
        <NumberInputReadonly
          className={classes.total}
          label="Total"
          source={getSource("valor_total")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item sx={{ mr: 5, mb: 2 }}>
        <ButtonAdd />
      </Grid>
    </Grid>
  );
}
