import React from "react";
import { SelectInput, SelectField } from "react-admin";

const choices = [
  { id: 0, name: "Aberto" },
  { id: 1, name: "Fechado" },
  { id: 2, name: "Conferido" },
];

const CaixaStatusInput = (props) => {
  return <SelectInput choices={choices} source='status' {...props} />;
};

const CaixaStatusField = (props) => {
  return <SelectField choices={choices} source='status' {...props} />;
};

export { CaixaStatusInput, CaixaStatusField };
