import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import { Notification, useNotify } from 'react-admin';
import { Field, withTypes } from "react-final-form";
import { Link } from "react-router-dom";
import { fetchJSON } from "../../dataProvider";
import TextInput from "./TextInput";

import pallasLogo from '../../static/media/pallas-logo-horizontal.svg';

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  card: {
    minWidth: 300,
    padding: '1em',
    marginTop: '6em',
  },
  instructions: {
    maxWidth: 300,
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    paddingBottom: 50,
    maxWidth: 270,
  },
  input: {
    marginTop: '1em',
  },
  login: {
    marginTop: '2em',
  },
  forgotPassword: {
    marginTop: '2em',
    display: "flex",
    flexDirection: "row",
  },
  progress: {
    marginRight: '1em'
  },
}));

const { Form } = withTypes();

const RecoveryPasswordForm = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Obrigatório";
    }
    return errors;
  };

  const handleSubmit = (event) => {
    setLoading(true);
    fetchJSON("users/sendResetPasswordEmail", {
      method: "POST",
      body: JSON.stringify({
        email: event.email,
      }),
    })
    .then(_ => notify('Email enviado com sucesso.'))
    .catch(_ => notify('Error ao enviar email para redefinir a senha.', 'warning'))
    .finally(() => setLoading(false));
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <img
                  src={pallasLogo}
                  className={classes.image}
                  alt="Sistema Pallas"
                />
              </div>
              <h2>Esqueci minha senha</h2>
              <p className={classes.instructions}>
                <small>Enviaremos um link por e-mail com instruções para você redefinir sua senha.</small>
              </p>
              <div className={classes.form}>
                <Field
                  autoFocus
                  name="email"
                  component={TextInput}
                  label="Email"
                  disabled={loading}
                  type="email"
                  fullWidth
                />
              </div>
              <Button
                className={classes.login}
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
                fullWidth
              >
                {loading && (
                  <CircularProgress
                    size={25}
                    thickness={2}
                    className={classes.progress}
                  />
                )}
                Redefinir senha
              </Button>
              <Box
                className={classes.forgotPassword}
              >
                <Link to="/login">Voltar</Link>
              </Box>
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

export default RecoveryPasswordForm;
