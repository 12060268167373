import React from 'react';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ProdutoCreate from "./ProdutoCreate";
import ProdutoEdit from "./ProdutoEdit";
import ProdutoList from "./ProdutoList";

const produtoPonte = {
    getResource: (p) => {
        return {
            name: "produtoPontes",
            create: ProdutoCreate,
            list: ProdutoList,
            edit: ProdutoEdit,
        }
    },
    getMenu: (p) => {
        return { 
            key: "produtoPontes",
            to: `/produtoPontes`,
            primaryText: "Pontes",
            leftIcon: iconProduto,
        }
    },
}

export default produtoPonte;
export const iconProduto = <AccountTreeIcon/>;