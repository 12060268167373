import React from "react";
import { Datagrid, FormDataConsumer, NumberField } from "react-admin";
import { keyBy } from "lodash";
import { NFeTipoPagamentoField } from "./fields/NFeTipoPagamento";
import DataGridRemoveButton from "../../common/form/DataGridRemoveButton";

export default function NFeEmissaoPagamentosGrid({ loaded, record, formData }) {
  const data = keyBy(formData.pagamentos, "index");
  const ids = formData.pagamentos.map(({ index }) => index);

  return (
    <Datagrid
      loaded={loaded}
      data={data}
      ids={ids}
      currentSort={{ field: "index", order: "ASC" }}
    >
      <NFeTipoPagamentoField source="tipo" label="Tipo" />
      <NumberField source="valor" label="Valor" />

      {!record.xmlUrl && (
        <FormDataConsumer>
          {({ formData, record: itemData }) => (
            <DataGridRemoveButton
              formData={formData}
              record={itemData}
              arrayName="pagamentos"
            />
          )}
        </FormDataConsumer>
      )}
    </Datagrid>
  );
}
