import React from "react";
import { TextInput, Filter } from "react-admin";

const CestFilter = props => (
  <Filter {...props}>
    <TextInput source="id" label="Código Cest" alwaysOn />
    <TextInput source="q" label="Descrição" alwaysOn />
  </Filter>
);

export default CestFilter;
