import React from "react";
import { Create } from "react-admin";

import MoedaAppForm from "./MoedaAppForm";
import MoedaAppToolbar from "./MoedaAppToolbar";

const MoedaAppCreate = props => (
  <Create {...props}>
    <MoedaAppForm toolbar={<MoedaAppToolbar />} />
  </Create>
);

export default MoedaAppCreate;
