import React, { useState } from "react";
import Switch from "@material-ui/core/Switch";
import { API_URL } from "../../config/constants";
import fetchJson from "../../dataProvider/fetchJson";
import { withUser } from "../../common/util/hocs";
import { useDataProvider, useNotify } from "react-admin";

function ButtonStatusVenda({ record, loja }) {
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const changeFirebase = async (data) => {
    if (loja.appDeliveryUri) {
      return fetchJson(`${API_URL}/apps/firebaseGrupoStatus`, {
        method: "POST",
        body: JSON.stringify({
          lojaId: data.lojaId,
          appDeliveryUri: loja.appDeliveryUri,
          grupoId: data.id,
          status: data.status,
          appRetirada: loja.appRetirada,
          appEntrega: loja.appEntrega,
          appMesa: loja.appMesa,
        }),
      });
    }
  };

  const handleChange = () => {
    setLoading(true);
    dataProvider
      .update("grupos", {
        updateAttributes: true,
        data: {
          id: record.id,
          lojaId: record.lojaId,
          status: !record.status,
        },
      })
      .then((response) => changeFirebase(response.data))
      .catch((err) => {
        notify("Erro do alterar status do grupo", "warning");
        console.log(JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };

  return record ? (
    <Switch
      checked={record.status}
      onChange={handleChange}
      color="primary"
      disabled={loading}
    />
  ) : null;
}

export default withUser(ButtonStatusVenda);
