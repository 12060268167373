import React from "react";
import { Create } from "react-admin";
import PedidosForm from "./PedidosForm";
import PedidoToolbar from "./PedidoToolbar";

export default function PedidoCreate(props) {
  return (
    <Create {...props}>
      <PedidosForm toolbar={<PedidoToolbar />} />
    </Create>
  );
}
