import React, { useEffect, useState } from "react";
import { Edit } from "react-admin";
import NFeEmissaoForm from "./NFeEmissaoForm";
import NFeToolbar from "./NFeEmissaoToolbar";

const WrappedNFeEmissaoForm = ({ record, toolbar, ...rest }) => {
  const [arrays, setArrays] = useState({
    loaded: false,
    itens: [],
    pagamentos: [],
    chaves: [],
  });

  useEffect(() => {
    if (
      record.itens instanceof Array &&
      (arrays.loaded === false || record.updatedAt > arrays.updatedAt)
    ) {
      const itens = record.itens.map((i, index) => {
        i.index = index;
        return i;
      });

      const pagamentos = record.pagamentos.map((p, index) => {
        p.index = index;
        return p;
      });

      const chaves = record.chaves.map((c, index) => {
        c.index = index;
        return c;
      });

      setArrays({
        loaded: true,
        updatedAt: record.updatedAt,
        itens,
        pagamentos,
        chaves,
      });
    }
  }, [arrays, record]);

  return (
    <NFeEmissaoForm
      {...rest}
      record={{ ...record, ...arrays }}
      toolbar={<NFeToolbar />}
    />
  );
};

export default function NFeEmissaosEdit(props) {
  return (
    <Edit {...props}>
      <WrappedNFeEmissaoForm />
    </Edit>
  );
}
