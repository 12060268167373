import React from "react";
import { useLocation } from "react-router-dom";
import { SaveButton, Toolbar } from "react-admin";

const ParceiroToolbar = (props) => {
  const location = useLocation();
  const regex = /^\/[a-z0-9]+\/[a-z0-9]+\/1/;
  const hideToolbar = regex.test(location.pathname);

  return hideToolbar ? null : (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
};

export default ParceiroToolbar;
