import React, { useState } from "react";
import {
  Button,
  useDataProvider,
  useRefresh,
  useNotify,
  useUnselectAll,
  SimpleForm,
  FormDataConsumer,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
  NumberInput,
  Toolbar,
  SaveButton,
  required,
} from "react-admin";
import EditIcon from "@material-ui/icons/Edit";
import { Alert, AlertTitle, Dialog, DialogTitle } from "@mui/material";
import LazyReferenceInput from "../../common/form/LazyReferenceInput";
import UXDelay from "../../dataProvider/uxdelay";

const filter_NCM_CEST = (searchText) => {
  if (searchText.match(/^\d+$/)) {
    return { id: { like: `${searchText}%` } };
  } else {
    return { q: searchText };
  }
};

const match_NCM_CEST = (filter, choice) => true;

const validateReq = [required()];

const colunas = [
  //REFERENCE
  {
    name: "Grupo",
    component: (
      <ReferenceInput source="grupoId" reference="grupos">
        <AutocompleteInput optionText="nome" />
      </ReferenceInput>
    ),
  },
  {
    name: "Unidade",
    component: (
      <ReferenceInput source="unidadeId" reference="unidades">
        <AutocompleteInput optionText="nome" />
      </ReferenceInput>
    ),
  },
  {
    name: "Impressora",
    component: (
      <ReferenceInput source="impressoraId" reference="impressoras" allowEmpty>
        <AutocompleteInput
          optionText={(i) => (i && i.id ? i.nome : "Nenhum")}
        />
      </ReferenceInput>
    ),
    required: false,
  },
  {
    name: "Imposto",
    component: (
      <ReferenceInput source="impostoId" reference="impostos">
        <AutocompleteInput optionText="nome" />
      </ReferenceInput>
    ),
  },
  {
    name: "NCM",
    component: (
      <LazyReferenceInput
        source="ncmId"
        reference="ncms"
        nameSource="ncm"
        minLength={3}
        filterToQuery={filter_NCM_CEST}
      >
        <AutocompleteInput
          matchSuggestion={match_NCM_CEST}
          optionText={(i) =>
            i && i.id ? `${i.id} - ${i.descricao}` : "Nenhum"
          }
        />
      </LazyReferenceInput>
    ),
  },
  {
    name: "CEST",
    component: (
      <LazyReferenceInput
        source="cestId"
        reference="cests"
        nameSource="cest"
        minLength={3}
        filterToQuery={filter_NCM_CEST}
      >
        <AutocompleteInput
          matchSuggestion={match_NCM_CEST}
          optionText={(i) =>
            i && i.id ? `${i.id} - ${i.descricao}` : "Nenhum"
          }
        />
      </LazyReferenceInput>
    ),
  },

  //BOOLEAN
  {
    name: "Ativo",
    component: <BooleanInput source="stt_prod" initialValue={false} />,
  },
  {
    name: "Delivery",
    component: <BooleanInput source="appDelivery" initialValue={false} />,
  },
  {
    name: "Balcão",
    component: <BooleanInput source="appBalcao" initialValue={false} />,
  },
  {
    name: "Na mesa",
    component: <BooleanInput source="appMesa" initialValue={false} />,
  },
  {
    name: "Destaque",
    component: <BooleanInput source="appDestaque" initialValue={false} />,
  },
  {
    name: "PDV Mobile",
    component: <BooleanInput source="appPdvMobile" initialValue={false} />,
  },

  {
    name: "Envia para balança",
    component: <BooleanInput source="balanca_prod" initialValue={false} />,
  },
  {
    name: "Paga serviço do garçom",
    component: <BooleanInput source="paga_servico_prod" initialValue={false} />,
  },
  {
    name: "Adicional",
    component: <BooleanInput source="adicional" initialValue={false} />,
  },
  {
    name: "Matéria prima",
    component: (
      <BooleanInput source="materia_prima_prod" initialValue={false} />
    ),
  },
  {
    name: "Alcoólico",
    component: <BooleanInput source="alcoolico" initialValue={false} />,
  },

  //NUMBER
  {
    name: "Validade",
    component: <NumberInput source="dias_vld_prod" />,
  },
  {
    name: "Comissão",
    component: <NumberInput source="comissao_prod" />,
  },
  {
    name: "Desconto máximo",
    component: <NumberInput source="dsct_max_prod" />,
  },
  {
    name: "Estoque mínimo",
    component: <NumberInput source="qtd_min_prod" />,
  },
  {
    name: "Ordem",
    component: <NumberInput source="ordem" />,
  },
];

const ProdutoBulkUpdateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Alterar todos" redirect={false} submitOnEnter={false} />
  </Toolbar>
);

const ProdutoBulkUpdate = ({ selectedIds }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const handleClick = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSave = (record) => {
    setLoading(true);

    const fieldToUpdate = record.field;
    const coluna = colunas.find((c) => c.name === fieldToUpdate);
    const source = coluna.component.props.source;
    const newData = {
      [source]: record[source],
      updatedAt: new Date(),
    };

    UXDelay(
      dataProvider.updateMany("produtos", {
        ids: selectedIds,
        data: newData,
      })
    )
      .then((response) => {
        refresh();
        notify("Produtos alterados com sucesso!");
        unselectAll("produtos");
      })
      .catch((err) => {
        notify("Erro ao alterar os produtos", "warning");
        console.log(JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Button label="Alterar selecionados" onClick={handleClick}>
        <EditIcon />
      </Button>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Alteração em massa</DialogTitle>
        <Alert severity="info" sx={{ m: 2 }}>
          <AlertTitle>Info</AlertTitle>
          Esta ação afetará todos os{" "}
          <strong>{selectedIds.length} produtos</strong> selecionados.
        </Alert>
        <SimpleForm
          record={{ values: [] }}
          save={handleSave}
          saving={loading}
          toolbar={<ProdutoBulkUpdateToolbar />}
        >
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              const coluna = colunas.find((c) => c.name === formData.field);
              return (
                <>
                  <SelectInput
                    label="Campo para alterar"
                    source="field"
                    optionText="name"
                    optionValue="name"
                    choices={colunas}
                    validate={validateReq}
                    fullWidth
                  />
                  {formData.field &&
                    React.cloneElement(coluna.component, {
                      label: `${coluna.name} (Novo valor)`,
                      validate: coluna.required === false ? null : validateReq,
                      fullWidth: true,
                      translateChoice: false,
                    })}
                </>
              );
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Dialog>
    </>
  );
};

export default ProdutoBulkUpdate;
