import React from "react";
import {
  Datagrid,
  List,
  NumberField,
  TextField,
  BooleanField,
  EditButton,
} from "react-admin";

import CNPJCPFField from "../../common/form/CNPJCPFField";
import ParceiroFilter from "./ParceiroFilter";

const ParceiroList = (props) => (
  <List {...props} filters={<ParceiroFilter />} exporter={false}>
    <Datagrid>
      <NumberField source="id" label="Id" />
      <CNPJCPFField source="cnpj" label="CNPJ" />
      <TextField source="nome_fantasia" label="Nome fantasia" />
      <TextField source="telefone1" label="Telefone 1" />
      <TextField source="usuario_pdv" label="Usuário" />
      <BooleanField source="ativo" label="Ativo" />
      <EditButton />
    </Datagrid>
  </List>
);

export default ParceiroList;
