import React from "react";
import { SaveButton, Toolbar } from "react-admin";
import { fetchJSON } from "../../dataProvider";

const LojaToolbar = (props) => {
  const transform = async (data) => {
    const previousData = props.record;
    console.log("transform appDeliveryUri", data, previousData);
    if (
      previousData &&
      previousData.appDeliveryUri &&
      data.appDeliveryUri !== previousData.appDeliveryUri
    ) {
      console.log("change appDeliveryUri");
      await fetchJSON("Lojas/deletarMenu", {
        method: "DELETE",
        body: JSON.stringify({ lojaId: previousData.id }),
      });
    }

    return data;
  };

  return (
    <Toolbar {...props}>
      <SaveButton transform={transform} />
    </Toolbar>
  );
};

export default LojaToolbar;
