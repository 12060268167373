import React from "react";
import {
  Box,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

function BillType({ value, onChange, id }) {
  const handleChange = (event) => onChange(id, event.target.value);

  return (
    <FormControl variant='standard' sx={{ minWidth: 180 }}>
      <InputLabel id='bill-type'>Tipo</InputLabel>
      <Select labelId='bill-type' onChange={handleChange} value={value}>
        <MenuItem value='PAGAR'>
          <Box display='flex'>
            <Avatar
              sx={{
                width: 20,
                height: 20,
                bgcolor: red[500],
                marginRight: 1,
              }}
            >
              <ArrowDownwardIcon fontSize='small' />
            </Avatar>
            Contas a pagar
          </Box>
        </MenuItem>
        <MenuItem value='RECEBER'>
          <Box display='flex'>
            <Avatar
              sx={{
                width: 20,
                height: 20,
                bgcolor: green[500],
                marginRight: 1,
              }}
            >
              <ArrowUpwardIcon fontSize='small' />
            </Avatar>
            Contas a receber
          </Box>
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export default BillType;
