import React from "react";
import { Edit } from "react-admin";

import NcmForm from "./NcmForm";
import NcmToolbar from "./NcmToolbar";

const NcmEdit = props => (
  <Edit {...props}>
    <NcmForm toolbar={<NcmToolbar />} />
  </Edit>
);

export default NcmEdit;
