import React, { useState, Fragment } from "react";
import { Edit, SaveButton, Toolbar, useUpdate } from "react-admin";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  form: {
    padding: "0 1em 1em 1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonIcon: {
    padding: 0,
  },
  icon: {
    marginBottom: 10,
  },
}));

function EditButton({ reference, onCreate, label, CreateForm, record }) {
  const [showDialog, setShowDialog] = useState(false);
  const classes = useStyles();
  const [update] = useUpdate(reference);

  const handleClick = () => setShowDialog(true);

  const handleCloseClick = () => setShowDialog(false);

  const onSubmit = (values) => {
    console.log(values, record);
    update(
      {
        payload: { id: record.id, data: values, previousData: record },
      },
      {
        onSuccess: ({ data: newRecord }) => {
          onCreate(newRecord);
          setShowDialog(false);
        },
      }
    );
  };

  return (
    <Fragment>
      <IconButton
        onClick={handleClick}
        color="primary"
        className={classes.buttonIcon}
      >
        <EditIcon className={classes.icon} />
      </IconButton>
      <Dialog
        fullWidth
        maxWidth={"lg"}
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>Editar {label}</DialogTitle>
        <DialogContent>
          <Edit
            basePath={`/${reference}`}
            id={record.id}
            resource={reference}
            component="div"
            title={" "}
          >
            <CreateForm
              toolbar={
                <Toolbar>
                  <SaveButton onSave={onSubmit} />
                </Toolbar>
              }
            />
          </Edit>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

export default EditButton;
