import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { Title } from "react-admin";
import { Pie } from "react-chartjs-2";
import { fetchJSON } from "../dataProvider";
import UXDelay from "../dataProvider/uxdelay";
import Search from "./Search";
import Table from "../common/table/TableReport";
import { useAuthenticated } from "react-admin";
import moment from "moment";
import Total from "./ReportTotalFaturamentoCard";
import uniq from "lodash/uniq";
import isEmpty from "lodash.isempty";
import { reportPDVEnable } from "../permissionsHelper";

const styles = {
  root: {
    minHeight: "100%",
  },
  toolbarContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  chartContainer: {
    height: 300,
    width: "90%",
  },
  totalCard: {
    maxWidth: 400,
  },
};

const ReportConciliacaoBancaria = (props) => {
  useAuthenticated();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ array: [], total: 0, categorias: [] });

  async function retrieveReportData({
    lojaId,
    startDate,
    endDate,
    intervalHour,
  }) {
    try {
      setLoading(true);
      const response = await UXDelay(
        fetchJSON("Movimento_recebimentos/conciliacaoBancaria", null, {
          lojaId,
          startDate,
          endDate,
          intervalHour,
        })
      );
      const dates = Object.keys(response);
      const array = dates
        .map((date) => ({ date, ...response[date] }))
        .sort((d1, d2) => new Date(d1).getTime() - new Date(d2).getTime());
      const total = dates
        .map((date) =>
          Object.values(response[date]).reduce((a1, a2) => a1 + a2, 0)
        )
        .reduce((a1, a2) => a1 + a2, 0);
      const categorias = uniq(
        dates
          .map((date) => Object.keys(response[date]))
          .reduce((c1, c2) => c1.concat(c2), [])
      );
      const categoriasTotal = categorias.map((categoria) =>
        dates
          .map((date) => response[date][categoria] || 0)
          .reduce((v1, v2) => v1 + v2, 0)
      );
      const chartData = {
        labels: categorias,
        datasets: [
          {
            data: categoriasTotal,
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
            hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          },
        ],
      };
      setData({ array, total, chartData, categorias });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  const columns = [
    {
      title: "Data",
      field: "data",
      render: (rowData) => moment(rowData.date).format("DD/MM/YYYY"),
    },
  ].concat(
    data.categorias.map((categoria) => {
      return {
        title: categoria,
        field: categoria,
        render: (rowData) =>
          Number(rowData[categoria] || 0).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }),
      };
    })
  );

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
  };

  const dataEmpty = isEmpty(data.array);

  return (
    <Card className={props.classes.root}>
      <Title title="Conciliação Bancária" />
      <CardContent>
        <Search
          fields={["dateRange", "intervalHour"]}
          onSearch={retrieveReportData}
          loading={loading}
          dataEmpty={dataEmpty}
        />
        {!dataEmpty && (
          <>
            <Grid
              container
              spacing={3}
              className={props.classes.toolbarContainer}
            >
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={props.classes.toolbarContainer}
                >
                  <Total
                    total={data.total}
                    className={props.classes.totalCard}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={props.classes.chartContainer}
                >
                  <Pie data={data.chartData} options={chartOptions} />
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Table
                  data={data.array}
                  columns={columns}
                  // options={options}
                  containerClassName={props.classes.root}
                  isLoading={loading}
                />
              </Grid>
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(ReportConciliacaoBancaria);

export const conciliacaoBancaria = {
  getMenu: (p, a) =>
    reportPDVEnable(p, a)
      ? {
          key: "conciliacaoBancaria",
          to: "/relatorio/conciliacao-bancaria",
          primaryText: "Conciliação Bancária",
        }
      : null,
};
