import React from "react";
import { Datagrid, List, NumberField, DateField } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import LogFilter from "./LogFilter";
import CopyPropButton from "../../common/form/CopyPropButton";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    maxWidth: 400,
    textOverflow: "ellipsis",
    whiteSpace: "normal",
    wordWrap: "break-word",
    overflow: "hidden",
  },
}));

const TextFieldSubstring = ({ record, source, max }) => {
  const value = record && record[source] ? record[source] : "";
  const valueSubstring =
    value.length > max ? value.slice(0, max) + "..." : value;
  const valueLines = valueSubstring.split("\n");
  return (
    <span>
      {valueLines.map((v) => (
        <div>{v}</div>
      ))}
    </span>
  );
};

const LogList = (props) => {
  const classes = useStyles();
  return (
    <List
      {...props}
      filters={<LogFilter />}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid rowClick="edit">
        <NumberField source="shopId" label="Loja Id" />
        <DateField
          source="createdAt"
          label="Criado em"
          showTime
          locales="pt-BR"
        />
        <CopyPropButton source="stack" />
        <TextFieldSubstring source="stack" max={400} />
        <CopyPropButton source="data" />
        <TextFieldSubstring
          source="data"
          max={400}
          cellClassName={classes.tableCell}
        />
      </Datagrid>
    </List>
  );
};

export default LogList;
