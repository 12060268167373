import React, { useState } from 'react';
import Switch from '@material-ui/core/Switch';
import { API_URL } from "../../config/constants";
import fetchJson from '../../dataProvider/fetchJson';
import { withUser } from '../../common/util/hocs';
import { useDataProvider, useNotify } from 'react-admin';

function ButtonStatusVenda ({ record, loja }) {
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(false);
    const notify = useNotify();

    const changeFirebase = async (data) => {
        if (loja.appDeliveryUri) {
            return fetchJson(`${API_URL}/apps/firebaseProdutoStatus`, {
                method: "POST",
                body: JSON.stringify({
                    lojaId: data.lojaId,
                    appDeliveryUri: loja.appDeliveryUri,
                    produtoId: data.id,
                    stt_prod: data.stt_prod,
                    appRetirada: loja.appRetirada,
                    appEntrega: loja.appEntrega,
                    appMesa: loja.appMesa,
                })
            });
        }
    }

    const handleChange = () => {
        setLoading(true);
        dataProvider
            .update('produtos', { 
                updateAttributes: true, 
                data: { 
                    id: record.id, 
                    lojaId: record.lojaId,
                    stt_prod: !record.stt_prod,
                } 
            })
            .then(response => changeFirebase(response.data))
            .catch(err => {
                notify("Erro do alterar status do produto", 'warning');
                console.log(JSON.stringify(err));
            })
            .finally(() => setLoading(false));
    }

    return record ? (
        <Switch
            checked={record.stt_prod}
            onChange={handleChange}
            color="primary"
            disabled={loading}
        />
    ) : null;
}

export default withUser(ButtonStatusVenda);