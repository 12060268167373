import React from "react";
import { TextInput, NumberInput, DateTimeInput, Filter } from "react-admin";

const LojaFilter = props => (
  <Filter {...props}>
    <NumberInput source="id" label="Id" alwaysOn />
    <TextInput source="cnpj_like" label="CNPJ" alwaysOn />
    <TextInput source="q" label="Nome ou Razão social" alwaysOn />
    <TextInput source="appDeliveryUri_like" label="App delivery nome" alwaysOn />
    <DateTimeInput source="updatedAt_gte" label="Atualizado de" />
    <DateTimeInput source="updatedAt_lte" label="Atualizado até" />
  </Filter>
);

export default LojaFilter;
