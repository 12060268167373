import React from "react";
import { SelectInput, SelectField } from "react-admin";

const tipos = [
  { id: 1, name: "1 - Operação Interna" },
  { id: 2, name: "2 - Operação Interestadual" },
  { id: 3, name: "3 - Operação com Exterior" },
];

function NFeIdentificadorOperacaoInput(props) {
  return (
    <SelectInput
      label="Identificador de Operação"
      source="identificador_operacao"
      choices={tipos}
      initialValue={1}
      {...props}
    />
  );
}

function NFeIdentificadorOperacaoField(props) {
  return (
    <SelectField
      label="Identificador de Operação"
      source="identificador_operacao"
      choices={tipos}
      {...props}
    />
  );
}

export { NFeIdentificadorOperacaoInput, NFeIdentificadorOperacaoField };
