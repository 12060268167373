import React from "react";
import { Datagrid, DateField, List, TextField } from "react-admin";
import ConnectStoneStatus from "./ConnectStoneStatus";
import ConnectStoneCancelarButton from "./ConnectStoneCancelarButton";
import CurrencyField from "../../common/form/CurrencyField";
import ConnectStoneFilter from "./ConnectStoneFilter";

const ConnectStoneList = (props) => (
  <List
    title="Listar Pagamentos do Connect Stone"
    filters={<ConnectStoneFilter />}
    {...props}
    sort={{ field: "id", order: "DESC" }}
    bulkActionButtons={false}
    exporter={false}
  >
    <Datagrid>
      <TextField source="id" label="Id" />
      <TextField source="tipo" label="Tipo" />
      <TextField source="terminalSN" label="Terminal" />
      <DateField source="createdAt" label="Data" showTime={true} />
      <CurrencyField source="valor" label="Valor" />
      <ConnectStoneStatus label="Status" />
      <ConnectStoneCancelarButton />
    </Datagrid>
  </List>
);

export default ConnectStoneList;
