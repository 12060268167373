import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import {
  ArrayField,
  Button,
  Datagrid,
  DateField,
  SimpleShowLayout,
} from "react-admin";
import CircularProgress from "../../common/CircularProgress";
import CurrencyField from "../../common/form/CurrencyField";
import useFetch from "../../dataProvider/useFetch";

const ModalPedidos = ({ record, lojaId }) => {
  const [open, setOpen] = React.useState(false);
  const { data, isLoading, error, updateSkip } = useFetch(
    "Apps/user/points",
    {
      appUserId: record.id,
      uuid: record.uuid,
      lojaId,
    },
    {
      method: "POST",
    },
    true
  );

  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    setOpen(true);
    updateSkip(false);
  };

  return (
    <>
      <Button label="pedidos" onClick={handleOpen} />
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle>{`${record.nome} - Pedidos válidos`}</DialogTitle>
        <DialogContent>
          {isLoading && <CircularProgress />}
          {error && error.message}
          <SimpleShowLayout record={data}>
            <ArrayField source="pedidosValidos" label="" fullWidth>
              <Datagrid>
                <DateField source="createdAt" label="Pedido em" />
                <CurrencyField source="fidelityDiscount" label="R$ Desconto" />
                <CurrencyField source="totalDisplay" label="R$ Total" />
              </Datagrid>
            </ArrayField>
          </SimpleShowLayout>
        </DialogContent>
      </Dialog>
    </>
  );
};

const FidelidadePedidosButton = ({ record, lojaId }) => {
  return record ? <ModalPedidos record={record} lojaId={lojaId} /> : null;
};

export default FidelidadePedidosButton;
