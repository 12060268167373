import React from "react";
import { Create } from "react-admin";

import InventarioForm from "./InventarioForm";
import InventarioToolbar from "./InventarioToolbar";

const InventarioCreate = (props) => (
  <Create {...props}>
    <InventarioForm toolbar={<InventarioToolbar />} />
  </Create>
);

export default InventarioCreate;
