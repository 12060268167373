import React from "react";
import { BooleanInput, Button } from "react-admin";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { useForm } from "react-final-form";

export default function DataGridRemoveButton({
  label,
  arrayName,
  formData,
  record,
  onClick,
  ...rest
}) {
  const form = useForm();
  const source = `${arrayName}[${record.index}].deletar`;

  const handleRemove = (e) => {
    const { index } = record;
    const newArray = [...formData[arrayName].filter((_, i) => i !== index)].map(
      (item, index) => ({ ...item, index })
    );

    if (onClick instanceof Function) {
      onClick(arrayName, newArray);
    } else {
      form.change(arrayName, newArray);
    }
  };

  const handleChange = (boolValue) => {
    if (onClick instanceof Function) {
      onClick(source, boolValue);
    }
  };

  return record && record.id ? (
    <BooleanInput
      source={source}
      label={label || "Excluir"}
      {...rest}
      onChange={handleChange}
    />
  ) : (
    <Button label="Remover" onClick={handleRemove} {...rest}>
      <RemoveCircleOutlineIcon />
    </Button>
  );
}
