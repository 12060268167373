import React from "react";
import LanguageIcon from "@material-ui/icons/Language";
import NFeEmissao from "./NFeEmissaoList";
import NFeEmissaoCreate from "./NFeEmissaoCreate";
import NFeEmissaoEdit from "./NFeEmissaoEdit";
import { estoqueEnable } from "../../permissionsHelper";

const pedido = {
  getResource: (p, a) => {
    return estoqueEnable(p, a)
      ? {
          name: "NFe_emissaos",
          list: NFeEmissao,
          create: NFeEmissaoCreate,
          edit: NFeEmissaoEdit,
        }
      : null;
  },
  getMenu: (p, a) => {
    return estoqueEnable(p, a)
      ? {
          key: "NFe_emissaos",
          to: "/NFe_emissaos",
          primaryText: "NFe Emitidas",
          leftIcon: iconNFeEmitida,
        }
      : null;
  },
};

export default pedido;
export const iconNFeEmitida = <LanguageIcon />;
