import { InputAdornment, Tooltip, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { NumberInput } from "react-admin";

const useStyles = makeStyles({
  input: {
    width: "8em",
    marginRight: "1em",
  },
  readOnlyInput: {
    cursor: "not-allowed",
  },
  formRow: {
    display: "flex",
    width: "99%",
  },
});

// const validateReq = [required()];

export default function PedidosTotais(props) {
  const classes = useStyles();

  return (
    <Grid container align="center" alignItems="center">
      {/* <Grid item>
        <NumberInput
          className={classes.input}
          label="R$ Frete"
          source="total_frete"
          validate={validateReq}
          initialValue={0}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item>
        <NumberInput
          className={classes.input}
          label="R$ Seguro"
          source="total_seguro"
          initialValue={0}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
          validate={validateReq}
        />
      </Grid>
      <Grid item>
        <NumberInput
          className={classes.input}
          label="R$ Outros"
          source="total_outro"
          initialValue={0}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
          validate={validateReq}
        />
      </Grid> */}
      <Grid item>
        <Tooltip title="Preenchido automaticamente">
          <NumberInput
            variant="outlined"
            className={`${classes.input} ${classes.readOnlyInput}`}
            label="R$ Produtos"
            source="total_produtos"
            initialValue={0}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              ),
              readOnly: true,
              classes: {
                input: classes.readOnlyInput,
              },
            }}
          />
        </Tooltip>
      </Grid>
      {/* <Grid item>
        <NumberInput
          className={classes.input}
          label="R$ Descontos"
          source="total_descontos"
          initialValue={0}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
          validate={validateReq}
        />
      </Grid> */}
      <Grid item>
        <Tooltip title="Preenchido automaticamente">
          <NumberInput
            variant="outlined"
            className={`${classes.input} ${classes.readOnlyInput}`}
            label="R$ Total"
            source="total_pedido"
            initialValue={0}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              ),
              readOnly: true,
              classes: {
                input: classes.readOnlyInput,
              },
            }}
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
}
