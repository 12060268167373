import React from "react";
import { Create } from "react-admin";

import ImpressoraForm from "./ImpressoraForm";
import ImpressoraToolbar from "./ImpressoraToolbar";

const ImpressoraCreate = props => (
  <Create {...props}>
    <ImpressoraForm toolbar={<ImpressoraToolbar />} />
  </Create>
);

export default ImpressoraCreate;
