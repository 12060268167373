import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import {
  TabbedForm,
  FormTab,
  Datagrid,
  DateField,
  ArrayField,
  required,
  FormDataConsumer,
} from "react-admin";
import { IdDates } from "../../common/form/ConditionalInputs";
import PedidosTotais from "./PedidosTotais";
import {
  PedidoStatusInput,
  PedidoStatusField,
  DIGITACAO,
} from "./PedidoStatus";
import { PedidoTipoInput } from "./PedidoTipo";
import DetailsButton from "./DetailsButton";
import { Grid } from "@mui/material";
import Decimal from "decimal.js";
import ParceiroLazyReferenceInput from "../parceiro/ParceiroLazyReferenceInput";
import ParceiroForm from "../parceiro/ParceiroForm";
import PedidosProdutosGrid from "./PedidosProdutosGrid";
import PedidosContasGrid from "./PedidosContasGrid";

const initialValues = {
  total_frete: 0,
  total_seguro: 0,
  total_outro: 0,
  total_produtos: 0,
  total_descontos: 0,
  total_pedido: 0,

  itens: [],
  contas: [],

  recalc: 0,
};

const validateReq = [required()];

const useStyles = makeStyles({
  formRow: {
    display: "flex",
    width: "99%",
  },
  autocomplete: {
    width: "11 em",
    marginRight: "1em",
  },
  input: {
    marginRight: "1em",
  },
  itemInput: {
    marginRight: "10px",
    display: "inline-block",
    verticalAlign: "middle",
  },
  statusForm: {
    "& li > p": {
      display: "none",
    },
  },
  historico: {
    width: "25em",
  },
});

const validatePedido = (values) => {
  const errors = {};
  if (values.contas && values.status !== DIGITACAO) {
    const total_pago = values.contas
      .filter((c) => c && c.valor)
      .reduce((t, c) => t.plus(c.valor), new Decimal(0));
    const totaisIguais = total_pago.minus(values.total_pedido).equals(0);
    if (!totaisIguais) {
      errors.status = "Total dos pagamentos deve ser igual ao total do pedido";
    }
  }
  return errors;
};

function PedidoForm(props) {
  const classes = useStyles();
  const { record } = props;
  const creating = !record || !record.id;
  const loaded = creating || record.loaded;
  const canEdit = creating || record.status === DIGITACAO;

  return (
    <TabbedForm
      {...props}
      initialValues={initialValues}
      validate={validatePedido}
    >
      <FormTab label="Dados do pedido">
        <Grid container alignItems="center" fullWidth spacing={2}>
          <Grid item>
            <IdDates record={record} />
          </Grid>
          <Grid item>
            <DetailsButton record={record} text="Compartilhar" />
          </Grid>
        </Grid>

        <Grid container alignItems="center" fullWidth spacing={2}>
          <Grid item>
            <PedidoTipoInput validate={validateReq} />
          </Grid>
          <Grid item>
            <PedidoStatusInput label="Status" source="status" />
          </Grid>
          <Grid item>
            <ParceiroLazyReferenceInput
              label="Parceiro"
              source="parceiroId"
              allowEmpty
              record={record}
              addCreateButton={true}
              CreateForm={ParceiroForm}
              required
            />
          </Grid>
        </Grid>
        <PedidosTotais />

        <FormDataConsumer>
          {({ formData }) => (
            <PedidosProdutosGrid
              loaded={loaded}
              canEdit={canEdit}
              formData={formData}
            />
          )}
        </FormDataConsumer>
      </FormTab>

      <FormTab label="Pagamentos">
        <FormDataConsumer>
          {({ formData }) => (
            <PedidosContasGrid
              loaded={loaded}
              canEdit={canEdit}
              formData={formData}
            />
          )}
        </FormDataConsumer>
      </FormTab>

      <FormTab label="Histórico de status">
        {record.id && (
          <ArrayField
            className={classes.historico}
            source="historico_status"
            label=""
          >
            <Datagrid className={classes.historico}>
              <DateField source="createdAt" label="Criado em" showTime={true} />
              <PedidoStatusField label="Status" />
            </Datagrid>
          </ArrayField>
        )}
      </FormTab>
    </TabbedForm>
  );
}

export default PedidoForm;
