import React from 'react';
import { 
    Labeled,
    DateField as DFRA,
    useLocale
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    labeled: { 
        width: 256,
    }
});

export const DateFieldNL = props => {
    const locale = useLocale();
    return <DFRA locales={locale} {...props} />
}

export const DateField = ({label, ...rest}) => {
    const classes = useStyles();
    return <Labeled label={label} className={classes.labeled}>
        <DateFieldNL {...rest} />
    </Labeled>
}