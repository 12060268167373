import React from "react";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import UsuarioLojaCreate from "./UsuarioLojaCreate";
import UsuarioLojaList from "./UsuarioLojaList";
import UsuarioLojaEdit from "./UsuarioLojaEdit";
import { isHighLevel } from "../../permissionsHelper";

const usuarios_loja = {
  getResource: (p, a) => {
    return {
      name: "user_lojas",
      create: isHighLevel(p, a) ? UsuarioLojaCreate : null,
      list: isHighLevel(p, a) ? UsuarioLojaList : null,
      edit: isHighLevel(p, a) ? UsuarioLojaEdit : null,
    };
  },
  getMenu: (p, a) =>
    isHighLevel(p, a)
      ? {
          key: "user_lojas",
          to: "/user_lojas",
          primaryText: "Usuários",
          leftIcon: iconUsuarioLoja,
        }
      : null,
};

export default usuarios_loja;
export const iconUsuarioLoja = <GroupWorkIcon />;
