import React from "react";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import CertificadoDigitalList from "./CertificadoDigitalList";

const certificadoDigital = {
  getResource: (p) => {
    if (p.ADMIN) {
      return {
        name: "Loja_certificados",
        list: CertificadoDigitalList,
      };
    }
  },
  getMenu: (p) => {
    if (p.ADMIN) {
      return {
        key: "Loja_certificados",
        to: "/Loja_certificados",
        primaryText: "Certificados",
        leftIcon: iconCertificadoDigital,
      };
    }
  },
};

export default certificadoDigital;
export const iconCertificadoDigital = <FingerprintIcon />;
