import React from "react";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import MoedaCreate from "./MoedaCreate";
import MoedaEdit from "./MoedaEdit";
import MoedaList from "./MoedaList";

const moeda = {
  getResource: (p, a) => {
    return {
      name: "moedas",
      create: p.cadastros_ver ? MoedaCreate : null,
      list: p.cadastros_ver ? MoedaList : null,
      edit: p.cadastros_ver ? MoedaEdit : null,
    };
  },
  getMenu: (p, a) =>
    p.cadastros_ver
      ? {
          key: "moedas",
          to: "/moedas",
          primaryText: "Moedas PDV",
          leftIcon: iconMoeda,
        }
      : null,
};

export default moeda;
export const iconMoeda = <AccountBalanceWalletIcon />;
