import React, { useState } from "react";
import { useNotify, useDataProvider } from "react-admin";
import CheckIcon from "@material-ui/icons/Check";
import { fetchJSON } from "../../dataProvider";
import { blue } from "@material-ui/core/colors";
import { styled } from "@mui/material/styles";
import LoadingButton from "../../common/LoadingButton";

const ColorButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: blue[500],
  "&:hover": {
    backgroundColor: blue[700],
  },
}));

function NFeCienciaOperacaoButton({ record }) {
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const handleClick = async () => {
    setLoading(true);
    try {
      const resp = await fetchJSON("Sefaz/cienciaOperacao", {
        method: "POST",
        body: JSON.stringify({
          eventoId: record.id,
          lojaId: record.lojaId,
        }),
      });

      await dataProvider.update("nfes", {
        onlyUpdateLocalStore: true,
        data: {
          ...record,
          ...resp.nfeInstance,
        },
      });

      notify("Ciência da operação manifestada com sucesso");
    } catch (err) {
      notify("Erro ao manifestar ciência da operação", "warning");
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ColorButton
      text="Manifestar"
      onClick={handleClick}
      loading={loading}
      icon={<CheckIcon fontSize="large" />}
      variant="contained"
    />
  );
}

export default NFeCienciaOperacaoButton;
