import React from "react";
import { Edit } from "react-admin";

import UnidadeForm from "./UnidadeForm";
import UnidadeToolbar from "./UnidadeToolbar";

const UnidadeEdit = props => (
  <Edit {...props}>
    <UnidadeForm toolbar={<UnidadeToolbar />} />
  </Edit>
);

export default UnidadeEdit;
