import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import { formatCNPJCPF } from "../../common/util/formatter";

const NFeEmitente = ({ record }) => {
  if (!record) return null;
  return (
    <Box my={1}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography variant="body1">
            <strong>{record.xNome}</strong>
          </Typography>
        </Grid>
        {record.CNPJ && (
          <Grid item>
            <Typography variant="body2">
              {formatCNPJCPF(record.CNPJ)}
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Typography variant="body2">{record.chNFe}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NFeEmitente;
