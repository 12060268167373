import { Box, Card, CardContent } from "@material-ui/core";
import React from "react";

import { useStyles } from "./styles";

const EmptyChartLayout = () => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginY={3}
        >
          <p>Nenhum registro localizado.</p>
        </Box>
      </CardContent>
    </Card>
  );
};

export default EmptyChartLayout;
