import React from "react";
import { Create } from "react-admin";
import NFeEmissaoForm from "./NFeEmissaoForm";
import NFeToolbar from "./NFeEmissaoToolbar";

export default function NFeEmissaoCreate(props) {
  return (
    <Create {...props}>
      <NFeEmissaoForm toolbar={<NFeToolbar />} />
    </Create>
  );
}
