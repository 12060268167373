import React from "react";
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  BooleanField,
  ReferenceField,
} from "react-admin";
import { UsuarioTipoField } from "../usuario/UsuarioTipoInput";

const UsuarioLojaList = (props) => (
  <List
    title="Listar Usuários"
    {...props}
    exporter={false}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source="usuario.email" label="Email" />
      <ReferenceField
        source="parceiroId"
        reference="parceiros"
        label="Vendedor"
      >
        <TextField source="nome_fantasia" />
      </ReferenceField>
      <BooleanField source="usuario.emailVerified" label="Senha Criada" />
      <UsuarioTipoField source="type" label="Perfil" />
      <EditButton />
    </Datagrid>
  </List>
);

export default UsuarioLojaList;
