import React from "react";
import { TextInput, Filter } from "react-admin";

const UnidadeFilter = props => (
  <Filter {...props}>
    <TextInput source="nome_like" label="Nome da impressora" alwaysOn />
  </Filter>
);

export default UnidadeFilter;
