import React from "react";
import { Edit } from "react-admin";
import ClassificacaoForm from "./ClassificacaoForm";

const ClassificacaoEdit = (props) => (
  <Edit {...props}>
    <ClassificacaoForm />
  </Edit>
);

export default ClassificacaoEdit;
