import React from "react";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import InventarioCreate from "./InventarioCreate";
import InventarioEdit from "./InventarioEdit";
import InventarioList from "./InventarioList";
import { estoqueEnable } from "../../permissionsHelper";

const inventario = {
  getResource: (p, a) => {
    return estoqueEnable(p, a)
      ? {
          name: "inventarios",
          create: InventarioCreate,
          list: InventarioList,
          edit: InventarioEdit,
        }
      : null;
  },
  getMenu: (p, a) => {
    return estoqueEnable(p, a)
      ? {
          key: "inventarios",
          to: "/inventarios",
          primaryText: "Inventarios",
          leftIcon: iconInventario,
        }
      : null;
  },
};

export default inventario;
export const iconInventario = <PlaylistAddCheckIcon />;
