import React from "react";
import { Edit } from "react-admin";

import MoedaAppForm from "./MoedaAppForm";
import MoedaAppToolbar from "./MoedaAppToolbar";

const MoedaAppEdit = props => (
  <Edit {...props}>
    <MoedaAppForm toolbar={<MoedaAppToolbar />} />
  </Edit>
);

export default MoedaAppEdit;
