import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { uploadImages } from "../aws";
import { CircularProgress } from "@mui/material";
import { FOTO_PREFIX } from "../config/constants";
import { useDataProvider } from "react-admin";

function ListImage({ record, resource }) {
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);

  const source = "fotoUrl";
  const fileSource = `${FOTO_PREFIX}${source}`;

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length === 0) return;

      setLoading(true);

      const data = {
        id: record.id,
        lojaId: record.lojaId,
        [source]: record[source],
        [fileSource]: {
          rawFile: acceptedFiles[0],
        },
      };

      await uploadImages(resource, data);

      dataProvider.update(resource, {
        updateAttributes: true,
        data: {
          id: record.id,
          lojaId: record.lojaId,
          [source]: data[source],
        },
      });

      setLoading(false);
    },
    [record, resource, source, fileSource, dataProvider]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  if (!record) return null;

  const currentUrl = record[source];

  // if (record.id === 3656) {
  //   console.log(record.fotoUrl);
  // }

  const dragStyle = {
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
  };

  return (
    <div
      {...getRootProps()}
      style={{
        minWidth: 180,
        minHeight: 80,
        textAlign: "center",
        ...(isDragActive ? dragStyle : {}),
      }}
    >
      <input {...getInputProps()} />

      {isDragActive && <p>Solte o arquivo aqui</p>}

      {loading && <CircularProgress size="2rem" sx={{ mt: 3 }} />}

      {!loading && currentUrl && (
        <img
          src={currentUrl}
          alt="imagem do grupo"
          style={{ margin: 5, maxWidth: 180, maxHeight: 80 }}
        />
      )}
    </div>
  );
}

export default ListImage;
