import React from "react";
import { SelectInput } from "react-admin";

export const OPCOES_PIS = [
  {
    id: "01",
    name: "01 - Operação Tributável (base de cálculo = valor da operação (alíquota normal (cumulativo/não cumulativo)))",
  },
  {
    id: "02",
    name: "02 - Operação Tributável (base de cálculo = valor da operação (alíquota diferenciada)",
  },
  // {
  //   id: "03",
  //   name: "03 - Operação Tributável (base de cálculo = quantidade vendida (alíquota por unidade de produto)",
  // },
  {
    id: "04",
    name: "04 - Operação Tributável (tributação monofásica (alíquota zero)",
  },
  { id: "05", name: "05 - Operação Tributável por Substituição Tributária" },
  { id: "06", name: "06-  Operação Tributável (alíquota zero" },
  { id: "07", name: "07 - Operação Isenta da Contribuição" },
  { id: "08", name: "08 - Operação Sem Incidência da Contribuição" },
  { id: "09", name: "09 - Operação com Suspensão da Contribuição" },
  { id: "49", name: "49 - Outras Operações de Saída" },
  { id: "99", name: "99 - Outras Operações" },
];
const PISInput = (props) => {
  return <SelectInput {...props} choices={OPCOES_PIS} />;
};

export default PISInput;
