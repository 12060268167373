import React from "react";
import {
  SimpleForm,
  TextInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  required,
  maxLength,
  minValue,
  FormDataConsumer,
  useDataProvider,
  useNotify,
  maxValue,
} from "react-admin";
import { useForm } from "react-final-form";
import { InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IdDates } from "../../common/form/ConditionalInputs";
import { withUser } from "../../common/util/hocs";
import ArrayInputRemoveButton from "../../common/form/ArrayInputRemoveButton";
import NumberInputSimple from "../../common/form/NumberInputSimple";
import ProdutoLazyReferenceInput from "../produto/ProdutoLazyReferenceInput";
import { validateDecimalPlaces } from "../../common/util/ra-validations";

const useStyles = makeStyles({
  formNome: {
    display: "inline-block",
    marginRight: 32,
    width: "18em",
  },
  formProd: {
    display: "inline-block",
    marginRight: 32,
    width: "18em",
  },
  formGroup: {
    display: "inline-block",
    marginRight: 32,
  },
  preco: {
    display: "inline-block",
    marginRight: 32,
    width: "7em",
  },
  excluir: {
    display: "inline-block",
    marginRight: 32,
    width: "5em",
  },
});

const validateDesc = [required(), maxLength(100)];
const validateQtdMin = [required(), minValue(0)];
const validateQtdMax = [required(), minValue(1)];
const validateVenda = [
  required(),
  minValue(0),
  maxValue(9999999999.99),
  validateDecimalPlaces(2),
];

function ProdutoReferenceInput({
  price2,
  price3,
  price4,
  getSource,
  ...props
}) {
  const form = useForm();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleChangeProduct = async (productId) => {
    if (!productId) return;
    try {
      const { data: produto } = await dataProvider.getOne("produtos", {
        id: productId,
      });

      form.change(getSource("nome"), produto.desc_prod);
      form.change(getSource("valor"), produto.preco_vnd_prod);

      if (price2) {
        form.change(getSource("valor2"), produto.preco_vnd2_prod);
      }

      if (price3) {
        form.change(getSource("valor3"), produto.preco_vnd3_prod);
      }

      if (price4) {
        form.change(getSource("valor4"), produto.preco_vnd4_prod);
      }
    } catch (error) {
      notify("Erro ao carregar os dados do produto.", "warning");
      console.log(JSON.stringify(error));
    }
  };

  return (
    <ProdutoLazyReferenceInput
      source={getSource("produtoId")}
      nameSource={getSource("produto")}
      label="Produto"
      onChange={handleChangeProduct}
      allowEmpty
      {...props}
    />
  );
}

function PerguntasForm(props) {
  const classes = useStyles();
  const { loja, record } = props;

  const price2 = loja.tabelasPreco.length > 1 && loja.tabelasPreco[1].nome;
  const price3 = loja.tabelasPreco.length > 2 && loja.tabelasPreco[2].nome;
  const price4 = loja.tabelasPreco.length > 3 && loja.tabelasPreco[3].nome;

  return (
    <SimpleForm {...props} toolbar={props.toolbar} redirect="list">
      <IdDates />
      <TextInput
        source="descricao"
        label="Pergunta"
        validate={validateDesc}
        fullWidth
      />

      <NumberInputSimple
        source="qtd_min"
        label="Quantidade mínima de respostas"
        initialValue={0}
        validate={validateQtdMin}
        formClassName={classes.formGroup}
      />

      <NumberInputSimple
        source="qtd_max"
        label="Quantidade máxima de respostas"
        initialValue={1}
        validate={validateQtdMax}
        formClassName={classes.formGroup}
      />

      <BooleanInput
        source="repetir_resposta"
        label="Repetir resposta?"
        formClassName={classes.formGroup}
      />

      <ArrayInput source="respostas" label="Respostas" fullWidth>
        <SimpleFormIterator
          disableRemove
          TransitionProps={{ enter: false, exit: false }}
        >
          <FormDataConsumer formClassName={classes.formProd}>
            {({ getSource }) => (
              <ProdutoReferenceInput
                record={record}
                price2={price2}
                price3={price3}
                price4={price4}
                getSource={getSource}
                fullWidth
              />
            )}
          </FormDataConsumer>

          <TextInput
            source="nome"
            label="Nome da resposta"
            validate={validateDesc}
            formClassName={classes.formNome}
            fullWidth
          />

          {loja.tabelasPreco.length <= 1 && (
            <NumberInputSimple
              source="valor"
              label={
                loja.tabelasPreco.length > 0 && loja.tabelasPreco[0].nome
                  ? loja.tabelasPreco[0].nome
                  : "Valor"
              }
              fullWidth
              formClassName={classes.preco}
              initialValue={0}
              validate={validateVenda}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
            />
          )}

          <BooleanInput
            source="ativo"
            label="Ativo"
            initialValue={true}
            fullWidth
            formClassName={classes.excluir}
          />

          <FormDataConsumer formClassName={classes.excluir}>
            {({ formData, scopedFormData, getSource }) => (
              <ArrayInputRemoveButton
                formData={formData}
                itemData={scopedFormData}
                getSource={getSource}
              />
            )}
          </FormDataConsumer>

          {loja.tabelasPreco.length > 1 && <div />}

          {loja.tabelasPreco.length > 1 && (
            <NumberInputSimple
              source="valor"
              label={
                loja.tabelasPreco.length > 0 && loja.tabelasPreco[0].nome
                  ? loja.tabelasPreco[0].nome
                  : "Valor"
              }
              fullWidth
              formClassName={classes.preco}
              initialValue={0}
              validate={validateVenda}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
            />
          )}

          {price2 && (
            <NumberInputSimple
              source="valor2"
              label={loja.tabelasPreco[1].nome}
              fullWidth
              formClassName={classes.preco}
              initialValue={0}
              validate={validateVenda}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
            />
          )}

          {price3 && (
            <NumberInputSimple
              source="valor3"
              label={loja.tabelasPreco[2].nome}
              fullWidth
              formClassName={classes.preco}
              initialValue={0}
              validate={validateVenda}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
            />
          )}

          {price4 && (
            <NumberInputSimple
              source="valor4"
              label={loja.tabelasPreco[3].nome}
              fullWidth
              formClassName={classes.preco}
              initialValue={0}
              validate={validateVenda}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
            />
          )}
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
}

export default withUser(PerguntasForm);
