import React from "react";
import {
  SimpleForm,
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  BooleanInput,
  NumberInput,
  AutocompleteInput,
  required,
  maxLength,
  FormDataConsumer,
} from "react-admin";
import { Grid } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import LazyReferenceInput from "../../common/form/LazyReferenceInput";
import ImageInputPreview from "../../common/form/ImageInputPreview";
import { IdDates } from "../../common/form/ConditionalInputs";
import ColorInput from "../../common/form/ColorInput";
import ArrayInputRemoveButton from "../../common/form/ArrayInputRemoveButton";
import DisponibilidadeItem from "./DisponibilidadeItem";

const validateReq = [required()];
const validateNome = [required(), maxLength(100)];
const validateRemoveProd = [maxLength(45)];

const useStyles = makeStyles({
  formGroup: { display: "inline-block", marginRight: 32 },
});

function GrupoAddItem({ record, formData, itemData, getSource }) {
  return (
    <Grid container alignItems="center" spacing={3}>
      <Grid item>
        <LazyReferenceInput
          record={record}
          source={getSource("grupoAddId")}
          nameSource={getSource("grupo")}
          label="Grupo"
          reference="grupos"
          validate={validateReq}
        >
          <AutocompleteInput optionText="nome" />
        </LazyReferenceInput>
      </Grid>
      <Grid item>
        <ArrayInputRemoveButton
          formData={formData}
          itemData={itemData}
          getSource={getSource}
        />
      </Grid>
    </Grid>
  );
}

function GruposForm(props) {
  const record = props.record;
  const classes = useStyles();
  return (
    <SimpleForm {...props} redirect="list">
      <IdDates />

      <TextInput
        source="nome"
        label="Nome do grupo"
        validate={validateNome}
        formClassName={classes.formGroup}
      />

      <LazyReferenceInput
        label="Grupo pai"
        source="paiId"
        reference="grupos"
        nameSource="pai"
        allowEmpty
        formClassName={classes.formGroup}
      >
        <AutocompleteInput optionText="nome" />
      </LazyReferenceInput>

      <BooleanInput
        source="status"
        label="Status"
        defaultValue={true}
        formClassName={classes.formGroup}
      />
      <div></div>

      <BooleanInput
        source="android"
        label="Android"
        formClassName={classes.formGroup}
      />

      <BooleanInput
        source="categoria"
        label="Categoria"
        formClassName={classes.formGroup}
      />

      <BooleanInput
        source="pizza"
        label="Pizza"
        formClassName={classes.formGroup}
      />
      <div></div>

      <ColorInput source="cor" label="Cor" formClassName={classes.formGroup} />
      <NumberInput
        source="ordem"
        label="Ordem"
        formClassName={classes.formGroup}
      />
      <TextInput
        source="remove_prod"
        label="Remove do nome do produto"
        validate={validateRemoveProd}
        formClassName={classes.formGroup}
      />

      <ArrayInput
        source="grupos_add"
        label="Lista de grupos adicionais"
        fullWidth
      >
        <SimpleFormIterator
          disableReordering
          TransitionProps={{ enter: false, exit: false }}
          disableRemove
        >
          <FormDataConsumer>
            {({ formData, scopedFormData, getSource }) => (
              <GrupoAddItem
                record={record}
                formData={formData}
                itemData={scopedFormData}
                getSource={getSource}
              />
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>

      <ArrayInput source="disponibilidades" label="Disponibilidade" fullWidth>
        <SimpleFormIterator
          disableReordering
          TransitionProps={{ enter: false, exit: false }}
          disableRemove
        >
          <FormDataConsumer>
            {({ formData, scopedFormData, getSource }) => (
              <DisponibilidadeItem
                record={record}
                formData={formData}
                itemData={scopedFormData}
                getSource={getSource}
              />
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
      <FormDataConsumer>
        {({ formData }) => (
          <ImageInputPreview record={record} formData={formData} />
        )}
      </FormDataConsumer>
    </SimpleForm>
  );
}

export default GruposForm;
