import React from "react";
import { Edit, TopToolbar } from "react-admin";
import ProdutoForm from "./ProdutoForm";
import PermissionCloneButton from "../../common/form/CloneButton";
import PermissionToolbar from "../../common/form/PermissionToolbar";

const ProdutoEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <PermissionCloneButton
      permissionKey="cardapio_editar"
      basePath={basePath}
      record={data}
    />
  </TopToolbar>
);

const ProdutoEdit = (props) => (
  <Edit actions={<ProdutoEditActions />} {...props}>
    <ProdutoForm toolbar={<PermissionToolbar canDelete="cardapio_excluir" />} />
  </Edit>
);

export default ProdutoEdit;
