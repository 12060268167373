import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import {
  DateInput,
  NumberInput,
  BooleanInput,
  SelectInput,
  required,
  minValue,
} from "react-admin";
import { useForm } from "react-final-form";
import { addMonths, addWeeks } from "date-fns";

const validateReq = [required()];
const validateOcorrencias = [required(), minValue(2)];

const useStyles = makeStyles({
  numberInput: {
    width: "7em",
  },
  dateInput: {
    width: "10em",
    marginRight: "1em",
  },
});

const recorrencias = [
  {
    id: "mensal",
    name: "Mensalmente",
    getDate: (data, index) => addMonths(data, index),
  },
  {
    id: "semanal",
    name: "Semanalmente",
    getDate: (data, index) => addWeeks(data, index),
  },
  {
    id: "personalizado",
    name: "Personalizado",
    getDate: (data, index) => data,
  },
];

const proximosVencimentos = (startDate, recorrencia, ocorrencias) => {
  const n = parseInt(ocorrencias, 10);
  if (n < 2) return [];
  const rec = recorrencias.find((r) => r.id === recorrencia);
  const list = Array(n)
    .fill(startDate)
    .map((data, index) =>
      rec.getDate(data, index).toISOString().substring(0, 10)
    );
  return list;
};

export default function ContaRepete({ formData }) {
  const form = useForm();
  const classes = useStyles();
  const { vencimento, repete, recorrencia, ocorrencias, vencimentos } =
    formData;

  useEffect(() => {
    if (!vencimento || !ocorrencias || !recorrencia) {
      return;
    }

    const n = vencimento.split(/\D/);
    const startDate = new Date(n[0], n[1] - 1, n[2]);
    const novosVencimentos = proximosVencimentos(
      startDate,
      recorrencia,
      ocorrencias
    );
    form.change("vencimentos", novosVencimentos);
  }, [vencimento, recorrencia, ocorrencias, form]);

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <BooleanInput source="repete" />
      </Grid>
      {repete && (
        <>
          <Grid item>
            <SelectInput
              label="Recorrência"
              source="recorrencia"
              defaultValue={"mensal"}
              choices={recorrencias}
              validate={validateReq}
            />
          </Grid>
          <Grid item>
            <NumberInput
              className={classes.numberInput}
              label="Ocorrências"
              source="ocorrencias"
              defaultValue={2}
              validate={validateOcorrencias}
            />
          </Grid>
          <Grid item xs={12}>
            {vencimentos &&
              vencimentos.map((data, index) => (
                <DateInput
                  className={classes.dateInput}
                  label={`${index + 1}º Vencimento`}
                  source={`vencimentos[${index}]`}
                  validate={validateReq}
                  disabled={recorrencia !== "personalizado"}
                  defaultValue={data}
                />
              ))}
          </Grid>
        </>
      )}
    </Grid>
  );
}
