import React from "react";
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
import PerguntaCreate from "./PerguntaCreate";
import PerguntaEdit from "./PerguntaEdit";
import PerguntaList from "./PerguntaList";
import PerguntaShow from "./PerguntaShow";

const pergunta = {
  getResource: (p, a) => {
    return {
      name: "pergunta",
      list: p.cardapio_ver ? PerguntaList : null,
      show: p.cardapio_ver && !p.cardapio_editar ? PerguntaShow : null,
      create: p.cardapio_criar ? PerguntaCreate : null,
      edit: p.cardapio_editar ? PerguntaEdit : null,
    };
  },
  getMenu: (p, a) =>
    p.cardapio_ver
      ? {
          key: "pergunta",
          to: "/pergunta",
          primaryText: "Perguntas",
          leftIcon: <QuestionAnswer />,
        }
      : null,
};

export default pergunta;
export const IconPergunta = QuestionAnswer;
