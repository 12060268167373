import React from "react";
import NewReleases from "@material-ui/icons/NewReleases";
import ImpostoCreate from "./ImpostoCreate";
import ImpostoEdit from "./ImpostoEdit";
import ImpostoList from "./ImpostoList";

const imposto = {
  getResource: (p) => {
    return {
      name: "impostos",
      create: p.cadastros_ver ? ImpostoCreate : null,
      list: p.cadastros_ver ? ImpostoList : null,
      edit: p.cadastros_ver ? ImpostoEdit : null,
    };
  },
  getMenu: (p) =>
    p.cadastros_ver
      ? {
          key: "impostos",
          to: "/impostos",
          primaryText: "Impostos",
          leftIcon: iconImposto,
        }
      : null,
};

export default imposto;
export const iconImposto = <NewReleases />;
