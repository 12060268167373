import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    padding: "1em",
    marginTop: "6em",
  },
  wizardCard: {
    display: "flex",
    minWidth: 300,
    marginTop: "6em",
  },
  wizardContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "space-evenly",
    paddingRight: 50,
    paddingLeft: 50,
  },
  wizardImage: {
    width: 500,
    height: 600,
  },
  wizardButtons: {
    display: "flex",
    justifyContent: "space-between",
    padding: "2em 0 0",
  },
  wizardFirstStepButtons: {
    padding: "2em 0 0",
    justifyContent: "flex-end",
  },
  wizardSubmitButton: {
    width: 200,
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  image: {
    paddingBottom: 50,
    maxWidth: 270,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  input: {
    marginTop: "1em",
  },
  phoneInput: {
    marginTop: "1em",
  },
  select: {
    marginTop: "1em",
    width: "100%",
  },
  register: {
    marginTop: "1em",
    display: "none",
  },
  login: {
    marginTop: "2em",
  },
  forgotPassword: {
    marginTop: "2em",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  progress: {
    marginRight: "1em",
  },
}));
