import React from "react";
import { Create } from "react-admin";
import ClassificacaoForm from "./ClassificacaoForm";

const ClassificacaoCreate = (props) => (
  <Create {...props}>
    <ClassificacaoForm />
  </Create>
);

export default ClassificacaoCreate;
