import React from "react";
import { Loading, Error, Title, useGetMany } from "react-admin";
import { Link } from "react-router-dom";
import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
} from "@mui/material";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import { iconParceiro } from "../resources/parceiro";
import { makeStyles } from "@material-ui/core";
import { useAuthenticated } from "react-admin";
import { withUser } from "../common/util/hocs";
import useFetch from "../dataProvider/useFetch";
import { formatCurrency, formatPhone } from "../common/util/formatter";
import Decimal from "decimal.js";
import { reportPDVEnable } from "../permissionsHelper";

const filters = [
  {
    nome: "Cliente",
    filter: (parceiro) => parceiro.cliente,
  },
  {
    nome: "Funcionário",
    filter: (parceiro) => parceiro.funcionario,
  },
  {
    nome: "Fornecedor",
    filter: (parceiro) => parceiro.fornecedor,
  },
];

const useStyles = makeStyles((theme) => ({
  listItemText: {
    paddingLeft: "1em",
    margin: 0,
  },
  typographyImportant: {
    fontWeight: "bold",
  },
  typographyEmFalta: {
    fontWeight: "bold",
    color: "#f44336",
  },
  typographyMinimo: {
    fontWeight: "bold",
    color: "#f57f17",
  },
}));

const CardCustom = ({ label, value, color = "#283593", component }) => {
  return (
    <Card
      sx={{
        border: "1px solid #c5c5c5",
        borderBottom: `7px solid ${color}`,
        pl: 2,
        pr: 2,
        display: "flex",
        alignItems: "center",
      }}
    >
      <CardContent>
        {label && typeof label === "string" && (
          <Typography variant="subtitle1" color="textPrimary">
            {label}
          </Typography>
        )}
        {value && typeof value === "string" && (
          <Typography sx={{ fontWeight: "bold" }} variant="h5">
            {value}
          </Typography>
        )}
        {!!component && component}
      </CardContent>
    </Card>
  );
};

const ReportSaldoCorrentistas = ({ loja }) => {
  useAuthenticated();
  const classes = useStyles();

  const [checkedFilters, setCheckedFilters] = React.useState([]);

  const {
    data: saldos,
    isLoading,
    error,
  } = useFetch("Relatorio/correntista/saldos", {
    lojaId: loja.id,
  });

  const handleToggle = (value) => () => {
    const currentIndex = checkedFilters.findIndex((v) => v.nome === value.nome);
    const newChecked = [...checkedFilters];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedFilters(newChecked);
  };

  if (error) {
    return (
      <>
        <Title title="Saldo dos Correntistas" />
        <Error error={error} />
      </>
    );
  }

  if (isLoading) {
    return (
      <>
        <Title title="Saldo dos Correntistas" />
        <Loading />
      </>
    );
  }

  const totalNegativo = saldos
    .filter((s) => s.saldo_outros < 0)
    .reduce((acc, s) => acc.plus(s.saldo_outros), Decimal(0));
  const totalPositivo = saldos
    .filter((s) => s.saldo_outros > 0)
    .reduce((acc, s) => acc.plus(s.saldo_outros), Decimal(0));
  const totalGeral = totalNegativo.plus(totalPositivo);

  return (
    <>
      <Title title="Saldo dos Correntistas" />
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Card>
            <CardContent>
              <Typography variant="overline">Filtros</Typography>
              <Box mt={2} display="flex" alignItems="center">
                <Box mr={1}>{iconParceiro}</Box>
                <Typography variant="overline">Tipo</Typography>
              </Box>
              <List dense disablePadding>
                {filters.map((value) => {
                  const isSelected =
                    checkedFilters.findIndex((v) => v.nome === value.nome) !==
                    -1;
                  return (
                    <ListItem
                      button
                      onClick={handleToggle(value)}
                      selected={isSelected}
                      key={value.nome}
                    >
                      <ListItemText
                        className={classes.listItemText}
                        data-selected={isSelected ? "true" : "false"}
                      >
                        {value.nome}
                      </ListItemText>
                      {isSelected && (
                        <ListItemSecondaryAction>
                          <IconButton
                            size="small"
                            onClick={handleToggle(value)}
                          >
                            <CancelIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  );
                })}
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={10}>
          <Grid spacing={4} container>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="space-between">
                <CardCustom
                  label="Total negativo"
                  color="red"
                  value={formatCurrency(totalNegativo)}
                />
                <CardCustom
                  label="Total positivo"
                  value={formatCurrency(totalPositivo)}
                />
                <CardCustom
                  label="Total geral"
                  color={totalGeral.lessThan(0) ? "red" : "#283593"}
                  value={formatCurrency(totalGeral)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <TableSaldos
                    saldos={saldos}
                    checkedFilters={checkedFilters}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const TableSaldos = ({ saldos, checkedFilters }) => {
  const {
    data: clientes,
    loading,
    error,
  } = useGetMany(
    "parceiros",
    saldos.map((s) => s.parceiroId)
  );

  if (error) {
    return <Error error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  const orderedData = saldos
    .map((s) => ({
      ...(clientes.find((c) => c && s.parceiroId === c.id) || {
        nome_fantasia: `Parceiro ${s.parceiroId} não encontrado`,
      }),
      saldo: s.saldo_outros,
    }))
    .sort((a, b) => a.saldo - b.saldo);

  const filteredData =
    checkedFilters.length > 0
      ? orderedData.filter((c) =>
          checkedFilters.reduce((r, f) => f.filter(c) || r, false)
        )
      : orderedData;

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Telefone 1</TableCell>
            <TableCell>Telefone 2</TableCell>
            <TableCell align="right">Saldo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((c) => (
            <TableRow
              key={c.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell component="th" scope="row">
                <Link to={c.id ? `/parceiros/${c.id}/2` : `#`}>
                  {c.nome_fantasia}
                </Link>
              </TableCell>
              <TableCell>{formatPhone(c.telefone1)}</TableCell>
              <TableCell>{formatPhone(c.telefone2)}</TableCell>
              <TableCell
                align="right"
                sx={{ color: c.saldo < 0 ? "red" : "blue" }}
              >
                <strong>{formatCurrency(c.saldo)}</strong>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default withUser(ReportSaldoCorrentistas);

export const saldoCorrentistas = {
  getMenu: (p, a) =>
    reportPDVEnable(p, a)
      ? {
          key: "saldoCorrentistas",
          to: "/relatorio/saldo-correntistas",
          primaryText: "Saldo dos correntistas",
        }
      : null,
};
