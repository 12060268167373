import React from "react";
import {
  TextInput,
  NumberInput,
  SelectInput,
  BooleanInput,
  Filter,
} from "react-admin";

const MODE = [
  { id: "DELIVERY", name: "DELIVERY" },
  { id: "TAKEOUT", name: "TAKEOUT" },
  { id: "INDOOR", name: "INDOOR" },
];

const AppPedidosVisitorFilter = (props) => (
  <Filter {...props}>
    <NumberInput source="lojaId" label="Loja id" alwaysOn />
    <TextInput source="lojaNome_like" label="Loja" alwaysOn />
    <NumberInput source="shortReference" label="Referência Cliente" alwaysOn />

    <BooleanInput source="confirmed" label="Confirmado" />
    <BooleanInput source="canceled" label="Cancelado" />

    <TextInput source="customerName_like" label="Cliente" />
    <TextInput source="customerPhone_like" label="Telefone" />
    <TextInput source="deliveryStreetName_like" label="Endereço" />
    <NumberInput source="totalDisplay" label="Total Pago" />
    <SelectInput source="mode" label="Tipo" choices={MODE} />
    <BooleanInput source="paymentPrepaid" label="Pagamento Online" />
    <BooleanInput source="isScheduledOrder" label="Agendado" />
    <NumberInput source="appUserId" label="Fidelidade id" />
    <NumberInput source="fidelityDiscount_gt" label="Desconto Fidelidade >" />
  </Filter>
);

export default AppPedidosVisitorFilter;
