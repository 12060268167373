import React from "react";
import { TextInput, DateTimeInput, BooleanInput, Filter } from "react-admin";

const GrupoFilter = (props) => (
  <Filter {...props}>
    <TextInput source="nome_like" label="Nome do grupo" alwaysOn />
    <BooleanInput source="android" label="Android" />
    <BooleanInput source="categoria" label="Categoria" />
    <BooleanInput source="pizza" label="Pizza" />
    <DateTimeInput source="updatedAt_gte" label="Atualizado de" />
    <DateTimeInput source="updatedAt_lte" label="Atualizado até" />
  </Filter>
);

export default GrupoFilter;
