import React from "react";
import {
  FormDataConsumer,
  NumberInput,
  TextInput,
  required,
  minValue,
  maxValue,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { InputAdornment } from "@material-ui/core";
import Datagrid from "../../common/form/Datagrid/Datagrid";
import { keyBy } from "lodash";
import DateInputUTC from "../../common/form/DateInputUTC";
import { ContaTipoField } from "../conta/ContaTipo";
import NumberInputRemoveArrowsCSS from "../../common/form/NumberInputRemoveArrowsCSS.json";
import DataGridRemoveButton from "../../common/form/DataGridRemoveButton";
import { validateDecimalPlaces } from "../../common/util/ra-validations";

const validateReq = [required()];
const validateContaValor = [
  required(),
  minValue(0.01),
  maxValue(9999999999.99),
  validateDecimalPlaces(2),
];

const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-sizeSmall": {
      padding: "6px 12px 6px 12px",
    },
  },
  contaValorInput: {
    width: "9em",
    ...NumberInputRemoveArrowsCSS,
  },
});

const NFeContasGrid = ({ formData }) => {
  const classes = useStyles();
  const { contas } = formData;

  return (
    <Datagrid
      classes={classes}
      loaded={contas.length > 0}
      data={keyBy(formData.contas, "index")}
      ids={formData.contas.map(({ index }) => index)}
      currentSort={{ field: "index", order: "ASC" }}
      fullWidth
    >
      <ContaTipoField label="Tipo" source="tipo" />
      <FormDataConsumer label="Vencimento">
        {({ formData, record }) => {
          if (!formData.contas) return null;
          const { index } = record;
          return (
            <DateInputUTC
              label=""
              variant="standard"
              source={`contas[${index}].vencimento`}
              validate={validateReq}
            />
          );
        }}
      </FormDataConsumer>

      <FormDataConsumer label="R$ Valor">
        {({ formData, record }) => {
          if (!formData.contas) return null;
          const { index } = record;
          return (
            <NumberInput
              label=""
              variant="standard"
              source={`contas[${index}].valor`}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              validate={validateContaValor}
              className={classes.contaValorInput}
            />
          );
        }}
      </FormDataConsumer>

      <FormDataConsumer label="Observação">
        {({ formData, record }) => {
          if (!formData.contas) return null;
          const { index } = record;
          return (
            <TextInput
              label=""
              variant="standard"
              source={`contas[${index}].observacao`}
            />
          );
        }}
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData, record }) => {
          if (!formData.contas) return null;
          const { index } = record;
          if (index === 0) return null;
          return (
            <DataGridRemoveButton
              formData={formData}
              record={record}
              arrayName="contas"
            />
          );
        }}
      </FormDataConsumer>
    </Datagrid>
  );
};

export default NFeContasGrid;
