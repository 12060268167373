import React from 'react';
import TotalCard from './ReportTotalCard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';


const ReportTotalFaturamentoCard = props => {
    return (
        <TotalCard icon={ShoppingCartIcon} title="Faturamento do período" type="currency" {...props}/>
    );
};

ReportTotalFaturamentoCard.propTypes = {
    
};

export default ReportTotalFaturamentoCard;