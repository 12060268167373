import { InputAdornment, Grid, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Decimal from "decimal.js";
import React, { useEffect } from "react";
import {
  NumberInput,
  BooleanInput,
  required,
  minValue,
  maxValue,
} from "react-admin";
import { useForm } from "react-final-form";
import NumberInputRemoveArrowsCSS from "../../common/form/NumberInputRemoveArrowsCSS.json";
import DateInputUTC from "../../common/form/DateInputUTC";
import { validateDecimalPlaces } from "../../common/util/ra-validations";

const validateReq = [required()];
const validateValor = [
  required(),
  minValue(0),
  maxValue(9999999999.99),
  validateDecimalPlaces(2),
];

const useStyles = makeStyles({
  numberInput: {
    width: "8em",
    ...NumberInputRemoveArrowsCSS,
  },
  readOnlyInput: {
    width: "9em",
    cursor: "not-allowed",
  },
});

export default function PagamentoValues({ formData }) {
  const form = useForm();
  const classes = useStyles();
  const { valor, desconto, juros, pago, tipo } = formData;

  useEffect(() => {
    const valor_pago = Decimal(valor || 0)
      .minus(desconto || 0)
      .plus(juros || 0)
      .toNumber();
    form.change("valor_pago", valor_pago);
  }, [form, valor, desconto, juros]);

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <BooleanInput source="pago" />
      </Grid>
      {pago && (
        <>
          <Grid item>
            <DateInputUTC
              label="Pago em"
              source="pagamento"
              defaultValue={new Date().toISOString().substring(0, 10)}
              validate={validateReq}
            />
          </Grid>
          <Grid item>
            <NumberInput
              className={classes.numberInput}
              label="Desconto"
              source="desconto"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              validate={validateValor}
              defaultValue={0}
            />
          </Grid>
          <Grid item>
            <NumberInput
              className={classes.numberInput}
              label="Juros"
              source="juros"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              validate={validateValor}
              defaultValue={0}
            />
          </Grid>
          <Grid item>
            <Tooltip title="Preenchido automaticamente">
              <span>
                <NumberInput
                  variant="outlined"
                  className={classes.readOnlyInput}
                  label="R$ Total"
                  source="valor_pago"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                    readOnly: true,
                    classes: {
                      input: classes.readOnlyInput,
                    },
                  }}
                />
              </span>
            </Tooltip>
          </Grid>
        </>
      )}
      {!pago && tipo === "RECEBER" && <BooleanInput source="perda" />}
    </Grid>
  );
}

export const trasnformPago = (data) => {
  return data.pago
    ? {
        ...data,
        perda: false,
      }
    : {
        ...data,
        pagamento: null,
        desconto: null,
        juros: null,
        valor_pago: null,
      };
};
