import React from "react";
import { Edit } from "react-admin";
import LojaForm from "./LojaForm";
import LojaToolbar from "./LojaToolbar";

function LojaEdit(props) {
  return (
    <Edit {...props}>
      <LojaForm toolbar={<LojaToolbar />} />
    </Edit>
  );
}

export default LojaEdit;
