import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import SwapVertIcon from '@material-ui/icons/SwapVert';

const useStyles = makeStyles({
    icon: { paddingRight: '0.5em' },
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
});

const LinkToMovimentacao = ({ produtoId }) => {
    const classes = useStyles();
    return (
        <Button
            size="small"
            color="primary"
            component={Link}
            to={`/estoque/${produtoId}/movimentacao`}
            className={classes.link}
        >
            <SwapVertIcon className={classes.icon} />
            Movimentação
        </Button>
    );
};

export default LinkToMovimentacao;