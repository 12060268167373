import React from "react";
import { Pie } from "react-chartjs-2";

export default function GroupedBillsChart({ reportData }) {
  const options = {
    maintainAspectRatio: false,
  };

  const data = {
    labels: reportData.map((item) => item.nome),
    datasets: [
      {
        data: reportData.map((item) => item.total),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(132, 99, 255, 0.2)",
          "rgba(235, 162, 54, 0.2)",
          "rgba(86, 206, 255, 0.2)",
          "rgba(192, 192, 75, 0.2)",
          "rgba(255, 102, 153, 0.2)",
          "rgba(64, 159, 255, 0.2)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return <Pie data={data} options={options} />;
}
