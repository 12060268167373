import React from "react";
import { Edit } from "react-admin";

import LojaEntregaForm from "./LojaEntregaForm";
import LojaEntregaToolbar from "./LojaEntregaToolbar";

const LojaEntregaEdit = (props) => {
  return (
    <Edit {...props}>
      <LojaEntregaForm toolbar={<LojaEntregaToolbar />} />
    </Edit>
  );
};

export default LojaEntregaEdit;
