import React from "react";
import { Edit } from "react-admin";

import MoedaAdquirenteForm from "./MoedaAdquirenteForm";
import MoedaAdquirenteToolbar from "./MoedaAdquirenteToolbar";

const MoedaAdquirenteEdit = props => (
  <Edit {...props}>
    <MoedaAdquirenteForm toolbar={<MoedaAdquirenteToolbar />} />
  </Edit>
);

export default MoedaAdquirenteEdit;
