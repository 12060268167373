import React from "react";
import { Create } from "react-admin";

import NcmForm from "./NcmForm";
import NcmToolbar from "./NcmToolbar";

const NcmCreate = props => (
  <Create {...props}>
    <NcmForm toolbar={<NcmToolbar />} />
  </Create>
);

export default NcmCreate;
