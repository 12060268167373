import React from "react";
import IconSave from "@material-ui/icons/Save";
import { Button, CircularProgress } from "@mui/material";

function LoadingButton(props) {
  const { text, icon, loading, disabled, ...rest } = props;
  const startIcon = loading ? (
    <CircularProgress size={20} thickness={2} />
  ) : (
    icon || <IconSave />
  );

  return (
    <Button disabled={loading || disabled} startIcon={startIcon} {...rest}>
      {text === false ? "" : text || "Salvar"}
    </Button>
  );
}

export default LoadingButton;
