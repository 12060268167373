import React from "react";
import { Edit } from "react-admin";
import ParceiroForm from "./ParceiroForm";
import ParceiroToolbar from "./ParceiroToolbar";

function ParceiroEdit(props) {
  return (
    <Edit {...props}>
      <ParceiroForm toolbar={<ParceiroToolbar />} />
    </Edit>
  );
}

export default ParceiroEdit;
