import React from "react";
import { Edit } from "react-admin";

import EquipamentoForm from "./EquipamentoForm";
import EquipamentoToolbar from "./EquipamentoToolbar";
import EquipamentoEditActions from "./EquipamentoEditActions";

const EquipamentoEdit = props => (
  <Edit actions={<EquipamentoEditActions />} {...props} >
    <EquipamentoForm toolbar={<EquipamentoToolbar />} />
  </Edit>
);

export default EquipamentoEdit;
