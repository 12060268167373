import React, { useState } from "react";
import { Title, useAuthenticated } from "react-admin";
import { Box, Card, CardHeader, CardContent, Stack, Grid } from "@mui/material";
import StoreIcon from "@material-ui/icons/Store";
import Remove from "@material-ui/icons/Remove";
import Decimal from "decimal.js";
import moment from "moment";
import { Bar, Pie, Line } from "react-chartjs-2";
import { fetchJSON } from "../dataProvider";
import UXDelay from "../dataProvider/uxdelay";
import { useStyles } from "./ReportDailyInvoicings/styles";
import isEmpty from "lodash.isempty";
import union from "lodash.union";
import keyBy from "lodash.keyby";
import get from "lodash.get";
import Search from "./Search";
import { formatCurrency } from "../common/util/formatter";
import { withUser } from "../common/util/hocs";
import ReportTotalFaturamentoCard from "./ReportTotalFaturamentoCard";
import ReportTotalCard from "./ReportTotalCard";
import { reportPDVEnable } from "../permissionsHelper";

const COLORS = [
  "#FF6384",
  "#36A2EB",
  "#FFCE56",
  "#4caf50",
  "#ff8a65",
  "#90a4ae",
  "#05668D",
  "#4C2E05",
  "#7FD1B9",
  "#621708",
  "#FF88DC",
  "#F3E1DD",
  "#6A4C93",
  "#1982C4",
  "#780116",
  "#9CF6F6",
  "#C46D5E",
  "#2D3047",
  "#F9EA9A",
  "#C4BBAF",
  "#5A2A27",
];

const ReportFaturamentoMoeda = ({ usuario, loja, ...props }) => {
  useAuthenticated();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  async function retrieveReportData({ stores, startDate, endDate }) {
    setLoading(true);
    try {
      const storeFetchs = stores.map((storeId) =>
        fetchJSON("Relatorio/pdv/faturamento_moeda", null, {
          lojaId: storeId,
          startDate,
          endDate,
        })
      );

      const reportData = await UXDelay(Promise.all(storeFetchs));
      const data = convertToDatasetByStore(reportData, usuario.lojas);
      const lineDataset = convertToLineDataSet(
        reportData,
        usuario.lojas,
        stores
      );

      setData({
        total: data.generalTotal,
        storesQuantity: stores.length,
        ...data,
        ...lineDataset,
      });
    } catch (err) {
      console.log(JSON.stringify(err));
    } finally {
      setLoading(false);
    }
  }

  const averageAnnotation = {
    type: "box",
    borderColor: "black",
    backgroundColor: "black",
    borderWidth: 3,
    label: {
      display: true,
      color: "#000",
      position: "start",
      content: (ctx) => {
        const values = ctx.chart.data.datasets[0].data;
        const total = values.sum();
        const average = Decimal(total).dividedBy(values.length).toFixed(2);
        return `Média: ${formatCurrency(average)}`;
      },
    },
    xMax: (ctx) => {
      const values = ctx.chart.data.datasets[0].data;
      const total = values.sum();
      return Decimal(total).dividedBy(values.length).plus(5).valueOf();
    },
    xMin: (ctx) => {
      const values = ctx.chart.data.datasets[0].data;
      const total = values.sum();
      return Decimal(total).dividedBy(values.length).minus(5).valueOf();
    },
    xScaleID: "x",
  };

  const barChartByCoinOptions = {
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
    indexAxis: "y",
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => formatCurrency(context.parsed.x),
        },
      },
      datalabels: {
        display: true,
        anchor: "end",
        align: "left",
        color: "white",
        formatter: (value, _) => formatCurrency(value),
        font: {
          weight: "bold",
        },
      },
    },
  };

  const barChartByStoreOptions = {
    ...barChartByCoinOptions,
    plugins: {
      ...barChartByCoinOptions.plugins,
      annotation: {
        annotations: {
          averageAnnotation,
        },
      },
    },
  };

  const pieChartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) =>
            `${context.label}: ${Math.round(context.parsed)}%`,
        },
      },
      datalabels: {
        display: true,
        anchor: "center",
        align: "center",
        color: "white",
        formatter: (value, _) => `${Math.round(value)}%`,
        font: {
          weight: "bold",
        },
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
  };

  const lineChartOptions = {
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        display: false,
      },
    },
    elements: {
      line: {
        fill: false,
        tension: 0.4,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
      },
    },
  };

  const dataEmpty = isEmpty(data) || Decimal(data?.total ?? 0).isZero();

  return (
    <Card className={classes.root}>
      <Title title="Faturamento por moeda" />
      <CardContent>
        <Search
          fields={["dateRange", "timeRange", "stores"]}
          onSearch={retrieveReportData}
          loading={loading}
          dataEmpty={dataEmpty}
        />
        {!dataEmpty && (
          <div>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
              sx={{ my: 5 }}
            >
              <Grid item xs={3}>
                <ReportTotalFaturamentoCard total={data.total} />
              </Grid>
              {data.storesQuantity > 1 && (
                <Grid item xs={3}>
                  <ReportTotalCard
                    icon={StoreIcon}
                    title="Média por loja"
                    type="currency"
                    total={Decimal(data.total)
                      .dividedBy(data.storesQuantity)
                      .toFixed(2)}
                    color="#FF6384"
                  />
                </Grid>
              )}
              <Grid item xs={3}>
                <ReportTotalCard
                  total={data.generalTotalFees}
                  type="currency"
                  title={"Taxas dos cartões"}
                  icon={Remove}
                  color={"red"}
                />
              </Grid>
              <Grid item xs={3}>
                <ReportTotalCard
                  total={data.generalNetTotal}
                  type="currency"
                  title={"Total descontando as taxas"}
                  color={"green"}
                />
              </Grid>
            </Grid>
            <Stack spacing={5}>
              {data.storesQuantity > 1 && (
                <Grid container justifyContent="space-between">
                  <Grid item xs={9}>
                    <Card className={classes.card}>
                      <CardHeader
                        className={classes.cardHeader}
                        title={"Valor faturado por loja"}
                      />
                      <CardContent>
                        <Box height={500}>
                          <Bar
                            data={data.barChartDataByStore}
                            options={barChartByStoreOptions}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={2.7}>
                    <Card className={classes.card}>
                      <CardHeader
                        className={classes.cardHeader}
                        title={"% faturado por loja"}
                      />
                      <CardContent>
                        <Box height={500}>
                          <Pie
                            data={data.pieChartDataByStore}
                            options={pieChartOptions}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
              <Grid container justifyContent="space-between">
                <Grid item xs={9}>
                  <Card className={classes.card}>
                    <CardHeader
                      className={classes.cardHeader}
                      title={"Valor faturado por moeda"}
                    />
                    <CardContent>
                      <Box height={500}>
                        <Bar
                          data={data.barChartDataByCoin}
                          options={barChartByCoinOptions}
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={2.7}>
                  <Card className={classes.card}>
                    <CardHeader
                      className={classes.cardHeader}
                      title={"% faturado por moeda"}
                    />
                    <CardContent>
                      <Box height={500}>
                        <Pie
                          data={data.pieChartDataByCoin}
                          options={pieChartOptions}
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Card className={classes.card}>
                    <CardHeader
                      className={classes.cardHeader}
                      title={"Valor faturado por dia"}
                    />
                    <CardContent>
                      <Box height={500}>
                        <Line
                          data={data.lineChartData}
                          options={lineChartOptions}
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Stack>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default withUser(ReportFaturamentoMoeda);

export const faturamentoMoeda = {
  getMenu: (p, a) =>
    reportPDVEnable(p, a)
      ? {
          key: "faturamentoMoeda",
          to: "/relatorio/faturamento-moeda",
          primaryText: "Faturamento Moeda",
        }
      : null,
};

function convertToDatasetByStore(data, stores) {
  const dataByStore = keyBy(data, "lojaId");
  const generalTotal = data.reduce(
    (total, storeData) => total.plus(storeData.total),
    new Decimal(0)
  );
  const generalTotalFees = data.reduce(
    (total, storeData) => total.plus(storeData.total_taxa),
    new Decimal(0)
  );
  const generalNetTotal = data.reduce(
    (total, storeData) => total.plus(storeData.total_descontado),
    new Decimal(0)
  );

  const reducedDataByStore = stores.reduce(
    (reducedData, store) =>
      Object.keys(dataByStore).includes(`${store.id}`)
        ? reducedData.concat({
            id: store.id,
            name: store.nome_fantasia,
            total: new Decimal(dataByStore[store.id].total),
          })
        : reducedData,
    []
  );

  const storesOrdered = reducedDataByStore.orderBy("total", true);
  const barChartDataByStore = {
    labels: storesOrdered.map((i) => i.name),
    datasets: [
      {
        data: storesOrdered.map((i) => i.total.toNumber()),
        backgroundColor: COLORS,
        hoverBackgroundColor: COLORS,
      },
    ],
  };

  const pieChartDataByStore = {
    labels: storesOrdered.map((i) => i.name),
    datasets: [
      {
        data: storesOrdered.map((i) =>
          i.total.dividedBy(generalTotal).times(100).toNumber()
        ),
        backgroundColor: COLORS,
        hoverBackgroundColor: COLORS,
      },
    ],
  };

  const reducedTotalByCoins = union(
    ...data.map((storeData) => storeData.totalPorMoeda)
  ).reduce((reducedData, totalByCoin) => {
    if (Object.keys(reducedData).includes(totalByCoin.moeda)) {
      reducedData[totalByCoin.moeda] = {
        total: Decimal(reducedData[totalByCoin.moeda].total).plus(
          totalByCoin.total
        ),
        coin: totalByCoin.moeda,
      };
    } else {
      reducedData[totalByCoin.moeda] = {
        total: Decimal(totalByCoin.total),
        coin: totalByCoin.moeda,
      };
    }

    return reducedData;
  }, {});

  const coinsOrdered = Object.values(reducedTotalByCoins).orderBy(
    "total",
    true
  );
  const barChartDataByCoin = {
    labels: coinsOrdered.map((i) => i.coin),
    datasets: [
      {
        data: coinsOrdered.map((i) => i.total.toNumber()),
        backgroundColor: COLORS,
        hoverBackgroundColor: COLORS,
      },
    ],
  };

  const pieChartDataByCoin = {
    labels: coinsOrdered.map((i) => i.coin),
    datasets: [
      {
        data: coinsOrdered.map((i) =>
          i.total.dividedBy(generalTotal).times(100).toNumber()
        ),
        backgroundColor: COLORS,
        hoverBackgroundColor: COLORS,
      },
    ],
  };

  return {
    generalTotal: generalTotal.toNumber(),
    generalTotalFees: generalTotalFees.toNumber(),
    generalNetTotal: generalNetTotal.toNumber(),
    pieChartDataByStore,
    barChartDataByStore,
    barChartDataByCoin,
    pieChartDataByCoin,
  };
}

function convertToLineDataSet(data, stores, selectedStoresIds) {
  const daysArray = union(
    ...data.map((report) => {
      const labels = report.totalPorDiaMoeda.map((dia) => dia.data);

      return labels;
    })
  );

  const unifiedData = data.reduce((acc, item) => {
    const totals = item.totalPorDiaMoeda.map((i) => ({
      ...i,
      lojaId: item.lojaId,
    }));
    return [...acc, ...totals];
  }, []);

  const dataPerDay = daysArray.reduce((labeledData, label) => {
    const valuesPerDay = unifiedData.filter((d) => d.data === label);
    labeledData[label] = valuesPerDay;

    return labeledData;
  }, {});

  const selectedStores = stores.filter((store) =>
    selectedStoresIds.includes(store.id)
  );

  const lineDatasets = selectedStores.map((store, index) => ({
    label: store.nome_fantasia,
    backgroundColor: COLORS[index],
    borderColor: COLORS[index],
    borderWidth: 3,
    data: daysArray.map((label) => {
      const dataPerStore = keyBy(dataPerDay[label], "lojaId");
      return get(dataPerStore, `${store.id}.total`, "0");
    }),
    datalabels: {
      display: "auto",
      align: "center",
      anchor: "center",
      formatter: (value, _) => formatCurrency(value),
      backgroundColor: COLORS[index],
      color: "white",
      borderRadius: 4,
      padding: 6,
      font: {
        weight: "bold",
      },
    },
  }));

  const daysLabels = daysArray.map((day) =>
    moment(day.substring(0, 10)).format("DD/MM/YYYY")
  );

  const lineChartData = {
    labels: daysLabels,
    datasets: lineDatasets,
  };

  return {
    lineChartData,
  };
}
