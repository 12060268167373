import React from "react";
import {
  Filter,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
} from "react-admin";
import { InputAdornment } from "@material-ui/core";

const ContaFilter = (props) => (
  <Filter {...props}>
    <NumberInput
      label='Valor'
      source='valor'
      InputProps={{
        startAdornment: <InputAdornment position='start'>R$</InputAdornment>,
      }}
    />
    <ReferenceInput
      label='Classificação'
      source='classificacaoId'
      reference='Conta_classificacoes'
    >
      <AutocompleteInput optionText='nome' />
    </ReferenceInput>

    <ReferenceInput
      label='Centro de custo'
      source='centroCustoId'
      reference='Conta_centros_custos'
    >
      <AutocompleteInput optionText='nome' />
    </ReferenceInput>

    <ReferenceInput label='Parceiro' source='parceiroId' reference='parceiros'>
      <AutocompleteInput optionText='nome_fantasia' />
    </ReferenceInput>
  </Filter>
);

export default ContaFilter;
