import React from "react";
import { TextInput, Filter, BooleanInput } from "react-admin";

const EquipamentoFilter = (props) => (
  <Filter {...props}>
    <TextInput source="nome_like" label="Nome da equipamento" alwaysOn />
    <BooleanInput source="ativo" label="Ativo" />
  </Filter>
);

export default EquipamentoFilter;
