import React from "react";
import { CloneButton } from "react-admin";
import { cloneDeep } from "lodash";
import { withUser } from "../util/hocs";

export function cloneRecord(record) {
  const newRecord = cloneDeep(record);
  deleteIdsTimestamp(newRecord);
  return newRecord;
}

const deleteIdsTimestamp = (record, level = 0) => {
  if (!record || level > 1) {
    return;
  }

  delete record.id;
  delete record.createdAt;
  delete record.updatedAt;
  delete record.fotoUrl;

  Object.keys(record).forEach((k) => {
    const field = record[k];
    if (Array.isArray(field)) {
      field.forEach((i) => deleteIdsTimestamp(i));
    }
  });
};

function PermissionCloneButton({ permissao, permissionKey, basePath, record }) {
  const newRecord = cloneRecord(record);

  return !permissionKey || permissao[permissionKey] ? (
    <CloneButton
      basePath={basePath}
      record={newRecord}
      className="button-clone"
    />
  ) : null;
}

export default withUser(PermissionCloneButton);
