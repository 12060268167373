import React from "react";
import { Edit } from "react-admin";

import LojaHorarioForm from "./LojaHorarioForm";
import LojaHorarioToolbar from "./LojaHorarioToolbar";

const LojaHorarioEdit = props => (
  <Edit {...props}>
    <LojaHorarioForm toolbar={<LojaHorarioToolbar />} />
  </Edit>
);

export default LojaHorarioEdit;
