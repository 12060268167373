import React, { useState } from "react";
import { Grid, Box, Card, CardHeader, CardContent } from "@mui/material";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { Title } from "react-admin";

import { fetchJSON } from "../dataProvider";
import UXDelay from "../dataProvider/uxdelay";
import Search from "./Search";
import { Bar } from "react-chartjs-2";
import { useAuthenticated } from "react-admin";
import TotalValorUnitarioMedioCard from "./ReportTotalValorUnitarioMedioCard";
import TotalCard from "./ReportTotalCard";
import TotalQuantidadeCard from "./ReportTotalQuantidadeCard";
import * as formatter from "../common/util/formatter";
import { useStyles } from "./ReportDailyInvoicings/styles";
import isEmpty from "lodash.isempty";
import { WEEK_DAYS_EN, WEEK_DAYS_PT } from "../common/util/weekday";
import { reportPDVEnable } from "../permissionsHelper";

/*
numero_pessoas: 28314
total_bruto: 976511.2299999984
permanencia_minutos: 791082
ticket_medio: 34.48863565727196
permanencia_media: 27.939605848696758
*/

function createDatasets(array) {
  return [
    {
      type: "line",
      label: "Clientes",
      data: array.map((v) => v.numero_pessoas),
      backgroundColor: "#fa5424",
      borderColor: "#fa5424",
      borderWidth: 5,
      yAxisID: "y1",
      datalabels: {
        display: "auto",
        align: "center",
        anchor: "center",
        backgroundColor: "#fa5424",
        color: "white",
        borderRadius: 4,
        padding: 6,
        font: {
          weight: "bold",
        },
      },
    },
    {
      type: "line",
      label: "Tempo (min)",
      data: array.map((v) => v.permanencia_media),
      backgroundColor: "#fbc306",
      borderColor: "#fbc306",
      borderWidth: 5,
      yAxisID: "y2",
      datalabels: {
        display: "auto",
        align: "end",
        anchor: "end",
        backgroundColor: "#fbc306",
        color: "white",
        borderRadius: 4,
        padding: 6,
        font: {
          weight: "bold",
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `Tempo: ${context.parsed.y} min`;
          },
        },
      },
    },
    {
      type: "bar",
      label: "Ticket Médio",
      data: array.map((v) => v.ticket_medio),
      backgroundColor: "#4eaa56",
      yAxisID: "y3",
      datalabels: {
        display: true,
        anchor: "end",
        align: "bottom",
        color: "white",
        formatter: function (value, context) {
          return new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(value);
        },
        font: {
          weight: "bold",
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return (
              "Ticket Médio: " +
              new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(context.parsed.y)
            );
          },
        },
      },
    },
  ];
}

const ReportTicketMedio = () => {
  useAuthenticated();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    ticketDia: [],
    ticketHora: [],
    totais: {},
  });
  const classes = useStyles();

  async function retrieveReportData({
    lojaId,
    startDate,
    endDate,
    salesChannels,
    serviceTypes,
  }) {
    try {
      setLoading(true);
      const retrievedReportData = await UXDelay(
        fetchJSON("Relatorio/pdv/ticket_medio", null, {
          lojaId,
          startDate,
          endDate,
          channels: salesChannels,
          types: serviceTypes,
        })
      );
      setData(retrievedReportData);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y1: {
        grid: {
          display: false,
          drawBorder: false,
        },
        type: "linear",
        display: false,
      },
      y2: {
        grid: {
          display: false,
          drawBorder: false,
        },
        type: "linear",
        display: false,
      },
      y3: {
        grid: {
          display: false,
          drawBorder: false,
        },
        type: "linear",
        display: false,
      },
    },
    elements: {
      line: {
        fill: false,
        tension: 0.4,
      },
    },
  };

  const all7Days = WEEK_DAYS_EN.map((w) => {
    const day = data.ticketDia.find((td) => td.dia === w);
    return day || {};
  });

  const chartDataDia = {
    labels: WEEK_DAYS_PT,
    datasets: createDatasets(all7Days),
  };

  const chartDataHora = {
    labels: data.ticketHora.map((v) => v.hora + "h"),
    datasets: createDatasets(data.ticketHora),
  };

  const dataEmpty = isEmpty(data.ticketHora);

  return (
    <Card className={classes.root}>
      <Title title="Ticket Médio" />
      <CardContent>
        <Search
          fields={["dateRange", "timeRange", "salesChannels", "serviceTypes"]}
          onSearch={retrieveReportData}
          loading={loading}
          dataEmpty={dataEmpty}
        />
        {!dataEmpty && (
          <>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
              sx={{ my: 5 }}
            >
              {/* <Grid item xs={3} md={3}>
                <TotalFaturamentoCard total={data.totais.total_bruto} />
              </Grid> */}
              <Grid item xs={3}>
                <TotalQuantidadeCard
                  total={data.totais.numero_pessoas}
                  title="Clientes"
                />
              </Grid>
              <Grid item xs={3}>
                <TotalValorUnitarioMedioCard
                  total={data.totais.ticket_medio}
                  title="Ticket Médio"
                />
              </Grid>
              <Grid item xs={3}>
                <TotalCard
                  total={`${formatter.formatNumber(
                    data.totais.permanencia_media
                  )} min`}
                  type="text"
                  icon={AccessTimeIcon}
                  title="Tempo Médio"
                  color="rgb(255, 193, 7)"
                />
              </Grid>
            </Grid>
            <Grid sx={{ my: 5 }}>
              <Card className={classes.card}>
                <CardHeader
                  className={classes.cardHeader}
                  title={"Ticket médio por dia"}
                />
                <CardContent>
                  <Box height={500}>
                    <Bar data={chartDataDia} options={options} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid>
              <Card className={classes.card}>
                <CardHeader
                  className={classes.cardHeader}
                  title={"Ticket médio por hora"}
                />
                <CardContent>
                  <Box height={500}>
                    <Bar data={chartDataHora} options={options} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ReportTicketMedio;

export const ticketMedio = {
  getMenu: (p, a) =>
    reportPDVEnable(p, a)
      ? {
          key: "ticketMedio",
          to: "/relatorio/ticket-medio",
          primaryText: "Ticket Médio",
        }
      : null,
};
