import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback } from "react";
import { useNotify, Button } from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import { Grid, InputAdornment, Typography } from "@mui/material";
import { useForm, useFormState } from "react-final-form";
import NumberInputSimple from "../../common/form/NumberInputSimple";
import { NFeTipoPagamentoInput } from "./fields/NFeTipoPagamento";

const useStyles = makeStyles({
  numberInput: {
    width: "7em",
    marginRight: "1em",
    marginLeft: "1em",
  },
});

const ButtonAdd = (props) => {
  const form = useForm();
  const formState = useFormState();
  const notify = useNotify();

  const handleAdd = () => {
    const { pagamentos, pagamentoNovo } = formState.values;
    const { tipo, valor } = pagamentoNovo || {};

    if (!tipo) {
      notify(`Preencha o tipo!`);
      return;
    }

    if (!valor && valor !== 0) {
      notify(`Preencha o valor!`);
      return;
    }

    form.change(`pagamentos`, [
      ...pagamentos,
      {
        ...pagamentoNovo,
        index: pagamentos.length,
      },
    ]);
    form.change(`pagamentoNovo`, null);
  };

  return (
    <Button onClick={handleAdd} label="Adicionar" variant="outlined">
      <AddIcon />
    </Button>
  );
};

export default function NFeEmissaoPagamentoNovo({ formData }) {
  const classes = useStyles();

  const getSource = useCallback((field) => `pagamentoNovo.${field}`, []);

  return (
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom sx={{ mt: 4, ml: 1 }}>
          Novo Pagamento
        </Typography>
      </Grid>

      <Grid item>
        <NFeTipoPagamentoInput
          label="Tipo Pagamento"
          source={getSource("tipo")}
        />
      </Grid>
      <Grid item>
        <NumberInputSimple
          className={classes.numberInput}
          label="Valor unit."
          source={getSource("valor")}
          InputProps={{
            startAdornment: (
              <InputAdornment sx={{ mt: 2, mr: 1 }}>R$</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item sx={{ mr: 5, mb: 2 }}>
        <ButtonAdd />
      </Grid>
    </Grid>
  );
}
