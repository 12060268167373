import React from "react";
import { Datagrid, List, TextField } from "react-admin";
import PermissionEditButton from "../../common/form/PermissionEditButton";

import PerguntaVisitorFilter from "./PerguntaFilter";
import VincularPerguntaButton from "./VincularPerguntaButton";

const PerguntaList = (props) => (
  <List {...props} filters={<PerguntaVisitorFilter />} exporter={false}>
    <Datagrid>
      <TextField source="descricao" label="Descrição" />
      <TextField source="qtd_min" label="Quantidade mínima" />
      <TextField source="qtd_max" label="Quantidade máxima" />
      <PermissionEditButton canEdit="cardapio_editar" canShow="cardapio_ver" />
      <VincularPerguntaButton />
    </Datagrid>
  </List>
);

export default PerguntaList;
