import React from "react";
import { Datagrid, List, FunctionField, EditButton } from "react-admin";

import LojaEntregaActions from "./LojaEntregaActions";
import LojaEntregaFilter from "./LojaEntregaFilter";

const LojaEntregaList = (props) => (
  <List
    {...props}
    actions={<LojaEntregaActions />}
    filters={<LojaEntregaFilter />}
    exporter={false}
    empty={false}
  >
    <Datagrid>
      <FunctionField
        render={(r) => (r.distancia > 0 ? `Até ${r.distancia} km` : r.bairro)}
        label="Local"
      />
      <FunctionField
        render={(r) =>
          r.distancia > 0 || r.status ? r.valor.toFixed(2) : "Não entrega"
        }
        label="R$ Taxa"
      />
      <FunctionField
        render={(r) =>
          r.distancia > 0 || r.status ? r.minutos : "Não entrega"
        }
        label="Tempo (min)"
      />
      <EditButton />
    </Datagrid>
  </List>
);

export default LojaEntregaList;
