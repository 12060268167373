import React from "react";
import { Edit } from "react-admin";
import ContasForm from "./ContasForm";
import { trasnformPago } from "./ContaPagamento";

const ContasEdit = (props) => {
  return (
    <Edit {...props} transform={trasnformPago}>
      <ContasForm />
    </Edit>
  );
};

export default ContasEdit;
