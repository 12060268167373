import React from "react";
import { Create } from "react-admin";
import UsuarioLojaForm from "./UsuarioLojaForm";

const UsuarioLojaCreate = (props) => (
  <Create title="Novo Usuário" {...props}>
    <UsuarioLojaForm />
  </Create>
);

export default UsuarioLojaCreate;
