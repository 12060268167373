//import Collections from "@material-ui/icons/Collections";
import MoedaAdquirenteCreate from "./MoedaAdquirenteCreate";
import MoedaAdquirenteEdit from "./MoedaAdquirenteEdit";
import MoedaAdquirenteList from "./MoedaAdquirenteList";

const moedaAdquirente = {
  getResource: (p) => {
    if (p.ADMIN) {
      return {
        name: "moeda_adquirentes",
        create: MoedaAdquirenteCreate,
        list: MoedaAdquirenteList,
        edit: MoedaAdquirenteEdit,
      };
    }
  },
  getMenu: (p) =>
    p.ADMIN
      ? {
          key: "moeda_adquirentes",
          to: "/moeda_adquirentes",
          primaryText: "Adquirentes",
        }
      : null,
};

export default moedaAdquirente;
