import React from "react";
import { SelectInput, SelectField } from "react-admin";
import { Grid, Box } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import SendIcon from "@material-ui/icons/Send";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import green from "@material-ui/core/colors/green";

const DIGITACAO = "DIGITACAO";

const status = [
  { id: "DIGITACAO", name: "Digitação", icon: <EditIcon /> },
  { id: "ENVIADO", name: "Enviado", icon: <SendIcon /> },
  { id: "APROVADO", name: "Aprovado", icon: <DoneIcon /> },
  { id: "EM TRANSITO", name: "Em trânsito", icon: <LocalShippingIcon /> },
  {
    id: "CONCLUIDO",
    name: "Concluído",
    icon: <DoneAllIcon style={{ color: green[500] }} />,
  },
  { id: "REJEITADO", name: "Rejeitado", icon: <CloseIcon /> },
];

const StatusIcon = ({ record }) => {
  return (
    <Grid container align="center" alignItems="center">
      <Grid item>
        <Box mr={2}>{record.icon}</Box>
      </Grid>
      <Grid item>{record.name}</Grid>
    </Grid>
  );
};

function PedidoStatusInput(props) {
  return (
    <SelectInput
      label="Status"
      source="status"
      defaultValue={status[0].id}
      choices={status}
      optionText={<StatusIcon />}
      {...props}
    />
  );
}

function PedidoStatusField(props) {
  return (
    <SelectField
      label="Status"
      source="status"
      choices={status}
      optionText={<StatusIcon />}
      {...props}
    />
  );
}

export { DIGITACAO, PedidoStatusInput, PedidoStatusField };
