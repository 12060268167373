import React from "react";
import { NumberInput, Filter } from "react-admin";

const MovimentoFilter = (props) => (
  <Filter {...props}>
    <NumberInput source="mov" label="MOV" />
    <NumberInput source="nsp" label="NSP" />
  </Filter>
);

export default MovimentoFilter;
