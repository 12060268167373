import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { green } from '@material-ui/core/colors';
import { Notification, useNotify } from 'react-admin';
import { Field, withTypes } from "react-final-form";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { fetchJSON } from "../../dataProvider";
import TextInput from "./TextInput";

import pallasLogo from '../../static/media/pallas-logo-horizontal.svg';

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  card: {
    minWidth: 300,
    padding: '1em',
    marginTop: '6em',
  },
  instructions: {
    maxWidth: 300,
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    paddingBottom: 50,
    maxWidth: 270,
  },
  input: {
    marginTop: '1em',
  },
  login: {
    marginTop: '2em',
  },
  forgotPassword: {
    marginTop: '2em',
    display: "flex",
    flexDirection: "row",
  },
  progress: {
    marginRight: '1em'
  },
  success: {
    textAlign: 'center',
  }
}));

const { Form } = withTypes();

const DefinePasswordForm = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const notify = useNotify();
  const history = useHistory();
  const { search } = useLocation();

  const params = queryString.parse(search);
  const token = params.t;
  const reset = params.reset;

  const title = reset ? 'Redefinir senha' : 'Criar senha';
  const helpPassword = reset ? 'Nova senha' : 'Senha';
  const helpConfirm = reset ? 'Confirmar nova senha' : 'Confirmar senha';
  const successMessage = reset ? 'Senha redefinida com sucesso!' : 'Senha criada com sucesso!';

  const validate = (values) => {
    const errors = {};
    if (!values.password) {
      errors.password = "Obrigatório";
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = "Obrigatório";
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "As senhas não são iguais";
    }

    return errors;
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    await fetchJSON('ConfirmationTokens/confirmacao', {
      method: 'POST',
      body: JSON.stringify({
        token,
        password: event.password
      })
    })
      .then(_ => setSuccess(true))
      .catch(_ => {
        notify('Error ao definir senha. Por favor, tente novamente.', 'warning');
        setLoading(false);
      });
  };

  const handleLogin = (e) => {
    history.push("/login");
  }

  if (success) {
    return (
      <div className={classes.main}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            <img
              src={pallasLogo}
              className={classes.image}
              alt="Sistema Pallas"
            />
          </div>
          <div className={classes.success}>
            <CheckCircleOutlineIcon style={{ color: green[500], fontSize: 60 }}/>
            <p>
              {successMessage}
              <br />
              Para realizar o login clique no botão abaixo.
            </p>
          </div>
          <Button
            className={classes.login}
            variant="contained"
            type="submit"
            color="primary"
            fullWidth
            onClick={handleLogin}
          >
            Login
          </Button>
        </Card>
      </div>
    );
  }

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <img
                  src={pallasLogo}
                  className={classes.image}
                  alt="Sistema Pallas"
                />
              </div>
              <h2>{title}</h2>
              <div className={classes.form}>
                <Field
                  fullWidth
                  required
                  name="password"
                  component={TextInput}
                  type="password"
                  label={helpPassword}
                  divStyle={classes.input}
                />
                <Field
                  fullWidth
                  required
                  name="confirmPassword"
                  component={TextInput}
                  type="password"
                  label={helpConfirm}
                  divStyle={classes.input}
                />
              </div>
              <Button
                className={classes.login}
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
                fullWidth
              >
                {loading && (
                  <CircularProgress
                    size={25}
                    thickness={2}
                    className={classes.progress}
                  />
                )}
                {title}
              </Button>
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );

  // return (
  //   <Container>
  //     <ToastContainer
  //       position="top-center"
  //       autoClose={5000}
  //       closeOnClick
  //       pauseOnHover
  //     />
  //     <Box
  //       display="flex"
  //       flexDirection="row"
  //       justifyContent="center"
  //       alignItems="center"
  //       height="100vh"
  //     >
  //       <Form
  //         onSubmit={submitPassword}
  //         validate={validate}
  //         render={({ handleSubmit }) => (
  //           <form onSubmit={handleSubmit}>
  //             <Card variant="outlined">
  //               <CardHeader title="Sistema Pallas" />
  //               <CardContent>
  //                 <Field
  //                   fullWidth
  //                   required
  //                   name="password"
  //                   component={TextInput}
  //                   type="password"
  //                   label="Nova Senha"
  //                   divStyle={classes.input}
  //                 />
  //                 <Field
  //                   fullWidth
  //                   required
  //                   name="confirmPassword"
  //                   component={TextInput}
  //                   type="password"
  //                   label="Confirmar Nova Senha"
  //                   divStyle={classes.input}
  //                 />
  //               </CardContent>
  //               <CardActions className={classes.actions}>
  //                 <Button
  //                   variant="contained"
  //                   type="submit"
  //                   color="primary"
  //                   disabled={loading}
  //                 >
  //                   Salvar senha
  //                 </Button>
  //                 {loading && <SmallCircularProgress />}
  //               </CardActions>
  //             </Card>
  //           </form>
  //         )}
  //       />
  //     </Box>
  //   </Container>
  // );
};

export default DefinePasswordForm;
