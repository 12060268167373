import React, { useState } from "react";
import { useNotify, useRefresh } from "react-admin";
import EmailIcon from "@material-ui/icons/Email";
import { fetchJSON } from "../../dataProvider";
import LoadingButton from "../../common/LoadingButton";

function ContadorArquivoEnviarEmailButton({ record }) {
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  const handleClick = async () => {
    setLoading(true);
    try {
      await fetchJSON("Contador_arquivo/sendEmail", {
        method: "POST",
        body: {
          arquivoId: record.id,
          lojaId: record.lojaId,
        },
      });

      refresh();
      notify("Email enviado com sucesso!");
    } catch (err) {
      notify("Erro ao enviar email para o contador.", "warning");
      console.log(JSON.stringify(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingButton
      size="small"
      variant="contained"
      text="Enivar Email"
      icon={<EmailIcon />}
      onClick={handleClick}
      loading={loading}
    />
  );
}

export default ContadorArquivoEnviarEmailButton;
