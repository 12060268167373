import React from "react";
import { Datagrid, List, TextField, DateField, EditButton } from "react-admin";
import CurrencyField from "../../common/form/CurrencyField";

import AppPedidosFilter from "./AppPedidosFilter";

const AppPedidosList = (props) => (
  <List
    {...props}
    filters={<AppPedidosFilter />}
    sort={{ field: "id", order: "DESC" }}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source="id" label="#" />
      <TextField source="lojaNome" label="Loja" />
      <DateField
        source="createdAt"
        label="Criado em"
        showTime
        locales="pt-BR"
      />
      <TextField source="mode" label="Tipo" />
      <TextField source="shortReference" label="Referência Cliente" />
      <TextField source="customerName" label="Cliente" />
      <TextField source="paymentName" label="Pagamento" />
      <CurrencyField source="totalDisplay" label="Total" />
      <EditButton />
    </Datagrid>
  </List>
);

export default AppPedidosList;
