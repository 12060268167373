import React, { useState } from "react";
import { Title, useAuthenticated, useNotify } from "react-admin";
import { Box, Card, CardContent, CardHeader, Grid, Stack } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { formatCurrency } from "../common/util/formatter";
import { fetchJSON } from "../dataProvider";
import Search from "./Search";
import UXDelay from "../dataProvider/uxdelay";
import isEmpty from "lodash.isempty";
import get from "lodash.get";
import { reportPDVEnable } from "../permissionsHelper";
import ReportTotalSimple from "./ReportTotalSimple";
import GroupedBillsTable from "./ReportBills/GroupedBillsTable";

const Chart = ({ array }) => {
  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        anchor: "end",
        align: "bottom",
        color: "black",
        formatter: function (value, context) {
          return new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(value);
        },
      },
      font: {
        weight: "bold",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  const data = {
    labels: array.map((item) => item.nome),
    datasets: [
      {
        data: array.map((item) => item.total),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(132, 99, 255, 0.2)",
          "rgba(235, 162, 54, 0.2)",
          "rgba(86, 206, 255, 0.2)",
          "rgba(192, 192, 75, 0.2)",
          "rgba(255, 102, 153, 0.2)",
          "rgba(64, 159, 255, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(132, 99, 255, 1)",
          "rgba(235, 162, 54, 1)",
          "rgba(86, 206, 255, 1)",
          "rgba(192, 192, 75, 1)",
          "rgba(255, 102, 153, 1)",
          "rgba(64, 159, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return <Bar data={data} options={options} />;
};

const CardChartTable = ({ title, array }) => (
  <Card
    sx={{
      boxShadow:
        "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
    }}
  >
    <CardHeader
      title={title}
      sx={{
        fontWeight: "bold",
        paddingBottom: "8px",
        borderBottom: "1px solid rgba(0, 0, 0, 0.24)",
      }}
    />
    <CardContent>
      <Box height={300}>
        <Chart array={array} />
      </Box>
      <GroupedBillsTable data={array} />
    </CardContent>
  </Card>
);

const ReportData = ({ data }) => {
  const {
    receitas: { total: totalReceitas, array: arrayReceitas },
    despesas: { total: totalDespesas, array: arrayDespesas },
    resultado,
    lucratividade,
  } = data;

  return (
    <Stack direction="column" spacing={5} sx={{ my: 5 }}>
      <Stack direction="row" justifyContent="space-between">
        <ReportTotalSimple
          label="Receitas"
          value={formatCurrency(totalReceitas)}
        />
        <ReportTotalSimple
          label="Despesas"
          value={formatCurrency(totalDespesas)}
          color="red"
        />
        <ReportTotalSimple
          label="Lucro"
          value={formatCurrency(resultado)}
          color="green"
        />
        <ReportTotalSimple
          label="Lucratividade"
          value={`${lucratividade}%`}
          color="green"
        />
      </Stack>
      <Grid container>
        <Grid item xs={6} sx={{ pr: 2 }}>
          <CardChartTable title="Receitas por moeda" array={arrayReceitas} />
        </Grid>
        <Grid item xs={6} sx={{ pl: 2 }}>
          <CardChartTable
            title="Despesas por classificação"
            array={arrayDespesas}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

const ReportResult = (props) => {
  useAuthenticated();
  const [reportData, setReportData] = useState({});
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  async function retrieveReportData({
    lojaId,
    startDate,
    endDate,
    startDateNoTime,
    endDateNoTime,
  }) {
    try {
      setLoading(true);
      const retrievedReportData = await UXDelay(
        fetchJSON("relatorio/pdv/resultado", null, {
          lojaId,
          incomeStartDate: startDate,
          incomeEndDate: endDate,
          billsStartDate: startDateNoTime,
          billsEndDate: endDateNoTime,
        })
      );
      setReportData(retrievedReportData);
    } catch (error) {
      console.error(error);
      notify("Erro ao buscar os dados.", "warning");
    } finally {
      setLoading(false);
    }
  }

  const dataEmpty =
    isEmpty(get(reportData, "receitas.array")) &&
    isEmpty(get(reportData, "despesas.array"));

  return (
    <Card sx={{ minHeight: "100%" }}>
      <CardContent>
        <Title title="Resultado" />
        <Search
          fields={["dateRange"]}
          onSearch={retrieveReportData}
          loading={loading}
          dataEmpty={dataEmpty}
        />
        {!dataEmpty && <ReportData data={reportData} />}
      </CardContent>
    </Card>
  );
};

export default ReportResult;

export const reportResult = {
  getMenu: (p, a) =>
    reportPDVEnable(p, a)
      ? {
          key: "ReportResult",
          to: "/relatorio/resultado",
          primaryText: "Resultado",
        }
      : null,
};
