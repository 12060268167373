import React from "react";
import { SelectInput, SelectField } from "react-admin";
import { Grid, Box } from "@material-ui/core";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

const COMPRA = "COMPRA";
const VENDA = "VENDA";

const tipos = [
  { id: COMPRA, name: "Compra", icon: <ArrowUpwardIcon style={{ color: green[500] }} /> },
  { id: VENDA, name: "Venda", icon: <ArrowDownwardIcon style={{ color: red[500] }} /> },
];

const TipoIcon = ({ record }) => {
  return (
    <Grid container align="center" alignItems="center"  >
      <Grid item>
        <Box mr={2}>
          {record.icon}
        </Box>
      </Grid>
      <Grid item>
        {record.name}
      </Grid>
    </Grid>
  );
}

function PedidoTipoInput(props) {
  return (
    <SelectInput
      label="Tipo"
      source="tipo"
      choices={tipos}
      optionText={<TipoIcon />}
      {...props}
    />
  )
}

function PedidoTipoField(props) {
  return (
    <SelectField
      label="Tipo"
      source="tipo"
      choices={tipos}
      optionText={<TipoIcon />}
      {...props}
    />
  )
}

export {
  COMPRA,
  VENDA,
  PedidoTipoInput,
  PedidoTipoField
};
