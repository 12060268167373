import React from "react";
import { FunctionField } from "react-admin";
import { formatCNPJCPF } from "../util/formatter";

function CNPJCPFField(props) {
  const { source } = props;
  return (
    <FunctionField
      render={(record) =>
        record[source] ? formatCNPJCPF(record[source]) : null
      }
    />
  );
}

export default CNPJCPFField;
