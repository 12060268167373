import React, { useState } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import { fetchJSON } from "../../../dataProvider";
import { useNotify } from "react-admin";
import LoadingButton from "../../../common/LoadingButton";

const NFeDownloadPDFCceButton = ({ record }) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  if (!record || !record.xmlCceUrl) return null;

  const downloadXmlFile = async () => {
    try {
      setLoading(true);
      const url = await fetchJSON("NFe_emissaos/urlDanfeCce", null, {
        lojaId: record.lojaId,
        nfeId: record.id,
      });
      const tab = window.open(url, "_blank");
      tab.focus();
    } catch (err) {
      console.log(JSON.stringify(err));
      notify("Ocorreu um erro ao baixar o PDF da carta de correção");
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingButton
      variant="contained"
      text="Download PDF CCe"
      loading={loading}
      icon={<GetAppIcon />}
      onClick={downloadXmlFile}
    />
  );
};

export default NFeDownloadPDFCceButton;
