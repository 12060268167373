const permissoes = [
  {
    label: "Cardápio",
    description: "Acesso a grupos, produtos, combos, perguntas e respostas.",
    permissoes: [
      { label: "Ver", source: "cardapio_ver" },
      { label: "Criar", source: "cardapio_criar" },
      { label: "Editar", source: "cardapio_editar" },
      { label: "Excluir", source: "cardapio_excluir" },
      { label: "Publicar Menu", source: "cardapio_publicar" },
    ],
  },
  {
    label: "Cadastros",
    description:
      "Acesso a parceiros, impostos, impressoras, unidades, moedas pdv e equipamentos.",
    permissoes: [
      { label: "Ver, Criar, Editar e Excluir", source: "cadastros_ver" },
      // { label: "Criar", source: "cadastros_criar" },
      // { label: "Editar", source: "cadastros_editar" },
      // { label: "Excluir", source: "cadastros_excluir" },
    ],
  },
  {
    label: "Cardápio Digital",
    description:
      "Acesso ao programa de fidelidade, promoções, áres de entrega, horários de funcionamento, moedas e relatórios.",
    permissoes: [
      { label: "Ver, Criar, Editar e Excluir", source: "cardapio_digital_ver" },
      // { label: "Criar", source: "cardapio_digital_criar" },
      // { label: "Editar", source: "cardapio_digital_editar" },
      // { label: "Excluir", source: "cardapio_digital_excluir" },
      { label: "Relatórios", source: "cardapio_digital_relatorios" },
    ],
  },
  {
    label: "Estoque",
    description:
      "Acesso a inventários, pedidos de compra/venda e entradas de notas.",
    permissoes: [
      { label: "Ver, Criar, Editar e Excluir", source: "estoque_ver" },
      // { label: "Criar", source: "estoque_criar" },
      // { label: "Editar", source: "estoque_editar" },
      // { label: "Excluir", source: "estoque_excluir" },
      { label: "Relatórios", source: "estoque_relatorios" },
    ],
  },
  {
    label: "Contas",
    description:
      "Acesso a contas a pagar e receber, classificações, centros de custo e relatórios.",
    permissoes: [
      { label: "Ver, Criar, Editar e Excluir", source: "contas_ver" },
      // { label: "Criar", source: "contas_criar" },
      // { label: "Editar", source: "contas_editar" },
      // { label: "Excluir", source: "contas_excluir" },
      { label: "Relatórios", source: "contas_relatorios" },
    ],
  },
  {
    label: "PDV",
    permissoes: [
      { label: "Deletar item", source: "pdv_deletar" },
      { label: "Transferir item", source: "pdv_transferir" },
      { label: "Alterar preço do item", source: "pdv_alterar_preco" },
      { label: "Desconto item", source: "pdv_desconto" },
      { label: "Mudar tabela de preço", source: "pdv_mudar_tabela" },
    ],
  },
  {
    label: "Caixa",
    permissoes: [
      { label: "Operar caixa", source: "caixa_operar" },
      { label: "Utiliza dinheiro", source: "caixa_dinheiro" },
      { label: "Desconto no total", source: "caixa_desconto_total" },
      { label: "Remove serviço do garçom", source: "caixa_remove_servico" },
      {
        label: "Vender para cliente com limite de crédito ultrapassado",
        source: "caixa_cliente_limite",
      },
      { label: "Sangria", source: "caixa_sangria" },
      { label: "Suprimento", source: "caixa_suprimento" },
      { label: "Pagamento", source: "caixa_pagamento" },
      { label: "Correntista", source: "caixa_correntista" },
      {
        label: "Recebimento de correntista",
        source: "caixa_correntista_recebimento",
      },
      { label: "Vale para correntista", source: "caixa_correntista_vale" },
      {
        label: "Devolução de correntista",
        source: "caixa_correntista_devolucao",
      },
      { label: "Crediário ", source: "caixa_crediario" },
      {
        label: "Recebimento de crediário",
        source: "caixa_crediario_recebimento",
      },
      { label: "Consumo", source: "caixa_consumo" },
      { label: "Perda", source: "caixa_perda" },
      { label: "Extravio", source: "caixa_extravio" },
      { label: "Abrir gaveta", source: "caixa_abrir_gaveta" },
      { label: "Devolução de venda", source: "caixa_devolucao" },
      { label: "Cancelar venda", source: "caixa_cancelar_venda" },
      { label: "Pedidos pagos", source: "caixa_pedidos_pagos" },

      { label: "Conferir Caixa", source: "caixa_conferir" },
      { label: "Relatórios de Vendas", source: "pdv_relatorios" },
    ],
  },
];

export default permissoes;
