//import Collections from "@material-ui/icons/Collections";
import MoedaAppCreate from "./MoedaAppCreate";
import MoedaAppEdit from "./MoedaAppEdit";
import MoedaAppList from "./MoedaAppList";

const moedaApp = {
  getResource: (p) => {
    if (p.ADMIN) {
      return {
        name: "moeda_Apps",
        create: MoedaAppCreate,
        list: MoedaAppList,
        edit: MoedaAppEdit,
      };
    }
  },
  getMenu: (p) =>
    p.ADMIN
      ? {
          key: "moeda_Apps",
          to: "/moeda_Apps",
          primaryText: "Moedas App",
        }
      : null,
};

export default moedaApp;
