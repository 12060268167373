import React from "react";
import { Create } from "react-admin";

import MoedaForm from "./MoedaForm";
import MoedaToolbar from "./MoedaToolbar";

const MoedaCreate = props => (
  <Create {...props}>
    <MoedaForm toolbar={<MoedaToolbar />} />
  </Create>
);

export default MoedaCreate;
