import React from "react";
import { SelectInput, SelectField } from "react-admin";

const tipos = [
  { id: 1, name: "01 - Dinheiro" },
  { id: 2, name: "02 - Cheque" },
  { id: 3, name: "03 - Cartão de Crédito" },
  { id: 4, name: "04 - Cartão de Débito" },
  { id: 5, name: "05 - Crédito Loja" },
  { id: 10, name: "10 - Vale Alimentação" },
  { id: 11, name: "11 - Vale Refeição" },
  { id: 12, name: "12 - Vale Presente" },
  { id: 13, name: "13 - Vale Combustível" },
  { id: 14, name: "14 - Duplicata Mercantil" },
  { id: 15, name: "15 - Boleto Bancário" },
  { id: 16, name: "16 - Depósito Bancário" },
  { id: 17, name: "17 - Pagamento Instantâneo (PIX)" },
  { id: 18, name: "18- -Transferência bancária, Carteira Digital" },
  { id: 19, name: "19 - Programa de fidelidade, Cashback, Crédito Virtual" },
  { id: 90, name: "90 - Sem pagamento" },
  { id: 99, name: "99 - Outros" },
];

function NFeTipoPagamentoInput(props) {
  return (
    <SelectInput
      label="Tipo"
      source="tipo"
      choices={tipos}
      initialValue={1}
      {...props}
    />
  );
}

function NFeTipoPagamentoField(props) {
  return <SelectField label="Tipo" source="tipo" choices={tipos} {...props} />;
}

export { NFeTipoPagamentoInput, NFeTipoPagamentoField };
