import React from "react";
import Table from "./Table";
import { Paper } from "@material-ui/core";

const TableReport = (props) => {
  return (
    <Table
      {...props}
      style={{ width: "100%" }}
      title=""
      components={{
        Container: (innerProps) => (
          <Paper
            {...innerProps}
            className={props.containerClassName}
            elevation={0}
          />
        ),
      }}
      options={{
        paging: false,
        search: false,
        toolbar: false,
        ...props.options,
      }}
    />
  );
};

export default TableReport;
