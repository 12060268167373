import React, { useEffect, useState } from "react";
import { useForm } from "react-final-form";
import {
  TabbedForm,
  FormTab,
  TextInput,
  NumberInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  AutocompleteInput,
  required,
  maxLength,
  minLength,
  minValue,
  FormDataConsumer,
} from "react-admin";
import { InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IdDates } from "../../common/form/ConditionalInputs";
import LazyReferenceInput from "../../common/form/LazyReferenceInput";
import ColorInput from "../../common/form/ColorInput";
import IndEscalaInput from "../../common/form/IndEscalaInput";
import ProdutoCustoMargemVenda from "./ProdutoCustoMargemVenda";
import ProdutoFormApp from "./ProdutoFormApp";
import ProdutoPromocao from "./ProdutoPromocao";
import ProdutoProximoCodigo from "./ProdutoProximoCodigo";
import useProdutoValidateCod from "./useProdutoValidateCod";
import { withUser } from "../../common/util/hocs";
import { fetchJSON } from "../../dataProvider";
import { Avatar, Grid, Link, Stack, Typography } from "@mui/material";
import NumberInputRemoveArrowsCSS from "../../common/form/NumberInputRemoveArrowsCSS.json";
import ArrayInputRemoveButton from "../../common/form/ArrayInputRemoveButton";
import NcmLazyReferenceInput from "../ncm/NcmLazyReferenceInput";
import CestLazyReferenceInput from "../cest/CestLazyReferenceInput";

const validateReq = [required()];
const validateNome = [required(), minLength(1), maxLength(1000)];
const validateTamEmb = [maxLength(10)];
const validateIngredientes = [maxLength(512)];
const validateCodAnp = [maxLength(100)];
const validateCodBenef = [maxLength(45)];
const validateCnpjFab = [maxLength(45)];

const useStyles = makeStyles((theme) => ({
  codProd: {
    display: "inline-block",
    marginRight: 32,
    ...NumberInputRemoveArrowsCSS,
  },
  formGroup: {
    display: "inline-block",
    marginRight: 32,
  },
  mr96: {
    display: "inline-block",
    marginRight: 56,
  },
}));

function ConsultaEAN({ formData: { cod_prod } }) {
  const [produto, setProduto] = useState(null);
  const form = useForm();

  useEffect(() => {
    setProduto(null);
    const validLength = [8, 12, 13, 14];
    if (!cod_prod || !validLength.includes(cod_prod.toString().length)) {
      return;
    }

    fetchJSON("produtos/consultaEAN", null, {
      ean: cod_prod,
    })
      .then((json) => setProduto(json))
      .catch((err) => console.error(err));
  }, [cod_prod]);

  const handleClick = (e) => {
    e.preventDefault();
    const keys = Object.keys(produto);
    for (const k of keys) {
      if (produto[k] !== null) {
        form.change(k, produto[k]);
      }
    }
    setProduto(null);
  };

  if (produto) {
    return (
      <Stack direction="row" spacing={3} sx={{ ml: 2, mb: 2 }}>
        <Avatar alt="logo" src={produto.fotoUrl} />
        <Link href="#" onClick={handleClick}>
          <Typography variant="body2">
            {produto.desc_prod} <br />
            {`NCM: ${produto.ncmId}`}
          </Typography>
        </Link>
      </Stack>
    );
  }

  return null;
}

function ProdutoForm(props) {
  const { loja, record } = props;
  const { uniq: uniqCod } = useProdutoValidateCod(record, loja.id, "cod_prod");
  const { uniq: uniqAux } = useProdutoValidateCod(record, loja.id, "cod_aux");

  const validateCodProd = [required(), minValue(1), maxLength(18), uniqCod];
  const validateCodAux = [minLength(1), maxLength(20), uniqAux];

  const classes = useStyles();
  const loadCompleted = !!(record && (!record.id || record.grupo));

  return (
    <TabbedForm {...props}>
      <FormTab label="Dados do produto">
        <IdDates />
        <NumberInput
          source="cod_prod"
          label="Código do produto"
          validate={validateCodProd}
          formClassName={classes.codProd}
          InputProps={{
            endAdornment: <ProdutoProximoCodigo lojaId={loja.id} />,
          }}
        />

        <TextInput
          source="cod_aux"
          label="Código auxiliar do produto"
          validate={validateCodAux}
          formClassName={classes.formGroup}
        />
        <div />

        <FormDataConsumer>
          {(formDataProps) => <ConsultaEAN formData={formDataProps.formData} />}
        </FormDataConsumer>

        <TextInput
          source="desc_prod"
          label="Nome do produto"
          validate={validateNome}
          fullWidth
        />

        <FormDataConsumer>
          {(formDataProps) => (
            <ProdutoCustoMargemVenda {...formDataProps} loja={loja} />
          )}
        </FormDataConsumer>

        <LazyReferenceInput
          label="Grupo"
          source="grupoId"
          reference="grupos"
          nameSource="grupo"
          validate={validateReq}
          addCreateButton={true}
          formClassName={classes.formGroup}
        >
          <AutocompleteInput optionText="nome" />
        </LazyReferenceInput>

        <LazyReferenceInput
          label="Unidade"
          source="unidadeId"
          reference="unidades"
          nameSource="unidade"
          validate={validateReq}
          addCreateButton={true}
          formClassName={classes.formGroup}
        >
          <AutocompleteInput optionText="nome" />
        </LazyReferenceInput>

        <LazyReferenceInput
          label="Impressora"
          source="impressoraId"
          reference="impressoras"
          nameSource="impressora"
          allowEmpty
          formClassName={classes.formGroup}
        >
          <AutocompleteInput optionText="nome" />
        </LazyReferenceInput>
        <div></div>

        <LazyReferenceInput
          label="Imposto"
          source="impostoId"
          reference="impostos"
          nameSource="imposto"
          validate={validateReq}
          formClassName={classes.mr96}
        >
          <AutocompleteInput optionText="nome" />
        </LazyReferenceInput>

        <NcmLazyReferenceInput
          label="NCM"
          source="ncmId"
          loadCompleted={loadCompleted}
          validate={validateReq}
          formClassName={classes.mr96}
        />

        <CestLazyReferenceInput
          label="CEST"
          source="cestId"
          loadCompleted={loadCompleted}
          formClassName={classes.formGroup}
        />
      </FormTab>

      <FormTab label="App delivery">
        <FormDataConsumer>
          {(formDataProps) => <ProdutoFormApp {...formDataProps} />}
        </FormDataConsumer>
      </FormTab>

      <FormTab label="Mais informações">
        <BooleanInput source="stt_prod" label="Ativo" initialValue={true} />

        <BooleanInput
          source="balanca_prod"
          label="Envia para balança"
          formClassName={classes.formGroup}
        />
        <NumberInput
          source="dias_vld_prod"
          label="Validade"
          formClassName={classes.formGroup}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">Dias</InputAdornment>
            ),
          }}
        />
        <div />

        <BooleanInput
          source="paga_servico_prod"
          label="Paga serviço do garçom"
          formClassName={classes.formGroup}
        />
        <BooleanInput
          source="adicional"
          label="Adicional"
          formClassName={classes.formGroup}
        />
        <BooleanInput
          source="materia_prima_prod"
          label="Matéria prima"
          formClassName={classes.formGroup}
        />
        <BooleanInput
          source="alcoolico"
          label="Alcoólico"
          formClassName={classes.formGroup}
        />
        <div />

        <NumberInput
          source="comissao_prod"
          label="Comissão"
          formClassName={classes.formGroup}
          initialValue={-1}
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
        <NumberInput
          source="dsct_max_prod"
          label="Desconto máximo"
          formClassName={classes.formGroup}
          initialValue={100}
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
        <NumberInput
          source="qtd_min_prod"
          label="Estoque mínimo"
          formClassName={classes.formGroup}
        />
        <div />

        <ColorInput
          source="cor"
          label="Cor"
          formClassName={classes.formGroup}
        />
        <NumberInput
          source="ordem"
          label="Ordem"
          initialValue={999}
          formClassName={classes.formGroup}
        />
        <div />

        <NumberInput
          source="tam_emb_prod"
          label="Tamanho da embalagem"
          validate={validateTamEmb}
        />

        <TextInput
          source="ingredientes_prod"
          label="Ingredientes etiqueta"
          validate={validateIngredientes}
          fullWidth
          multiline
          resettable
        />

        <TextInput
          source="codigo_anp"
          label="Código ANP"
          validate={validateCodAnp}
          formClassName={classes.formGroup}
        />
        <TextInput
          source="codigo_beneficio"
          label="Código benefício fiscal"
          validate={validateCodBenef}
          formClassName={classes.formGroup}
        />

        <div />
        <IndEscalaInput
          source="ind_escala"
          label="Escala relevante"
          formClassName={classes.formGroup}
        />
        <TextInput
          source="cnpj_fab"
          label="CNPJ fabricante"
          validate={validateCnpjFab}
          formClassName={classes.formGroup}
        />

        <div />
        <BooleanInput
          source="combo"
          label="Combo"
          initialValue={false}
          disabled
          formClassName={classes.formGroup}
        />

        <BooleanInput
          source="ponte"
          label="ponte"
          initialValue={false}
          disabled
          formClassName={classes.formGroup}
        />
      </FormTab>

      <FormTab label="Promoção">
        <ProdutoPromocao />
      </FormTab>

      <FormTab label="Perguntas">
        <ArrayInput source="perguntas" label="" fullWidth>
          <SimpleFormIterator
            disableReordering
            TransitionProps={{ enter: false, exit: false }}
            disableRemove
          >
            <FormDataConsumer>
              {({ formData, scopedFormData, getSource }) => (
                <Grid container alignItems="center" spacing={3}>
                  <Grid item>
                    <LazyReferenceInput
                      record={record}
                      source={getSource("perguntaId")}
                      nameSource={getSource("pergunta")}
                      label="Pergunta"
                      reference="pergunta"
                    >
                      <AutocompleteInput optionText="descricao" />
                    </LazyReferenceInput>
                  </Grid>
                  <Grid item>
                    <ArrayInputRemoveButton
                      formData={formData}
                      itemData={scopedFormData}
                      getSource={getSource}
                    />
                  </Grid>
                </Grid>
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  );
}

export default withUser(ProdutoForm);
