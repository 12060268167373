import React from "react";
import { Datagrid, List, TextField, DateField } from "react-admin";
import CurrencyField from "../../common/form/CurrencyField";

import NFCeFilter from "./NFCeFilter";
import NFCeStatus from "./NFCeStatus";
import LinkToMovimento from "./LinkToMovimento";

const MovimentoList = (props) => (
  <List
    {...props}
    filters={<NFCeFilter />}
    sort={{ field: "id", order: "DESC" }}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source="serie" label="Série" />
      <TextField source="nNF" label="Número" />
      <DateField source="dhEmi" label="Emissão" showTime locales="pt-BR" />
      <CurrencyField source="vNF" label="Total" />
      <NFCeStatus />
      <LinkToMovimento />
    </Datagrid>
  </List>
);

export default MovimentoList;
