import React, { useCallback, useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, Tab, Tabs } from "@mui/material";
import { Button } from "react-admin";
import TabICMS from "./tabs/TabICMS";
import TabPIS from "./tabs/TabPIS";
import TabCOFINS from "./tabs/TabCOFINS";
import TabIPI from "./tabs/TabIPI";
import TabProduto from "./tabs/TabProduto";

function NFeEditarImpostosButton({ record, loja, itemData }) {
  const [showDialog, setShowDialog] = useState(false);
  const [tabValue, setTabValue] = useState(1);

  const getSource = useCallback(
    (field) => `itens[${itemData.index}].${field}`,
    [itemData.index]
  );

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClick = () => setShowDialog(true);
  const handleCloseClick = () => setShowDialog(false);

  return (
    <>
      <Button label="Editar" onClick={handleClick}>
        <EditIcon />
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>{`Editar Impostos - ${itemData.codigo} - ${itemData.descricao}`}</DialogTitle>
        <DialogContent>
          <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
            <Tabs
              variant="fullWidth"
              value={tabValue}
              onChange={handleChangeTab}
            >
              <Tab label="PRODUTO" />
              <Tab label="ICMS" />
              <Tab label="PIS" />
              <Tab label="COFINS" />
              <Tab label="IPI" />
            </Tabs>
          </Box>
          <TabProduto
            index={0}
            record={record}
            tabValue={tabValue}
            getSource={getSource}
          />
          <TabICMS
            index={1}
            tabValue={tabValue}
            loja={loja}
            itemData={itemData}
            getSource={getSource}
          />
          <TabPIS
            index={2}
            tabValue={tabValue}
            loja={loja}
            itemData={itemData}
            getSource={getSource}
          />
          <TabCOFINS
            index={3}
            tabValue={tabValue}
            loja={loja}
            itemData={itemData}
            getSource={getSource}
          />
          <TabIPI
            index={4}
            tabValue={tabValue}
            loja={loja}
            itemData={itemData}
            getSource={getSource}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default NFeEditarImpostosButton;
