import React from "react";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import PromocaoCreate from "./PromocaoCreate";
import PromocaoEdit from "./PromocaoEdit";
import PromocaoList from "./PromocaoList";
import { cardapioDigitalEnable } from "../../permissionsHelper";

const appPromocao = {
  getResource: (p, a) => {
    return cardapioDigitalEnable(p, a)
      ? {
          name: "AppPromocaos",
          create: PromocaoCreate,
          list: PromocaoList,
          edit: PromocaoEdit,
        }
      : null;
  },
  getMenu: (p, a) => {
    return cardapioDigitalEnable(p, a)
      ? {
          key: "AppPromocaos",
          to: "/AppPromocaos",
          primaryText: "Promoções",
          leftIcon: iconPromocao,
        }
      : null;
  },
};

export default appPromocao;
export const iconPromocao = <LocalOfferIcon />;
