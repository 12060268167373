import React, { useState } from "react";
import MailIcon from "@material-ui/icons/Mail";
import { fetchJSON } from "../../../dataProvider";
import { useNotify } from "react-admin";
import LoadingButton from "../../../common/LoadingButton";

const NFeEnviaEmailButton = ({ record }) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  if (!record || !record.xmlUrl) return null;

  const sendMail = async () => {
    try {
      setLoading(true);
      await fetchJSON("NFe_emissaos/sendEmail", {
        method: "POST",
        body: {
          lojaId: record.lojaId,
          nfeId: record.id,
        },
      });
      notify(`Email envido com sucesso!`);
    } catch (exception) {
      console.log(JSON.stringify(exception));
      notify(exception.body.error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingButton
      variant="contained"
      text="Enviar Email"
      loading={loading}
      icon={<MailIcon />}
      onClick={sendMail}
    />
  );
};

export default NFeEnviaEmailButton;
