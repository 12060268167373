import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  Chip,
} from "@mui/material";
import { getList } from "../../dataProvider";

function Partners({ value, onChange, id }) {
  const [sellers, setSellers] = useState([]);

  const handleChange = (event) => {
    const seletedSellers = event.target.value;

    // const shouldClearSelect = seletedSellers.some(
    //   (seller) => seller === "clear"
    // );
    // if (shouldClearSelect) {
    //   onChange(id, []);
    //   return;
    // }

    onChange(id, seletedSellers);
  };

  useEffect(() => {
    getList("parceiros", { filter: { funcionario: true } }).then((r) =>
      setSellers(r.data)
    );
  }, []);

  return (
    <FormControl sx={{ minWidth: 360, maxWidth: 420 }}>
      <InputLabel id="sellerSelectLabel" aria-labelledby="Parceiros">
        Parceiros
      </InputLabel>
      <Select
        labelId="sellerSelectLabel"
        multiple
        value={value}
        onChange={handleChange}
        input={<Input id="select-multiple-chip" />}
        renderValue={renderPartner}
      >
        {/* <MenuItem value={"clear"}>Limpar</MenuItem> */}
        {sellers.map((g) => (
          <MenuItem key={g.id} value={g}>
            {g.nome_fantasia}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function renderPartner(selected) {
  return (
    <div>
      {selected.map((value) => (
        <Chip key={value.id} label={value.nome_fantasia} />
      ))}
    </div>
  );
}

export default Partners;
