import React from "react";
import {
  Datagrid,
  List,
  TextField,
  NumberField,
  ReferenceField,
  DateField,
} from "react-admin";

import NpsFilter from "./NpsFilter";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import useFetch from "../../dataProvider/useFetch";
import { formatNumber } from "../../common/util/formatter";

const CardTotal = ({
  label,
  value,
  color = "#283593",
  component,
  isMobile,
}) => {
  return (
    <Card
      sx={{
        border: "1px solid #c5c5c5",
        borderBottom: `7px solid ${color}`,
        display: "flex",
        alignItems: "center",
        pr: isMobile ? 0 : 2,
        pl: isMobile ? 0 : 2,
      }}
    >
      <CardContent>
        {label && typeof label === "string" && (
          <Typography variant="subtitle1" color="textPrimary">
            {label}
          </Typography>
        )}
        {value && typeof value === "string" && (
          <Typography sx={{ fontWeight: "bold" }} variant="h5">
            {value}
          </Typography>
        )}
        {!!component && component}
      </CardContent>
    </Card>
  );
};

const NpsScore = (props) => {
  const { data } = useFetch("Nps/score");
  if (!data) {
    return null;
  }

  return (
    <Stack direction="row" justifyContent="space-evenly" sx={{ my: 3 }}>
      <CardTotal
        label={`Detratores ${data.detratores.count}`}
        value={`${formatNumber(data.detratores.percentage)}%`}
        color="red"
      />
      <CardTotal
        label={`Passivos ${data.passivos.count}`}
        value={`${formatNumber(data.passivos.percentage)}%`}
        color="grey"
      />
      <CardTotal
        label={`Promotores ${data.promotores.count}`}
        value={`${formatNumber(data.promotores.percentage)}%`}
        color="green"
      />
      <CardTotal label="Nota" value={formatNumber(data.score)} />
    </Stack>
  );
};

const NpsList = (props) => (
  <Box>
    <NpsScore />

    <List
      {...props}
      filters={<NpsFilter />}
      sort={{ field: "id", order: "DESC" }}
      exporter={false}
    >
      <Datagrid>
        <NumberField source="id" label="#" />
        <DateField
          source="createdAt"
          label="Criado em"
          showTime
          locales="pt-BR"
        />
        <ReferenceField source="userId" reference="users">
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField source="lojaId" reference="lojas">
          <TextField source="nome_fantasia" />
        </ReferenceField>
        <NumberField source="score" />
        <TextField source="feedback" />
      </Datagrid>
    </List>
  </Box>
);

export default NpsList;
