import React, { Fragment } from "react";
import ExpandMore from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    marginTop: "8px",
    marginBottom: "8px",
  },
  icon: { minWidth: theme.spacing(5) },
  boxIsOpen: {
    borderLeft: "gray 1px solid",
  },
  boxIsClose: {
    background: "#EEE",
    borderTopLeftRadius: "18px",
    borderBottomLeftRadius: "18px",
  },
  sidebarIsOpen: {
    paddingLeft: 28,
    transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  },
  sidebarIsClosed: {
    transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  },
}));

const SubMenu = ({
  handleToggle,
  sidebarIsOpen,
  isOpen,
  name,
  icon,
  children,
  dense,
}) => {
  const classes = useStyles();

  const header = (
    <MenuItem
      dense={dense}
      button
      onClick={handleToggle}
      className={classes.menuItem}
    >
      <ListItemIcon className={classes.icon}>
        {isOpen ? <ExpandMore /> : icon}
      </ListItemIcon>
      <Typography color="textSecondary">{name}</Typography>
    </MenuItem>
  );

  return (
    <Fragment>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={name} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          dense={dense}
          component="div"
          disablePadding
          className={
            sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
          }
        >
          <Box
            className={sidebarIsOpen ? classes.boxIsOpen : classes.boxIsClose}
          >
            {children}
          </Box>
        </List>
      </Collapse>
    </Fragment>
  );
};

export default SubMenu;
