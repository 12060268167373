import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

const HORAS = [8, 12, 24];

function IntervalHour({ value, onChange, id }) {
  const handleChange = (event) => onChange(id, event.target.value);

  return (
    <FormControl variant='standard' sx={{ minWidth: 100 }}>
      <InputLabel id='demo-simple-select-helper-label'>
        Intervalo Horas
      </InputLabel>
      <Select label='' value={value} onChange={handleChange}>
        {HORAS.map((h) => (
          <MenuItem key={h} value={h}>
            {h}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default IntervalHour;
