import React from "react";
import { SelectInput, SelectField } from "react-admin";

const tipos = [
  { id: 0, name: "0 - Normal" },
  { id: 1, name: "1 - Consumidor final" },
];

function NFeConsumidorInput(props) {
  return (
    <SelectInput
      label="Consumidor"
      source="consumidor"
      choices={tipos}
      initialValue={1}
      {...props}
    />
  );
}

function NFeConsumidorField(props) {
  return (
    <SelectField
      label="Consumidor"
      source="consumidor"
      choices={tipos}
      {...props}
    />
  );
}

export { NFeConsumidorInput, NFeConsumidorField };
