import React from "react";
import { useForm } from "react-final-form";
import {
  TabbedForm,
  FormTab,
  TextInput,
  NumberInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  AutocompleteInput,
  FormDataConsumer,
  required,
  maxLength,
  minLength,
  minValue,
} from "react-admin";
import { InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ProdutoFormApp from "../produto/ProdutoFormApp";
import ProdutoProximoCodigo from "../produto/ProdutoProximoCodigo";
import useProdutoValidateCod from "../produto/useProdutoValidateCod";
import { IdDates } from "../../common/form/ConditionalInputs";
import LazyReferenceInput from "../../common/form/LazyReferenceInput";
import ColorInput from "../../common/form/ColorInput";
import { withUser } from "../../common/util/hocs";
import { Grid } from "@mui/material";
import ArrayInputRemoveButton from "../../common/form/ArrayInputRemoveButton";
import ProdutoLazyReferenceInput from "../produto/ProdutoLazyReferenceInput";

const validateReq = [required()];
const validateRS = [required(), minValue(0)];
const validateNome = [required(), minLength(1), maxLength(1000)];

const useStyles = makeStyles({
  produto: {
    width: "35em",
  },
  formGroup: {
    display: "inline-block",
    marginRight: 32,
  },
  preco: {
    display: "inline-block",
    marginRight: 32,
    width: "7em",
  },
});

function ProdutoForm(props) {
  const { loja, record } = props;
  const { uniq: uniqCod } = useProdutoValidateCod(record, loja.id, "cod_prod");

  const validateCodProd = [required(), minValue(1), uniqCod];

  const classes = useStyles();

  return (
    <TabbedForm {...props} toolbar={props.toolbar}>
      <FormTab label="Dados do produto">
        <IdDates />
        <NumberInput
          source="cod_prod"
          label="Código do produto"
          validate={validateCodProd}
          formClassName={classes.formGroup}
          InputProps={{
            endAdornment: <ProdutoProximoCodigo lojaId={loja.id} />,
          }}
        />
        <BooleanInput
          source="stt_prod"
          label="Ativo"
          initialValue={true}
          formClassName={classes.formGroup}
        />
        <div />

        <TextInput
          source="desc_prod"
          label="Nome do produto"
          validate={validateNome}
          fullWidth
        />

        <LazyReferenceInput
          label="Grupo"
          source="grupoId"
          reference="grupos"
          nameSource="grupo"
          validate={validateReq}
          addCreateButton={true}
          formClassName={classes.formGroup}
        >
          <AutocompleteInput optionText="nome" />
        </LazyReferenceInput>

        <ColorInput
          source="cor"
          label="Cor"
          formClassName={classes.formGroup}
        />

        <NumberInput
          source="ordem"
          label="Ordem"
          initialValue={999}
          formClassName={classes.formGroup}
        />

        <ArrayInput
          source="comboItens"
          label="Lista de produtos do combo"
          fullWidth
        >
          <SimpleFormIterator
            disableReordering
            TransitionProps={{ enter: false, exit: false }}
            disableRemove
          >
            <FormDataConsumer>
              {({ formData, scopedFormData, getSource }) => (
                <Grid container alignItems="center" spacing={3}>
                  <Grid item>
                    <ProdutoLazyReferenceInput
                      record={record}
                      source={getSource("produtoIdItem")}
                      nameSource={getSource("produto")}
                      label="Produto"
                      className={classes.produto}
                    />
                  </Grid>
                  <Grid item>
                    <NumberInput
                      record={record}
                      source={getSource("preco_item")}
                      label="Preço"
                      validate={validateRS}
                      initialValue={0}
                      className={classes.preco}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <ArrayInputRemoveButton
                      formData={formData}
                      itemData={scopedFormData}
                      getSource={getSource}
                    />
                  </Grid>
                </Grid>
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput source="perguntas" label="Lista de perguntas" fullWidth>
          <SimpleFormIterator
            disableReordering
            TransitionProps={{ enter: false, exit: false }}
            disableRemove
          >
            <FormDataConsumer>
              {({ formData, scopedFormData, getSource }) => (
                <Grid container align="center" alignItems="center" spacing={3}>
                  <Grid item>
                    <LazyReferenceInput
                      record={record}
                      source={getSource("perguntaId")}
                      nameSource={getSource("pergunta")}
                      label="Pergunta"
                      reference="pergunta"
                      formClassName={classes.formGroup}
                    >
                      <AutocompleteInput optionText="descricao" />
                    </LazyReferenceInput>
                  </Grid>
                  <Grid item>
                    <ArrayInputRemoveButton
                      formData={formData}
                      itemData={scopedFormData}
                      getSource={getSource}
                    />
                  </Grid>
                </Grid>
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>

        <FormDataConsumer>
          {(formDataProps) => {
            const total = (formDataProps.formData.comboItens || [])
              .filter((c) => c && c.preco_item)
              .reduce((acc, c) => acc + c.preco_item, 0);
            return <ComboTotal total={total} />;
          }}
        </FormDataConsumer>
      </FormTab>

      <FormTab label="App delivery">
        <ProdutoFormApp />
      </FormTab>
    </TabbedForm>
  );
}

function ComboTotal({ total }) {
  const form = useForm();

  form.change("preco_vnd_prod", total);

  return (
    <NumberInput
      source="preco_vnd_prod"
      label="Preço do combo"
      initialValue={0}
      disabled={true}
      InputProps={{
        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
      }}
    />
  );
}

export default withUser(ProdutoForm);
