import React from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  Chip,
} from "@mui/material";

function MovTypes({ value, onChange, id }) {
  const movs = [
    // { id: 1, nome: 'A Vista' },
    // { id: 2, nome: 'Correntista' },
    // { id: 3, nome: 'Rec. Correntista' },
    { id: 4, nome: "Consumo" },
    { id: 5, nome: "Perda" },
    // { id: 6, nome: 'Vale' },
    // { id: 7, nome: 'Sangria' },
    // { id: 8, nome: 'Pagamento' },
    // { id: 9, nome: 'Devolucao' },
    // { id: 10, nome: 'Crediario' },
    // { id: 11, nome: 'Suprimento' },
    // { id: 12, nome: 'Rec. Crediario' },
    // { id: 13, nome: 'Dev. Correntista' },
    // { id: 14, nome: 'Orçamento' },
    // { id: 15, nome: '?' },
  ];

  const handleChange = (event) => onChange(id, event.target.value);

  return (
    <FormControl sx={{ minWidth: 120 }}>
      <InputLabel>Movimentos</InputLabel>
      <Select
        multiple
        value={value}
        onChange={handleChange}
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => (
          <div>
            {selected.map((value) => (
              <Chip key={value} label={movs.find((m) => m.id === value).nome} />
            ))}
          </div>
        )}
      >
        {movs.map((m) => (
          <MenuItem key={m.id} value={m.id}>
            {m.nome}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MovTypes;
