import React from "react";
import { Edit } from "react-admin";
import KdsForm from "./KdsForm";
import KdsToolbar from "./KdsToolbar";

const KdsEdit = (props) => (
  <Edit {...props}>
    <KdsForm toolbar={<KdsToolbar />} />
  </Edit>
);

export default KdsEdit;
