import React from "react";
import { Edit } from "react-admin";

import IFoodOauthForm from "./IFoodOauthForm";

const IFoodOauthEdit = (props) => (
  <Edit {...props}>
    <IFoodOauthForm toolbar={null} />
  </Edit>
);

export default IFoodOauthEdit;
