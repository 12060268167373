import React from "react";
import { TextInput, NumberInput, Filter, BooleanInput } from "react-admin";
import { TelefoneInput } from "../../common/form/NumberMaskInput";
import QuickFilter from "../../common/form/QuickFilter";

export const parceiro_filters = {
  funcionario_pdv_windows: {
    usuario_pdv: { gt: 0 },
  },
};

const ParceiroFilter = (props) => (
  <Filter {...props}>
    <NumberInput source="id" label="Id" alwaysOn />
    <TextInput source="cnpj_like" label="CNPJ" alwaysOn />
    <TextInput source="q" label="Nome ou Razão social" alwaysOn />
    <BooleanInput source="ativo" defaultValue={true} />
    <BooleanInput source="cliente" defaultValue={true} />
    <BooleanInput
      source="funcionario"
      label="Funcionário"
      defaultValue={true}
    />
    <BooleanInput source="fornecedor" defaultValue={true} />
    <TextInput
      source="telefone1_like"
      label="Telefone 1"
      InputProps={{
        inputComponent: TelefoneInput,
      }}
    />
    <QuickFilter
      source="funcionario_pdv_windows"
      label="Acesso ao PDV Windows"
      defaultValue={true}
    />
  </Filter>
);

export default ParceiroFilter;
