import React, { useState } from "react";
import { Card, CardContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Title } from "react-admin";
import { fetchJSON } from "../dataProvider";
import UXDelay from "../dataProvider/uxdelay";
import Table from "../common/table/TableReport";
import { useAuthenticated } from "react-admin";
import isEmpty from "lodash.isempty";
import Search from "./Search";
import { reportPDVEnable } from "../permissionsHelper";
import { Grid } from "@mui/material";
import ReportTotalQuantidadeCard from "./ReportTotalQuantidadeCard";
import ReportTotalFaturamentoCard from "./ReportTotalFaturamentoCard";

const styles = {
  root: {
    minHeight: "100%",
  },
  classeA: {
    backgroundColor: "#C8E6C9",
    fontSize: `12px`,
  },
  classeB: {
    backgroundColor: "#FFF9C4",
    fontSize: `12px`,
  },
  classeC: {
    backgroundColor: "#FFAB91",
    fontSize: `12px`,
  },
};

const ReportCurvaABCTipoMov = (props) => {
  useAuthenticated();

  const [loading, setLoading] = useState(false);
  const [exception, setException] = useState(null);
  const [data, setData] = useState({ array: [] });

  async function retrieveReportData({
    lojaId,
    startDate,
    endDate,
    movTypes,
    groups,
    units,
  }) {
    try {
      setException(null);
      setLoading(true);
      const retrievedReportData = await UXDelay(
        fetchJSON("relatorio/pdv/curva_abc_tipo_mov", null, {
          lojaId,
          startDate,
          endDate,
          movTypes,
          groups,
          units,
        })
      );
      setData(retrievedReportData);
    } catch (exception) {
      setException(exception);
    } finally {
      setLoading(false);
    }
  }

  /*
    descricao: "Carne Fria Kg"
    qtd: 0.55
    valor_final: 38.5
    valor_custo: 0
    valor_unitario_medio: 0
    lucro: 38.5
    qtd_p: 0
    qtd_abc: 0.01
    venda_p: 0.09
    venda_abc: 0.19
    lucro_p: 0.12
    lucro_abc: 0.26
    classe: "A"
  */

  const defaultColumns = [
    {
      title: "Produto",
      field: "descricao",
      sorting: false,
      width: "40%",
    },
    {
      title: "R$ Unit. Médio",
      field: "valor_unitario_medio",
      sorting: false,
      render: (rowData) =>
        Number(rowData.valor_unitario_medio).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
    },

    {
      title: "Quant.",
      field: "qtd",
      sorting: false,
      render: (rowData) => Number(rowData.qtd).toLocaleString("pt-BR"),
    },
    {
      title: "R$ Custo",
      field: "valor_final",
      sorting: false,
      render: (rowData) =>
        Number(rowData.valor_final).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      title: "% Custo",
      field: "venda_p",
      sorting: false,
      render: (rowData) => `${rowData.venda_p}%`,
    },
    {
      title: "ABC Custo",
      field: "venda_abc",
      sorting: false,
      render: (rowData) => `${rowData.venda_abc}%`,
    },
  ];

  const options = {
    rowStyle: (rowData) => {
      return styles[`classe${rowData.classe}`];
    },
  };

  const dataEmpty = isEmpty(data.array);

  return (
    <Card className={props.classes.root}>
      <Title title="Curva ABC" />
      <CardContent>
        <Search
          fields={["dateRange", "timeRange", "movTypes", "groups", "units"]}
          onSearch={retrieveReportData}
          loading={loading}
          exception={exception}
          dataEmpty={dataEmpty}
        />
        {!dataEmpty && (
          <>
            <Grid container spacing={3} sx={{ my: 5 }}>
              <Grid item xs={3}>
                <ReportTotalFaturamentoCard
                  total={data.total_venda}
                  title="Total de Custo"
                />
              </Grid>
              <Grid item xs={3}>
                <ReportTotalQuantidadeCard total={data.total_qtd} />
              </Grid>
            </Grid>
            <Table
              data={data.array}
              columns={defaultColumns}
              options={options}
              containerClassName={props.classes.root}
              isLoading={loading}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(ReportCurvaABCTipoMov);

export const curvaABCTipoMov = {
  getMenu: (p, a) =>
    reportPDVEnable(p, a)
      ? {
          key: "curvaABC",
          to: "/relatorio/curva-abc-tipo-mov",
          primaryText: "Curva ABC Perda",
        }
      : null,
};
