import React from "react";
import {
  Datagrid,
  List,
  TextField,
  ShowButton,
  BooleanField,
} from "react-admin";

import UsuarioFilter from "./UsuarioFilter";

const UsuarioList = (props) => (
  <List
    {...props}
    filters={<UsuarioFilter />}
    bulkActionButtons={false}
    exporter={false}
  >
    <Datagrid>
      <TextField source="id" label="Id" />
      <TextField source="email" label="Email" />
      <BooleanField source="emailVerified" label="Senha Criada" />
      <ShowButton />
    </Datagrid>
  </List>
);

export default UsuarioList;
