import React from "react";
import PeopleIcon from "@material-ui/icons/People";
import UsuarioShow from "./UsuarioShow";
import UsuarioList from "./UsuarioList";

const usuario = {
  getResource: (p) => {
    if (p.ADMIN) {
      return {
        name: "users",
        list: UsuarioList,
        show: UsuarioShow,
      };
    }
  },
  getMenu: (p) =>
    p.ADMIN
      ? {
          key: "users",
          to: "/users",
          primaryText: "Usuários",
          leftIcon: iconUsuario,
        }
      : null,
};

export default usuario;
export const iconUsuario = <PeopleIcon />;
