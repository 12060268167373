import React, { useCallback } from "react";
import { useNotify, Button, FormDataConsumer } from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import { useForm, useFormState } from "react-final-form";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@mui/material";
import { Datagrid, TextField, TextInput } from "react-admin";
import { keyBy } from "lodash";
import DataGridRemoveButton from "../../../common/form/DataGridRemoveButton";

const useStyles = makeStyles({
  chave: {
    width: "30em",
    marginRight: "1em",
  },
});

const ButtonAdd = (props) => {
  const form = useForm();
  const formState = useFormState();
  const notify = useNotify();

  const handleAdd = () => {
    const { chaves, chaveNova } = formState.values;
    const { chave } = chaveNova || {};

    if (!chave) {
      notify(`Preencha a chave de acesso!`);
      return;
    }

    const chaveNumbers = chave.onlyNumbers();
    if (chaveNumbers.length !== 44) {
      notify(`Preencha a chave de acesso com 44 caracteres!`);
      return;
    }

    form.change(`chaves`, [
      ...chaves,
      {
        chave: chaveNumbers,
        index: chaves.length,
      },
    ]);
    form.change(`chaveNova`, null);
  };

  return (
    <Button onClick={handleAdd} label="Adicionar" variant="outlined">
      <AddIcon />
    </Button>
  );
};

export default function TabChavesRef({ loaded, record, formData }) {
  const classes = useStyles();
  const getSource = useCallback((field) => `chaveNova.${field}`, []);
  const data = keyBy(formData.chaves, "index");
  const ids = formData.chaves.map(({ index }) => index);

  return (
    <>
      {!record.xmlUrl && (
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom sx={{ mt: 4, ml: 1 }}>
              Nova Chave Referênciada
            </Typography>
          </Grid>

          <Grid item>
            <TextInput
              label="Chave"
              source={getSource("chave")}
              className={classes.chave}
            />
          </Grid>
          <Grid item sx={{ mr: 5, mb: 2 }}>
            <ButtonAdd />
          </Grid>
        </Grid>
      )}

      <Typography variant="h5" gutterBottom sx={{ mt: 4, ml: 1 }}>
        Lista de Chaves Referênciadas
      </Typography>
      <Datagrid
        loaded={loaded}
        data={data}
        ids={ids}
        currentSort={{ field: "index", order: "ASC" }}
      >
        <TextField source="chave" label="" />

        {!record.xmlUrl && (
          <FormDataConsumer>
            {({ formData, record: itemData }) => (
              <DataGridRemoveButton
                formData={formData}
                record={itemData}
                arrayName="chaves"
              />
            )}
          </FormDataConsumer>
        )}
      </Datagrid>
    </>
  );
}
