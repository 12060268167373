//import Collections from "@material-ui/icons/Collections";
import MoedaTipoCreate from "./MoedaTipoCreate";
import MoedaTipoEdit from "./MoedaTipoEdit";
import MoedaTipoList from "./MoedaTipoList";

const moedaTipo = {
  getResource: (p) => {
    if (p.ADMIN) {
      return {
        name: "moeda_tipos",
        create: MoedaTipoCreate,
        list: MoedaTipoList,
        edit: MoedaTipoEdit,
      };
    }
  },
  getMenu: (p) =>
    p.ADMIN
      ? {
          key: "moeda_tipos",
          to: "/moeda_tipos",
          primaryText: "Moeda tipos",
        }
      : null,
};

export default moedaTipo;
