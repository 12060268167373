// eslint-disable-next-line no-unused-vars
import prototype from "../prototype";
import React, { createContext, useState, useEffect, useCallback } from "react";
import { Admin } from "react-admin";
import getRoutes from "./routes";
import { dataProvider, fetchJSON } from "../dataProvider";
import authProvider, { Permissoes } from "../authProvider";
import { LoginPage, Layout, NotFoundPage } from "../layout";
import Dashboard from "../layout/Dashboard";
import i18nProvider from "../i18n/i18nProvider";
import getResources from "./Resources";
import { Chart } from "chart.js";
import ChartDataLabelsPlugin from "chartjs-plugin-datalabels";
import ChartAnnotationPlugin from "chartjs-plugin-annotation";
import AlertDialog from "../common/AlertDialog";

Chart.register(ChartDataLabelsPlugin);
Chart.register(ChartAnnotationPlugin);

export var updateToken, mudaLoja;
export const Context = createContext({});

const LOJAID = "lojaId";
const TOKEN = "token";

const configInicial = {
  admin_user: undefined,
  usuario: undefined,
  loja: undefined,
  permissao: undefined,
  admin_user_active: false,
};

const Ready = () => (
  <div>
    <h1>Sistema Pallas</h1>
    <p>Aguarde...</p>
  </div>
);

function App() {
  const [atualizaLoja, setAtualizaLoja] = useState(0);
  const [token, setToken] = useState(localStorage.getItem(TOKEN));
  const [config, setConfig] = useState(configInicial);
  const [message, setMessage] = useState(null);

  const mudaUsuarioLoja = useCallback(async (usuario) => {
    let lojaId = localStorage.getItem(LOJAID);
    let loja = usuario.lojas.find((l) => l.id === Number(lojaId));

    if (!loja) {
      loja = usuario.lojas[0];
      lojaId = loja.id;
      localStorage.setItem(LOJAID, lojaId);
    }

    const responsePerfil = await dataProvider.getOne("Loja_perfil", {
      id: loja.id,
    });
    const responsePermissao = await dataProvider.getList("user_lojas", {
      filter: {
        lojaId: loja.id,
        userId: usuario.id,
      },
    });
    loja = responsePerfil.data;
    const permissao = responsePermissao.data[0];

    permissao.ADMIN = permissao.type === Permissoes.ADMIN;
    permissao.PROPRIETARIO = permissao.type === Permissoes.PROPRIETARIO;

    const admin_user_active =
      loja.admin_user && loja.admin_user.active && !loja.admin_user.block;

    setConfig({
      admin_user: loja.admin_user,
      usuario,
      loja,
      permissao,
      admin_user_active,
    });
  }, []);

  const logout = useCallback(async () => {
    fetchJSON("users/logout", { method: "POST" })
      .catch(() => {})
      .finally(() => {
        localStorage.removeItem(TOKEN);
        localStorage.removeItem(LOJAID);
        setToken(null);
        setConfig(configInicial);
      });
  }, []);

  useEffect(() => {
    if (token) {
      fetchJSON("users/configuracao", { method: "POST" })
        .then((user) => mudaUsuarioLoja(user))
        .catch((err) => {
          console.log(JSON.stringify(err));
          setMessage(err.body.error.message);
          logout();
        });
    }
  }, [token, atualizaLoja, mudaUsuarioLoja, logout]);

  updateToken = (newToken) => {
    if (!newToken && token) {
      logout();
    } else if (newToken !== token) {
      setToken(newToken);
    }
  };

  mudaLoja = (lojaId) => {
    localStorage.setItem(LOJAID, lojaId);
    setConfig(configInicial);
    setAtualizaLoja(atualizaLoja + 1);
  };

  return (
    <Context.Provider value={config}>
      <Admin
        ready={Ready}
        catchAll={NotFoundPage}
        loginPage={LoginPage}
        customRoutes={getRoutes(config)}
        dataProvider={dataProvider}
        authProvider={authProvider}
        layout={Layout}
        dashboard={Dashboard}
        i18nProvider={i18nProvider}
      >
        {getResources(config)}
      </Admin>
      <AlertDialog
        title="Aviso"
        open={!!message}
        message={message}
        handleClose={() => setMessage(null)}
      />
    </Context.Provider>
  );
}

document.addEventListener("wheel", function (event) {
  if (document.activeElement.type === "number") {
    document.activeElement.blur();
  }
});

export default App;
