import React from "react";
import {
  Datagrid,
  List,
  Filter,
  Pagination,
  TextInput
} from "react-admin";

import MoedaAppIconText from '../moeda_app/MoedaAppIconText';
import ButtonTrocar from './AtivarMoedaButton';

const LojaMoedaAppPagination = props => <Pagination rowsPerPageOptions={[100]} {...props} />;

const LojaMoedaAppFilter = props => (
  <Filter {...props}>
    <TextInput source="nome" alwaysOn />
  </Filter>
);

const LojaMoedaAppList = props => (
  <List {...props} 
    perPage={100}
    filters={<LojaMoedaAppFilter />} 
    pagination={<LojaMoedaAppPagination />} 
    bulkActionButtons={false} 
    exporter={false}
  >
    <Datagrid>
      <MoedaAppIconText source="moeda_app.nome" label="Nome"/>
      <ButtonTrocar label="Status"/>
    </Datagrid>
  </List>
);

export default LojaMoedaAppList;
