import React from "react";
import Pedidos from "./PedidosList";
import PedidosCreate from "./PedidosCreate";
import PedidosEdit from "./PedidosEdit";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import { estoqueEnable } from "../../permissionsHelper";

const pedido = {
  getResource: (p, a) => {
    return estoqueEnable(p, a)
      ? {
          name: "pedidos",
          list: Pedidos,
          create: PedidosCreate,
          edit: PedidosEdit,
        }
      : null;
  },
  getMenu: (p, a) => {
    return estoqueEnable(p, a)
      ? {
          key: "pedidos",
          to: "/pedidos",
          primaryText: "Pedidos",
          leftIcon: iconPedido,
        }
      : null;
  },
};

export default pedido;
export const iconPedido = <CompareArrowsIcon />;
