import React from "react";
import { Create } from "react-admin";

import ParceiroForm from "./ParceiroForm";
import ParceiroToolbar from "./ParceiroToolbar";

const ParceiroCreate = props => (
  <Create {...props}>
    <ParceiroForm toolbar={<ParceiroToolbar />} />
  </Create>
);

export default ParceiroCreate;
