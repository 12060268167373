import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import {
  TabbedForm,
  FormTab,
  FormDataConsumer,
  BooleanInput,
  Confirm,
  useRedirect,
} from "react-admin";
import NFeEmissaoProdutoNovo from "./NFeEmissaoProdutoNovo";
import NFeEmissaoProdutosGrid from "./NFeEmissaoProdutosGrid";
import { NFeModoFreteInput } from "./fields/NFeModoFrete";
import TabDestinatario from "./tabs/TabDestinatario";
import TabIdentificacao from "./tabs/TabIdentificacao";
import TabTotais from "./tabs/TabTotais";
import ParceiroForm from "../parceiro/ParceiroForm";
import ParceiroLazyReferenceInput from "../parceiro/ParceiroLazyReferenceInput";
import { withUser } from "../../common/util/hocs";
import { Typography } from "@mui/material";
import NFeEmissaoPagamentoNovo from "./NFeEmissaoPagamentoNovo";
import NFeEmissaoPagamentosGrid from "./NFeEmissaoPagamentosGrid";
import TabChavesRef from "./tabs/TabChavesRef";
import TabAutorizacao from "./tabs/TabAutorizacao";
import NFeInutilizacaoForm from "./NFeInutilizacaoForm";
import { Inutilizacao } from "./calculos";

const useStyles = makeStyles({
  natureza: {
    width: "51em",
    marginRight: `1em`,
  },
  bool: {
    marginTop: `3em`,
  },
});

function CodigoRandom() {
  const min = 12345678;
  const max = 98765432;
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const initialValues = {
  cnf: CodigoRandom(),
  modelo: 55,
  emitida_em: new Date(),
  natureza_operacao: "VENDA",
  finalidade: 1,
  tipo_doc: 1,
  identificador_operacao: 1,
  identificador_ie_dest: 1,
  modo_frete: 1,
  consumidor: 1,
  presenca: 9,

  itens: [],
  pagamentos: [],
  chaves: [],

  total_desconto: 0,
  total_frete: 0,
  total_seguro: 0,
  total_outro: 0,
  recalc: 0,
};

function NFeForm(props) {
  const redirect = useRedirect();
  const classes = useStyles();
  const { record, loja } = props;
  const edit = record && record.id;
  const loaded = !edit || record.loaded;
  const goToProfile =
    !loja.inscricao_estadual ||
    !loja.cep ||
    !loja.logradouro ||
    !loja.numero ||
    !loja.bairro ||
    !loja.cidade ||
    !loja.codigo_municipio ||
    !loja.uf;

  if (Inutilizacao(record)) {
    return <NFeInutilizacaoForm {...props} />;
  }

  const validateForm = (values) => {
    const errors = { itemNovo: {}, pagamentoNovo: {} };
    if (values.itens.filter((i) => i && !i.deletar).length === 0) {
      errors.itemNovo.produtoId = "Adicione ao menos um produto";
    }

    if (values.pagamentos.filter((i) => i && !i.deletar).length === 0) {
      errors.pagamentoNovo.tipo = "Adicione ao menos um pagamentos";
    }

    return errors;
  };

  const handleGoToProfile = (event) => {
    redirect(`/loja_perfil/${loja.id}`);
  };

  return (
    <TabbedForm
      {...props}
      initialValues={initialValues}
      validate={validateForm}
      submitOnEnter={false}
    >
      <FormTab label="Identificação">
        <Confirm
          isOpen={goToProfile}
          title="Atenção"
          content="Antes de emitir notas fiscais é necessário preencher a Incrição Estadual e o Endereço completo da loja."
          confirm="Preencher os dados"
          onConfirm={handleGoToProfile}
          onClose={handleGoToProfile}
        />
        <TabIdentificacao loja={loja} edit={edit} />
      </FormTab>
      <FormTab label="Destinatário">
        <TabDestinatario record={record} loja={loja} />
      </FormTab>
      <FormTab label="Produtos">
        <FormDataConsumer>
          {({ formData }) => (
            <>
              {!record.xmlUrl && <NFeEmissaoProdutoNovo formData={formData} />}

              <Typography variant="h5" gutterBottom sx={{ mt: 4, ml: 1 }}>
                Lista de Produtos
              </Typography>
              <NFeEmissaoProdutosGrid
                loaded={loaded}
                record={record}
                formData={formData}
                loja={loja}
              />

              {!record.xmlUrl && (
                <BooleanInput
                  source="calculo_manual"
                  label="Cálculo Manual"
                  className={classes.bool}
                />
              )}
            </>
          )}
        </FormDataConsumer>
      </FormTab>
      <FormTab label="Totais">
        <TabTotais />
      </FormTab>
      <FormTab label="Pagamentos">
        <FormDataConsumer>
          {({ formData }) => (
            <>
              {!record.xmlUrl && (
                <NFeEmissaoPagamentoNovo formData={formData} />
              )}

              <Typography variant="h5" gutterBottom sx={{ mt: 4, ml: 1 }}>
                Lista de Pagamentos
              </Typography>
              <NFeEmissaoPagamentosGrid
                loaded={loaded}
                record={record}
                formData={formData}
                loja={loja}
              />
            </>
          )}
        </FormDataConsumer>
      </FormTab>
      <FormTab label="Chaves ref.">
        <FormDataConsumer>
          {({ formData }) => (
            <TabChavesRef record={record} formData={formData} />
          )}
        </FormDataConsumer>
      </FormTab>
      <FormTab label="Transportadora">
        <Typography
          variant="h5"
          gutterBottom
          sx={{ my: 4, ml: 1, width: "100%" }}
        >
          Transportadora dos Produtos
        </Typography>
        <ParceiroLazyReferenceInput
          label="Transportadora"
          source="transportadoraId"
          nameSource="transportadora"
          record={record}
          addCreateButton={true}
          CreateForm={ParceiroForm}
          className={classes.natureza}
          fullWidth
        />
        <NFeModoFreteInput className={classes.natureza} />
      </FormTab>
      <FormTab label="Intermediador">
        <Typography
          variant="h5"
          gutterBottom
          sx={{ my: 4, ml: 1, width: "100%" }}
        >
          Intermediador da Venda
        </Typography>
        <ParceiroLazyReferenceInput
          label="Intermediador da venda"
          source="intermediadorId"
          nameSource="intermediador"
          record={record}
          addCreateButton={true}
          CreateForm={ParceiroForm}
          className={classes.natureza}
          fullWidth
        />
      </FormTab>
      {record.xmlUrl && (
        <FormTab label="Autorização">
          <TabAutorizacao />
        </FormTab>
      )}
    </TabbedForm>
  );
}

export default withUser(NFeForm);
