import React, { useCallback } from "react";
import { useNotify, Title } from "react-admin";
import {
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Delete from "@material-ui/icons/Delete";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { fetchJSON } from "../dataProvider";
import LoadingButton from "../common/LoadingButton";
import UXDelay from "../dataProvider/uxdelay";
import logo from "../static/media/pickngo.jpeg";
import { withUser } from "../common/util/hocs";

const useStyles = makeStyles((theme) => ({
  cover: {
    height: "100%",
    backgroundColor: "#000000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
  },
  image: {
    maxHeight: "150px",
    padding: 100,
  },
  deleteButton: {
    color: theme.palette.error.main,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  fullWidth: {
    width: "100%",
  },
}));

export function PickNGoLogo() {
  const classes = useStyles();

  return (
    <div className={classes.cover}>
      <img src={logo} className={classes.image} alt="PickNGo" />
    </div>
  );
}

function PickNGo({ loja }) {
  const classes = useStyles();
  const notify = useNotify();

  const [credentials, setCredentials] = React.useState(null);
  const [erro, setErro] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);

  const fetchPickNGoCredentials = useCallback(async (lojaId) => {
    return fetchJSON(`PickNGo`, null, {
      filter: { where: { lojaId } },
    }).then((list) => list[0] || null);
  }, []);

  const fetchStates = useCallback(async (lojaId) => {
    return fetchJSON("PickNGo/states", null, { lojaId });
  }, []);

  const fetchCities = useCallback(async (lojaId, stateId) => {
    return fetchJSON("PickNGo/cities", null, { lojaId, stateId });
  }, []);

  React.useEffect(() => {
    setLoading(true);
    UXDelay(fetchPickNGoCredentials(loja.id))
      .then((response) => {
        if (!response) {
          return;
        }

        setCredentials((credentials) => ({
          ...credentials,
          id: response.id,
          appId: response.app_id,
          appKey: response.app_key,
          stateId: response.state_id,
          cityId: response.city_id,
        }));
      })
      .catch((err) => {
        notify("Erro ao recuperar configurações do PickNGo", "warning");
      })
      .finally(() => setLoading(false));
  }, [loja, notify, fetchPickNGoCredentials]);

  React.useEffect(() => {
    if (!credentials?.id || !credentials?.appId || !credentials?.appKey) {
      return;
    }
    setLoading(true);
    UXDelay(fetchStates(loja.id))
      .then((response) => {
        if (!response.success) {
          throw new Error("Fail to find states");
        }

        setStates(response.states);
      })
      .catch((err) => {
        notify("Não possível recuperar estados", "warning");
      })
      .finally(() => setLoading(false));
  }, [
    credentials?.appId,
    credentials?.appKey,
    credentials?.id,
    fetchStates,
    loja.id,
    notify,
  ]);

  React.useEffect(() => {
    if (
      !credentials?.id ||
      !credentials?.appId ||
      !credentials?.appKey ||
      !credentials?.stateId
    ) {
      return;
    }
    setLoading(true);
    UXDelay(fetchCities(loja.id, credentials?.stateId))
      .then((response) => {
        if (!response.success) {
          throw new Error("Fail to find cities");
        }

        setCities(response.cities);
      })
      .catch((err) => {
        notify("Não possível recuperar cidades", "warning");
      })
      .finally(() => setLoading(false));
  }, [
    credentials?.appId,
    credentials?.appKey,
    credentials?.id,
    credentials?.stateId,
    fetchCities,
    loja.id,
    notify,
  ]);

  const handleInstall = () => {
    setLoading(true);
    UXDelay(
      fetchJSON(
        `PickNGo/upsertWithWhere`,
        {
          method: "POST",
          body: JSON.stringify({
            lojaId: loja.id,
            app_id: credentials?.appId || "",
            app_key: credentials?.appKey || "",
            city_id: credentials?.cityId || "",
            state_id: credentials?.stateId || "",
          }),
        },
        {
          where: { lojaId: loja.id },
        }
      )
    )
      .then((response) => {
        setCredentials((credentials) => ({ ...credentials, id: response.id }));
        notify("PickNGo instalado com sucesso!", "success");
      })
      .catch((err) => {
        setErro(err);
        notify("Erro ao instalar o PickNGo", "warning");
      })
      .finally(() => setLoading(false));
  };

  const handleSaveLocation = () => {
    setLoading(true);
    UXDelay(
      fetchJSON(
        `PickNGo/upsertWithWhere`,
        {
          method: "POST",
          body: JSON.stringify({
            lojaId: loja.id,
            city_id: credentials?.cityId || "",
            state_id: credentials?.stateId || "",
          }),
        },
        {
          where: { lojaId: loja.id },
        }
      )
    )
      .then((response) => {
        setCredentials((credentials) => ({ ...credentials, id: response.id }));
        notify("Localização salva com sucesso!", "success");
      })
      .catch((err) => {
        setErro(err);
        notify("Erro ao salvar localização", "warning");
      })
      .finally(() => setLoading(false));
  };

  const handleUninstall = () => {
    setLoading(true);
    UXDelay(
      fetchJSON(`PickNGo/del`, {
        method: "DELETE",
        body: JSON.stringify({
          id: credentials.id,
          lojaId: loja.id,
        }),
      })
    )
      .then(() => {
        setCredentials(null);
        notify("PickNGo desinstalado com sucesso!", "success");
      })
      .catch((err) => {
        setErro(err);
        notify("Erro ao desinstalar PickNGo", "warning");
      })
      .finally(() => setLoading(false));
  };

  const testIntegration = () => {
    setLoading(true);
    UXDelay(
      fetchJSON(`PickNGo/integrationTest`, {
        method: "POST",
        body: JSON.stringify({
          lojaId: loja.id,
          appId: credentials?.appId,
          appKey: credentials?.appKey,
        }),
      })
    )
      .then(() => {
        notify("Configurações válidas", "success");
      })
      .catch(() => {
        notify(
          "Erro ao testar. Por favor, verifique se as configurações estão corretas",
          "warning"
        );
      })
      .finally(() => setLoading(false));
  };

  const handleChangeAppId = (event) =>
    setCredentials((credentials) => ({
      ...credentials,
      appId: event.target.value,
    }));

  const handleChangeAppKey = (event) =>
    setCredentials((credentials) => ({
      ...credentials,
      appKey: event.target.value,
    }));

  const handleChangeState = (event) =>
    setCredentials((credentials) => ({
      ...credentials,
      stateId: event.target.value,
    }));

  const handleChangeCity = (event) =>
    setCredentials((credentials) => ({
      ...credentials,
      cityId: event.target.value,
    }));

  return (
    <Card sx={{ display: "flex", p: 3 }}>
      <Title title="Integração do PickNGo" />
      <Box sx={{ width: 1 / 4 }}>
        <PickNGoLogo />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography variant="h5" color="textPrimary" sx={{ mb: 2 }}>
            PickNGo
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            Envie seus pedidos usando o PickNGo.
          </Typography>
          {erro && (
            <Typography variant="body2" color="error" sx={{ my: 2 }}>
              {erro.message}
            </Typography>
          )}
          <Box display="flex">
            <Grid
              item
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              sx={{ mb: 2 }}
            >
              <Grid item>
                <Typography variant="h6" color="textPrimary" sx={{ mb: 2 }}>
                  Configurações
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  label="App ID"
                  variant="filled"
                  value={credentials?.appId || ""}
                  disabled={loading}
                  onChange={handleChangeAppId}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="App Key"
                  variant="filled"
                  value={credentials?.appKey || ""}
                  disabled={loading}
                  onChange={handleChangeAppKey}
                />
              </Grid>
            </Grid>
            {!!credentials?.id && (
              <Grid
                item
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
                sx={{ pl: 4, mb: 2 }}
              >
                <Grid item>
                  <Typography variant="h6" color="textPrimary" sx={{ mb: 2 }}>
                    Localização da loja
                  </Typography>
                </Grid>
                <Grid item className={classes.fullWidth}>
                  <FormControl className={classes.fullWidth}>
                    <InputLabel id="stateSelectorId">Estado</InputLabel>
                    <Select
                      labelId="stateSelectorId"
                      value={credentials?.stateId || ""}
                      variant="filled"
                      onChange={handleChangeState}
                      disabled={loading}
                    >
                      {states.map(
                        ({ estadoID: id, estadoUF: stateShortName }) => (
                          <MenuItem key={id} value={id}>
                            {stateShortName}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item className={classes.fullWidth}>
                  <FormControl className={classes.fullWidth}>
                    <InputLabel id="citySelectorId">Cidade</InputLabel>
                    <Select
                      labelId="citySelectorId"
                      value={credentials?.cityId || ""}
                      variant="filled"
                      disabled={!credentials?.stateId || loading}
                      onChange={handleChangeCity}
                    >
                      {cities?.map(({ cidadeID: id, cidadeNome: cityName }) => (
                        <MenuItem key={id} value={id}>
                          {cityName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    loading={loading}
                    disabled={!credentials?.stateId || !credentials?.cityId}
                    onClick={handleSaveLocation}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            )}
          </Box>
        </CardContent>
        {!!credentials && (
          <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              loading={loading}
              disabled={!credentials?.appKey || !credentials?.appId}
              onClick={testIntegration}
            >
              Testar configurações
            </Button>
          </Box>
        )}
        <Box sx={{ display: "flex", alignItems: "center", pl: 1 }}>
          {!credentials?.id && (
            <LoadingButton
              variant="contained"
              color="primary"
              className={classes.button}
              text="Instalar"
              loading={loading}
              disabled={!credentials?.appKey || !credentials?.appId}
              onClick={handleInstall}
            />
          )}
          {credentials?.id && (
            <LoadingButton
              variant="contained"
              color="secondary"
              className={classes.deleteButton}
              icon={<Delete />}
              text="Desinstalar"
              loading={loading}
              onClick={handleUninstall}
            />
          )}
        </Box>
      </Box>
    </Card>
  );
}

export default withUser(PickNGo);
