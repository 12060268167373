import { useState, useEffect } from "react";
import { API_URL } from "../config/constants";
import fetchJson from "./fetchJson";

const useFetch = (
  initialUrl,
  initialQueryParams = {},
  initialFetchParams = null,
  initialSkip = false
) => {
  const [url, updateUrl] = useState(initialUrl);
  const [queryParams, updateQueryParams] = useState(initialQueryParams);
  const [fetchParams, updateFetchParams] = useState(initialFetchParams);
  const [skip, updateSkip] = useState(initialSkip);
  const [data, setData] = useState(null);
  const [lastSuccessData, setLastSuccessData] = useState(null);
  const [isLoading, setIsLoading] = useState(!initialSkip);
  const [error, setError] = useState(null);
  const [refetchIndex, setRefetchIndex] = useState(0);
  const refetch = () =>
    setRefetchIndex((prevRefetchIndex) => prevRefetchIndex + 1);

  useEffect(() => {
    const fetchData = async () => {
      if (skip) return;
      setIsLoading(true);
      try {
        const queryString = Object.keys(queryParams)
          .map((k) => {
            const v = queryParams[k];
            return (
              encodeURIComponent(k) +
              "=" +
              encodeURIComponent(v instanceof Object ? JSON.stringify(v) : v)
            );
          })
          .join("&");
        const resp = await fetchJson(
          `${API_URL}/${url}?${queryString}`,
          fetchParams
        );
        setLastSuccessData(resp.json);
        setData(resp.json);
        setError(null);
      } catch (err) {
        setData(null);
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [url, queryParams, fetchParams, skip, refetchIndex]);

  return {
    data,
    lastSuccessData,
    isLoading,
    error,
    updateUrl,
    updateQueryParams,
    updateFetchParams,
    updateSkip,
    refetch,
  };
};

export default useFetch;
