import React, {Component} from "react";
import {
  SimpleForm,
  TextInput,
  required,
  maxLength
} from "react-admin";
import { IdDates } from '../../common/form/ConditionalInputs';

const validateNome = [required(), maxLength(10)];

class UnidadesForm extends Component {
  constructor(props) {
    super(props);    
    this.state = { choices: [] };
  }

  render() {
    return (
      <SimpleForm { ...this.props} toolbar={this.props.toolbar} redirect="list">
        <IdDates />
        <TextInput source="nome" label="Nome da unidade" validate={validateNome} />
      </SimpleForm>
      );
    }
  }

export default UnidadesForm;
