import React from "react";
import IconSave from "@material-ui/icons/Save";
import { CircularProgress } from "@mui/material";
import { Button } from "react-admin";

function LoadingButtonReactAdmin(props) {
  const { text, icon, loading, disabled, ...rest } = props;
  const startIcon = loading ? (
    <CircularProgress size={20} thickness={2} />
  ) : (
    icon || <IconSave />
  );

  return (
    <Button
      disabled={loading || disabled}
      label={text === false ? "" : text || "Salvar"}
      {...rest}
    >
      {startIcon}
    </Button>
  );
}

export default LoadingButtonReactAdmin;
