import React from "react";
import { Edit } from "react-admin";

import InventarioForm from "./InventarioForm";
import InventarioToolbar from "./InventarioToolbar";

const InventarioEdit = (props) => (
  <Edit {...props}>
    <InventarioForm toolbar={<InventarioToolbar />} />
  </Edit>
);

export default InventarioEdit;
