import React from "react";
import { AppBar } from "react-admin";
import { useLocation } from 'react-router-dom';
import UserMenu from './UserMenu'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

const CustomAppBar = props => {
  const classes = useStyles();
  const location = useLocation();
  React.useEffect(() => {
    window.gtag("event", "page_view", {
      page_path: location.pathname + location.search
    });
  }, [location.pathname, location.search]);
  return <AppBar {...props} userMenu={<UserMenu />} >
    <Typography
      variant="h6"
      color="inherit"
      className={classes.title}
      id="react-admin-title"
    />
    
    <span className={classes.spacer} />
  </AppBar>
}

export default CustomAppBar;
