import React from "react";
import { Box, Typography } from "@mui/material";

const DatagridEmpty = ({ message }) => (
  <Box sx={{ ml: 1 }}>
    <Typography variant="subtitle1" gutterBottom>
      {message}
    </Typography>
  </Box>
);

export default DatagridEmpty;
