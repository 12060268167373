import { Box, Card, CardContent, CardHeader, Grid } from "@mui/material";
import React, { useState } from "react";
import { Title, useAuthenticated, useNotify } from "react-admin";
import isEmpty from "lodash.isempty";

import { fetchJSON } from "../../dataProvider";
import UXDelay from "../../dataProvider/uxdelay";
import { useStyles } from "./styles";
import GroupedBillsChart from "./GroupedBillsChart";
import GroupedBillsTable from "./GroupedBillsTable";
import Search from "../Search";
import { reportContasEnable } from "../../permissionsHelper";

//
// EXEMPLO DE DADOS
//
// {
//   "billsByClassification": [
//       {
//           "id": 4,
//           "nome": "Teste 2",
//           "total": 58.69,
//           "percentage": 0.609745
//       },
//       {
//           "id": 1,
//           "nome": "Fixas",
//           "total": 6304.67,
//           "percentage": 65.500751
//       },
//       {
//           "id": 7,
//           "nome": "AAAA",
//           "total": 3261.98,
//           "percentage": 33.889504
//       }
//   ],
//   "billsByCostCenter": [
//       {
//           "id": 1,
//           "nome": "Loja",
//           "total": 3723.36,
//           "percentage": 38.682893
//       },
//       {
//           "id": 2,
//           "nome": "Teste",
//           "total": 5901.98,
//           "percentage": 61.317107
//       }
//   ],
//   "generalBillsInfo": [
//       {
//           "total": 9625.34
//       }
//   ]
// }

const ReportBillsByClassification = () => {
  useAuthenticated();
  const [reportData, setReportData] = useState({});
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const notify = useNotify();

  async function retrieveReportData({
    lojaId,
    startDateNoTime,
    endDateNoTime,
    billType,
  }) {
    try {
      setLoading(true);
      const retrievedReportData = await UXDelay(
        fetchJSON("relatorio/contas/pagas", null, {
          lojaId,
          startDate: startDateNoTime,
          endDate: endDateNoTime,
          billType,
        })
      );
      setReportData(retrievedReportData);
    } catch (error) {
      console.error(error);
      notify("Erro ao buscar os dados.", "warning");
    } finally {
      setLoading(false);
    }
  }

  const dataEmpty =
    isEmpty(reportData.billsByClassification) &&
    isEmpty(reportData.billsByCostCenter);

  return (
    <Card className={classes.root}>
      <Title title="Análise de contas pagas" />
      <CardContent>
        <Search
          fields={["dateRange", "billType"]}
          onSearch={retrieveReportData}
          loading={loading}
          dataEmpty={dataEmpty}
        />
        {!dataEmpty && (
          <Grid container spacing={5} sx={{ my: 3 }}>
            <Grid item xs={6} md={6}>
              <Card className={classes.card}>
                <CardHeader
                  className={classes.cardHeader}
                  title="Contas por Classificação"
                />
                <CardContent>
                  <Box height={300}>
                    <GroupedBillsChart
                      reportData={reportData.billsByClassification}
                    />
                  </Box>
                  <GroupedBillsTable data={reportData.billsByClassification} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} md={6}>
              <Card className={classes.card}>
                <CardHeader
                  className={classes.cardHeader}
                  title="Contas por Centro de Custo"
                />
                <CardContent>
                  <Box height={300}>
                    <GroupedBillsChart
                      reportData={reportData.billsByCostCenter}
                    />
                  </Box>
                  <GroupedBillsTable data={reportData.billsByCostCenter} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default ReportBillsByClassification;

export const reportBillsByClassification = {
  getMenu: (p, a) => {
    return reportContasEnable(p, a)
      ? {
          key: "ReportBills",
          to: "/Conta/analise-contas-pagas",
          primaryText: "Pagas por Classificacão",
        }
      : null;
  },
};
