import React from "react";
import { SelectInput } from "react-admin";

export const OPCOES = [
  { id: null, name: "Nenhum" },
  { id: "0", name: "Genérico" },
  { id: "2", name: "Débito" },
  { id: "3", name: "Crédito" },
  { id: "PIX", name: "PIX" }
];

const optionRenderer = choice => (choice.id ? `${choice.id} - ` : '') + choice.name;

const SitefFuncaoInput = props => {
  return <SelectInput 
    {...props} 
    choices={OPCOES} 
    optionText={optionRenderer}
  />;
};

export default SitefFuncaoInput;
