import React from "react";
import { Show } from "react-admin";
import UsuarioForm from "./UsuarioForm";

const UsuarioShow = (props) => (
  <Show {...props}>
    <UsuarioForm toolbar={false} />
  </Show>
);

export default UsuarioShow;
