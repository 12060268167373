import React from "react";
import { useNotify, Title } from "react-admin";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { fetchJSON } from "../dataProvider";
import mfLogo from "../static/media/matriz-filial.jpg";
import { withUser } from "../common/util/hocs";
import { formatCNPJ } from "../common/util/formatter";

const useStyles = makeStyles((theme) => ({
  cover: {
    minHeight: "260px",
    height: "100%",
    backgroundColor: "#aeccd7",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
  },
  image: {
    padding: 10,
  },
}));

export function MatrizFilialLogo() {
  const classes = useStyles();

  return (
    <div className={classes.cover}>
      <img src={mfLogo} className={classes.image} alt="Matriz Filiais" />
    </div>
  );
}

function MatrizFilial(props) {
  const notify = useNotify();

  const {
    loja,
    usuario: { lojas },
  } = props;
  const lojaId = loja.id;

  const [erro, setErro] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [checked, setChecked] = React.useState(null);

  React.useEffect(() => {
    fetchJSON(`Loja_filials`, null, { filter: { where: { lojaId } } })
      .then((list) => {
        setChecked(list.map((l) => l.filialId));
      })
      .catch((err) => {
        setErro(err);
        notify("Erro ao recuperar filiais", "warning");
      });
  }, [lojaId, notify]);

  const handleToggle = (filialId) => async () => {
    const currentIndex = checked.indexOf(filialId);
    const newChecked = [...checked];
    const addToArray = currentIndex === -1;

    try {
      setLoading(true);
      if (addToArray) {
        await createFilial(filialId);
        newChecked.push(filialId);
      } else {
        await deleteFiliais(filialId);
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    } catch (err) {
      setErro(err);
      notify("Erro ao configurar filial", "warning");
    } finally {
      setLoading(false);
    }
  };

  const createFilial = async (filialId) => {
    return fetchJSON(`Loja_filials`, {
      method: "POST",
      body: {
        lojaId,
        filialId,
      },
    });
  };

  const deleteFiliais = async (filialId) => {
    const filiaisToDelete = await fetchJSON(`Loja_filials`, null, {
      filter: { where: { lojaId, filialId } },
    });

    return Promise.all(
      filiaisToDelete.map((f) =>
        fetchJSON(`Loja_filials/del`, {
          method: "DELETE",
          body: {
            id: f.id,
            lojaId,
          },
        })
      )
    );
  };

  return (
    <Card sx={{ display: "flex", p: 3 }}>
      <Title title="Configuração Matriz Filial" />
      <Box sx={{ width: 1 / 4 }}>
        <MatrizFilialLogo />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 10 auto" }}>
          <Typography variant="h5" color="textPrimary" sx={{ mb: 2 }}>
            {`Matriz: ${loja.nome_fantasia}`}
            <br />
            {formatCNPJ(loja.cnpj)}
            <br />
            {`${loja.logradouro}, ${loja.numero}`}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            Selecione quais lojas são filiais:
          </Typography>
          {erro && (
            <Typography variant="body2" color="error" sx={{ my: 2 }}>
              {erro.message}
            </Typography>
          )}

          {Array.isArray(checked) && (
            <List dense sx={{ width: "100%", maxWidth: 600 }}>
              {lojas
                .filter((l) => l.id !== loja.id)
                .map((l) => (
                  <ListItem
                    key={l.id}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        onChange={handleToggle(l.id)}
                        checked={checked.indexOf(l.id) !== -1}
                      />
                    }
                    disablePadding
                  >
                    <ListItemButton disabled={loading}>
                      <ListItemAvatar>
                        <Avatar src={`${l.fotoUrl}`} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={l.nome_fantasia}
                        secondary={
                          <>
                            <span>{`${formatCNPJ(l.cnpj)}`}</span>
                            <br />
                            <span>{`${l.logradouro}, ${l.numero}`}</span>
                          </>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
          )}
        </CardContent>
      </Box>
    </Card>
  );
}

export default withUser(MatrizFilial);
