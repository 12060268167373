import React from "react";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import LojaHorarioCreate from "./LojaHorarioCreate";
import LojaHorarioEdit from "./LojaHorarioEdit";
import LojaHorarioList from "./LojaHorarioList";
import { cardapioDigitalEnable } from "../../permissionsHelper";

const lojaHorario = {
  getResource: (p, a) => {
    return cardapioDigitalEnable(p, a)
      ? {
          name: "Loja_Horarios",
          create: LojaHorarioCreate,
          list: LojaHorarioList,
          edit: LojaHorarioEdit,
        }
      : null;
  },
  getMenu: (p, a) => {
    return cardapioDigitalEnable(p, a)
      ? {
          key: "Loja_Horarios",
          to: "/Loja_Horarios",
          primaryText: "Horários",
          leftIcon: iconLojaHorario,
        }
      : null;
  },
};

export default lojaHorario;
export const iconLojaHorario = <QueryBuilderIcon />;
