import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import ShareIcon from "@material-ui/icons/Share";

const useStyles = makeStyles({
  link: {
    textDecoration: "unset",
  },
});

export default function DetailsButton(props) {
  const { record, text } = props;
  const classes = useStyles();

  if (!record || !record.id) {
    return null;
  }

  return (
    <Link
      className={classes.link}
      to={`/pedidoDetalhes?uuid=${record.uuid}&lojaId=${record.lojaId}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Button variant="text">
        <ShareIcon />
        {text}
      </Button>
    </Link>
  );
}
