import React from "react";
import {
  Datagrid,
  List,
  TextField,
  DateField,
  ReferenceField,
} from "react-admin";
import CurrencyField from "../../common/form/CurrencyField";
import MercadoPagoPreferenceStatus from "./MercadoPagoPreferenceStatus";

const MercadoPagoPreferenceList = (props) => (
  <List
    title="Listar Pagamentos do Mercado Pago"
    {...props}
    sort={{ field: "id", order: "DESC" }}
    bulkActionButtons={false}
    exporter={false}
  >
    <Datagrid>
      <TextField source="external_reference" label="Pedido id" />
      <DateField source="createdAt" label="Data" showTime={true} />
      <TextField source="origin" label="Origem" />
      <TextField source="tipo" label="Tipo" />
      <MercadoPagoPreferenceStatus label="Status" />
      <ReferenceField
        source="external_reference"
        reference="AppPedido_loja"
        label="R$ Valor"
        link={false}
      >
        <CurrencyField source="totalDisplay" />
      </ReferenceField>
      {/* <RefundButton /> */}
    </Datagrid>
  </List>
);

export default MercadoPagoPreferenceList;
