import React from "react";
import { FunctionField as FF } from "react-admin";
import { parseISO, format } from "date-fns";

function FunctionField(props) {
  const { source, formatDate, render, ...rest } = props;

  return render ? (
    <FF {...rest} render={render} />
  ) : (
    <FF
      {...rest}
      render={(record) => {
        const value = record[source];
        if (!value) {
          return "";
        }

        if (formatDate) {
          try {
            if (typeof value === "string") {
              const date = parseISO(value);
              return format(date, formatDate);
            } else {
              return format(value, formatDate);
            }
          } catch (erro) {
            return `Erro ${value}`;
          }
        }

        return value;
      }}
    />
  );
}

export default FunctionField;
