import React from "react";
import { required, SimpleForm, TextInput } from "react-admin";
import { IdDates } from "../../common/form/ConditionalInputs";

const validateReq = [required()];

const CentroForm = (props) => (
  <SimpleForm {...props} redirect="list">
    <IdDates />
    <TextInput source="nome" validate={validateReq} />
  </SimpleForm>
);

export default CentroForm;
