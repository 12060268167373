import React from "react";
import { Datagrid, List, EditButton, BooleanField } from "react-admin";
import { Box, Chip } from "@mui/material";
import { lightGreen as green, deepOrange } from "@mui/material/colors";
import LojaHorarioFilter from "./LojaHorarioFilter";
import LojaHorarioActions from "./LojaHorarioActions";
import { DiaSemanaIntervaloField } from "../../common/form/DiaSemanaInput";
import FunctionField from "../../common/form/FunctionField";
import ConditionalComponent from "../../common/form/ConditionalComponent";
import DuracaoField from "../../common/form/DuracaoField";

const Aberto = ({ record }) => {
  if (!record) return null;
  return (
    <Chip
      key='horario-aberto'
      label={record.aberto ? "Aberto" : "Fechado"}
      size='small'
      style={{
        margin: "2px",
        backgroundColor: record.aberto ? green[200] : deepOrange[200],
        borderColor: record.aberto ? green[200] : deepOrange[200],
      }}
    />
  );
};

const TipoPedido = ({ record }) => {
  if (!record) return null;
  const chips = [];

  if (record.entrega) {
    chips.push(
      <Chip
        key='horario-status-entrega'
        label='Entrega'
        size='small'
        style={{ margin: "2px" }}
      />
    );
  }

  if (record.retirada) {
    chips.push(
      <Chip
        key='horario-status-retirada'
        label='Retirada'
        size='small'
        style={{ margin: "2px" }}
      />
    );
  }

  if (record.mesa) {
    chips.push(
      <Chip
        key='horario-status-mesa'
        label='Mesa'
        size='small'
        style={{ margin: "2px" }}
      />
    );
  }

  return (
    <Box sx={{ display: "inline-block", maxWidth: "200px" }}>
      {chips.map((c) => c)}
    </Box>
  );
};

const LojaHorarioList = (props) => (
  <List
    {...props}
    actions={<LojaHorarioActions />}
    filters={<LojaHorarioFilter />}
    exporter={false}
    empty={false}
  >
    <Datagrid>
      <Aberto label='Aberto' />
      <ConditionalComponent
        render={(r) =>
          r.aberto ? (
            <DiaSemanaIntervaloField
              ini='dia_semana_ini'
              fim='dia_semana_fim'
            />
          ) : (
            <BooleanField source='aberto' />
          )
        }
        label='Dia da semana'
      />

      <ConditionalComponent
        render={(r) =>
          r.aberto ? (
            <FunctionField formatDate='HH:mm' />
          ) : (
            <FunctionField formatDate='dd/MM/yyyy HH:mm' />
          )
        }
        label='Início'
        source='inicio'
      />

      <ConditionalComponent
        render={(r) =>
          r.aberto ? (
            <FunctionField formatDate='HH:mm' />
          ) : (
            <FunctionField formatDate='dd/MM/yyyy HH:mm' />
          )
        }
        label='Fim'
        source='fim'
      />

      <ConditionalComponent
        render={(r) =>
          r.aberto ? (
            <DuracaoField
              getSource={(r) => r.milisegundos / 60000}
              apenasHora={r.aberto}
            />
          ) : (
            <DuracaoField inicio='inicio' fim='fim' />
          )
        }
        label='Duração'
      />
      <TipoPedido label='Tipo de pedido' />
      <EditButton />
    </Datagrid>
  </List>
);

export default LojaHorarioList;
