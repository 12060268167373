import React from "react";
import { SelectInput, SelectField } from "react-admin";

const tipos = [
  { id: 0, name: "0 - Entrada" },
  { id: 1, name: "1 - Saída" },
];

function NFeTipoDocumentoInput(props) {
  return (
    <SelectInput
      label="Tipo Documento"
      source="tipo_doc"
      choices={tipos}
      initialValue={1}
      {...props}
    />
  );
}

function NFeTipoDocumentoField(props) {
  return (
    <SelectField
      label="Tipo Documento"
      source="tipo_doc"
      choices={tipos}
      {...props}
    />
  );
}

export { NFeTipoDocumentoInput, NFeTipoDocumentoField };
