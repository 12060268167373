import React from 'react';
import TotalCard from './ReportTotalCard';
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter';

const ReportTotalValorUnitarioMedioCard = props => {
    return (
        <TotalCard icon={VerticalAlignCenterIcon} title="Valor Unit. Médio" color="rgb(76, 175, 80)" type="currency" {...props}/>
    );
};

ReportTotalValorUnitarioMedioCard.propTypes = {
    
};

export default ReportTotalValorUnitarioMedioCard;