import React, { useState, useCallback, useMemo } from "react";
import get from "lodash.get";
import debounce from "lodash/debounce";
import { Labeled, LinearProgress, useInput } from "react-admin";
import { Grid } from "@mui/material";
import CreateButton from "./CreateButton";
import useGetCurrent from "./useGetCurrent";
import useGetListLazy from "./useGetListLazy";

function LazyReferenceInput(props) {
  const {
    label,
    reference,
    children,
    record,
    nameSource,
    loadCompleted,
    filterToQuery,
    minLength,
    limit,
    filter: permanetFilter,
    getDataFromStore,
    addCreateButton,
    CreateForm,
  } = props;

  const [focus, setFocus] = useState(false);
  const [filterValues, setFilterValues] = useState(
    permanetFilter ? permanetFilter : {}
  );
  const [newRecord, setNewRecord] = useState(null);
  const { input } = useInput(props);
  const fetchOnFocus = !filterToQuery;

  const { data, ids } = useGetListLazy(
    reference,
    filterValues,
    limit,
    fetchOnFocus,
    focus,
    input.value
  );

  const currentFromServer =
    record && nameSource ? get(record, nameSource) : null;

  const referenceRecord = useGetCurrent(
    getDataFromStore,
    reference,
    record,
    loadCompleted,
    currentFromServer,
    input.value,
    data,
    newRecord
  );

  let finalIds, finalData;
  if (!referenceRecord || ids.includes(input.value)) {
    finalIds = ids;
    finalData = data;
  } else {
    finalIds = [input.value, ...ids];
    finalData = { [input.value]: referenceRecord, ...data };
  }

  const debouncedFilter = useMemo(
    () =>
      debounce((text) => {
        if (!filterToQuery) {
          return;
        }
        if (text.length > minLength) {
          setFilterValues((state) => ({
            ...state,
            ...filterToQuery(text),
          }));
        }
      }, 500),
    [filterToQuery, minLength]
  );

  const setFilter = useCallback(debouncedFilter, [debouncedFilter]);

  const onFocus = () => {
    setFocus(true);
  };

  if (React.Children.count(children) !== 1) {
    throw new Error("<LazyReferenceInput> only accepts a single child");
  }

  const allowEmpty = "allowEmpty" in props;
  if (
    record &&
    record.id &&
    !currentFromServer &&
    !allowEmpty &&
    !referenceRecord &&
    input.value
  ) {
    return (
      <Labeled label={label}>
        <LinearProgress timeout={0} />
      </Labeled>
    );
  }

  const optionText = children.props.optionText;
  const childrenProps = { ...children.props };

  if (allowEmpty && typeof optionText === "string") {
    childrenProps.optionText = (i) => (i && i.id ? i[optionText] : "Nenhum");
  }

  const onCreate = (newRecord) => {
    setNewRecord(newRecord);
    input.onChange(newRecord.id);
  };

  const choices = finalIds.map((id) => finalData[id]);

  return addCreateButton ? (
    <Grid container spacing={0} alignItems="center">
      <Grid item>
        {React.cloneElement(children, {
          ...props,
          ...childrenProps,
          choices,
          setFilter,
          onFocus,
          translateChoice: false,
        })}
      </Grid>
      <Grid item>
        <CreateButton
          label={label}
          reference={reference}
          onCreate={onCreate}
          CreateForm={CreateForm}
        />
      </Grid>
    </Grid>
  ) : (
    React.cloneElement(children, {
      ...props,
      ...childrenProps,
      choices,
      setFilter,
      onFocus,
      translateChoice: false,
    })
  );
}

LazyReferenceInput.defaultProps = {
  minLength: -1,
};

export default LazyReferenceInput;
