import React from "react";
import { Edit } from "react-admin";

import MovimentoForm from "./MovimentoForm";

const MovimentoEdit = (props) => (
  <Edit {...props}>
    <MovimentoForm toolbar={null} />
  </Edit>
);

export default MovimentoEdit;
