import React from "react";
import LinearScale from "@material-ui/icons/LinearScale";
import UnidadeCreate from "./UnidadeCreate";
import UnidadeEdit from "./UnidadeEdit";
import UnidadeList from "./UnidadeList";

const unidade = {
  getResource: (p) => {
    return {
      name: "unidades",
      create: p.cadastros_ver ? UnidadeCreate : null,
      list: p.cadastros_ver ? UnidadeList : null,
      edit: p.cadastros_ver ? UnidadeEdit : null,
    };
  },
  getMenu: (p) =>
    p.cadastros_ver
      ? {
          key: "unidades",
          to: "/unidades",
          primaryText: "Unidades",
          leftIcon: iconUnidade,
        }
      : null,
};

export default unidade;
export const iconUnidade = <LinearScale />;
