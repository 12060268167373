import React, { useState } from "react";
import { useNotify } from "react-admin";
import LoadingButton from "../../common/LoadingButton";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import { fetchJSON } from "../../dataProvider";
import { withUser } from "../../common/util/hocs";

const KdsLimparButton = ({ loja }) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    try {
      setLoading(true);
      await fetchJSON("Kds/limparLoja", {
        method: "POST",
        body: {
          lojaId: loja.id,
        },
      });
      notify("KDS limpado com sucesso!");
    } catch (err) {
      console.log(JSON.stringify(err));
      notify("Ocorreu um erro ao limpar o KDS", "warning");
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingButton
      text="Limpar Pedidos Prontos"
      icon={<ClearAllIcon />}
      loading={loading}
      onClick={handleClick}
    />
  );
};

export default withUser(KdsLimparButton);
