import { API_URL } from "./config/constants";
import { updateToken } from "./app/App";
import fetchJson from "./dataProvider/fetchJson";

export const PermissoesChoices = [
  { id: 0, name: "Administrador" },
  { id: 1, name: "Proprietário" },
  { id: 2, name: "Funcionário" },
];

export const Permissoes = {
  ADMIN: 0,
  PROPRIETARIO: 1,
  CAIXA: 2,
};

const authProvider = {
  login: async ({ username, password }) => {
    return fetchJson(`${API_URL}/users/login`, {
      method: "POST",
      body: JSON.stringify({
        email: username,
        password: password,
      }),
    })
      .then((resp) => {
        const auth = resp.json;
        localStorage.setItem("token", auth.id);
        updateToken(auth.id);
      })
      .catch((error) => {
        return Promise.reject(
          error.status === 401 ? "Usuário ou senha incorretos!" : error.message
        );
      });
  },
  logout: async () => {
    updateToken(null);
    return Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  },
  checkError: (error) => {
    return error.status === 401 || error.status === 403
      ? Promise.reject()
      : Promise.resolve();
  },
  getPermissions: () => {
    return Promise.resolve();
  },
};

export default authProvider;
