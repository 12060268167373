import Decimal from "decimal.js";
import {
  calculateProfitMargin,
  calculateSellingPrice,
} from "../../common/util/util";
import { formatCurrency } from "../../common/util/formatter";

export function calculaEstoqueCustoVendaItens(itens, nameValueChange) {
  for (let i = 0; i < itens.length; i++) {
    const item = itens[i];

    item.estoque = Decimal(item.fator || 1)
      .times(item.qCom)
      .toString();

    item.custo = Decimal(item.vProdTotal)
      .dividedBy(item.estoque)
      .toFixed(4, Decimal.ROUND_UP);

    const margemChanged =
      nameValueChange && nameValueChange.includes(".margem");

    const { produto } = item;

    if (item.venda === null || item.venda === undefined) {
      item.venda = Decimal(
        produto ? produto.preco_vnd_prod : item.custo
      ).toFixed(2);
    }

    if (margemChanged) {
      item.venda = calculateSellingPrice(item.margem || 0, item.custo);
    } else {
      item.margem = calculateProfitMargin(item.venda || 0, item.custo);
    }

    item.margemHelperText =
      produto && produto.id
        ? `${calculateProfitMargin(
            produto.preco_vnd_prod,
            produto.preco_cst_prod
          )}%`
        : "";

    item.vendaHelperText =
      produto && produto.id ? formatCurrency(produto.preco_vnd_prod) : "";

    if (!item.produtoNovo) {
      item.produtoNovo = {
        cod_prod: item.cEANTrib,
        desc_prod: item.xProd,
        ncmId: item.NCM,
        cestId: item.CEST,
        preco_cst_prod: item.custo,
        preco_vnd_prod: item.venda,
      };

      item.produto = item.produto ? item.produto : { ...item.produtoNovo };
    }
  }
}

export function calculosNFeTomada(form, formData, nameValueChange) {
  calculaEstoqueCustoVendaItens(formData.itens, nameValueChange);
  form.batch(() => {
    const itens = formData.itens.map((i) => ({ ...i }));
    form.change("itens", itens);
  });
}
