import React from "react";
import { BulkDeleteButton, Datagrid, List, TextField } from "react-admin";
import ProdutoFilter from "../produto/ProdutoFilter";
import CurrencyField from "../../common/form/CurrencyField";
import PermissionEditButton from "../../common/form/PermissionEditButton";
import { withUser } from "../../common/util/hocs";

const ProdutoList = ({ permissao, ...rest }) => (
  <List
    {...rest}
    filters={<ProdutoFilter />}
    bulkActionButtons={permissao.cardapio_editar ? <BulkDeleteButton /> : false}
    exporter={false}
  >
    <Datagrid>
      <TextField source="cod_prod" label="Código" />
      <TextField source="cod_aux" label="Cód.Aux." />
      <TextField source="desc_prod" label="Nome" />
      <CurrencyField source="preco_vnd_prod" label="R$ Venda" />
      <PermissionEditButton canEdit="cardapio_editar" canShow="cardapio_ver" />
    </Datagrid>
  </List>
);

export default withUser(ProdutoList);
