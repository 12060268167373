import { contasEnable } from "../../permissionsHelper";
import ClassificacaoCreate from "./ClassificacaoCreate";
import ClassificacaoEdit from "./ClassificacaoEdit";
import ClassificacaoList from "./ClassificacaoList";

const conta_classificacao = {
  getResource: (p, a) => {
    return contasEnable(p, a)
      ? {
          name: "Conta_classificacoes",
          list: ClassificacaoList,
          create: ClassificacaoCreate,
          edit: ClassificacaoEdit,
        }
      : null;
  },
  getMenu: (p, a) => {
    return contasEnable(p, a)
      ? {
          key: "Conta_classificacoes",
          to: "/Conta_classificacoes",
          primaryText: "Classificação",
          leftIcon: null,
        }
      : null;
  },
};

export default conta_classificacao;
