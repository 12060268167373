import React from "react";
import { Datagrid, List, TextField, EditButton, DateField } from "react-admin";

import InventarioFilter from "./InventarioFilter";

const InventarioList = (props) => (
  <List {...props} filters={<InventarioFilter />} exporter={false}>
    <Datagrid>
      <TextField source='nome' label='Nome' />
      <DateField source='createdAt' label='Criado em' showTime={true} />
      <EditButton />
    </Datagrid>
  </List>
);

export default InventarioList;
