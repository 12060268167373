import React from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  Chip,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { withUser } from "../../common/util/hocs";
import { formatCNPJCPF } from "../../common/util/formatter";

function Stores({ value, onChange, id, usuario, loja }) {
  if (usuario.lojas.length === 1) return null;

  const handleChange = (event) => onChange(id, event.target.value);
  const selectedStoes = value.map((storeId) =>
    usuario.lojas.find((store) => store.id === storeId)
  );

  return (
    <FormControl sx={{ minWidth: 360 }}>
      <InputLabel>Comparar Lojas</InputLabel>
      <Select
        multiple
        value={value}
        onChange={handleChange}
        input={<Input id="select-multiple-chip" />}
        renderValue={() => (
          <div>
            {selectedStoes.map((store) => (
              <Chip key={store.id} label={store.nome_fantasia} />
            ))}
          </div>
        )}
      >
        {usuario.lojas
          .filter((store) => store.id !== loja.id)
          .map((store) => (
            <MenuItem key={store.id} value={store.id}>
              <Checkbox checked={value.includes(store.id)} />
              <ListItemText
                primary={`${formatCNPJCPF(store.cnpj)} - ${
                  store.nome_fantasia
                }`}
              />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

export default withUser(Stores);
