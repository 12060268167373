import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { IconLoja } from '../loja';

const useStyles = makeStyles({
    icon: { paddingRight: '0.5em' },
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
});

const LinkToLoja = ({ record }) => {
    const classes = useStyles();
    return record ?
        <Button
            size="small"
            color="primary"
            component={Link}
            to={`/lojas/${record.lojaId}`}
            className={classes.link}
        >
            <IconLoja className={classes.icon} />
            Loja
        </Button>
    : null;
};

export default LinkToLoja;