import React, { useState } from 'react';
import { Notification, useLogin, useNotify } from 'react-admin';
import { Field, withTypes } from 'react-final-form';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';

import TextInput from '../common/form/TextInput';
import pallasLogo from '../static/media/pallas-logo-horizontal.svg';
import { useStyles } from './styles';

const validate = (values) => {
    const errors = {};
    if (!values.username) {
        errors.username = "Obrigatório";
    }
    if (!values.password) {
        errors.password = "Obrigatório";
    }
    return errors;
};

const { Form } = withTypes();

const LoginPage = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const login = useLogin();
    const notify = useNotify();

    const handleSubmit = async (e) => {
        setLoading(true)
        login(e)
            .catch(err => notify(err, 'warning'))
            .finally(() => setLoading(false))
    };

    return (
        <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.main}>
                        <Card className={classes.card}>
                            <div className={classes.avatar}>
                                    <img
                                        src={pallasLogo}
                                        className={classes.image}
                                        alt="Sistema Pallas"
                                    />
                            </div>
                            <h2>Login</h2>
                            <div className={classes.form}>
                                <Field
                                    autoFocus
                                    name="username"
                                    component={TextInput}
                                    label="Email"
                                    disabled={loading}
                                    type="email"
                                    fullWidth
                                />
                                <Field
                                    name="password"
                                    component={TextInput}
                                    label="Senha"
                                    disabled={loading}
                                    type="password"
                                    fullWidth
                                    divStyle={classes.input}
                                />
                            </div>
                                <Button
                                    className={classes.login}
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    fullWidth
                                >
                                    {loading && (
                                        <CircularProgress
                                            size={25}
                                            thickness={2}
                                            className={classes.progress}
                                        />
                                    )}
                                    Entrar
                                </Button>
                                <Link to="/cadastroPublico">
                                    <Button
                                        className={classes.register}
                                        variant="contained"
                                        color="primary"
                                        disabled={loading}
                                        fullWidth
                                    >
                                        Criar conta
                                    </Button>
                                </Link>
                                <Box
                                    className={classes.forgotPassword}
                                >
                                <Link to="/recuperarSenha">Esqueci minha senha</Link>
                                </Box>
                        </Card>
                        <Notification />
                    </div>
                </form>
            )}
        />
    );
};

export default LoginPage;