import React from "react";
import { Filter, ReferenceInput, AutocompleteInput } from "react-admin";
import NumberInputSimple from "../../common/form/NumberInputSimple";

const NFeEmissaosFilters = (props) => (
  <Filter {...props}>
    <NumberInputSimple source="serie" label="Série" alwaysOn />
    <NumberInputSimple source="numero" label="Número" alwaysOn />
    <ReferenceInput
      label="Destinatario"
      source="destinatarioId"
      reference="parceiros"
      alwaysOn
    >
      <AutocompleteInput optionText="nome_fantasia" />
    </ReferenceInput>
  </Filter>
);

export default NFeEmissaosFilters;
