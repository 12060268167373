import { AutocompleteInput, required } from "react-admin";
import LazyReferenceInput from "../../common/form/LazyReferenceInput";
import Highlighted from "../../common/Highlighted";
import { formatCNPJCPF, formatPhone } from "../../common/util/formatter";

const filter = (searchText) => {
  if (searchText.match(/^\d+$/)) {
    return {
      or: [
        { cnpj: { like: `${searchText}%` } },
        { telefone1: { like: `${searchText}%` } },
      ],
    };
  } else {
    return { q: searchText };
  }
};

const matchSuggestion = (filter, choice) => {
  const { cnpj, telefone1 } = choice;
  if (filter.match(/^\d+$/)) {
    return (
      (cnpj && cnpj.toString().startsWith(filter)) ||
      (telefone1 && telefone1.toString().startsWith(filter))
    );
  } else {
    return true;
  }
};

function ParceiroItem({ record, filterValue }) {
  record = record || { nome_fantasia: "", cnpj: "", telefone1: "" };
  const { nome_fantasia, cnpj, telefone1 } = record;
  return (
    <div>
      <Highlighted text={nome_fantasia} highlight={filterValue} />
      <br />
      {cnpj && (
        <small>
          <Highlighted
            text={cnpj.toString()}
            highlight={filterValue}
            formatFunction={formatCNPJCPF}
          />
          {"  "}
        </small>
      )}
      {telefone1 && (
        <small>
          <Highlighted
            text={telefone1}
            highlight={filterValue}
            formatFunction={formatPhone}
          />
        </small>
      )}
    </div>
  );
}

const validateParceiro = [required()];

function ParceiroLazyReferenceInput({ nameSource, required, ...rest }) {
  return (
    <LazyReferenceInput
      reference="parceiros"
      nameSource={nameSource || "parceiro"}
      filterToQuery={filter}
      minLength={0}
      limit={25}
      {...rest}
    >
      <AutocompleteInput
        validate={required ? validateParceiro : null}
        matchSuggestion={matchSuggestion}
        optionText={<ParceiroItem />}
        inputText={(record) => (record ? record.nome_fantasia : "")}
      />
    </LazyReferenceInput>
  );
}

export default ParceiroLazyReferenceInput;
