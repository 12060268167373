import React from "react";
import PropTypes from "prop-types";
import { NumberField } from "react-admin";
import EditableTextField from "../../resources/utils/EditableTextField";

function CurrencyFieldEditable(props) {
  return (
    <EditableTextField
      type="number"
      options={{ style: "currency", currency: "BRL" }}
      {...props}
    >
      <NumberField />
    </EditableTextField>
  );
}

CurrencyFieldEditable.propTypes = {
  addLabel: PropTypes.bool,
};

CurrencyFieldEditable.defaultProps = {
  addLabel: true,
  textAlign: "left",
};

export default CurrencyFieldEditable;
