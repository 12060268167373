import React from "react";
import ImportantDevices from "@material-ui/icons/ImportantDevices";

import EquipamentoCreate from "./EquipamentoCreate";
import EquipamentoEdit from "./EquipamentoEdit";
import EquipamentoList from "./EquipamentoList";

const equipamento = {
  getResource: (p) => {
    return {
      name: "equipamentos",
      create: p.cadastros_ver ? EquipamentoCreate : null,
      list: p.cadastros_ver ? EquipamentoList : null,
      edit: p.cadastros_ver ? EquipamentoEdit : null,
    };
  },
  getMenu: (p) =>
    p.cadastros_ver
      ? {
          key: "equipamentos",
          to: "/equipamentos",
          primaryText: "Equipamentos",
          leftIcon: iconEquipamento,
        }
      : null,
};

export default equipamento;
export const iconEquipamento = <ImportantDevices />;
