import React from "react";
import { Show } from "react-admin";
import ProdutoForm from "./ProdutoForm";

const ProdutoEdit = (props) => (
  <Show {...props}>
    <ProdutoForm toolbar={false} />
  </Show>
);

export default ProdutoEdit;
