import React from "react";
import { Create } from "react-admin";

import CestForm from "./CestForm";
import CestToolbar from "./CestToolbar";

const CestCreate = props => (
  <Create {...props}>
    <CestForm toolbar={<CestToolbar />} />
  </Create>
);

export default CestCreate;
