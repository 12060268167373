import React from "react";
import { SelectInput, SelectField } from "react-admin";

const tipos = [
  { id: 1, name: "1 - NF-e normal" },
  { id: 2, name: "2 - NF-e complementar" },
  { id: 3, name: "3 - NF-e de ajuste" },
  { id: 4, name: "4 - Devolução" },
];

function NFeFinalidadeInput(props) {
  return (
    <SelectInput
      label="Finalidade Emissão"
      source="finalidade"
      choices={tipos}
      initialValue={1}
      {...props}
    />
  );
}

function NFeFinalidadeField(props) {
  return (
    <SelectField
      label="Finalidade Emissão"
      source="finalidade"
      choices={tipos}
      {...props}
    />
  );
}

export { NFeFinalidadeInput, NFeFinalidadeField };
