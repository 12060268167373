import React from "react";
import {
  Datagrid,
  List,
  TextField,
  BooleanField,
  BulkDeleteButton,
} from "react-admin";
import PermissionEditButton from "../../common/form/PermissionEditButton";
import { withUser } from "../../common/util/hocs";
import ButtonStatusVenda from "./ButtonStatusVenda";
import GrupoFilter from "./GrupoFilter";
import LinkToProdutos from "./LinkToProdutos";
import ListImage from "../../common/ListImage";
import ListColor from "../../common/ListColor";

const GrupoList = ({ permissao, ...rest }) => (
  <List
    {...rest}
    filters={<GrupoFilter />}
    exporter={false}
    bulkActionButtons={permissao.cardapio_editar ? <BulkDeleteButton /> : false}
  >
    <Datagrid>
      <ListImage label="Foto" />
      <ListColor label="Cor" />
      <TextField source="nome" label="Nome" />
      <BooleanField source="android" label="Android" />
      <BooleanField source="categoria" label="Categoria" />
      <TextField source="ordem" label="Ordem" />
      <ButtonStatusVenda label="Status" />
      <LinkToProdutos />
      <PermissionEditButton canEdit="cardapio_editar" canShow="cardapio_ver" />
    </Datagrid>
  </List>
);

export default withUser(GrupoList);
