import React from "react";
import {
  Datagrid,
  List,
  NumberField,
  TextField,
  DateField,
  ReferenceField,
} from "react-admin";
import CertificadoDigitalFilter from "./CertificadoDigitalFilter";
import CertificadoDigitalValidadeChip from "./CertificadoDigitalValidadeChip";
import useFetch from "../../dataProvider/useFetch";

const UltimoNSU = ({ record }) => {
  const { data, isLoading } = useFetch("Sefaz/ultimoNSU", {
    lojaId: record.lojaId,
  });

  return isLoading ? "..." : data;
};

const CertificadoDigitalList = (props) => (
  <List
    {...props}
    filters={<CertificadoDigitalFilter />}
    sort={{ field: "id", order: "DESC" }}
    exporter={false}
  >
    <Datagrid>
      <NumberField source="lojaId" label="Loja id" />
      <ReferenceField source="lojaId" reference="lojas">
        <TextField source="nome_fantasia" />
      </ReferenceField>
      <CertificadoDigitalValidadeChip label="Validade" />
      <DateField source="validoDe" label="Valido de" showTime locales="pt-BR" />
      <DateField
        source="validoAte"
        label="Valido até"
        showTime
        locales="pt-BR"
      />
      <UltimoNSU label="NSU" />
      <DateField
        source="updatedAt"
        label="Ultima Pesquisa"
        showTime
        locales="pt-BR"
      />
      <TextField source="cStat" label="Status" />
      <TextField source="xMotivo" label="Motivo" />
    </Datagrid>
  </List>
);

export default CertificadoDigitalList;
