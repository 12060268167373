import findKey from "lodash/findKey";

const WEEKDAYS = {
  1: "domingo",
  2: "segunda",
  3: "terça",
  4: "quarta",
  5: "quinta",
  6: "sexta",
  7: "sábado",
};

export function generateWeekdaysLabels() {
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 1);
  return Object.keys(WEEKDAYS)
    .map(() => {
      const day = WEEKDAYS[startDate.getDay() + 1];
      startDate.setDate(startDate.getDate() - 1);
      return day;
    })
    .reverse();
}

export function getTotalsPerWeekday(labels, dataPerWeekdays) {
  return labels.map((day) => {
    const weekdayKey = findKey(WEEKDAYS, (d) => d === day);
    const data = dataPerWeekdays.find(
      (item) => `${item.dayOfWeek}` === weekdayKey
    );
    return data ? data.total : 0;
  });
}

export function generateWeekTotalLabel(chartData) {
  const labels = {};

  const {
    params: { startDate, endDate, startDate2, endDate2 },
  } = chartData;

  labels["week1"] = `${getDayAndMonth(startDate)} - ${getDayAndMonth(endDate)}`;

  labels["week2"] = `${getDayAndMonth(startDate2)} - ${getDayAndMonth(
    endDate2
  )}`;

  return labels;
}

export function getDayAndMonth(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  return `${day}/${month}`;
}
