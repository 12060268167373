const DELAY_MS = 2000;

const uxdelay = (promise, delay = DELAY_MS) => {
  return Promise.allSettled([
    new Promise((resolve) => setTimeout(resolve, delay)),
    promise,
  ])
    .then((results) => results[1])
    .then((result) => {
      if (result.status === "fulfilled") {
        return result.value;
      }

      throw result.reason;
    });
};

export default uxdelay;
