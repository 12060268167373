import React from "react";
import {
  Title,
  Error,
  useGetList,
  EditButton,
  CreateButton,
  List,
  Datagrid,
  TextField,
  DateField,
  Loading,
} from "react-admin";
import GradeIcon from "@material-ui/icons/Grade";
import { Box, Card, Stack, Typography } from "@mui/material";
import FidelidadeFilter from "./FidelidadeFilter";
import FidelidadePedidosButton from "./FidelidadePedidosButton";

function FidelidadeList(props) {
  const resource = "AppFidelidades";
  const basePath = "/AppFidelidades";
  const { data, isLoading, error } = useGetList(resource, { limit: 1 });

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  const keys = Object.keys(data);
  const record = keys.length > 0 ? data[keys[0]] : null;

  const title = record
    ? "Programa de Fidelidade Habilitado"
    : "Programa de Fidelidade Desabilitado";
  const subtitle = record ? record.descricao : "";

  return (
    <Stack>
      {!record && <Title title="Programa de Fidelidade" />}
      <Card sx={{ my: 2, mx: 0, p: 3, background: "#fff3cd" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" spacing={3}>
            <GradeIcon style={{ height: 46, width: 46 }} />
            <Box>
              <Stack direction="column" spacing={1}>
                <Typography variant="h6">{title}</Typography>
                <Typography variant="subtitle1">{subtitle}</Typography>
              </Stack>
            </Box>
          </Stack>
          {record && (
            <EditButton
              resource={resource}
              basePath={basePath}
              record={record}
            />
          )}
          {!record && <CreateButton resource={resource} basePath={basePath} />}
        </Stack>
      </Card>
      {record && (
        <List
          title="Programa de Fidelidade"
          resource="AppUsers"
          basePath="/AppUsers"
          exporter={false}
          filters={<FidelidadeFilter />}
        >
          <Datagrid>
            <TextField source="nome" label="Nome" />
            <TextField source="telefone" label="Telefone" />
            <DateField
              source="nascido_em"
              label="Data Nascimento"
              options={{ timeZone: "UTC" }}
            />
            <FidelidadePedidosButton lojaId={record.lojaId} />
          </Datagrid>
        </List>
      )}
    </Stack>
  );
}

export default FidelidadeList;
