import React from "react";
import { FormDataConsumer } from "react-admin";
import CircularProgress from '../CircularProgress';
import { Context as UserContext } from "../../app/App";

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

export function withContext(WrappedComponent, Context) {
  const WithContext = props => {
    return (
      <Context.Consumer>
        {values => <WrappedComponent {...values} {...props} />}
      </Context.Consumer>
    );
  };
  WithContext.displayName = `WithContext(${getDisplayName(WrappedComponent)})`;
  return WithContext;
}

export function withFormData(WrappedComponent) {
  const WithFormData = props => {
    return (
      <FormDataConsumer>
        {({ formData, ...formProps }) => (
          <WrappedComponent
            formData={formData}
            formProps={formProps}
            {...props}
          />
        )}
      </FormDataConsumer>
    );
  };
  WithFormData.displayName = `WithFormData(${getDisplayName(
    WrappedComponent
  )})`;
  return WithFormData;
}

export function withUser(WrappedComponent) {
  const WithContext = props => {
    return (
      <UserContext.Consumer>
        {
          values => !values || !values.usuario || !values.loja
            ? <CircularProgress />
            : <WrappedComponent {...values} {...props} />
        }
      </UserContext.Consumer>
    );
  };
  WithContext.displayName = `withUser(${getDisplayName(WrappedComponent)})`;
  return WithContext;
}