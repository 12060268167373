import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { formatNumber } from "../../common/util/formatter";
import Decimal from "decimal.js";
import { Link } from "react-router-dom";

const ListItens = ({
  produtos,
  recebimentos,
  total_bruto,
  total_desconto,
  total_servico,
}) => {
  const total = Decimal(total_bruto)
    .plus(total_desconto)
    .plus(total_servico)
    .toNumber();

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Codigo.</TableCell>
          <TableCell>Produto</TableCell>
          <TableCell>Qtd.</TableCell>
          <TableCell align="right">R$ Unit.</TableCell>
          <TableCell align="right">Total</TableCell>
          <TableCell align="right">Desconto</TableCell>
          <TableCell align="right">Total</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {produtos.map((i) => (
          <TableRow key={i.id}>
            <TableCell component="th" scope="row">
              <Link to={`/produtos/${i.produtoId}`}>{i.cod_prod}</Link>
            </TableCell>
            <TableCell>{i.descricao}</TableCell>
            <TableCell>{i.qtd}</TableCell>
            <TableCell align="right">
              {formatNumber(i.valor_unitario, 2)}
            </TableCell>
            <TableCell align="right">
              {formatNumber(i.valor_bruto, 2)}
            </TableCell>
            <TableCell align="right">
              {formatNumber(i.valor_desconto, 2)}
            </TableCell>
            <TableCell align="right">
              {formatNumber(
                Decimal(i.valor_bruto).minus(i.valor_desconto).toNumber(),
                2
              )}
            </TableCell>
          </TableRow>
        ))}

        <TableRow key="total-produtos">
          <TableCell align="right" colSpan={5}>
            <strong>Total dos Produtos</strong>
          </TableCell>
          <TableCell align="right" colSpan={2}>
            <strong>{formatNumber(total_bruto, 2)}</strong>
          </TableCell>
        </TableRow>

        {total_servico !== 0 && (
          <TableRow key="total-servico">
            <TableCell align="right" colSpan={5}>
              <strong>Serviço</strong>
            </TableCell>
            <TableCell align="right" colSpan={2}>
              <strong>{formatNumber(total_servico, 2)}</strong>
            </TableCell>
          </TableRow>
        )}

        {total_desconto !== 0 && (
          <TableRow key="total-desconto">
            <TableCell align="right" colSpan={5}>
              <strong>Desconto</strong>
            </TableCell>
            <TableCell align="right" colSpan={2}>
              <strong>{formatNumber(total_desconto, 2)}</strong>
            </TableCell>
          </TableRow>
        )}

        {total !== total_bruto && (
          <TableRow key="total-final">
            <TableCell align="right" colSpan={5}>
              <strong>Total</strong>
            </TableCell>
            <TableCell align="right" colSpan={2}>
              <strong>{formatNumber(total, 2)}</strong>
            </TableCell>
          </TableRow>
        )}

        {recebimentos.map((p) => (
          <TableRow key={p.id}>
            <TableCell align="right" colSpan={5}>
              {p.moeda.nome}
            </TableCell>
            <TableCell align="right" colSpan={2}>
              {formatNumber(p.valor, 2)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const MovimentoDetalhes = ({ record }) => {
  const { produtos, recebimentos } = record;

  if (!produtos || !recebimentos) {
    return null;
  }

  return (
    <Box sx={{ py: 5 }}>
      <Typography variant="h6" gutterBottom>
        Produtos
      </Typography>
      <ListItens
        produtos={produtos}
        recebimentos={recebimentos}
        total_bruto={record.total_bruto}
        total_desconto={record.total_desconto}
        total_servico={record.total_servico}
      />
    </Box>
  );
};

export default MovimentoDetalhes;
