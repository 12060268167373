import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

const ordenacao = [
  { value: "valor_final", text: "R$ Venda" },
  { value: "lucro", text: "R$ Lucro" },
  { value: "qtd", text: "Quantidade" },
];

function OrderABC({ value, onChange, id }) {
  const handleChange = (event) => onChange(id, event.target.value);

  return (
    <FormControl variant='standard' sx={{ minWidth: 100 }}>
      <InputLabel>Ordenação</InputLabel>
      <Select value={value} onChange={handleChange}>
        {ordenacao.map((o) => (
          <MenuItem key={o.value} value={o.value}>
            {o.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default OrderABC;
