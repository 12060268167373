import React from "react";
import {
  TextInput,
  BooleanInput,
  SelectInput,
  AutocompleteInput,
  required,
  minValue,
  minLength,
  maxLength,
  maxValue,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { InputAdornment } from "@material-ui/core";
import LazyReferenceInput from "../../common/form/LazyReferenceInput";
import useParceiroValidateUniq from "./useParceiroValidateUniq";
import { Box } from "@mui/material";
import NumberInputSimple from "../../common/form/NumberInputSimple";
import { validateDecimalPlaces } from "../../common/util/ra-validations";

const validateSenha = [minLength(1), maxLength(8)];
const validateValor = [
  required(),
  minValue(0),
  maxValue(9999999999.99),
  validateDecimalPlaces(2),
];
const validateComissao = [
  required(),
  minValue(-1),
  maxValue(9999999999.99),
  validateDecimalPlaces(2),
];
const validateDia = [minValue(1), maxValue(31), validateDecimalPlaces(0)];

const credito_tipo_alt_options = [
  { id: "DESCONTO", name: "Desconto" },
  { id: "ACRESCIMO", name: "Acrescimo" },
];

const styles = {
  inline: {
    display: "inline-block",
    marginRight: 32,
  },
  func: {
    display: "inline-block",
    marginRight: 32,
    width: "11em",
  },
  cli: {
    display: "inline-block",
    marginRight: 32,
    width: "10em",
  },
  limite: {
    display: "inline-block",
    marginRight: "1em",
    width: "8em",
  },
  boxConfig: {
    marginBottom: "1em",
    paddingTop: "1em",
    paddingLeft: "1em",
    borderWidth: 2,
    borderRadius: 10,
    borderColor: "#eeeeee",
    borderStyle: "double",
  },
};

const useStyles = makeStyles(styles);

function ParceiroClienteFuncionario({ formData, record, loja }) {
  const classes = useStyles();

  const { uniq: uniqUsuarioPDV } = useParceiroValidateUniq(
    loja.id,
    record,
    "usuario_pdv",
    "Usuário PDV Windows"
  );

  const validateUsuarioPDV = [
    required(),
    minValue(1),
    maxValue(999999),
    uniqUsuarioPDV,
  ];

  return (
    <>
      <Box style={styles.boxConfig}>
        <BooleanInput source="cliente" style={styles.inline} />
        {formData.cliente && (
          <>
            <BooleanInput
              source="credito_ativo"
              defaultValue={true}
              style={styles.cli}
            />
            <NumberInputSimple
              source="credito_limite"
              label="Limite"
              validate={validateValor}
              initialValue={0}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              className={classes.limite}
            />
            <NumberInputSimple
              source="credito_limite_dia"
              label="Limite diário"
              validate={validateValor}
              initialValue={0}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              className={classes.limite}
            />

            <SelectInput
              source="credito_tipo_alt"
              label="Tipo alteração"
              choices={credito_tipo_alt_options}
              className={classes.limite}
              fullWidth
            />

            <NumberInputSimple
              source="credito_valor_alt"
              label="Alteração"
              validate={validateValor}
              initialValue={0}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
              className={classes.limite}
            />

            <NumberInputSimple
              source="credito_dia_pagamento"
              label="Dia de pag."
              validate={validateDia}
              className={classes.limite}
            />

            <LazyReferenceInput
              label="Tabela de preço"
              source="tabelaPrecoId"
              reference="Loja_tabela_precos"
              nameSource="tabelaPreco"
              allowEmpty
            >
              <AutocompleteInput optionText="nome" />
            </LazyReferenceInput>
          </>
        )}
      </Box>

      <Box style={styles.boxConfig}>
        <BooleanInput
          source="funcionario"
          label="Funcionário"
          style={styles.inline}
        />
        {formData.funcionario && (
          <>
            <NumberInputSimple
              source="usuario_pdv"
              label="Usuário PDV Windows"
              validate={validateUsuarioPDV}
              style={styles.func}
            />
            <TextInput
              source="senha_pdv"
              label="Senha PDV Windows"
              validate={validateSenha}
              style={styles.func}
              type="password"
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
            />
            <NumberInputSimple
              source="comissao_venda"
              label="Comissao na venda"
              validate={validateComissao}
              initialValue={0}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
              style={styles.func}
            />
            <NumberInputSimple
              source="desconto_maximo"
              label="Desconto máximo"
              validate={validateValor}
              initialValue={100}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
              style={styles.func}
            />
          </>
        )}
      </Box>

      <Box style={styles.boxConfig}>
        <BooleanInput source="fornecedor" />
      </Box>
    </>
  );
}

export default ParceiroClienteFuncionario;
