import React from "react";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import ProdutoCreate from "./ProdutoCreate";
import ProdutoEdit from "./ProdutoEdit";
import ProdutoList from "./ProdutoList";
import ProdutoShow from "./ProdutoShow";

const produto = {
  getResource: (p, a) => {
    return {
      name: "produtos",
      list: p.cardapio_ver ? ProdutoList : null,
      show: p.cardapio_ver && !p.cardapio_editar ? ProdutoShow : null,
      create: p.cardapio_criar ? ProdutoCreate : null,
      edit: p.cardapio_editar ? ProdutoEdit : null,
    };
  },
  getMenu: (p, a) =>
    p.cardapio_ver
      ? {
          key: "produtos",
          to: "/produtos",
          primaryText: "Produtos",
          leftIcon: <ShoppingBasket />,
        }
      : null,
};

export default produto;
export const IconProduto = ShoppingBasket;
