import React, { useState } from "react";
import DateRangePicker from "../../common/DateRangePicker";

function DateRange({ value, onChange, id }) {
  const [focusedInput, setFocusedInput] = useState(null);

  const handleChange = ({ startDate, endDate }) =>
    onChange(id, { startDate, endDate });

  return (
    <DateRangePicker
      startDate={value.startDate}
      endDate={value.endDate}
      focusedInput={focusedInput}
      onFocusChange={setFocusedInput}
      onDatesChange={handleChange}
    />
  );
}

export default DateRange;
