import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, InputAdornment, Typography } from "@mui/material";
import NumberInputSimple from "../../../common/form/NumberInputSimple";
import { BooleanInput } from "react-admin";
import useChangeCalc from "../useChangeCalc";

const useStyles = makeStyles({
  numberInput: {
    width: "7em",
    marginRight: "1em",
  },
});

export default function ICMSNormal({ getSource, icmsEnable, icmsRedBCEnable }) {
  const classes = useStyles();
  const changeCalc = useChangeCalc();

  return icmsEnable ? (
    <Grid container alignItems="center" sx={{ pb: 4 }}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          ICMS Normal
        </Typography>
      </Grid>

      {icmsRedBCEnable && (
        <Grid item>
          <NumberInputSimple
            className={classes.numberInput}
            label="Red BC ICMS"
            source={getSource("icms_red_bc")}
            InputProps={{
              endAdornment: <InputAdornment sx={{ mt: 3 }}>%</InputAdornment>,
            }}
            onChange={changeCalc}
          />
        </Grid>
      )}
      <Grid item>
        <NumberInputSimple
          className={classes.numberInput}
          label="BC ICMS"
          source={getSource("icms_bc")}
        />
      </Grid>
      <Grid item>
        <NumberInputSimple
          className={classes.numberInput}
          label="Alq ICMS"
          source={getSource("icms_alq")}
          InputProps={{
            endAdornment: <InputAdornment sx={{ mt: 3 }}>%</InputAdornment>,
          }}
          onChange={changeCalc}
        />
      </Grid>
      <Grid item>
        <NumberInputSimple
          className={classes.numberInput}
          label="Valor ICMS"
          source={getSource("icms_v")}
        />
      </Grid>
      <Grid item>
        <BooleanInput
          label="Destaca ICMS"
          source={getSource("icms_destaca")}
          disabled={!icmsEnable}
        />
      </Grid>
    </Grid>
  ) : null;
}
