import React from "react";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { Button } from "@mui/material";
import { Link } from "react-admin";
import { stringify } from "query-string";
import { cloneRecord } from "../../../common/form/CloneButton";

const NFeDuplicar = ({ basePath, record }) => {
  if (!record) return null;
  const handleDuplicar = () => {};

  const newRecord = cloneRecord(record);

  const fieldsToRemove = [
    "cnf",
    "modelo",
    "serie",
    "numero",
    "chave",
    "recibo",
    "protocolo_uso",

    "cancelamento",
    "cce_correcao",
    "cce_sequencia",

    "xmlUrl",
    "xmlCancelamentoUrl",
    "xmlCceUrl",

    "pdfUrl",
    "pdfCancelamentoUrl",
    "pdfCceUrl",
  ];

  for (const field of fieldsToRemove) {
    delete newRecord[field];
  }

  newRecord.emitida_em = new Date();

  return (
    <Button
      variant="contained"
      onClick={handleDuplicar}
      component={Link}
      to={{
        pathname: `${basePath}/create`,
        search: stringify({
          source: JSON.stringify(newRecord),
        }),
      }}
      startIcon={<FileCopyIcon />}
    >
      Duplicar
    </Button>
  );
};

export default NFeDuplicar;
