import React from 'react';
import IconSave from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

function LoadingButtonWithRecord(props) {
    const [loading, setLoading] = React.useState(false);
    const { text, icon, disabled, onClick, record, ...rest } = props;

    const onClickWithRecord = ({...params}) => {
        setLoading(true);
        onClick({...params, record})
            .then(() => setLoading(false));
    }

    return <Button
        disabled={loading || disabled}
        startIcon={icon || <IconSave />}
        onClick={onClickWithRecord}
        {...rest}
    >
        {loading && (
            <CircularProgress
                size={25}
                thickness={2}
            />
        )}
        {text || 'Salvar'}
    </Button>
}

export default LoadingButtonWithRecord;