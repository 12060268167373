import React, { useState } from "react";
import SendIcon from "@material-ui/icons/Send";
import LoadingButton from "../../common/LoadingButton";
import { fetchJSON } from "../../dataProvider";
import { HttpError, useDataProvider, useNotify } from "react-admin";

const NFCeEmitirButton = ({ movimento }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);

  const emitirNFCe = async () => {
    try {
      setLoading(true);
      const nfce = await fetchJSON("Sefaz/emiteNFCe", {
        method: "POST",
        body: {
          lojaId: movimento.lojaId,
          equipamentoId: movimento.equipamentoId,
          movimentoId: movimento.id,
        },
      });

      await dataProvider.update("NFCes", {
        onlyUpdateLocalStore: true,
        data: {
          ...nfce,
        },
      });
    } catch (err) {
      console.log(JSON.stringify(err));

      if (err instanceof HttpError) {
        if (err.status === 400) {
          notify(err.message);
          return;
        }
      }

      notify("Ocorreu um erro ao emitir a NFCe");
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingButton
      variant="contained"
      text="Emitir NFCe"
      loading={loading}
      icon={<SendIcon />}
      onClick={emitirNFCe}
    />
  );
};

export default NFCeEmitirButton;
