import * as React from "react";
import { ImageField, ImageInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import IconButton from "@material-ui/core/IconButton";
import { useTranslate } from "ra-core";
import { useForm } from "react-final-form";
import { FOTO_PREFIX } from "../../config/constants";

const useStyles = makeStyles(
  (theme) => ({
    file: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "10px",
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "#eeeeee",
      borderStyle: "dashed",
      backgroundColor: "#fafafa",
      color: "#bdbdbd",
      outline: "none",
      transition: "border .24s ease-in-out",
    },
    preview: {
      display: "inline-block",
    },
    removeButton: {
      display: "inline-block",
      position: "relative",
      float: "left",
      "& button": {
        position: "absolute",
        top: theme.spacing(1),
        right: theme.spacing(1),
        minWidth: theme.spacing(2),
        opacity: 0,
      },
      "&:hover button": {
        opacity: 1,
      },
    },
    removeIcon: {
      color: theme.palette.error.main,
    },
  }),
  { name: "RaFileInputPreview" }
);

const FileInputPreview = ({
  record,
  formData,
  source = "fotoUrl",
  label = "Imagem",
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const form = useForm();

  const onRemove = () => {
    form.change(source, null);
  };

  const fotoSource = `${FOTO_PREFIX}${source}`;

  return (
    <div className={classes.file}>
      <ImageInput source={fotoSource} label={label} accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>

      {formData && formData[source] && !formData[fotoSource] && (
        <div className={classes.removeButton}>
          <IconButton
            onClick={onRemove}
            aria-label={translate("ra.action.delete")}
            title={translate("ra.action.delete")}
          >
            <RemoveCircle className={classes.removeIcon} />
          </IconButton>
          <ImageField
            className={classes.preview}
            record={record}
            source={source}
            label="Foto atual"
            title="Foto atual"
          />
        </div>
      )}
    </div>
  );
};

export default FileInputPreview;
