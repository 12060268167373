import React from "react";
import { Create } from "react-admin";

import LojaEntregaForm from "./LojaEntregaForm";
import LojaEntregaToolbar from "./LojaEntregaToolbar";

const LojaEntregaCreate = (props) => {
  return (
    <Create {...props}>
      <LojaEntregaForm toolbar={<LojaEntregaToolbar />} />
    </Create>
  );
};

export default LojaEntregaCreate;
