import { useEffect, useState, useRef, useCallback } from "react";
import { useDataProvider } from "react-admin";
import { isEqual, isEmpty } from "lodash";

const useGetListLazy = (
  reference,
  filterValues,
  limit,
  fetchOnFocus,
  focus
) => {
  const dataProvider = useDataProvider();
  const lastFilter = useRef(null);
  const [data, setData] = useState({
    ids: [],
    data: {},
    total: 0,
  });

  const fetchList = useCallback(
    async (filter) => {
      if (isEqual(filter, lastFilter.current)) {
        return;
      }
      lastFilter.current = filter;
      const r = await dataProvider.getList(reference, {
        filter,
        limit: limit ? limit : 999,
      });
      const ids = r.data.map((i) => i.id);
      const dataHash = r.data.reduce((hash, i) => {
        hash[i.id] = i;
        return hash;
      }, {});

      setData({
        ids,
        data: dataHash,
        total: ids.length,
      });
    },
    [dataProvider, reference, limit]
  );

  useEffect(() => {
    if (fetchOnFocus && focus) {
      fetchList({});
    }
  }, [fetchList, fetchOnFocus, focus]);

  useEffect(() => {
    if (isEmpty(filterValues)) {
      return;
    }
    fetchList(filterValues);
  }, [fetchList, filterValues]);

  return data;
};

export default useGetListLazy;
