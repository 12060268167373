import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  Chip,
} from "@mui/material";
import { getList } from "../../dataProvider";

function Unit({ value, onChange, id }) {
  const [unidades, setUnidades] = useState([]);

  const handleChange = (event) => onChange(id, event.target.value);

  useEffect(() => {
    getList("unidades").then((r) => setUnidades(r.data));
  }, []);

  return (
    <FormControl sx={{ minWidth: 120 }}>
      <InputLabel>Unidades</InputLabel>
      <Select
        multiple
        value={value}
        onChange={handleChange}
        input={<Input id='select-multiple-chip' />}
        renderValue={(selected) => (
          <div>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </div>
        )}
      >
        {unidades.map((u) => (
          <MenuItem key={u.id} value={u.nome}>
            {u.nome}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default Unit;
