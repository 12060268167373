import Decimal from "decimal.js";

const sumTotal = (itens, field) =>
  itens.reduce((t, i) => t.plus(i[field] || 0), new Decimal(0)).valueOf();

function calculaImpostoICMS(item, calculo_manual) {
  const { valor_item, icms_cst, icms_alq } = item;
  const { icmsRedBCEnable, icmsEnable } = calculaICMS(icms_cst);

  if (!icmsRedBCEnable) {
    item.icms_red_bc = null;
  }

  if (icmsEnable) {
    if (calculo_manual) return;

    item.icms_bc = valor_item;
    const reducao_icms = Decimal(100)
      .minus(item.icms_red_bc || 0)
      .dividedBy(100);
    item.icms_v = Decimal(item.icms_bc)
      .times(reducao_icms)
      .times(icms_alq || 0)
      .dividedBy(100)
      .toDecimalPlaces(2)
      .valueOf();
  } else {
    item.icms_bc = null;
    item.icms_alq = null;
    item.icms_v = null;
    item.icms_destaca = null;
  }

  if (icmsEnable && item.fcp_alq !== null && item.fcp_alq >= 0) {
    if (calculo_manual) return;

    item.fcp_bc = item.icms_bc;
    item.fcp_v = Decimal(item.fcp_bc)
      .times(item.fcp_alq)
      .dividedBy(100)
      .toDecimalPlaces(2)
      .valueOf();
  } else {
    item.fcp_bc = null;
    item.fcp_alq = null;
    item.fcp_v = null;
  }
}

function calculaImpostoICMSST(item, calculo_manual) {
  const { icmsSTEnable } = calculaICMS(item.icms_cst);

  if (icmsSTEnable) {
    if (calculo_manual) return;

    item.icms_st_bc = Decimal(item.icms_bc)
      .times(item.icms_st_mva || 0)
      .dividedBy(100)
      .plus(item.icms_bc)
      .toDecimalPlaces(2)
      .valueOf();
    item.icms_st_v = Decimal(item.icms_st_bc)
      .times(item.icms_st_alq || 0)
      .dividedBy(100)
      .minus(item.icms_v)
      .toDecimalPlaces(2)
      .valueOf();
  } else {
    item.icms_st_mva = null;
    item.icms_st_bc = null;
    item.icms_st_alq = null;
    item.icms_st_v = null;
  }

  if (icmsSTEnable && item.fcp_st_alq !== null && item.fcp_st_alq >= 0) {
    if (calculo_manual) return;

    item.fcp_st_bc = item.icms_st_bc;
    item.fcp_st_v = Decimal(item.fcp_st_bc)
      .times(item.fcp_st_alq)
      .dividedBy(100)
      .toDecimalPlaces(2)
      .valueOf();
  } else {
    item.fcp_st_bc = null;
    item.fcp_st_alq = null;
    item.fcp_st_v = null;
  }
}

function calculaImpostoICMSCredSN(item, calculo_manual) {
  const { icmsCredSNEnable, icmsCredSNRequired } = calculaICMS(item.icms_cst);

  if (
    icmsCredSNRequired ||
    (icmsCredSNEnable && item.cred_sn_alq !== null && item.cred_sn_alq >= 0)
  ) {
    if (calculo_manual) return;

    if (icmsCredSNRequired) {
      item.cred_sn_alq = item.cred_sn_alq || 0;
    }

    item.cred_sn_v = Decimal(item.icms_bc || item.valor_item)
      .times(item.cred_sn_alq)
      .dividedBy(100)
      .toDecimalPlaces(2)
      .valueOf();
  } else {
    item.cred_sn_v = null;
    item.cred_sn_alq = null;
  }
}

function calculaImpostoPIS(item, calculo_manual) {
  const { pis_cst, valor_item } = item;

  if (calculaPIS(pis_cst)) {
    if (calculo_manual) return;

    item.pis_alq = item.pis_alq || 0;
    item.pis_v = Decimal(valor_item)
      .times(item.pis_alq)
      .dividedBy(100)
      .toDecimalPlaces(2)
      .valueOf();
  } else {
    item.pis_alq = null;
    item.pis_v = null;
  }
}

function calculaImpostoCOFINS(item, calculo_manual) {
  const { cofins_cst, valor_item } = item;

  if (calculaCOFINS(cofins_cst)) {
    if (calculo_manual) return;

    item.cofins_alq = item.cofins_alq || 0;
    item.cofins_v = Decimal(valor_item)
      .times(item.cofins_alq)
      .dividedBy(100)
      .toDecimalPlaces(2)
      .valueOf();
  } else {
    item.cofins_alq = null;
    item.cofins_v = null;
  }
}

function calculaImpostoIPI(item, calculo_manual) {
  const { ipi_cst, valor_item } = item;

  if (calculaIPI(ipi_cst)) {
    if (calculo_manual) return;

    item.ipi_alq = item.ipi_alq || 0;
    item.ipi_v = Decimal(valor_item)
      .times(item.ipi_alq)
      .dividedBy(100)
      .toDecimalPlaces(2)
      .valueOf();
  } else {
    item.ipi_alq = null;
    item.ipi_v = null;
  }
}

function calculaDescontoRateado(formData) {
  const { total_desconto, total_frete, total_seguro, total_outro } = formData;

  let desconto = Decimal(total_desconto || 0);
  let frete = Decimal(total_frete || 0);
  let seguro = Decimal(total_seguro || 0);
  let outro = Decimal(total_outro || 0);

  const itens = formData.itens.filter((i) => i && !i.deletar);

  const total_produtos = sumTotal(itens, "valor_total");

  for (let i = 0; i < itens.length; i++) {
    const item = itens[i];
    if (i.quantidade <= 0) {
      item.valor_desconto = 0;
      item.valor_frete = 0;
      item.valor_seguro = 0;
      item.valor_outro = 0;
    } else if (i === itens.length - 1) {
      item.valor_desconto = desconto.toDecimalPlaces(2).valueOf();
      item.valor_frete = frete.toDecimalPlaces(2).valueOf();
      item.valor_seguro = seguro.toDecimalPlaces(2).valueOf();
      item.valor_outro = outro.toDecimalPlaces(2).valueOf();
    } else {
      const p = Decimal(item.valor_total).dividedBy(total_produtos);

      item.valor_desconto = Decimal(total_desconto || 0)
        .times(p)
        .toDecimalPlaces(2)
        .valueOf();
      item.valor_frete = Decimal(total_frete || 0)
        .times(p)
        .toDecimalPlaces(2)
        .valueOf();
      item.valor_seguro = Decimal(total_seguro || 0)
        .times(p)
        .toDecimalPlaces(2)
        .valueOf();
      item.valor_outro = Decimal(total_outro || 0)
        .times(p)
        .toDecimalPlaces(2)
        .valueOf();

      desconto = desconto.minus(item.valor_desconto);
      frete = frete.minus(item.valor_frete);
      seguro = seguro.minus(item.valor_seguro);
      outro = outro.minus(item.valor_outro);
    }

    item.valor_item = Decimal(item.valor_total)
      .minus(item.valor_desconto)
      .plus(item.valor_frete)
      .plus(item.valor_seguro)
      .plus(item.valor_outro)
      .toDecimalPlaces(2)
      .valueOf();
  }
}

function calculaTotais(form, formData) {
  const itensToSum = formData.itens
    .filter((i) => i && !i.deletar)
    .map((i) => ({ ...i }));
  const total_produtos = sumTotal(itensToSum, "valor_total");
  const total_cred_sn_v = sumTotal(itensToSum, "cred_sn_v");

  const total_pis_v = sumTotal(itensToSum, "pis_v");
  const total_cofins_v = sumTotal(itensToSum, "cofins_v");
  const total_ipi_v = sumTotal(itensToSum, "ipi_v");

  const total_icms_bc = sumTotal(itensToSum, "icms_bc");
  const total_icms_v = sumTotal(itensToSum, "icms_v");
  const total_fcp_v = sumTotal(itensToSum, "fcp_v");

  const total_icms_st_bc = sumTotal(itensToSum, "icms_st_bc");
  const total_icms_st_v = sumTotal(itensToSum, "icms_st_v");
  const total_fcp_st_v = sumTotal(itensToSum, "fcp_st_v");

  const { total_desconto, total_frete, total_seguro, total_outro } = formData;

  const total_nota = Decimal(total_produtos)
    .minus(total_desconto || 0)
    .plus(total_frete || 0)
    .plus(total_seguro || 0)
    .plus(total_outro || 0)
    .plus(total_icms_st_v)
    .plus(total_fcp_st_v)
    .plus(total_ipi_v)
    .valueOf();

  const itens = formData.itens.map((i) => ({ ...i }));

  form.batch(() => {
    form.change("itens", itens);

    form.change("total_produtos", total_produtos);
    form.change("total_cred_sn_v", total_cred_sn_v);

    form.change("total_pis_v", total_pis_v);
    form.change("total_cofins_v", total_cofins_v);
    form.change("total_ipi_v", total_ipi_v);

    form.change("total_icms_bc", total_icms_bc);
    form.change("total_icms_v", total_icms_v);
    form.change("total_fcp_v", total_fcp_v);

    form.change("total_icms_st_bc", total_icms_st_bc);
    form.change("total_icms_st_v", total_icms_st_v);
    form.change("total_fcp_st_v", total_fcp_st_v);

    form.change("total_nota", total_nota);
  });
}

function calculaItensValorTotal(formData) {
  const itens = formData.itens.filter((i) => i && !i.deletar);
  for (let i = 0; i < itens.length; i++) {
    const item = itens[i];

    item.valor_unitario = Decimal(item.valor_unitario || 0)
      .toDecimalPlaces(6)
      .valueOf();
    item.quantidade = Decimal(item.quantidade || 0)
      .toDecimalPlaces(4)
      .valueOf();
    item.valor_total = Decimal(item.valor_unitario)
      .times(item.quantidade)
      .toDecimalPlaces(2)
      .valueOf();
  }
}

function calculaItensImpostos(formData) {
  if (formData.calculo_manual) return;
  const itens = formData.itens.filter((i) => i && !i.deletar);
  for (let i = 0; i < itens.length; i++) {
    const calculo_manual = formData.calculo_manual;
    const item = itens[i];
    calculaImpostoPIS(item, calculo_manual);
    calculaImpostoCOFINS(item, calculo_manual);
    calculaImpostoIPI(item, calculo_manual);
    calculaImpostoICMS(item, calculo_manual);
    calculaImpostoICMSST(item, calculo_manual);
    calculaImpostoICMSCredSN(item, calculo_manual);
  }
}

export function calculosNFe(form, formData) {
  formData.itens = formData.itens || [];
  calculaItensValorTotal(formData);
  calculaDescontoRateado(formData);
  calculaItensImpostos(formData);
  calculaTotais(form, formData);
}

export function calculaICMS(icms_cst) {
  const icmsRedBCEnable =
    icms_cst === "20" ||
    icms_cst === "51" ||
    icms_cst === "70" ||
    icms_cst === "90" ||
    icms_cst === "900";

  const icmsEnable =
    icms_cst === "00" ||
    icms_cst === "10" ||
    icms_cst === "20" || //ICMS OBRIGATÓRIO
    icms_cst === "51" ||
    icms_cst === "70" ||
    icms_cst === "90" || //ICMS OBRIGATÓRIO
    icms_cst === "900"; //ICMS OPICIONAL

  const icmsSTEnable =
    icms_cst === "10" ||
    icms_cst === "30" ||
    icms_cst === "70" || //ICMS ST OBRIGATÓRIO
    icms_cst === "201" ||
    icms_cst === "202" ||
    icms_cst === "203" || //ICMS ST OBRIGATÓRIO
    icms_cst === "90" ||
    icms_cst === "900"; //ICMS ST OPICIONAL

  const icmsCredSNEnable =
    icms_cst === "101" || //OBRIGATÓRIO
    icms_cst === "201" || //OBRIGATÓRIO
    icms_cst === "900"; //OPICIONAL

  const icmsCredSNRequired =
    icms_cst === "101" || //OBRIGATÓRIO
    icms_cst === "201"; //OBRIGATÓRIO

  return {
    icmsRedBCEnable,

    icmsEnable,

    icmsSTEnable,

    icmsCredSNEnable,
    icmsCredSNRequired,
  };
}

export function calculaPIS(pis_cst) {
  return (
    pis_cst === "01" || pis_cst === "02" || pis_cst === "49" || pis_cst === "99"
  );
}

export function calculaCOFINS(cofins_cst) {
  return (
    cofins_cst === "01" ||
    cofins_cst === "02" ||
    cofins_cst === "49" ||
    cofins_cst === "99"
  );
}

export function calculaIPI(ipi_cst) {
  return (
    ipi_cst === "00" || ipi_cst === "49" || ipi_cst === "50" || ipi_cst === "99"
  );
}

export function NFeEmProcessamento(nfe) {
  return nfe && nfe.recibo && nfe.xmlRecibo;
}

export function NFeAutorizada(nfe) {
  return nfe && nfe.xmlUrl && !NFeCancelada(nfe);
}

export function NFeCancelada(nfe) {
  return nfe && !!nfe.xmlCancelamentoUrl;
}

export function Inutilizacao(nfe) {
  return nfe && nfe.informacao_adicional === INUTILIZACAO;
}

export function NFeEmDigitacao(nfe) {
  return !NFeCancelada(nfe) && !NFeAutorizada(nfe) && !NFeEmProcessamento(nfe);
}

export const INUTILIZACAO = "INUTILIZACAO";
