import React from "react";
import {
  Loading,
  Error,
  Title,
  useQuery,
  Empty,
  useAuthenticated,
} from "react-admin";
import { Link } from "react-router-dom";
import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Badge,
  Stack,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import Decimal from "decimal.js";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import useFetch from "../../dataProvider/useFetch";
import { withUser } from "../../common/util/hocs";
import { iconGrupo } from "../grupo";
import { iconEstoque } from "./index";
import LinkToMovimentacao from "./LinkToMovimentacao";
import { formatCurrency } from "../../common/util/formatter";
import TablePag from "../../common/table/Table";

const filters = [
  {
    nome: "Em falta",
    filter: (prod) => prod.saldo <= 0,
    class: "badgeEmFalta",
  },
  {
    nome: "Mínimo",
    filter: (prod) => prod.saldo > 0 && prod.saldo <= prod.qtd_min_prod,
    class: "badgeMinimo",
  },
  {
    nome: "Acima do mínimo",
    filter: (prod) => prod.saldo > prod.qtd_min_prod,
  },
];

const CardCustom = ({ label, value, color = "#283593", component }) => {
  return (
    <Card
      sx={{
        border: "1px solid #c5c5c5",
        borderBottom: `7px solid ${color}`,
        pl: 2,
        pr: 2,
        display: "flex",
        alignItems: "center",
      }}
    >
      <CardContent>
        {label && typeof label === "string" && (
          <Typography variant="subtitle1" color="textPrimary">
            {label}
          </Typography>
        )}
        {value && typeof value === "string" && (
          <Typography sx={{ fontWeight: "bold" }} variant="h5">
            {value}
          </Typography>
        )}
        {!!component && component}
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  badgeEmFalta: {
    marginRight: "15px",
    height: "0.1em",
    width: "0.1em",
    backgroundColor: "#f44336",
  },
  badgeMinimo: {
    marginRight: "15px",
    height: "0.1em",
    width: "0.1em",
    backgroundColor: "#f57f17",
  },
  listItemText: {
    paddingLeft: "1em",
    margin: 0,
  },
  typographyImportant: {
    fontWeight: "bold",
  },
  typographyEmFalta: {
    fontWeight: "bold",
    color: "#f44336",
  },
  typographyMinimo: {
    fontWeight: "bold",
    color: "#f57f17",
  },
}));

const EstoqueList = ({ loja }) => {
  useAuthenticated();
  const classes = useStyles();
  const {
    data: produtos,
    isLoading,
    error,
  } = useFetch("Estoque/list", { filter: { where: { lojaId: loja.id } } });
  const { data: grupos } = useQuery({
    type: "getList",
    resource: "grupos",
  });

  const [checked, setChecked] = React.useState([]);
  const [checkedGrupos, setCheckedGrupos] = React.useState([]);

  const handleToggleEstoque = (value) => () => {
    const currentIndex = checked.findIndex((v) => v.nome === value.nome);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleToggleGrupo = (value) => () => {
    const currentIndex = checkedGrupos.findIndex((v) => v.nome === value.nome);
    const newChecked = [...checkedGrupos];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedGrupos(newChecked);
  };

  if (error) {
    return (
      <>
        <Title title="Estoque" />
        <Error error={error} />
      </>
    );
  }

  if (isLoading || !produtos) {
    return (
      <>
        <Title title="Estoque" />
        <Loading />
      </>
    );
  }

  if (produtos.length === 0) {
    return (
      <>
        <Title title="Estoque" />
        <Empty resource="Estoque" />
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          O estoque só é contabilizado para os produtos que possuem entradas.
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          Para começar crie um pedido de comrpa ou faça a entrada de uma nota
          fiscal ou crie um inventário.
        </Typography>
      </>
    );
  }

  const grupoIds = checkedGrupos.map((g) => g.id);
  const filteredGrupos =
    grupoIds.length > 0
      ? produtos.filter((prod) => grupoIds.includes(prod.grupoId))
      : produtos;

  const filteredData =
    checked.length > 0
      ? filteredGrupos.filter((prod) =>
          checked.reduce((r, f) => f.filter(prod) || r, false)
        )
      : filteredGrupos;

  const orderedData = filteredData
    .sort((a, b) => a.saldo - b.saldo)
    .map((p) => {
      p.saldo = new Decimal(p.saldo);
      p.emFalta = p.saldo.lessThanOrEqualTo(0);
      p.minimo =
        p.saldo.greaterThan(0) && p.saldo.lessThanOrEqualTo(p.qtd_min_prod);
      p.fontClass = p.emFalta
        ? classes.typographyEmFalta
        : p.minimo
        ? classes.typographyMinimo
        : classes.typographyImportant;
      return p;
    });

  let totalQtd = new Decimal(0);
  let totalMinimo = new Decimal(0);
  let totalVenda = new Decimal(0);
  let totalCusto = new Decimal(0);
  orderedData
    .filter((p) => p.saldo.greaterThan(0))
    .forEach((p) => {
      totalQtd = totalQtd.plus(p.saldo);
      totalMinimo = totalMinimo.plus(p.minimo ? 1 : 0);
      totalVenda = totalVenda.plus(
        new Decimal(p.saldo).times(p.preco_vnd_prod)
      );
      totalCusto = totalCusto.plus(
        new Decimal(p.saldo).times(p.preco_cst_prod)
      );
    });
  let totalEmfalta = orderedData.filter((p) =>
    p.saldo.lessThanOrEqualTo(0)
  ).length;
  let totalLucro = totalVenda.minus(totalCusto);

  const columns = [
    {
      title: "Código",
      field: "cod_prod",
      width: "20%",
      render: (prod) => (
        <Link to={`/produtos/${prod.id}`}>{prod.cod_prod}</Link>
      ),
    },
    {
      title: "Produto",
      field: "desc_prod",
      // width: "auto",
      render: (prod) => (
        <>
          {prod.emFalta && (
            <Badge variant="dot" classes={{ badge: classes.badgeEmFalta }} />
          )}
          {prod.minimo && (
            <Badge variant="dot" classes={{ badge: classes.badgeMinimo }} />
          )}
          <span className={classes.desc}>{prod.desc_prod}</span>
        </>
      ),
    },
    {
      title: "Estoque",
      field: "saldo",
      width: "20%",
      render: (prod) => (
        <Typography className={prod.fontClass} variant="h5">
          {prod.saldo.toNumber()}
        </Typography>
      ),
    },
    {
      title: "",
      width: "10%",
      render: (prod) => <LinkToMovimentacao produtoId={prod.id} />,
    },
  ];

  return (
    <>
      <Title title="Estoque" />
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Card>
            <CardContent>
              <Typography variant="overline">Filtros</Typography>
              <Box mt={2} display="flex" alignItems="center">
                <Box mr={1}>{iconEstoque}</Box>
                <Typography variant="overline">Estoque</Typography>
              </Box>
              <List dense disablePadding>
                {filters.map((value) => {
                  const isSelected =
                    checked.findIndex((v) => v.nome === value.nome) !== -1;
                  return (
                    <ListItem
                      button
                      onClick={handleToggleEstoque(value)}
                      selected={isSelected}
                      key={value.nome}
                    >
                      <ListItemText
                        className={classes.listItemText}
                        data-selected={isSelected ? "true" : "false"}
                      >
                        <Badge
                          variant="dot"
                          classes={{ badge: classes[value.class] }}
                        />
                        {value.nome}
                      </ListItemText>
                      {isSelected && (
                        <ListItemSecondaryAction>
                          <IconButton
                            size="small"
                            onClick={handleToggleEstoque(value)}
                          >
                            <CancelIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  );
                })}
              </List>
              {grupos && (
                <>
                  <Box mt={2} display="flex" alignItems="center">
                    <Box mr={1}>{iconGrupo}</Box>
                    <Typography variant="overline">Grupos</Typography>
                  </Box>
                  <List dense disablePadding>
                    {grupos.map((value) => {
                      const isSelected =
                        checkedGrupos.findIndex(
                          (v) => v.nome === value.nome
                        ) !== -1;
                      return (
                        <ListItem
                          button
                          onClick={handleToggleGrupo(value)}
                          selected={isSelected}
                          key={value.id}
                        >
                          <ListItemText
                            primary={value.nome}
                            className={classes.listItemText}
                            data-selected={isSelected ? "true" : "false"}
                          />
                          {isSelected && (
                            <ListItemSecondaryAction>
                              <IconButton
                                size="small"
                                onClick={handleToggleGrupo(value)}
                              >
                                <CancelIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          )}
                        </ListItem>
                      );
                    })}
                  </List>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={10}>
          <Grid spacing={4} container>
            <Grid item xs={12}>
              <Stack
                fullWidth
                container
                direction="row"
                justifyContent="space-between"
              >
                <CardCustom
                  label="Valor em estoque"
                  value={formatCurrency(totalVenda)}
                />
                <CardCustom
                  label="Custo do estoque"
                  value={formatCurrency(totalCusto)}
                  color="red"
                />
                <CardCustom
                  label="Lucro previsto"
                  value={formatCurrency(totalLucro)}
                />
                <CardCustom
                  component={
                    <>
                      <Typography
                        className={classes.typographyImportant}
                        variant="subtitle1"
                        color="textPrimary"
                      >
                        {`${totalQtd.toNumber()} itens em estoque`}
                      </Typography>
                      <Typography
                        className={classes.typographyMinimo}
                        variant="subtitle1"
                        color="textPrimary"
                      >
                        {`Estoque baixo ${totalMinimo.toNumber()}`}
                      </Typography>
                      <Typography
                        className={classes.typographyEmFalta}
                        variant="subtitle1"
                        color="textPrimary"
                      >
                        {`Sem estoque ${totalEmfalta}`}
                      </Typography>
                    </>
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <TablePag
                title="Lista de produtos"
                columns={columns}
                data={orderedData}
                options={{ pageSize: 10 }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withUser(EstoqueList);
