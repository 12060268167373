import React from "react";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import ProdutoCreate from "./ProdutoCreate";
import ProdutoEdit from "./ProdutoEdit";
import ProdutoList from "./ProdutoList";
import ProdutoShow from "./ProdutoShow";

const produtoCombo = {
  getResource: (p, a) => {
    return {
      name: "produtoCombos",
      options: { label: "Combos" },
      list: p.cardapio_ver ? ProdutoList : null,
      show: p.cardapio_ver && !p.cardapio_editar ? ProdutoShow : null,
      create: p.cardapio_criar ? ProdutoCreate : null,
      edit: p.cardapio_editar ? ProdutoEdit : null,
    };
  },
  getMenu: (p, a) =>
    p.cardapio_ver
      ? {
          key: "produtoCombos",
          to: `/produtoCombos`,
          primaryText: "Combos",
          leftIcon: iconProduto,
        }
      : null,
};

export default produtoCombo;
export const iconProduto = <GroupWorkIcon />;
