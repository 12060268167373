import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";
import maxBy from "lodash/maxBy";
import React from "react";
import { Bar } from "react-chartjs-2";

import { useStyles } from "./styles";

const HourPerformanceChart = ({ chartData }) => {
  const classes = useStyles();
  const labels = chartData.map(({ hour }) => `${hour}h`);
  const ordersPerHour = chartData.map(({ qtt }) => qtt);
  const bestTime = maxBy(chartData, "qtt");
  const bestTimeStart = bestTime.hour;
  const bestTimeEnd = String(
    new Date(0, 0, 0, bestTimeStart + 1).getHours()
  ).padStart(2, "0");
  const data = {
    labels,
    datasets: [
      {
        label: "Total de pedidos",
        data: ordersPerHour,
        backgroundColor: "rgba(0, 0, 0, 0.54)",
        borderColor: "rgba(0, 0, 0, 0.54)",
        yAxisID: "y",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
        },
      },
    },
  };

  return (
    <Card className={classes.card}>
      <CardHeader className={classes.cardHeader} title={"Por horário"} />
      <CardContent>
        <Box display='flex' flexDirection='column' marginBottom={2}>
          <Typography variant='body2' color='textSecondary'>
            Melhor horário de vendas
          </Typography>
          <Typography
            variant='h5'
            className={classes.typographyImportant}
          >{`${bestTimeStart}h - ${bestTimeEnd}h`}</Typography>
          <Typography
            variant='body2'
            color='textSecondary'
          >{`${bestTime.qtt} pedidos no total`}</Typography>
        </Box>
        <Box height={250}>
          <Bar data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default HourPerformanceChart;
