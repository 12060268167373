import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { IconPergunta } from "../pergunta";
import { withUser } from "../../common/util/hocs";

const useStyles = makeStyles({
  icon: { paddingRight: "0.5em" },
  link: {
    display: "inline-flex",
    alignItems: "center",
  },
});

const LinkToPergunta = ({ permissao, record }) => {
  const classes = useStyles();
  const show = permissao.cardapio_editar ? "" : "/show";
  const toUrl = `/pergunta/${record.perguntaId}${show}`;
  return record ? (
    <Button
      size="small"
      color="primary"
      component={Link}
      to={toUrl}
      className={classes.link}
    >
      <IconPergunta className={classes.icon} />
      Pergunta
    </Button>
  ) : null;
};

export default withUser(LinkToPergunta);
