import { Typography } from "@mui/material";
import { InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import {
  SimpleForm,
  NumberInput,
  required,
  maxLength,
  minValue,
  TextInput,
} from "react-admin";
import { withUser } from "../../common/util/hocs";
import NumberInputRemoveArrowsCSS from "../../common/form/NumberInputRemoveArrowsCSS.json";

const validateVias = [required(), minValue(1)];
const validateText = [maxLength(100)];

const useStyles = makeStyles((theme) => ({
  input: {
    width: "23em",
    ...NumberInputRemoveArrowsCSS,
  },
}));

function ImpressaoConfigsForm(props) {
  const classes = useStyles();
  return (
    <SimpleForm {...props}>
      <Typography variant="body1" fullWidth sx={{ mx: 1, mb: 3 }}>
        Aumente seu número de pedidos e a recorrência dos seus clientes com o
        Programa de Fidelidade! Configure como quiser e assim que seu cliente
        completar o Programa, o desconto será aplicado automaticamente na compra
        seguinte.
      </Typography>
      <NumberInput
        source="quantidade_pedidos"
        label="Número de pedidos para completar o Programa"
        defaultValue={10}
        validate={validateVias}
        className={classes.input}
      />
      <NumberInput
        source="prazo_dias"
        label="Prazo para completar o Programa"
        defaultValue={30}
        validate={validateVias}
        className={classes.input}
        InputProps={{
          endAdornment: <InputAdornment position="start">dias</InputAdornment>,
        }}
      />
      <NumberInput
        source="pedido_valor_minimo"
        label="Valor mínimo de pedido válido no Programa"
        defaultValue={15}
        validate={validateVias}
        className={classes.input}
        InputProps={{
          startAdornment: <InputAdornment position="start">R$</InputAdornment>,
        }}
      />
      <NumberInput
        source="pedido_intervalo_horas"
        label="Intervalo entre pedidos válidos no Programa"
        defaultValue={1}
        validate={validateVias}
        className={classes.input}
        InputProps={{
          endAdornment: <InputAdornment position="start">horas</InputAdornment>,
        }}
      />
      <NumberInput
        source="valor_desconto"
        label="Valor de desconto ao completar o Programa"
        defaultValue={1}
        validate={validateVias}
        className={classes.input}
        InputProps={{
          startAdornment: <InputAdornment position="start">R$</InputAdornment>,
        }}
      />
      <TextInput
        source="descricao"
        label="Descrição do Programa de Fidelidade"
        validate={validateText}
        multiline={true}
        required
        fullWidth
      />
    </SimpleForm>
  );
}

export default withUser(ImpressaoConfigsForm);
