import { Box, Stack, Typography } from "@mui/material";
import NFCeEmitirButton from "../nfce/NFCeEmitirButton";
import {
  Datagrid,
  TextField,
  DateField,
  ReferenceManyField,
} from "react-admin";
import CurrencyField from "../../common/form/CurrencyField";
import NFCeStatus from "../nfce/NFCeStatus";

const MovimentoNFCe = ({ record }) => {
  const { mov, nsp, produtos, recebimentos } = record;

  if (mov > 0 || nsp > 0 || !produtos || !recebimentos) {
    return null;
  }

  return (
    <Box sx={{ py: 5 }}>
      <Stack direction="row" spacing={3}>
        <Typography variant="h6" gutterBottom>
          NFCe
        </Typography>
        <NFCeEmitirButton movimento={record} />
      </Stack>
      <ReferenceManyField
        reference="NFCes"
        target="movimentoId"
        label=""
        fullWidth
      >
        <Datagrid>
          <TextField source="id" label="#" />
          <TextField source="serie" label="Série" />
          <TextField source="nNF" label="Número" />
          <DateField source="dhEmi" label="Emissão" showTime locales="pt-BR" />
          <CurrencyField source="vNF" label="Total" />
          <NFCeStatus />
        </Datagrid>
      </ReferenceManyField>
    </Box>
  );
};

export default MovimentoNFCe;
