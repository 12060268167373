import React from "react";
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";
import LojaEntregaCreate from "./LojaEntregaCreate";
import LojaEntregaEdit from "./LojaEntregaEdit";
import LojaEntregaList from "./LojaEntregaList";
import { cardapioDigitalEnable } from "../../permissionsHelper";

const lojaEntrega = {
  getResource: (p, a) =>
    cardapioDigitalEnable(p, a)
      ? {
          name: "Loja_Entregas",
          create: LojaEntregaCreate,
          list: LojaEntregaList,
          edit: LojaEntregaEdit,
        }
      : null,
  getMenu: (p, a) =>
    cardapioDigitalEnable(p, a)
      ? {
          key: "Loja_Entregas",
          to: "/Loja_Entregas",
          primaryText: "Áreas de entrega",
          leftIcon: iconLojaEntrega,
        }
      : null,
};

export default lojaEntrega;
export const iconLojaEntrega = <DirectionsBikeIcon />;
