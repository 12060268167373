import React, { useState } from "react";
import {
  Datagrid,
  List,
  TextField,
  Button,
  BulkDeleteButton,
  useListContext,
  FunctionField,
} from "react-admin";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ProdutoFilter from "./ProdutoFilter";
import ListActions from "../../common/ListActions";
import ButtonStatusVenda from "./ButtonStatusVenda";
import EditableListButton from "./EditableListButton";
import ProdutoUpdateAll from "./ProdutoBulkUpdate";
import { getList } from "../../dataProvider";
import UXDelay from "../../dataProvider/uxdelay";
import { SmallCircularProgress } from "../../common/CircularProgress";
import { withUser } from "../../common/util/hocs";
import PermissionEditButton from "../../common/form/PermissionEditButton";
import ProdutoMatrizFilialListButton from "../matriz_filial/ProdutoMatrizFilialListButton";
import { formatNumber } from "../../common/util/formatter";

const actionsList = [
  <EditableListButton
    key="produtos-list-modo-avancado"
    to="/produtosAlteracao"
    label="Modo Avançado"
  />,
];

const SelectAllButton = (props) => {
  const [hide, setHide] = useState(false);
  const [loading, setLoading] = useState(false);
  const { data, ids, total, selectedIds, filterValues, onSelect } =
    useListContext();
  const isAllSelected = Object.keys(data).reduce(
    (acc, id) => acc && selectedIds.includes(data[id].id),
    true
  );
  const hasMorePages = total > ids.length;

  const handleClick = (event) => {
    setLoading(true);
    UXDelay(
      getList("produtos", {
        filter: filterValues,
        fields: ["id"],
      })
    )
      .then((response) => {
        const allIds = Object.keys(response.data).map(
          (key) => response.data[key].id
        );
        onSelect(allIds);
        setHide(true);
      })
      .finally(() => setLoading(false));
  };

  return !hide && isAllSelected && hasMorePages ? (
    <Button
      label={`Selecionar todos os ${total} produtos`}
      onClick={handleClick}
      disabled={loading}
    >
      {loading ? <SmallCircularProgress /> : <DoneAllIcon />}
    </Button>
  ) : null;
};

const ProdutoBulkActionButtons = (props) => (
  <>
    <ProdutoUpdateAll {...props} />
    <SelectAllButton {...props} />
    <ProdutoMatrizFilialListButton {...props} />
    <BulkDeleteButton {...props} />
  </>
);

const ProdutoList = ({ permissao, ...rest }) => (
  <List
    {...rest}
    exporter={false}
    bulkActionButtons={
      permissao.cardapio_editar ? <ProdutoBulkActionButtons /> : null
    }
    actions={
      <ListActions
        actionsList={permissao.cardapio_editar ? actionsList : null}
      />
    }
    filters={<ProdutoFilter />}
  >
    <Datagrid>
      <TextField source="cod_prod" label="Código" />
      <TextField source="cod_aux" label="Cód.Aux." />
      <TextField source="desc_prod" label="Nome" />
      <FunctionField
        render={(record) => {
          const { preco_vnd_prod, appPreco } = record;
          if (!appPreco || appPreco === preco_vnd_prod)
            return formatNumber(preco_vnd_prod);

          return (
            <span>
              <strong>{formatNumber(preco_vnd_prod)}</strong>
              {` / ${formatNumber(appPreco)} `}
            </span>
          );
        }}
        label="R$ Venda"
      />
      <ButtonStatusVenda label="Status" />
      <PermissionEditButton canEdit="cardapio_editar" canShow="cardapio_ver" />
    </Datagrid>
  </List>
);

export default withUser(ProdutoList);
