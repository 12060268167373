import React from "react";
import Collections from "@material-ui/icons/Collections";
import GrupoCreate from "./GrupoCreate";
import GrupoEdit from "./GrupoEdit";
import GrupoList from "./GrupoList";
import GrupoShow from "./GrupoShow";

const grupo = {
  getResource: (p, a) => {
    return {
      name: "grupos",
      list: p.cardapio_ver ? GrupoList : null,
      show: p.cardapio_ver && !p.cardapio_editar ? GrupoShow : null,
      create: p.cardapio_criar ? GrupoCreate : null,
      edit: p.cardapio_editar ? GrupoEdit : null,
    };
  },
  getMenu: (p, a) =>
    p.cardapio_ver
      ? {
          key: "grupos",
          to: "/grupos",
          primaryText: "Grupos",
          leftIcon: iconGrupo,
        }
      : null,
};

export default grupo;
export const iconGrupo = <Collections />;
