import React from "react";
import { TextInput, NumberInput, Filter } from "react-admin";
import QuickFilter from "../../common/form/QuickFilter";

export const nps_filters = {
  nps_feedback_not_null: {
    feedback: { neq: "" },
  },
  nps_promotores: {
    score: { inq: [9, 10] },
  },
  nps_passivos: {
    score: { inq: [7, 8] },
  },
  nps_detratores: {
    score: { inq: [1, 2, 3, 4, 5, 6] },
  },
};

const NpsFilter = (props) => (
  <Filter {...props}>
    <NumberInput source="score" alwaysOn />
    <TextInput source="feedback" alwaysOn />
    <QuickFilter
      source="nps_feedback_not_null"
      label="Com Feedback"
      defaultValue={true}
    />
    <QuickFilter
      source="nps_promotores"
      label="Promotores"
      defaultValue={true}
    />
    <QuickFilter source="nps_passivos" label="Passivos" defaultValue={true} />
    <QuickFilter
      source="nps_detratores"
      label="Detratores"
      defaultValue={true}
    />
  </Filter>
);

export default NpsFilter;
