import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

export const COMISSION_TYPES = Object.freeze({
  SELLER: "SELLER",
  WAITER: "WAITER",
});

export const COMISSION_TYPES_OPTIONS = [
  {
    partnerType: COMISSION_TYPES.SELLER,
    partnerLabel: "Comissão do Produto/Vendedor",
  },
  { partnerType: COMISSION_TYPES.WAITER, partnerLabel: "Serviço do Garçom" },
];

export default function ComissionType({ value, onChange, id }) {
  const handleChange = (event) => onChange(id, event.target.value);

  return (
    <FormControl variant="standard" sx={{ minWidth: 150 }}>
      <InputLabel aria-labelledby="Tipo da comissão">
        Tipo da comissão
      </InputLabel>
      <Select value={value} onChange={handleChange}>
        {COMISSION_TYPES_OPTIONS.map((type) => (
          <MenuItem key={type.partnerType} value={type}>
            {type.partnerLabel}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
