import React from "react";
import PropTypes from "prop-types";
import { SelectInput } from "react-admin";
import { Regime } from "./RegimeInput";

export const OPCOES_ICMS_REGIME_NORMAL = [
  { id: "00", name: "00 - Tributada integralmente" },
  {
    id: "10",
    name: "10 - Tributada e com cobrança do ICMS por substituição tributária",
  },
  { id: "20", name: "20 - Com redução da BC" },
  {
    id: "30",
    name: "30 - Isenta / não tributada e com cobrança do ICMS por substituição tributária",
  },
  { id: "40", name: "40 - Isenta" },
  { id: "41", name: "41 - Não tributada" },
  { id: "50", name: "50 - Com suspensão" },
  { id: "51", name: "51 - Com diferimento" },
  {
    id: "60",
    name: "60 - ICMS cobrado anteriormente por substituição tributária",
  },
  {
    id: "70",
    name: "70 - Com redução da BC e cobrança do ICMS por substituição tributária",
  },
  { id: "90", name: "90 - Outras" },
];

export const OPCOES_ICMS_REGIME_SIMPLES_NACIONAL = [
  {
    id: "101",
    name: "101 - Tributada pelo Simples Nacional com permissão de crédito",
  },
  {
    id: "102",
    name: "102 - Tributada pelo Simples Nacional sem permissão de crédito",
  },
  {
    id: "103",
    name: "103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta",
  },
  {
    id: "201",
    name: "201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária",
  },
  {
    id: "202",
    name: "202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária",
  },
  {
    id: "203",
    name: "203 - Isenção do ICMS no Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária",
  },
  { id: "300", name: "300 - Imune" },
  { id: "400", name: "400 - Não tributada pelo Simples Nacional" },
  {
    id: "500",
    name: "500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação",
  },
  { id: "900", name: "900 - Outros" },
];

const ICMSInput = ({ regime, ...props }) => {
  const opcoes =
    regime === Regime.NORMAL.id
      ? OPCOES_ICMS_REGIME_NORMAL
      : OPCOES_ICMS_REGIME_SIMPLES_NACIONAL;
  return <SelectInput {...props} choices={opcoes} />;
};

ICMSInput.propTypes = {
  regime: PropTypes.oneOf(Object.keys(Regime).map((k) => Regime[k].id)),
};

ICMSInput.defaultProps = {
  regime: Regime.NORMAL.id,
};

export default ICMSInput;
