import { useRef } from "react";
import { fetchJSON } from "../../dataProvider";
import sleep from "../../common/util/sleep";

const useParceiroValidateUniq = (lojaId, record, field, label) => {
  const counter = useRef(0);
  const lastValue = useRef(record[field]);
  const lastResp = useRef(undefined);

  const uniq = async (value, allValues) => {
    counter.current++;
    const myCall = counter.current;

    await sleep(750);

    if (myCall < counter.current) {
      return "Aguarde....";
    }

    if (lastValue.current === value) {
      return lastResp.current;
    }

    lastValue.current = value;

    if (!value) {
      return undefined;
    }

    const list = await fetchJSON("parceiros", null, {
      filter: {
        where: {
          lojaId,
          [field]: value,
        },
        fields: ["id"],
      },
    });

    const error =
      list.length === 0 || list[0].id === allValues.id
        ? undefined
        : `${label} já utilizado`;
    lastResp.current = error;
    return error;
  };

  return { uniq };
};

export default useParceiroValidateUniq;
