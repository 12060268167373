import React from "react";
import { Create } from "react-admin";

import PromocaoForm from "./PromocaoForm";
import PromocaoToolbar from "./PromocaoToolbar";

const PromocaoCreate = props => (
  <Create {...props}>
    <PromocaoForm toolbar={<PromocaoToolbar />} />
  </Create>
);

export default PromocaoCreate;
