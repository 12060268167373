import React from "react";
import { useLocation } from "react-router-dom";
import { DeleteButton, SaveButton, Toolbar } from "react-admin";
import NFeCartaCorrecaoButton from "./actions/NFeCartaCorrecaoButton";
import NFeCancelaButton from "./actions/NFeCancelaButton";
import NFeDownloadXMLButton from "./actions/NFeDownloadXMLButton";
import NFeDownloadPDFButton from "./actions/NFeDownloadPDFButton";
import NFeEnviaEmailButton from "./actions/NFeEnviaEmailButton";
import NFeDuplicar from "./actions/NFeDuplicar";
import NFeEmitir from "./actions/NFeEmitir";
import NFeStatus from "./fields/NFeStatus";
import { makeStyles } from "@material-ui/core/styles";
import NFeDownloadPDFCanceladaButton from "./actions/NFeDownloadPDFCanceladaButton";
import NFeDownloadPDFEspelhoButton from "./actions/NFeDownloadPDFEspelhoButton";
import {
  Inutilizacao,
  NFeAutorizada,
  NFeCancelada,
  NFeEmDigitacao,
} from "./calculos";
import NFeInutilizar from "./actions/NFeInutilizar";
import NFeDownloadPDFCceButton from "./actions/NFeDownloadPDFCceButton";

const useStyles = makeStyles({
  defaultToolbar: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
  },
});

const NFeToolbar = (props) => {
  const location = useLocation();
  const classes = useStyles();
  const { record } = props;

  const hideToolbar = record.id
    ? !location.pathname.endsWith(`/${record.id}`)
    : location.pathname.includes("/create/");

  if (hideToolbar) {
    return null;
  }

  if (Inutilizacao(record)) {
    if (record.xmlUrl) {
      return (
        <Toolbar {...props} className={classes.defaultToolbar}>
          <NFeStatus size="big" />
        </Toolbar>
      );
    }

    return (
      <Toolbar {...props} className={classes.defaultToolbar}>
        <SaveButton />
        <NFeInutilizar />
        <NFeDuplicar />
        {record.id && <DeleteButton />}
      </Toolbar>
    );
  }

  if (NFeCancelada(record) || NFeAutorizada(record)) {
    return (
      <Toolbar {...props} className={classes.defaultToolbar}>
        <NFeStatus size="big" />
        <NFeCartaCorrecaoButton />
        <NFeCancelaButton />
        <NFeEnviaEmailButton />
        <NFeDownloadXMLButton />
        <NFeDownloadPDFButton />
        <NFeDownloadPDFCanceladaButton />
        <NFeDownloadPDFCceButton />
        <NFeDuplicar />
      </Toolbar>
    );
  }

  return (
    <Toolbar {...props} className={classes.defaultToolbar}>
      <NFeStatus size="big" />
      {NFeEmDigitacao(record) && <SaveButton />}
      <NFeEmitir />
      <NFeDownloadPDFEspelhoButton />
      <NFeDuplicar />
      {record.id && <DeleteButton />}
    </Toolbar>
  );
};

export default NFeToolbar;
