import ContadorArquivoList from "./ContadorArquivoList";
import AttachFileIcon from "@material-ui/icons/AttachFile";

const contador_arquivo = {
  getResource: (p) => {
    return {
      name: "Contador_arquivo",
      list: ContadorArquivoList,
    };
  },
  getMenu: (p) => ({
    key: "Contador_arquivo",
    to: "/Contador_arquivo",
    primaryText: "Contador arquivos",
    leftIcon: iconContadorArquivo,
  }),
};

export default contador_arquivo;
export const iconContadorArquivo = <AttachFileIcon />;
