import React, { useCallback, useState } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import { SaveButton, useRedirect, useRefresh } from "react-admin";
import { cleanCache, fetchJSON, save } from "../../../dataProvider";
import AlertDialog from "../../../common/AlertDialog";

const NFeDownloadPDFEspelhoButton = (props) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [persistedNfe, setPersistedNfe] = useState(null);

  const redirect = useRedirect();
  const refresh = useRefresh();

  const handleCloseAlert = () => {
    redirect(`/NFe_emissaos/${persistedNfe.id}`);
    refresh(true);
    setOpen(false);
  };

  const handleSave = useCallback(async (values) => {
    setMessage("Aguarde...");
    setOpen(true);
    try {
      const persistedRecord = await save("NFe_emissaos", values);
      cleanCache("NFe_emissaos");
      setPersistedNfe(persistedRecord);

      const url = await fetchJSON("Sefaz/nfeDanfeSemValidade", {
        method: "POST",
        body: {
          lojaId: persistedRecord.lojaId,
          nfeId: persistedRecord.id,
        },
      });
      const tab = window.open(url, "_blank");
      tab.focus();
      setOpen(false);
    } catch (exception) {
      console.log(JSON.stringify(exception));
      setMessage(exception.body.error.message);
    }
  }, []);

  return (
    <>
      <SaveButton
        {...props}
        label="Download Espelho"
        onSave={handleSave}
        icon={<GetAppIcon />}
      />
      <AlertDialog
        title="DANFE Sem Validade"
        message={message}
        open={open}
        handleClose={handleCloseAlert}
      />
    </>
  );
};

export default NFeDownloadPDFEspelhoButton;
