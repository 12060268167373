import { AutocompleteInput, required } from "react-admin";
import LazyReferenceInput from "../../common/form/LazyReferenceInput";
import Highlighted from "../../common/Highlighted";

const filter = (searchText) => {
  if (searchText.match(/^\d+$/)) {
    return {
      or: [
        { cod_prod: { like: `${searchText}%` } },
        { cod_aux: { like: `${searchText}%` } },
      ],
    };
  } else {
    return {
      or: [{ q: searchText }, { cod_aux: { like: `${searchText}%` } }],
    };
  }
};

const matchSuggestion = (filter, choice) => {
  const { cod_prod, cod_aux } = choice;
  if (filter.match(/^\d+$/)) {
    return (
      cod_prod.toString().startsWith(filter) ||
      (cod_aux && cod_aux.startsWith(filter))
    );
  } else {
    return true;
    // desc_prod.includes(filter) || (cod_aux && cod_aux.startsWith(filter))
  }
};

function ProdutoItem({ record, filterValue }) {
  record =
    record && record.id ? record : { cod_prod: "", cod_aux: "", desc_prod: "" };
  const { cod_prod, cod_aux, desc_prod } = record;
  return (
    <div>
      <Highlighted text={desc_prod} highlight={filterValue} />
      <br />
      <small>
        <Highlighted text={cod_prod.toString()} highlight={filterValue} />
      </small>
      {cod_aux && (
        <small>
          {" - "}
          <Highlighted text={cod_aux} highlight={filterValue} />
        </small>
      )}
    </div>
  );
}

const validateProd = [required()];

function ProdutoLazyReferenceInput({ nameSource, required, ...rest }) {
  return (
    <LazyReferenceInput
      reference="produtos"
      nameSource={nameSource || "produto"}
      filterToQuery={filter}
      minLength={0}
      limit={25}
      {...rest}
    >
      <AutocompleteInput
        validate={required ? validateProd : null}
        matchSuggestion={matchSuggestion}
        optionText={<ProdutoItem />}
        inputText={(record) => (record ? record.desc_prod : "")}
      />
    </LazyReferenceInput>
  );
}

export default ProdutoLazyReferenceInput;
