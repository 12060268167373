import React from "react";
import { Create } from "react-admin";

import MoedaTipoForm from "./MoedaTipoForm";
import MoedaTipoToolbar from "./MoedaTipoToolbar";

const MoedaTipoCreate = props => (
  <Create {...props}>
    <MoedaTipoForm toolbar={<MoedaTipoToolbar />} />
  </Create>
);

export default MoedaTipoCreate;
