import React from "react";
import { useRecordContext } from "react-admin";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Typography, Box, Stack } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import useFetch from "../../dataProvider/useFetch";
import LoadingButton from "../../common/LoadingButton";
import Decimal from "decimal.js";
import { formatCurrency } from "../../common/util/formatter";

const Detalhes = ({ uri, lojaId, id }) => {
  const { isLoading, data, error, updateSkip } = useFetch(
    uri,
    { lojaId, id },
    {},
    true
  );

  const handleClick = (e) => {
    updateSkip(false);
  };

  const keys = data ? Object.keys(data) : [];
  return (
    <Box mb={6}>
      {!data && (
        <LoadingButton
          text='ver'
          loading={isLoading}
          variant='text'
          onClick={handleClick}
          icon={<VisibilityIcon />}
        />
      )}

      {error && (
        <Typography variant='body2' color='error' sx={{ my: 2 }}>
          {error.message}
        </Typography>
      )}

      {data &&
        keys.length > 0 &&
        keys.map((k) => {
          const total = formatCurrency(
            data[k].reduce((t, i) => t.plus(i.total), Decimal(0)).toString()
          );
          return (
            <Accordion key={k}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  sx={{ width: "100%", pr: "1em" }}
                >
                  <Typography>{k}</Typography>
                  <Typography>{total}</Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer>
                  <Table aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell>Descrição</TableCell>
                        <TableCell align='right'>Qtd</TableCell>
                        <TableCell align='right'>R$ Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data[k].map((l) => (
                        <TableRow key={l.descricao}>
                          <TableCell component='th' scope='row'>
                            {l.descricao}
                          </TableCell>
                          <TableCell align='right'>{l.qtd}</TableCell>
                          <TableCell align='right'>{l.total}</TableCell>
                        </TableRow>
                      ))}

                      <TableRow sx={{ "td, th": { border: 0 } }}>
                        <TableCell align='right' colSpan={2}>
                          <strong>{`Total de ${k}`}</strong>
                        </TableCell>
                        <TableCell align='right'>
                          <strong>{total}</strong>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </Box>
  );
};

const CaixaRelatorio = () => {
  const record = useRecordContext();
  if (!record) return null;
  const { lojaId, id } = record;
  return <Detalhes lojaId={lojaId} id={id} uri='caixas/fechamento' />;
};

export default CaixaRelatorio;
