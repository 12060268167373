import React from "react";
import { TextInput, NumberInput, DateInput, Filter } from "react-admin";

const LogFilter = (props) => (
  <Filter {...props}>
    <NumberInput source="shopId" label="Loja id" alwaysOn />
    <TextInput source="stack_like" alwaysOn />
    <TextInput source="data_like" alwaysOn />
    <DateInput source="createdAt" showTime locales="pt-BR" />
  </Filter>
);

export default LogFilter;
