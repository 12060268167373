import React, { useState } from "react";
import { useNotify } from "react-admin";
import LoadingButton from "../../common/LoadingButton";
import SendIcon from "@material-ui/icons/Send";
import { fetchJSON } from "../../dataProvider";

const KdsPublicarButton = ({ record }) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  if (!record || !record.uuid) return null;

  const handleClick = async () => {
    try {
      setLoading(true);
      await fetchJSON("Kds/publicar", {
        method: "POST",
        body: {
          lojaId: record.lojaId,
          kdsId: record.id,
        },
      });
      notify("KDS publicado com sucesso!");
    } catch (err) {
      console.log(JSON.stringify(err));
      notify("Ocorreu um erro ao publicar o KDS", "warning");
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingButton
      text="Publicar KDS"
      icon={<SendIcon />}
      loading={loading}
      onClick={handleClick}
    />
  );
};

export default KdsPublicarButton;
