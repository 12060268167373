import { useForm, useFormState } from "react-final-form";
import { calculosPedido } from "./calculos";
import { set } from "lodash";

const useChangeCalc = () => {
  const form = useForm();
  const formState = useFormState();

  const changeCalc = (event) => {
    const formData = formState.values;
    if (event) {
      const name = event.target.name;
      const targetValue = event.target.value === "" ? null : event.target.value;
      set(formData, name, targetValue);
    }
    calculosPedido(form, formData);
  };

  return changeCalc;
};

export default useChangeCalc;
