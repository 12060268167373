import React from "react";
import { Create } from "react-admin";

import PerguntaForm from "./PerguntaForm";
import PerguntaToolbar from "./PerguntaToolbar";

const PerguntaCreate = props => (
  <Create {...props}>
    <PerguntaForm toolbar={<PerguntaToolbar />} />
  </Create>
);

export default PerguntaCreate;
