import React from "react";
import {
  Title,
  Error,
  useGetList,
  Create,
  Toolbar,
  SaveButton,
  Loading,
  Button,
  useRedirect,
} from "react-admin";
import ListIcon from "@material-ui/icons/List";
import { Box, Card } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../static/media/stone-pagamentos.png";
import PagarmeForm from "../resources/pagarme/PagarmeForm";

const useStyles = makeStyles((theme) => ({
  cover: {
    minHeight: "260px",
    height: "100%",
    backgroundColor: "#65A300",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
  },
  image: {},
}));

export function PagarmeLogo() {
  const classes = useStyles();

  return (
    <div className={classes.cover}>
      <img width={200} src={logo} className={classes.image} alt="Comprovante" />
    </div>
  );
}

function Pagarme(props) {
  const resource = "Pagarme_auth";
  const basePath = "/Pagarme_auth";
  const redirect = useRedirect();
  const { data, isLoading, error } = useGetList(resource, { limit: 1 });

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  const keys = Object.keys(data);
  const record = keys.length > 0 ? data[keys[0]] : null;

  const handleGoToListConnect = (event) => redirect(`/ConnectStone`);
  const handleGoToListApp = (event) => redirect(`/Transaction_pos`);

  return (
    <Card sx={{ display: "flex", p: 3 }}>
      <Title title="Integração com Stone / Pagar.me" />
      <Box sx={{ width: 1 / 4 }}>
        <PagarmeLogo />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", width: 3 / 4 }}>
        <Create
          basePath={basePath}
          resource={resource}
          record={record}
          component="div"
          title={" "}
        >
          <PagarmeForm
            redirect={false}
            toolbar={
              <Toolbar {...props}>
                <SaveButton />
                <Box mx={3}>
                  <Button
                    variant="contained"
                    label="Lista de Transações Connect"
                    size="big"
                    onClick={handleGoToListConnect}
                  >
                    <ListIcon />
                  </Button>
                </Box>
                <Box mx={3}>
                  <Button
                    variant="contained"
                    label="Lista de Transações App"
                    size="big"
                    onClick={handleGoToListApp}
                  >
                    <ListIcon />
                  </Button>
                </Box>
              </Toolbar>
            }
          />
        </Create>
      </Box>
    </Card>
  );
}

export default Pagarme;
