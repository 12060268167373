import React from "react";
import { TextInput, Filter, BooleanInput } from "react-admin";

const UsuarioVisitorFilter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label="Id" alwaysOn />
    <TextInput source="email_like" label="Email" alwaysOn />
    <BooleanInput source="emailVerified" label="Senha Criada" />
  </Filter>
);

export default UsuarioVisitorFilter;
