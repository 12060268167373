import React, { useState, useCallback } from "react";
import BackupIcon from "@material-ui/icons/Backup";
import { SaveButton, useRedirect, useRefresh } from "react-admin";
import { cleanCache, fetchJSON, save } from "../../../dataProvider";
import AlertDialog from "../../../common/AlertDialog";
import { NFeEmProcessamento } from "../calculos";

const NFeEmitir = (props) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [persistedNfe, setPersistedNfe] = useState(null);

  const redirect = useRedirect();
  const refresh = useRefresh();

  const handleCloseAlert = () => {
    redirect(`/NFe_emissaos/${persistedNfe.id}`);
    refresh(true);
    setOpen(false);
  };

  const handleSave = useCallback(async (values) => {
    setMessage("Aguarde...");
    setOpen(true);
    try {
      const persistedRecord = NFeEmProcessamento(values)
        ? values
        : await save("NFe_emissaos", values);
      cleanCache("NFe_emissaos");
      setPersistedNfe(persistedRecord);

      const nfe = await fetchJSON("Sefaz/emiteNFe", {
        method: "POST",
        body: { lojaId: persistedRecord.lojaId, nfeId: persistedRecord.id },
      });

      setMessage(`${nfe.cStat} - ${nfe.xMotivo}`);
    } catch (exception) {
      console.log(JSON.stringify(exception));
      setMessage(exception.body.error.message);
    }
  }, []);

  return (
    <>
      <SaveButton
        {...props}
        label="Emitir NFe"
        onSave={handleSave}
        icon={<BackupIcon />}
      />
      <AlertDialog
        title="Processando NFe"
        message={message}
        open={open}
        handleClose={handleCloseAlert}
      />
    </>
  );
};

export default NFeEmitir;
