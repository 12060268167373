import React, { useState } from "react";
import { withUser } from "../../common/util/hocs";
import { Button } from "react-admin";
import StoreIcon from "@material-ui/icons/Store";
import ProdutoMatrizFilialModalPreco from "./ProdutoMatrizFilialModalPreco";

function ProdutoMatrizFilialListButton({ loja, usuario, selectedIds }) {
  const [open, setOpen] = useState(false);

  if (usuario.lojas.length < 2) return null;

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  return (
    <>
      <Button onClick={handleOpen} color="primary" label="Enviar para Filiais">
        <StoreIcon />
      </Button>
      <ProdutoMatrizFilialModalPreco
        produtosIds={selectedIds}
        openDialog={open}
        handleClose={handleClose}
        loja={loja}
        usuario={usuario}
      />
    </>
  );
}

export default withUser(ProdutoMatrizFilialListButton);
