import React, { useState } from "react";
import { Title, useAuthenticated } from "react-admin";
import { Box, Card, CardHeader, CardContent, Grid } from "@mui/material";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Remove from "@material-ui/icons/Remove";
import get from "lodash.get";
import isEmpty from "lodash.isempty";
import uniq from "lodash.uniq";
import { Bar } from "react-chartjs-2";
import { fetchJSON } from "../dataProvider";
import UXDelay from "../dataProvider/uxdelay";
import { useStyles } from "./ReportDailyInvoicings/styles";
import Search from "./Search";
import { withUser } from "../common/util/hocs";
import { reportPDVEnable } from "../permissionsHelper";
import { formatCurrency } from "../common/util/formatter";
import Decimal from "decimal.js";
import TableReport from "../common/table/TableReport";
import { COMISSION_TYPES } from "./Search/ComissionType";
import ReportTotalCard from "./ReportTotalCard";

const COLOR_WITH_COMISSION = "#36A2EB";
const COLOR_WITHOUT_COMISSION = "#FF6384";

const ReportPartnerComission = ({ usuario, loja }) => {
  useAuthenticated();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [withCommissionTotals, setWithComissionTotals] = useState({});
  const [totals, setTotals] = useState({});

  async function retrieveReportData({
    lojaId,
    partners,
    startDate,
    endDate,
    partnerType,
  }) {
    setLoading(true);
    try {
      const sellersIds = partners.map((partner) => partner.id);
      const reportData = await UXDelay(
        fetchJSON(
          partnerType === COMISSION_TYPES.WAITER
            ? "Relatorio/pdv/servico_garcom"
            : "Relatorio/pdv/comissao_vendedor",
          null,
          {
            lojaId,
            sellersIds,
            startDate,
            endDate,
          }
        )
      );

      const barChartDataset = convertToDatasetByPartner(reportData);
      setWithComissionTotals(reportData.totalWithCommissionBySellerId);
      setTotals(reportData.totals);
      setData({
        chartData: barChartDataset,
        tableData: reportData.totalProductsBySellerId,
      });
    } catch (err) {
      console.log(JSON.stringify(err));
    } finally {
      setLoading(false);
    }
  }

  const summaryRowStyle = {
    fontWeight: "bold",
    border: "none",
  };

  const defaultColumns = [
    {
      title: "Produto",
      field: "productName",
      sorting: false,
      width: "40%",
      renderSummaryRow: (sellerId) => {
        return {
          value: "",
          style: summaryRowStyle,
        };
      },
    },
    {
      title: "Quantidade",
      field: "qtdTotal",
      sorting: false,
      renderSummaryRow: (sellerId) => {
        return {
          value: get(withCommissionTotals, `${sellerId}.qtdTotal`, "0"),
          style: summaryRowStyle,
        };
      },
    },
    {
      title: "R$ Total",
      field: "total",
      sorting: false,
      render: (rowData) => formatCurrency(rowData.total),
      renderSummaryRow: (sellerId) => {
        return {
          value: formatCurrency(
            get(withCommissionTotals, `${sellerId}.total`, "0")
          ),
          style: summaryRowStyle,
        };
      },
    },
    {
      title: "R$ Comissão",
      field: "commission",
      sorting: false,
      render: (rowData) => formatCurrency(rowData.commission),
      renderSummaryRow: (sellerId) => {
        return {
          value: formatCurrency(
            get(withCommissionTotals, `${sellerId}.commission`, "0")
          ),
          style: summaryRowStyle,
        };
      },
    },
  ];

  const barChartOptions = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    indexAxis: "y",
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => formatCurrency(context.parsed.x),
        },
      },
    },
  };

  const dataEmpty =
    isEmpty(data?.chartData) || !data?.chartData?.labels?.length;

  const chartHeight = Math.max(data?.chartData?.labels?.length * 70, 400);

  return (
    <Card className={classes.root}>
      <Title title="Relatório de comissão dos parceiros" />
      <CardContent>
        <Search
          fields={["partnerType", "dateRange", "timeRange", "partners"]}
          onSearch={retrieveReportData}
          loading={loading}
          dataEmpty={dataEmpty}
        />
        {!dataEmpty && (
          <div>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
              sx={{ my: 5 }}
            >
              <Grid item xs={3}>
                <ReportTotalCard
                  total={totals.total}
                  type="currency"
                  title={"Faturamento total"}
                  icon={ShoppingCartIcon}
                  color={"rgb(49, 112, 143)"}
                />
              </Grid>
              <Grid item xs={3}>
                <ReportTotalCard
                  total={totals.totalWithCommission}
                  percentage={totals.percentageWithComission}
                  type="currency"
                  title={"Faturamento com comissão"}
                  icon={ShoppingCartIcon}
                  color={COLOR_WITH_COMISSION}
                />
              </Grid>
              <Grid item xs={3}>
                <ReportTotalCard
                  total={totals.totalCommission}
                  percentage={totals.averagePpercentageComission}
                  type="currency"
                  title={"Total de comissão"}
                  icon={Remove}
                  color={COLOR_WITH_COMISSION}
                />
              </Grid>
            </Grid>

            <Grid container sx={{ my: 5 }}>
              <Grid item xs={12}>
                <Card className={classes.card}>
                  <CardHeader
                    className={classes.cardHeader}
                    title={"Totais de vendas por parceiro"}
                  />
                  <CardContent>
                    <Box height={chartHeight}>
                      <Bar data={data.chartData} options={barChartOptions} />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {Object.keys(data.tableData).map((sellerId) => (
              <Grid sx={{ my: 5 }} key={sellerId}>
                <Card className={classes.card}>
                  <CardHeader
                    className={classes.cardHeader}
                    title={get(data.tableData, `${sellerId}.0.sellerName`, "")}
                  />
                  <CardContent>
                    <TableReport
                      data={[...data.tableData[sellerId]]}
                      columns={defaultColumns}
                      renderSummaryRow={({ column }) =>
                        column.renderSummaryRow(sellerId)
                      }
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default withUser(ReportPartnerComission);

export const partnerCommission = {
  getMenu: (p, a) =>
    reportPDVEnable(p, a)
      ? {
          key: "partnerComission",
          to: "/relatorio/comissao-parceiro",
          primaryText: "Comissão dos parceiros",
        }
      : null,
};

function convertToDatasetByPartner(data) {
  const sellersIds = uniq(
    Object.keys(data?.totalWithoutCommissionBySellerId ?? {}).concat(
      Object.keys(data?.totalWithCommissionBySellerId ?? {})
    )
  );
  const labels = sellersIds.reduce((sellersNames, sellerId) => {
    const sellerName =
      get(
        data,
        `totalWithoutCommissionBySellerId.${sellerId}.sellerName`,
        null
      ) ??
      get(data, `totalWithCommissionBySellerId.${sellerId}.sellerName`, null) ??
      "";

    return sellerName ? sellersNames.concat(sellerName) : sellersNames;
  }, []);

  const datasets = [
    {
      label: "Vendas com comissão",
      data: sellersIds.reduce((sellersTotals, sellerId) => {
        const total = get(
          data,
          `totalWithCommissionBySellerId.${sellerId}.total`,
          0
        );
        return sellersTotals.concat(Decimal(total).toFixed(2));
      }, []),
      stack: "Stack_with_commission",
      backgroundColor: COLOR_WITH_COMISSION,
      datalabels: {
        display: true,
        anchor: "end",
        align: "left",
        formatter: (value, _) => formatCurrency(value),
        backgroundColor: COLOR_WITH_COMISSION,
        color: "white",
        borderRadius: 4,
        padding: 2,
        font: {
          weight: "bold",
        },
      },
    },
    {
      label: "Vendas sem comissão",
      data: sellersIds.reduce((sellersTotals, sellerId) => {
        const total = get(
          data,
          `totalWithoutCommissionBySellerId.${sellerId}.total`,
          0
        );
        return sellersTotals.concat(Decimal(total).toFixed(2));
      }, []),
      stack: "Stack_without_commission",
      backgroundColor: COLOR_WITHOUT_COMISSION,
      datalabels: {
        display: true,
        anchor: "end",
        align: "left",
        formatter: (value, _) => formatCurrency(value),
        backgroundColor: COLOR_WITHOUT_COMISSION,
        color: "white",
        borderRadius: 4,
        padding: 2,
        font: {
          weight: "bold",
        },
      },
    },
  ];

  return { labels, datasets };
}
