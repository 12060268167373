import React from "react";
import { Notification } from "react-admin";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  Box,
  Button,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Decimal from "decimal.js";
import CircularProgress from "../../common/CircularProgress";
import {
  formatCNPJCPF,
  formatCurrency,
  formatPhone,
  formatNumber,
} from "../../common/util/formatter";
import useFetch from "../../dataProvider/useFetch";

export default function PedidoDetails() {
  const { search } = useLocation();
  const params = queryString.parse(search);
  const uuid = params.uuid;
  const lojaId = params.lojaId;
  const {
    isLoading,
    data: orderData,
    error,
  } = useFetch("pedidos/pedidoDetalhes", { lojaId, uuid }, {});

  if (error) {
    return (
      <Container>
        <Box textAlign='center'>
          <h1>Sistema Pallas</h1>
          <h2>Pedido não encontrado.</h2>
        </Box>
        <Notification />
      </Container>
    );
  }

  if (isLoading || !orderData) {
    return (
      <Container>
        <Box textAlign='center'>
          <h1>Sistema Pallas</h1>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  const { loja, parceiro, itens } = orderData;

  const addressLine1 = loja.complemento
    ? `${loja.logradouro}, ${loja.numero} ${loja.complemento}`
    : `${loja.logradouro}, ${loja.numero}`;
  const addressLine2 = `${loja.bairro}, ${loja.cep}`;
  const addressLine3 = `${loja.cidade}, ${loja.uf}`;

  const discountExist = !itens.every((item) =>
    Decimal(item.valor_desconto).isZero()
  );
  const colSpan = discountExist ? 4 : 2;
  const totals =
    orderData.total_produtos === orderData.total_pedido
      ? [{ label: "Total do pedido", value: orderData.total_pedido }]
      : [
          { label: "Total do produtos", value: orderData.total_produtos },
          { label: "Frete", value: orderData.total_frete },
          { label: "Seguro", value: orderData.total_seguro },
          { label: "Outros", value: orderData.total_outro },
          { label: "Total do pedido", value: orderData.total_pedido },
        ].filter((t) => t.value > 0);

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item>
          <Box
            component='img'
            sx={{
              marginTop: "10px",
              maxHeight: "150px",
            }}
            src={loja.fotoUrl}
            alt={`Logotipo da loja ${loja.nome_fantasia}`}
          />
        </Grid>
        <Grid item display='flex' flexDirection='column'>
          <h2>{loja.nome_fantasia}</h2>
          <span>{`${loja.razao_social} - CNPJ ${formatCNPJCPF(
            loja.cnpj
          )}`}</span>
          <span>{addressLine1}</span>
          <span>{addressLine2}</span>
          <span>{addressLine3}</span>
          {loja.telephone && (
            <span>{`Telefone: ${formatPhone(loja.telephone)}`}</span>
          )}
          {loja.whatsapp && (
            <span>{`Whatsapp: ${formatPhone(loja.whatsapp)}`}</span>
          )}
        </Grid>
      </Grid>
      <Grid container display='flex' flexDirection='column' spacing={1}>
        <Grid item>
          <h2>{`Pedido de ${orderData.tipo}`}</h2>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <span>
                <strong>Parceiro: </strong>
                {parceiro.nome_fantasia}
              </span>
            </Grid>
            <Grid item>
              <span>
                <strong>Status: </strong>
                {orderData.status}
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <span>
            <strong>Observações do pedido: </strong>
            {orderData.observacao || "--"}
          </span>
        </Grid>
      </Grid>
      <hr />
      <Grid container display='flex' flexDirection='column' spacing={1}>
        <Grid item>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Produto</TableCell>
                <TableCell align='right'>Quantidade</TableCell>
                <TableCell align='right'>R$ Valor unit.</TableCell>
                <TableCell align='right'>R$ Total</TableCell>
                {discountExist && (
                  <>
                    <TableCell align='right'>R$ Desconto</TableCell>
                    <TableCell align='right'>R$ Valor item</TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {itens.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.descricao}</TableCell>
                  <TableCell align='right'>{item.quantidade}</TableCell>
                  <TableCell align='right'>
                    {formatNumber(item.valor_unitario)}
                  </TableCell>
                  <TableCell align='right'>
                    {formatNumber(item.valor_total)}
                  </TableCell>
                  {discountExist && (
                    <>
                      <TableCell align='right'>
                        {Decimal(item.valor_desconto).isZero()
                          ? ""
                          : formatNumber(item.valor_desconto)}
                      </TableCell>
                      <TableCell align='right'>
                        {formatNumber(item.valor_item)}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}
              {totals.map((total) => (
                <TableRow
                  key={total.label}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell colSpan={colSpan} sx={{ border: 0 }} />
                  <TableCell align='right'>
                    <strong>{total.label}</strong>
                  </TableCell>
                  <TableCell align='right'>
                    <strong>{formatCurrency(total.value)}</strong>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid item textAlign='center'>
          <Button
            sx={{ "@media print": { display: "none" } }}
            variant='contained'
            onClick={() => window.print()}
          >
            Imprimir
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
