import React from "react";
import {
  TabbedForm,
  FormTab,
  TextInput,
  DateInput,
  required,
  minLength,
  maxLength,
} from "react-admin";
import { withUser } from "../../common/util/hocs";
import TabAutorizacao from "./tabs/TabAutorizacao";
import NumberInputReadonly from "../../common/form/NumberInputReadonly";
import NumberInputSimple from "../../common/form/NumberInputSimple";
import { IdDates } from "../../common/form/ConditionalInputs";

const initialValues = {
  modelo: 55,
  emitida_em: new Date(),
};

const validateJustificativa = [required(), minLength(15), maxLength(255)];

function NFeForm(props) {
  const { record } = props;

  return (
    <TabbedForm {...props} initialValues={initialValues} submitOnEnter={false}>
      <FormTab label="Inutilização">
        <IdDates record={record} />
        <div />

        <DateInput source="emitida_em" label="Emitida em" required />
        <NumberInputReadonly label="Modelo" source="modelo" required />
        <NumberInputSimple label="Série" source="serie" required />
        <NumberInputSimple
          label="Número Inicial"
          source="consumidor"
          required
        />
        <NumberInputSimple label="Número Final" source="numero" required />
        <TextInput
          label="Justificativa"
          source="natureza_operacao"
          validate={validateJustificativa}
          fullWidth
          multiline
        />
      </FormTab>
      {record.xmlUrl && (
        <FormTab label="Autorização">
          <TabAutorizacao />
        </FormTab>
      )}
    </TabbedForm>
  );
}

export default withUser(NFeForm);
