import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";
import maxBy from "lodash/maxBy";
import React from "react";
import { Line } from "react-chartjs-2";

import { useStyles } from "./styles";

const WeekdayPerformanceChart = ({ chartData }) => {
  const classes = useStyles();
  const weekDays = {
    1: "domingo",
    2: "segunda",
    3: "terça",
    4: "quarta",
    5: "quinta",
    6: "sexta",
    7: "sábado",
  };
  const labels = Object.values(weekDays).map((day) => day.slice(0, 3));
  const qttPerWeekday = Object.keys(weekDays).reduce((ordersQtt, day) => {
    const data = chartData.find((item) => `${item.dayOfWeek}` === day);
    const orderQtt = data ? data.qtt : 0;
    ordersQtt.push(orderQtt);
    return ordersQtt;
  }, []);
  const bestDay = maxBy(chartData, "qtt");
  const data = {
    labels,
    datasets: [
      {
        label: "Pedidos por dia da semana",
        data: qttPerWeekday,
        backgroundColor: "rgba(255,99, 132, 0.7)",
        borderColor: "rgba(255,99, 132, 0.7)",
        yAxisID: "y",
        fill: true,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
        ticks: {
          beginAtZero: true,
        },
      },
    },
  };

  return (
    <Card className={classes.card}>
      <CardHeader className={classes.cardHeader} title={"Por dia"} />
      <CardContent>
        <Box display='flex' flexDirection='column' marginBottom={2}>
          <Typography variant='body2' color='textSecondary'>
            Melhor dia de vendas
          </Typography>
          <Typography variant='h5' className={classes.typographyImportant}>
            {weekDays[bestDay.dayOfWeek]}
          </Typography>
          <Typography
            variant='body2'
            color='textSecondary'
          >{`${bestDay.qtt} pedido(s) no total`}</Typography>
        </Box>
        <Box height={250}>
          <Line data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default WeekdayPerformanceChart;
