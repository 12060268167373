import React from "react";
import { Edit } from "react-admin";

import CestForm from "./CestForm";
import CestToolbar from "./CestToolbar";

const CestEdit = props => (
  <Edit {...props}>
    <CestForm toolbar={<CestToolbar />} />
  </Edit>
);

export default CestEdit;
