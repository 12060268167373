import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
  root: {
    minHeight: "100%",
  },
  card: {
    marginTop: "20px",
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
  },
  dailyDataCard: {
    maxWidth: 400,
    border: "1px solid #c5c5c5",
    borderBottom: "7px solid #283593",
  },
  cardHeader: {
    fontWeight: "bold",
    paddingBottom: "8px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.24)",
    "& .MuiCardHeader-content": {
      display: "flex",
      alignItems: "flex-end",
    },
    "& .MuiCardHeader-title": {
      marginRight: "0.5rem",
    },
  },
  totalCard: {
    maxWidth: 400,
    color: "rgb(255, 99, 132)",
  },
  typographyImportant: {
    fontWeight: "bold",
  },
  dataExplanationCard: {
    maxWidth: 250,
  },
});
