import { Card, CardContent } from "@material-ui/core";
import React, { useState } from "react";
import { Title, useAuthenticated, useNotify } from "react-admin";

import { fetchJSON } from "../../dataProvider";
import UXDelay from "../../dataProvider/uxdelay";
import InvoicingsCharts from "./InvoicingsCharts";
import { useStyles } from "./styles";
import Search from "../Search";
import isEmpty from "lodash.isempty";
import { reportCardapioDigitalEnable } from "../../permissionsHelper";

const ReportDailyInvoicings = () => {
  useAuthenticated();
  const [reportData, setReportData] = useState({});
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const notify = useNotify();

  async function retrieveReportData({
    lojaId,
    startDate,
    endDate,
    cdpModes,
    equipamentos,
  }) {
    try {
      setLoading(true);
      const retrievedReportData = await UXDelay(
        fetchJSON("Relatorio/cdp/faturamento_diario", null, {
          lojaId,
          startDate,
          endDate,
          modes: cdpModes,
          equipamentos,
        })
      );
      setReportData(retrievedReportData);
    } catch (error) {
      console.error(error);
      notify("Erro ao buscar os dados.", "warning");
    } finally {
      setLoading(false);
    }
  }

  const dataEmpty = isEmpty(reportData.general);

  return (
    <Card className={classes.root}>
      <Title title="Faturamento diário do Cardápio Digital" />
      <CardContent>
        <Search
          fields={["dateRange", "cdpModes", "equipamentos"]}
          onSearch={retrieveReportData}
          loading={loading}
          dataEmpty={dataEmpty}
        />
        {!dataEmpty && <InvoicingsCharts chartData={reportData} />}
      </CardContent>
    </Card>
  );
};

export default ReportDailyInvoicings;

export const reportDailyInvoicings = {
  getMenu: (p, a) => {
    return reportCardapioDigitalEnable(p, a)
      ? {
          key: "ReportDailyInvoicings",
          to: "/relatorio/daily_invoicings",
          primaryText: "Faturamento Diário",
        }
      : null;
  },
};
